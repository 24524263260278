/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import {Box, Chip, Stack, Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Button from '../shared/Button';
import { Trans } from 'react-i18next';
import {
  davoIntegrationsBottomPanel,
  davoIntegrationsImgContainer,
  sharedIntegrationDialogStyles,
  davoIntegrationsStyles
} from '../../jss/inlineStyles';
import _ from 'lodash';
import Checkbox from '../shared/Checkbox';
import { activateDavoPlan, deactivateDavoPlan, getDavoStatus  } from '../../actions/accountProfileActions';
import MessageDialog from '../shared/MessageDialog';
import routes from '../../constants/routes';
import {Link} from 'react-router-dom';
import DateUtil from '../util/DateUtil';

const DavoIntegrationDetails = (props) => {
  const {
    accountProfile,
    dispatch,
    t,
    user,
  } = props;

  const appRoutePrefix = globalApplicationLabel.path;

  const isDAVOConnected = accountProfile.davo?.is_integrated;
  const davoConnectedDate = DateUtil.formatDate(accountProfile.davo?.connected_since);
  const selectedMerchantAccount = _.find(user?.data?.merchantAccounts, {mea_id: Number(user?.selectedMerchantAccount)}) || null;
  const owner = accountProfile?.data?.principal;
  const businessAddress = accountProfile?.data?.business_address;
  const DBAName = accountProfile?.data?.corp_name;
  const mid = selectedMerchantAccount?.mid;

  const [secondStep, setSecondStep] = React.useState(false);
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);

  const label = LabelUtil.getLabel();
  const checkIcon = IconUtils.getIcon('CheckIcon', label.white, 'medium');
  const syncAltIcon = IconUtils.getIcon('SyncAltIcon', label.primaryColor, 'medium');
  const externalLinkIcon = IconUtils.getIcon('OpenInNewIcon', label.primaryColor);
  const percentIcon = IconUtils.getIcon('PercentIcon', label.primaryColor);
  const clearIcon = IconUtils.getIcon('Clear', label.primaryColor);
  const externalDocumentIcon = IconUtils.getIcon('DocumentIcon', label.primaryColor, 22);


  const tagsContainer = (tags) => {
    return (
      <Stack className={'tagsContainer'}
          direction='row'
          space={2}
      >
        {tags.map(tag => (
          <Chip
              className={'tag'}
              key={tag}
              label={tag}
          />
        ))}
      </Stack>
    );
  };

  const toggleSecondStep = () => {
    setSecondStep(!secondStep);
  };

  const toggleAgreeTerms = () => {
   setAgreeTerms(!agreeTerms);
  };

  const toggleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(!openConfirmationDialog);
  };

  const activateDavo = async () => {
    await dispatch(activateDavoPlan(user));
    await dispatch(getDavoStatus(user));
  };

  const deactivateDavo = async () => {
    await dispatch(deactivateDavoPlan(user));
    await dispatch(getDavoStatus(user));
  };

  const confirmationDialog = (
    <MessageDialog
        bodyText={
          <div className='davoDialogContent'>
            <img
                alt='DAVO logo'
                className='davoLogo'
                src='/images/davo/DAVO-Logo.svg'
            />
            <h3>{t('DavoIntegrationDetails.ConfirmationDialog.Title')}</h3>
            <p>{t('DavoIntegrationDetails.ConfirmationDialog.Body')}</p>
          </div>
        }
        cancelText={t('Cancel')}
        confirmText={t('DavoIntegrationDetails.ConfirmationDialog.Disconnect')}
        isChoiceRequired
        onConfirm={deactivateDavo}
        onRequestClose={toggleOpenConfirmationDialog}
        open={openConfirmationDialog}
    />
  );

  return (
    <Stack className='davoIntegrationDetails'
        spacing={2}
    >
      <Box>
        <p className='title'>{secondStep ? t('DavoIntegrationDetails.ConfirmTitle') : t('DavoIntegrationDetails.Title')}</p>
      </Box>

      {secondStep && (
        <div className={'confirmationHeader'}>
          <p className={'label top'}>{t('DavoIntegrationDetails.DBALabel')}</p>
          <Stack className={'boxed left'}
              spacing={1}
          >
            <p>{DBAName}</p>
          </Stack>
          <div className={'row'}>
            <p className={'label'}>{t('DavoIntegrationDetails.MID')}</p>
            <span>{mid}</span>
          </div>
          <div className={'row'}>
            <p className={'label'}>{t('DavoIntegrationDetails.Owner')}</p>
            <span>{owner}</span>
          </div>
          <div className={'row'}>
            <p className={'label'}>{t('DavoIntegrationDetails.BusinessAddress')}</p>
            <span>{businessAddress}</span>
          </div>
        </div>
      )}

      <Stack className={`boxed${secondStep ? ' secondStep' : ''}`}
          spacing={1}
      >
        <img alt='DAVO logo'
            className='davoLogo'
            src='/images/davo/DAVO-Logo.svg'
        />
        <p className='syncTitle'>{t('DavoIntegrationDetails.PaymentsHub')} {syncAltIcon} {t('DavoIntegrationDetails.Davo')}</p>
        {secondStep ? (
          <p className='boxBody'>{t('DavoIntegrationDetails.BottomBody')}</p>
        ) : (
          <p className='boxBody'>{t('DavoIntegrationDetails.BodyText')}</p>
        )}
        {!secondStep && tagsContainer([
          t('IntegrationsPage.Tags.Accounting'),
          t('IntegrationsPage.Tags.Tax')
        ])}
        <div className='divider' />
        {secondStep && (
          <Box
              className={'legal'}
              sx={davoIntegrationsStyles.legal}
          >
            <p className={'label'}>{t('DavoIntegrationDetails.Legal.Title')}</p>
            <Typography variant='primaryFontFamilyMedium'>
              {t('DavoIntegrationDetails.Legal.SubText')}
            </Typography>

            <List className={'decimal'}>
              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    sx={sharedIntegrationDialogStyles.listItemText}
                    variant='primaryFontFamilyBold'
                >
                  {t('DavoIntegrationDetails.Legal.Section1.Title')}
                </Typography>
              </ListItem>
              <List className={'latin'}>
                <ListItem
                    disableGutters
                    sx={{ display: 'list-item' }}
                >
                  <Typography
                      component='div'
                      variant='primaryFontFamilyMedium'
                  >
                    <Box
                        display='inline'
                        sx={sharedIntegrationDialogStyles.listItemText}
                    >
                      <Typography
                          variant='primaryFontFamilyBold'
                      >
                        {t('DavoIntegrationDetails.Legal.Section1.Item1')}
                      </Typography>
                    </Box>
                    {t('DavoIntegrationDetails.Legal.Section1.Item1Regular')}
                  </Typography>
                </ListItem>
                <ListItem
                    disableGutters
                    sx={{ display: 'list-item' }}
                >
                  <Typography
                      component='div'
                      variant='primaryFontFamilyMedium'
                  >
                    <Box
                        display='inline'
                        sx={sharedIntegrationDialogStyles.listItemText}
                    >
                      <Typography
                          variant='primaryFontFamilyBold'
                      >
                        {t('DavoIntegrationDetails.Legal.Section1.Item2')}
                      </Typography>
                    </Box>
                    {t('DavoIntegrationDetails.Legal.Section1.Item2Regular')}
                  </Typography>
                </ListItem>
                <ListItem
                    disableGutters
                    sx={{ display: 'list-item' }}
                >
                  <Typography
                      component='div'
                      variant='primaryFontFamilyMedium'
                  >
                    <Box
                        display='inline'
                        sx={sharedIntegrationDialogStyles.listItemText}
                    >
                      <Typography
                          variant='primaryFontFamilyBold'
                      >
                        {t('DavoIntegrationDetails.Legal.Section1.Item3')}
                      </Typography>
                    </Box>
                    {t('DavoIntegrationDetails.Legal.Section1.Item3Regular')}
                  </Typography>
                </ListItem>
              </List>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    sx={sharedIntegrationDialogStyles.listItemText}
                    variant='primaryFontFamilyBold'
                >
                  {t('DavoIntegrationDetails.Legal.Section2.Title')}
                </Typography>
              </ListItem>
              <List className={'latin'}>
                <ListItem
                    disableGutters
                    sx={{ display: 'list-item' }}
                >
                  <Typography
                      component='div'
                      variant='primaryFontFamilyMedium'
                  >
                    <Box
                        display='inline'
                        sx={sharedIntegrationDialogStyles.listItemText}
                    >
                      <Typography
                          variant='primaryFontFamilyBold'
                      >
                        {t('DavoIntegrationDetails.Legal.Section2.Item1')}
                      </Typography>
                    </Box>
                    {t('DavoIntegrationDetails.Legal.Section2.Item1Regular')}
                  </Typography>
                </ListItem>
                <ListItem
                    disableGutters
                    sx={{ display: 'list-item' }}
                >
                  <Typography
                      component='div'
                      variant='primaryFontFamilyMedium'
                  >
                    <Box
                        display='inline'
                        sx={sharedIntegrationDialogStyles.listItemText}
                    >
                      <Typography
                          variant='primaryFontFamilyBold'
                      >
                        {t('DavoIntegrationDetails.Legal.Section2.Item2')}
                      </Typography>
                    </Box>
                    {t('DavoIntegrationDetails.Legal.Section2.Item2Regular')}
                  </Typography>
                </ListItem>
                <List className={'roman'}>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section2.SubItem1')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section2.SubItem2')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section2.SubItem3')}
                    </Typography>
                  </ListItem>
                </List>
              </List>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    sx={sharedIntegrationDialogStyles.listItemText}
                    variant='primaryFontFamilyBold'
                >
                  {t('DavoIntegrationDetails.Legal.Section3.Title')}
                </Typography>
                <List className={'latin'}>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography
                            variant='primaryFontFamilyBold'
                        >
                          {t('DavoIntegrationDetails.Legal.Section3.Item1')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section3.Item1Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography
                            variant='primaryFontFamilyBold'
                        >
                          {t('DavoIntegrationDetails.Legal.Section3.Item2')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section3.Item2Regular')}
                    </Typography>
                  </ListItem>
                  <List className={'roman'}>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section3.SubItem1')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section3.SubItem2')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section3.SubItem3')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section3.SubItem4')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section3.SubItem5')}
                      </Typography>
                    </ListItem>
                  </List>
                </List>
              </ListItem>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    component='div'
                    variant='primaryFontFamilyMedium'
                >
                  <Box
                      display='inline'
                      sx={sharedIntegrationDialogStyles.listItemText}
                  >
                    <Typography variant='primaryFontFamilyBold'>
                      {t('DavoIntegrationDetails.Legal.Section4.Title')}
                    </Typography>
                  </Box>
                  {t('DavoIntegrationDetails.Legal.Section4.TitleRegular')}
                </Typography>
              </ListItem>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    component='div'
                    variant='primaryFontFamilyMedium'
                >
                  <Box
                      display='inline'
                      sx={sharedIntegrationDialogStyles.listItemText}
                  >
                    <Typography variant='primaryFontFamilyBold'>
                      {t('DavoIntegrationDetails.Legal.Section5.Title')}
                    </Typography>
                  </Box>
                  {t('DavoIntegrationDetails.Legal.Section5.TitleRegular')}
                </Typography>
                <List className={'latin'}>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section5.Item1')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section5.Item2')}
                    </Typography>
                  </ListItem>
                  <List className={'roman'}>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section5.SubItem1')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section5.SubItem2')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section5.SubItem3')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section5.SubItem4')}
                      </Typography>
                    </ListItem>
                  </List>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section5.Item3')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section5.Item4')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section5.Item5')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section5.Item6')}
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    component='div'
                    variant='primaryFontFamilyMedium'
                >
                  <Box
                      display='inline'
                      sx={sharedIntegrationDialogStyles.listItemText}
                  >
                    <Typography variant='primaryFontFamilyBold'>
                      {t('DavoIntegrationDetails.Legal.Section6.Title')}
                    </Typography>
                  </Box>
                  {t('DavoIntegrationDetails.Legal.Section6.TitleRegular')}
                </Typography>
                <List className={'latin'}>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section6.Item1')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section6.Item1Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section6.Item2')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section6.Item2Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section6.Item3')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section6.Item3Regular')}
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    component='div'
                    variant='primaryFontFamilyMedium'
                >
                  <Box
                      display='inline'
                      sx={sharedIntegrationDialogStyles.listItemText}
                  >
                    <Typography variant='primaryFontFamilyBold'>
                      {t('DavoIntegrationDetails.Legal.Section7.Title')}
                    </Typography>
                  </Box>
                  {t('DavoIntegrationDetails.Legal.Section7.TitleRegular')}
                </Typography>
                <List className={'latin'}>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section7.Item1')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section7.Item1Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section7.Item2')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section7.Item2Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section7.Item3')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section7.Item3Regular')}
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    component='div'
                    variant='primaryFontFamilyMedium'
                >
                  <Box
                      display='inline'
                      sx={sharedIntegrationDialogStyles.listItemText}
                  >
                    <Typography variant='primaryFontFamilyBold'>
                      {t('DavoIntegrationDetails.Legal.Section8.Title')}
                    </Typography>
                  </Box>
                  {t('DavoIntegrationDetails.Legal.Section8.TitleRegular')}
                </Typography>
                <List className={'latin'}>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section8.Item1')}
                    </Typography>
                  </ListItem>
                  <List className={'roman'}>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section8.SubItem1')}
                      </Typography>
                    </ListItem>
                    <ListItem
                        disableGutters
                        sx={{ display: 'list-item' }}
                    >
                      <Typography variant='primaryFontFamilyMedium'>
                        {t('DavoIntegrationDetails.Legal.Section8.SubItem2')}
                      </Typography>
                    </ListItem>
                  </List>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section8.Item2')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography variant='primaryFontFamilyMedium'>
                      {t('DavoIntegrationDetails.Legal.Section8.Item3')}
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>

              <ListItem
                  disableGutters
                  sx={{ display: 'list-item' }}
              >
                <Typography
                    sx={sharedIntegrationDialogStyles.listItemText}
                    variant='primaryFontFamilyBold'
                >
                  {t('DavoIntegrationDetails.Legal.Section9.Title')}
                </Typography>
                <List className={'latin'}>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section9.Item1')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section9.Item1Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section9.Item2')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section9.Item2Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section9.Item3')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section9.Item3Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section9.Item4')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section9.Item4Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section9.Item5')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section9.Item5Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section9.Item6')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section9.Item6Regular')}
                    </Typography>
                  </ListItem>
                  <ListItem
                      disableGutters
                      sx={{ display: 'list-item' }}
                  >
                    <Typography
                        component='div'
                        variant='primaryFontFamilyMedium'
                    >
                      <Box
                          display='inline'
                          sx={sharedIntegrationDialogStyles.listItemText}
                      >
                        <Typography variant='primaryFontFamilyBold'>
                          {t('DavoIntegrationDetails.Legal.Section9.Item7')}
                        </Typography>
                      </Box>
                      {t('DavoIntegrationDetails.Legal.Section9.Item7Regular')}
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>

            </List>
          </Box>
        )}

        {isDAVOConnected ? (
          <div className={'connectedContent'}>
            <div className={'activePlan'}>
              {checkIcon}
              <span>{t('Connected')}</span>
            </div>
            <div className={'text'}>
              <p>{t('DavoIntegrationDetails.ConnectedBottomText')}</p>
              <span className={'connectedDate'}>
                <Trans i18nKey={'DavoIntegrationDetails.ConnectedDate'}>
                  {{date: davoConnectedDate}}
                </Trans>
              </span>
            </div>
          </div>
        ) : !secondStep && (
          <p className='boxBottomBody'>
            {t('DavoIntegrationDetails.BottomBody')}
          </p>
        )}
      </Stack>

      {isDAVOConnected && (
        <div className={'section connectedLinks'}>
          <a
              className='davoPortalLink'
              href='https://portal.davosalestax.com/'
              rel='noopener noreferrer'
              target='_blank'
          >
            {t('DavoPortalLink')} {externalLinkIcon}
          </a>
          <a
              className='davoPortalLink'
              href='https://www.davosalestax.com/integrations/payanywhere/'
              rel='noopener noreferrer'
              target='_blank'
          >
            {t('DavoWebsiteLink')} {externalLinkIcon}
          </a>
          <a
              className='davoPortalLink'
              href='https://legal.avalara.com/product-terms.html#davo'
              rel='noopener noreferrer'
              target='_blank'
          >
            {t('DavoLegalLink')} {externalDocumentIcon}
          </a>
          <Link
              className={'davoPortalLink'}
              to={appRoutePrefix + routes.account.root + routes.account.settings}
          >
            {t('DavoTaxLink')} {percentIcon}
          </Link>
          <a
              className='davoPortalLink'
              id={'openConfirmationDialog'}
              onClick={toggleOpenConfirmationDialog}
              target='_blank'
          >
            {t('DavoDisconnectLink')} {clearIcon}
          </a>
        </div>
      )}

      {secondStep && (
        <div className={'actions'}>
          <Checkbox
              className={'termsCheckbox'}
              input={{value: agreeTerms, onChange: toggleAgreeTerms}}
              label={t('DavoIntegrationDetails.CheckboxText')}
          />
          <Button
              className={'termsButton'}
              disabled={!agreeTerms}
              label={t('Connect')}
              onClick={activateDavo}
          />
          <p>{t('DavoIntegrationDetails.ActionDisclaimer')}</p>
        </div>
      )}

      {!secondStep && (
        <>
          <Box className='section'>
            <p className='title'>{t('DavoIntegrationDetails.Section1.Title')}</p>
            <p className='body'>{t('DavoIntegrationDetails.Section1.Body')}</p>
          </Box>
          <Box className='section'>
            <p className='title'>{t('DavoIntegrationDetails.Section2.Title')}</p>
            <p className='body'>{t('DavoIntegrationDetails.Section2.Body')}</p>
          </Box>
          <Box className='section'>
            <p className='title'>{t('DavoIntegrationDetails.Section3.Title')}</p>
            <List>
              <ListItem className='listItem'>
                <ListItemIcon className='listItemIcon'><div>{checkIcon}</div></ListItemIcon>
                <ListItemText primary={<Trans i18nKey='DavoIntegrationDetails.Section3.Item1'>
                  <b>Time savings:</b> For just $54.99 per month per location DAVO manages sales tax from start to finish so you can save time and not worry about sales tax. Avoid the last minute sales tax scramble to file and pay.
                </Trans>}
                />
              </ListItem>
              <ListItem className='listItem'>
                <ListItemIcon className='listItemIcon'><div>{checkIcon}</div></ListItemIcon>
                <ListItemText primary={<Trans i18nKey='DavoIntegrationDetails.Section3.Item2'>
                  <b>Fast setup:</b> It installs in minutes — and it’s easy.
                </Trans>}
                />
              </ListItem>
              <ListItem className='listItem'>
                <ListItemIcon className='listItemIcon'><div>{checkIcon}</div></ListItemIcon>
                <ListItemText primary={<Trans i18nKey='DavoIntegrationDetails.Section3.Item3'>
                  <b>Easy to use:</b> Once DAVO is set up, your sales tax is on autopilot. Use the online dashboard to access past sales tax filing records and to make updates to your account as needed.
                </Trans>}
                />
              </ListItem>
            </List>
          </Box>
          <Box sx={davoIntegrationsImgContainer}>
            <img alt='DAVO site'
                src='/images/davo/davo-integrations.png'
            />
          </Box>
          {!isDAVOConnected && (
            <Box sx={davoIntegrationsBottomPanel}>
              <a className='davoPortalLink'
                  href='https://www.davosalestax.com/integrations/payanywhere/'
                  target='_blank'
              >{t('DavoWebsiteLink')} {externalLinkIcon}</a>
              <Box>
                <Button
                    className={'enableSecondStep'}
                    label={t('Connect')}
                    onClick={toggleSecondStep}
                />
              </Box>
            </Box>
          )}
        </>
      )}

      {confirmationDialog}
    </Stack>
  );
};

export default DavoIntegrationDetails;
