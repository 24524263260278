/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import moment from 'moment';
import Button from '../shared/Button';
import Select from '../shared/Select';
import MenuItem from '@mui/material/MenuItem';
import ReverseButton from '../shared/ReverseButton';
import InvoiceSummary from '../shared/enhancedInvoices/InvoiceSummary';
import {setVisibilityFilter} from '../../actions/userExperienceActions';
import Loading from '../Loading';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import PdfExporter from '../util/PdfExporter';
import {getCustomerInvoicesTransactions} from '../../actions/transactionsActions';
import {sendInvoiceNotification, createInvoiceReceiptPdf} from '../../actions/invoicesActions';
import {iconAdornmentStyles} from '../../jss/inlineStyles'
import withStyles from '@mui/styles/withStyles';
import UpdateSpinner from '../UpdateSpinner';
import CustomerPaymentHeadline from '../shared/enhancedInvoices/CustomerPaymentHeadline';
import actionTypes from '../../constants/actionTypes';
import NoPaymentHistory from '../icons/NoPaymentHistory';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LabelUtil from '../util/LabelUtil';

const StyledAdornment = withStyles(iconAdornmentStyles)(InputAdornment);

export default class PaymentHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      allSelected: false,
      yearFilter: 'All',
      searchFilter: '',
      sending: false
    }

    this.loadData = this.loadData.bind(this);
    this.selectInvoice = this.selectInvoice.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.downloadReceipt = this.downloadReceipt.bind(this);
    this.selectYearFilter = this.selectYearFilter.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.clearSearchFilter = this.clearSearchFilter.bind(this);
    this.sendInvoice = this.sendInvoice.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const {token} = this.props;
    this.props.dispatch(setVisibilityFilter('', 'search'));
    this.props.dispatch(getCustomerInvoicesTransactions(token));
  }

  selectInvoice(key) {
    let array = this.state.selected;
    if (array.includes(key)) {
      array = array.filter(item => item !== key);
      this.setState({selected: array});
    } else {
      array.push(key);
      this.setState({selected: array});
    }
  }

  clearSelected() {
    this.setState({selected: [], allSelected: false});
  }

  async downloadReceipt() {
    this.setState({sending: true});
    const {transactions, dispatch, token} = this.props;
    const {filteredData} = transactions;
    const {selected} = this.state;

    for (const receipt of selected) {
      const res = await dispatch(createInvoiceReceiptPdf(token, filteredData[receipt]))
      if (res.type === actionTypes.createInvoiceReceiptSuccess) {
        const data = res.response.data;
        await PdfExporter.download(data, `invoice-${filteredData[receipt].receipt_id}.pdf`)
      }
    }

    this.setState({sending: false});
    this.clearSelected();

  }

  selectYearFilter(event) {
    this.props.dispatch(setVisibilityFilter('', 'search'));
    const value = event?.target?.value;
    this.props.dispatch(setVisibilityFilter(
      {type: 'year', value }, 'secondaryFilter'));
    this.setState({yearFilter: value});
    this.clearSelected();
  }

  searchFilter(event) {
    const {searchFilter} = this.state;
    const value = event?.target?.value;
    this.setState({searchFilter: value});
    this.props.dispatch(setVisibilityFilter(value, 'search'));
  }

  clearSearchFilter() {
    this.setState({searchFilter: ''})
    this.props.dispatch(setVisibilityFilter('', 'search'));
  }

  async sendInvoice() {
    // PA-38310 hide send invoice (unhide button to allow functionality)
    this.setState({sending: true});
    const {selected} = this.state;
    const {dispatch, token, transactions, invoices} = this.props;
    const {filteredData} = transactions;
    const mappedInvoiceTransactions = filteredData?.length ? filteredData?.map((transaction) => {
      const transactionInvoice = invoices?.data
        ?.find((invoice) => invoice?.receipt_id === transaction?.receipt_id);
      return {
        ...transaction,
        invoice_id: Number(transactionInvoice?.id)
      }
    }) : [];
    const invoicesToSend = selected.map((inv) => mappedInvoiceTransactions[inv].invoice_id);
    await dispatch(sendInvoiceNotification(token, invoicesToSend));
    this.clearSelected();
    this.setState({sending: false});
  }

  renderItem(transaction, index) {
    const { selected } = this.state;
    const { invoices, t } = this.props;

    const transactionInvoice = invoices?.data
      ?.find((invoice) => invoice?.receipt_id === transaction?.receipt_id);

    const invoice = transactionInvoice && {
      name: transactionInvoice.name,
      total_amt: transaction.amount,
      payment_network: transaction.network,
      payment_first6: transaction.cc_first6,
      payment_last4: transaction.cc_last4,
      paid_date: transaction.inv_paid_date,
      statusList: { isPaid: true, isInvoiceFromSeries: false },
      series: null
    };

    return invoice ? (
      <InvoiceSummary
        invoice={invoice}
        key={index}
        showCheckbox={true}
        checkboxClick={() => this.selectInvoice(index)}
        checkboxInput={selected.includes(index)}
        t={t}
      />
    ) : null;

  }

  render() {

    const { yearFilter, searchFilter, sending, selected } = this.state;
    const { transactions, customers, loading, visibilityFilter, t, merchantSettings } = this.props;
    const {invoicesTransactions, filteredData} = transactions;
    const {search} = visibilityFilter
    const customerInfo = customers.customerPaymentData;
    const merchant = merchantSettings?.merchantSettings;
    const colorRender = merchant?.brand_color || LabelUtil.getLabelColor();

    if (loading) { return <Loading/>; }

    const availableYears = invoicesTransactions
      ?.map((transaction) => moment(transaction.inv_paid_date).format('MMM')); // TODO: Change to years, need a task

    const transactionsYears =
      [...new Set(availableYears)].map((year, index) => (
        <MenuItem key={index} value={year}>{year}</MenuItem>
      ));

    const dateFilter = (
      <div className='sectionLabel'>
        <span>{t('Select')}</span>
        <div className='select'>
          <Select
            className='noBorder'
            value={yearFilter}
            onChange={this.selectYearFilter}
            hideLabelContainer={true}>
            <MenuItem value='All'>{t('All')}</MenuItem>
            {transactionsYears}
          </Select>
        </div>
      </div>
    );

    const startIcon = (
      <InputAdornment position='start'>
        <SearchIcon/>
      </InputAdornment>
    );

    const endIcon = searchFilter ? (
      <StyledAdornment position='end' onClick={this.clearSearchFilter}>
        <CloseIcon/>
      </StyledAdornment>
    ) : null;

    const searchContent = (
      <div className='searchContainer'>
        <Input
          value={search}
          startAdornment={startIcon}
          endAdornment={endIcon}
          variant='standard'
          placeholder={t('PaymentHistory.SearchInvoices')}
          disableUnderline={true}
          onChange={this.searchFilter}/>
      </div>
    );

    const count = selected.length;
    const downloadLabel = count > 1 ? t('PaymentHistory.DownloadReceipts', { count }) : t('PaymentHistory.DownloadReceipt', { count });
    const sendLabel = count > 1 ? t('PaymentHistory.SendReceipts', { count }) : t('PaymentHistory.SendReceipt', { count });

    const selectedActionsContent = (
      <div className='selectedActionsContainer'>
        <ReverseButton label={t('Cancel')} className='grayBckBtn' onClick={this.clearSelected}/>
        <Button onClick={this.downloadReceipt} label={downloadLabel}/>
        <Button className='hideMe' onClick={this.sendInvoice} label={sendLabel}/>
      </div>
    );

    const emptyContent = (
      <Box
        data-test-id='payment-history-empty'
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <NoPaymentHistory
          color={colorRender}
          style={{
            marginBottom: '88px'
          }}
        />
        <Typography
          variant='h1'
          sx={{
            fontFamily: 'interSemiBold',
            fontSize: '30px',
            marginBottom: '15px',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '0 10px'
          }}
        >
          {t('PaymentHistory.NoPaymentHistoryTitle')}
        </Typography>
        <Typography
          variant='p'
          sx={{
            fontFamily: 'interMedium',
            fontSize: '17px',
            textAlign: 'center',
            maxWidth: '535px',
            marginBottom: '50px',
            padding: '0 10px'
          }}
        >
          {t('PaymentHistory.NoPaymentHistoryDescription')}
        </Typography>
      </Box>
    );

    const showContent = !!filteredData?.length || !!search;
    const emptyStateClass = showContent ? '' : 'customerPaymentSection_empty';

    return (
      <div className={`paymentHistory paymentHistorySection ${emptyStateClass}`}>
        {sending && <UpdateSpinner />}

        {showContent ? (
          <CustomerPaymentHeadline customerInfo={customerInfo}/>
        ) : null}

        {showContent ? (
          <div className='sectionTitle'>
            <h1>{t('PaymentHistory.Title')}</h1>
            {count ? selectedActionsContent : searchContent}
          </div>
        ) : null}

        {filteredData?.length ? dateFilter : null}
        {filteredData?.length ? filteredData.map(this.renderItem) : emptyContent}

      </div>
    );
  }

}
