/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { termsAndConditions } from '../../jss/termsAndConditions';

import _ from 'lodash';
import classNames from 'classnames';

class TermsAndConditions extends React.Component {

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.debouncedScroll = this.debouncedScroll.bind(this);
    this.state = {
      hasReachedBottom: false
    };
  }
  componentDidMount() {
    const element = this.scrollRef.current;
    this.debounceInstance = this.debouncedScroll();
    element?.addEventListener('scroll', this.debounceInstance);
  }

  componentWillUnmount() {
    const element = this.scrollRef.current;
    element?.removeEventListener('scroll', this.debounceInstance);
  }

  debouncedScroll() {
    const { markTermsAsRead } = this.props;
    return _.debounce(() => {
      const element = this.scrollRef.current;
      const { hasReachedBottom } = this.state;
      const { scrollTop, offsetHeight, scrollHeight } = element;

      if (!hasReachedBottom && (scrollHeight - offsetHeight - Math.ceil(scrollTop) < 1)) {
        this.setState({ hasReachedBottom: true }, () => {
          markTermsAsRead(true);
        });
      }
    },100);
  }

  render() {
    const { hasReachedBottom } = this.state;
    const { t } = this.props;
    return (
      <Box sx={termsAndConditions}>
        <Stack className='termsHeader'
            direction='row'
            justifyContent='space-between'
        >
          <p>{t('TermsAndConditions.TermsOfService')}</p>
          <p>{t('TermsAndConditions.UpdateTime')}</p>
        </Stack>
        <div>
          <div className='terms' ref={this.scrollRef}>
            Quas primas post initum Pontificatum dedimus ad universos sacrorum
            Antistites Encyclicas Litteras,
            meminimus in iis Nos aperte significasse — cum summas persequeremur
            earum calamitatum causas, quibus premi
            hominum genus conflictarique videremus — non modo eiusmodi malorum
            colluviem in orbem terrarnm idcirco invasisse
            quod pleriqne mortalium Iesum Christum sanctissimamque eius legem
            cum
            a sua ipsorum consuetudine et vita, tum a
            convictu domestico et a re publica submoverant; sed etiam fore
            nunquam
            ut mansurae inter populos pacis spes certa
            affulgeret, usque dum et homines singuli et civitates Salvatoris
            Nostri imperium abnuerent ac recusarent. Itaque
            pacem Christi ut quaerendam in regno Christi monuimns, ita Nos,
            quantum licuisset, praestituros ediximus: in regno Christi,
            inquimus, quippe Nobis videbamur ad pacem redintegrandam
            stabiliendamque non posse efficacius, quam, Domini Nostri imperio
            instaurando, contendere. Haud obscuram quidem exspectationem
            meliorum
            temporum Nobis interea moverunt studia populorum illa in
            Porro quotquot, sacri temporis decursu, in Urbem undique, Antistitum
            sacerdotumve suorum ductu, concessere, quid iis omnibus
            consilii fuit, nisi ut, expiatis rite animis, ad Apostolorum
            sepulcra
            et coram Nobis, se in imperio Christi et esse et futuros
            profiterentur?
            ita enim fit, ut vitae Iesu Christi mysteria ante per annum
            commemorata sacris Christi Regia sollemnibus veluti
            absolvantur et cumulentur, et, ante quam Omnium Sanctorum gloriam
            celebremus, Illius praedicetur efferaturque gloria
            , qui in omnibus Sanctis et electis triumphat. Itaque hoc vestrum,
            Venerabiles Fratres, esto munus, vestrae hae partes
            sunto, ut annuae celebritati praemittendas curetis, statis diebus,
            ad
            populum e singulis paroeciis contiones, quibus is
            de rei natura, significatione et momento accurate monitus atque
            eruditus, sic vitam instituat ac componat, ut iis digna sit,
            qui divini Regis imperio fideliter studioseque obsequuntur.

            Placet interea vobis, Venerabiles Fratres, in extremis hisce
            Litteris
            breviter declarare, quas demum publico ex hoc Christi Regis cultu
            utilitates, cum in Ecclesiae et civilis societatis, tum in
            singulorum
            fidelium bonum,
            Nobis spondeamus ac polliceamur. Hisce profecto honoribus dominico
            principatui deferendis in memoriam hominum redigi necesse est,
            Ecclesiam, utpote quae a
            C hristo perfecta societas constituta sit, nativo sane iure, quod
            abdicare nequit, plenam libertatem immunitatemque a civili potestate
            exposcere, eandemque,
            in obeundo munere sibi commisso divinitus docendi, regundi et ad
            aeternam perducendi beatitatem eos universos qui e regno Christi
            sunt,
            ex alieno arbitrio pendere non posse.
            Immo haud dissimilem debet praeterea respublica libertatem iis
            praestare religiosorum utriusque sexus Ordinibus ac Sodalitatibus,
            qui, cum adiutores Ecclesiae
            Pastoribus adsint validissimi, tum in regno Christi provehendo
            stabiliendove quam maxime elaborant, sive triplicem mundi
            concupiscentiam sacrorum religione votorum oppugnantes,
            sive ipsa perfectioris vitae professione efficientes, ut sanctitas
            illa, quam divinus Conditor insignitam Ecclesiae notam esse iussit,
            perpetuo auctoque in dies splendore ante
            oculos omnium emicet et colluceat.

            Placet interea vobis, Venerabiles Fratres, in extremis hisce
            Litteris
            breviter declarare, quas demum publico ex hoc Christi Regis cultu
            utilitates, cum in Ecclesiae et civilis societatis, tum in
            singulorum
            fidelium bonum,
            Nobis spondeamus ac polliceamur. Hisce profecto honoribus dominico
            principatui deferendis in memoriam hominum redigi necesse est,
            Ecclesiam, utpote quae a
            C hristo perfecta societas constituta sit, nativo sane iure, quod
            abdicare nequit, plenam libertatem immunitatemque a civili potestate
            exposcere, eandemque,
            in obeundo munere sibi commisso divinitus docendi, regundi et ad
            aeternam perducendi beatitatem eos universos qui e regno Christi
            sunt,
            ex alieno arbitrio pendere non posse.
            Immo haud dissimilem debet praeterea respublica libertatem iis
            praestare religiosorum utriusque sexus Ordinibus ac Sodalitatibus,
            qui, cum adiutores Ecclesiae
            Pastoribus adsint validissimi, tum in regno Christi provehendo
            stabiliendove quam maxime elaborant, sive triplicem mundi
            concupiscentiam sacrorum religione votorum oppugnantes,
            sive ipsa perfectioris vitae professione efficientes, ut sanctitas
            illa, quam divinus Conditor insignitam Ecclesiae notam esse iussit,
            perpetuo auctoque in dies splendore ante
            oculos omnium emicet et colluceat.

            Placet interea vobis, Venerabiles Fratres, in extremis hisce
            Litteris
            breviter declarare, quas demum publico ex hoc Christi Regis cultu
            utilitates, cum in Ecclesiae et civilis societatis, tum in
            singulorum
            fidelium bonum,
            Nobis spondeamus ac polliceamur. Hisce profecto honoribus dominico
            principatui deferendis in memoriam hominum redigi necesse est,
            Ecclesiam, utpote quae a
            C hristo perfecta societas constituta sit, nativo sane iure, quod
            abdicare nequit, plenam libertatem immunitatemque a civili potestate
            exposcere, eandemque,
            in obeundo munere sibi commisso divinitus docendi, regundi et ad
            aeternam perducendi beatitatem eos universos qui e regno Christi
            sunt,
            ex alieno arbitrio pendere non posse.
            Immo haud dissimilem debet praeterea respublica libertatem iis
            praestare religiosorum utriusque sexus Ordinibus ac Sodalitatibus,
            qui, cum adiutores Ecclesiae
            Pastoribus adsint validissimi, tum in regno Christi provehendo
            stabiliendove quam maxime elaborant, sive triplicem mundi
            concupiscentiam sacrorum religione votorum oppugnantes,
            sive ipsa perfectioris vitae professione efficientes, ut sanctitas
            illa, quam divinus Conditor insignitam Ecclesiae notam esse iussit,
            perpetuo auctoque in dies splendore ante
            oculos omnium emicet et colluceat.

            Placet interea vobis, Venerabiles Fratres, in extremis hisce
            Litteris
            breviter declarare, quas demum publico ex hoc Christi Regis cultu
            utilitates, cum in Ecclesiae et civilis societatis, tum in
            singulorum
            fidelium bonum,
            Nobis spondeamus ac polliceamur. Hisce profecto honoribus dominico
            principatui deferendis in memoriam hominum redigi necesse est,
            Ecclesiam, utpote quae a
            C hristo perfecta societas constituta sit, nativo sane iure, quod
            abdicare nequit, plenam libertatem immunitatemque a civili potestate
            exposcere, eandemque,
            in obeundo munere sibi commisso divinitus docendi, regundi et ad
            aeternam perducendi beatitatem eos universos qui e regno Christi
            sunt,
            ex alieno arbitrio pendere non posse.
            Immo haud dissimilem debet praeterea respublica libertatem iis
            praestare religiosorum utriusque sexus Ordinibus ac Sodalitatibus,
            qui, cum adiutores Ecclesiae
            Pastoribus adsint validissimi, tum in regno Christi provehendo
            stabiliendove quam maxime elaborant, sive triplicem mundi
            concupiscentiam sacrorum religione votorum oppugnantes,
            sive ipsa perfectioris vitae professione efficientes, ut sanctitas
            illa, quam divinus Conditor insignitam Ecclesiae notam esse iussit,
            perpetuo auctoque in dies splendore ante
            oculos omnium emicet et colluceat.
          </div>
          <div className={classNames('scrollToBottom', { hidden: hasReachedBottom } )}>
            {t('TermsAndConditions.ScrollDown')}
          </div>
        </div>
      </Box>
    );
  }
}

export default TermsAndConditions;
