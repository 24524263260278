/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import moment from 'moment';
import momentTimezone from 'moment-timezone';
import dateFilterTypes from '../../constants/dateFilterTypes';

const DateUtils = {
  getToday: function () {

    let start = moment().startOf('day');
    let end = moment().endOf('day');
    let previousStart = start.clone().subtract(1, 'week');
    let previousEnd = end.clone().subtract(1, 'week');

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1d',
      text: dateFilterTypes.TODAY
    };

  },

  getMomentDate: (date) => {
    return moment(date);
  },

  getUnixTimestamp: (date) => {
    return moment(date).unix();
  },

  getMomentDateFromUnix: (timeStamp) => {
    return moment.unix(timeStamp);
  },

  getTodayWithoutTime: () => {
    return moment().startOf('day');
  },

  getPersonalizedToday: function (date) {
    const start = moment().startOf('day');
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);

      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
    } else {
      start.hours(0);
      start.minutes(0);
      start.seconds(0);
    }
    const end = start.clone().add({hours: 23, minutes: 59, seconds: 59});
    const previousStart = start.clone().subtract(1, 'week');
    const previousEnd = end.clone().subtract(1, 'week');
    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1d',
      text: dateFilterTypes.TODAY
    };
  },

  getYesterday: function () {

    let start = moment().subtract(1, 'day').startOf('day');
    let end = moment().subtract(1, 'day').endOf('day');
    let previousStart = start.clone().subtract(1, 'week');
    let previousEnd = end.clone().subtract(1, 'week');

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '2d',
      text: dateFilterTypes.YESTERDAY
    };

  },

  getPersonalizedYesterday: function (date) {
    const start = moment().subtract(1, 'day').startOf('day');
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);

      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
    } else {
      start.hours(0);
      start.minutes(0);
      start.seconds(0);
    }
    const end = start.clone().add({hours: 23, minutes: 59, seconds: 59});
    const previousStart = start.clone().subtract(1, 'week');
    const previousEnd = end.clone().subtract(1, 'week');

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '2d',
      text: dateFilterTypes.YESTERDAY
    };
  },

  getThisWeek: function () {

    let start = moment().startOf('week').startOf('day');
    let end = moment().endOf('week').endOf('day');
    let previousStart = start.clone().subtract(1, 'week');
    let previousEnd = end.clone().subtract(1, 'week');

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1wk',
      text: dateFilterTypes.THIS_WEEK
    };

  },

  getPersonalizedThisWeek: function (date) {
    const result = {
      value: '1wk',
      text: dateFilterTypes.THIS_WEEK
    };
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);
      const start = moment().startOf('week').startOf('day');
      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
      let end = moment().endOf('week').endOf('day');
      if (!(hours === 0 && minutes === 0)) {
        end = moment().startOf('week');
        end.hours(hours);
        end.minutes(minutes);
        end.seconds(seconds);
        end.add({week: 1});
        end.subtract({seconds: 1});
      }
      const previousStart = start.clone().subtract(1, 'week');
      const previousEnd = end.clone().subtract(1, 'week');

      return {
        ...result,
        startDate: start,
        endDate: end,
        previousStartDate: previousStart,
        previousEndDate: previousEnd,
      };
    } else {
      const startDate = moment().startOf('week').startOf('day');
      const endDate = moment().endOf('week').endOf('day');
      return {
        ...result,
        startDate,
        endDate,
        previousStartDate: startDate.clone().subtract(1, 'week'),
        previousEndDate: endDate.clone().subtract(1, 'week')
      };
    }

  },

  getThisMonth: function () {

    let start = moment().startOf('month').startOf('day');
    let end = moment().endOf('month').endOf('day');
    let previousStart = moment().subtract(1, 'month').startOf('month').startOf('day');
    let previousEnd = moment().subtract(1, 'month').endOf('month').endOf('day');

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1mo',
      text: dateFilterTypes.THIS_MONTH
    };

  },

  getPersonalizedThisMonth: function (date) {
    const result = {
      value: '1mo',
      text: dateFilterTypes.THIS_MONTH
    };
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);
      const start = moment().startOf('month');
      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
      let end = moment().endOf('month');
      if (!(hours === 0 && minutes === 0)) {
        end = moment().startOf('month');
        end.hours(hours);
        end.minutes(minutes);
        end.seconds(seconds);
        end.add({month: 1});
        end.subtract({seconds: 1});
      }
      let previousStart = start.clone().subtract(1, 'month');
      let previousEnd = end.clone().subtract(1, 'month');

      return {
        ...result,
        startDate: start,
        endDate: end,
        previousStartDate: previousStart,
        previousEndDate: previousEnd,
      };
    } else {
      const start = moment().startOf('month');
      const end = moment().endOf('month');
      return {
        ...result,
        startDate: start,
        endDate: end,
        previousStartDate: start.clone().subtract(1, 'month'),
        previousEndDate: end.clone().subtract(1, 'month'),
      };
    }

  },

  getThisYear: function () {

    let start = moment().startOf('year').startOf('day');
    let end = moment().endOf('year').endOf('day');
    let previousStart = start.clone().subtract(1, 'year');
    let previousEnd = end.clone().subtract(1, 'year');

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1yr',
      text: dateFilterTypes.THIS_YEAR
    };

  },

  getPersonalizedThisYear: function (date) {
    const result = {
      value: '1yr',
      text: dateFilterTypes.THIS_YEAR
    };
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);
      const start = moment().startOf('year').startOf('day');
      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
      let end = moment().endOf('year').endOf('day');
      if (!(hours === 0 && minutes === 0)) {
        end = moment().startOf('year');
        end.hours(hours);
        end.minutes(minutes);
        end.seconds(seconds);
        end.add({year: 1});
        end.subtract({seconds: 1});
      }
      let previousStart = start.clone().subtract(1, 'year');
      let previousEnd = end.clone().subtract(1, 'year');

      return {
        ...result,
        startDate: start,
        endDate: end,
        previousStartDate: previousStart,
        previousEndDate: previousEnd
      };
    } else {
      const startDate = moment().startOf('year').startOf('day');
      const endDate = moment().endOf('year').endOf('day');
      return {
        ...result,
        startDate,
        endDate,
        previousStartDate: startDate.clone().subtract(1, 'year'),
        previousEndDate: endDate.clone().subtract(1, 'year')
      };
    }

  },

  getLastWeek: function () {
    let start = moment().startOf('week').startOf('day');
    let end = moment().endOf('week').endOf('day');
    let lastStart = start.clone().subtract(1, 'week');
    let lastEnd = end.clone().subtract(1, 'week');
    let previousStart = lastStart.clone().subtract(1, 'week');
    let previousEnd = lastEnd.clone().subtract(1, 'week');

    return {
      startDate: lastStart,
      endDate: lastEnd,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1wk',
      text: dateFilterTypes.LAST_WEEK
    };

  },

  getPersonalizedLastWeek: function (date) {
    const result = {
      value: '1wk',
      text: dateFilterTypes.LAST_WEEK
    };

    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);
      const start = moment().startOf('week').startOf('day');
      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
      let end = moment().endOf('week').endOf('day');
      if (!(hours === 0 && minutes === 0)) {
        end = moment().startOf('week');
        end.hours(hours);
        end.minutes(minutes);
        end.seconds(seconds);
        end.add({week: 1});
        end.subtract({seconds: 1});
      }
      const lastStart = start.clone().subtract(1, 'week');
      const lastEnd = end.clone().subtract({week: 1});
      const previousStart = lastStart.clone().subtract(1, 'week');
      const previousEnd = lastEnd.clone().subtract(1, 'week');

      return {
        ...result,
        startDate: lastStart,
        endDate: lastEnd,
        previousStartDate: previousStart,
        previousEndDate: previousEnd
      };
    } else {
      const startDate = moment().startOf('week').startOf('day').subtract(1, 'week');
      const endDate = moment().endOf('week').endOf('day').subtract({week: 1});
      return {
        ...result,
        startDate,
        endDate,
        previousStartDate: startDate.clone().subtract(1, 'week'),
        previousEndDate: endDate.clone().subtract(1, 'week')
      };
    }

  },

  getLastActiveWeek: function () {

    let end = moment().endOf('day');
    let start = end.clone().subtract(1, 'week').startOf('week');
    let previousEnd = end.clone().subtract(1, 'week').startOf('day');
    let previousStart = previousEnd.clone().subtract(1, 'week').startOf('week');

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1w',
      text: dateFilterTypes.LAST_ACTIVE_WEEK
    };

  },

  getPersonalizedLastActiveWeek: function (date) {
    const result = {
      value: '1w',
      text: dateFilterTypes.LAST_ACTIVE_WEEK
    };
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);
      let end = moment().endOf('day');
      let start = end.clone().subtract(1, 'week').startOf('week');
      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
      if (!(hours === 0 && minutes === 0)) {
        end = start.clone();
        end.hours(hours);
        end.minutes(minutes);
        end.seconds(seconds);
        end.add({week: 1});
        end.subtract({seconds: 1});
      }
      const previousEnd = end.clone().subtract(1, 'week');
      const previousStart = previousEnd.clone().subtract(1, 'week');

      return {
        ...result,
        startDate: start,
        endDate: end,
        previousStartDate: previousStart,
        previousEndDate: previousEnd
      };
    } else {
      const endDate = moment().endOf('day');
      const startDate = endDate.clone().subtract(1, 'week').startOf('week');
      const previousEndDate = endDate.clone().subtract(1, 'week');
      const previousStartDate = previousEndDate.clone().subtract(1, 'week');
      return {
        ...result,
        startDate,
        endDate,
        previousStartDate,
        previousEndDate
      };
    }

  },

  getLastMonth: function () {

    let lastStart = moment().subtract(1, 'month').startOf('month').startOf('day');
    let lastEnd = moment().subtract(1, 'month').endOf('month').endOf('day');
    let previousStart = moment().subtract(2, 'month').startOf('month').endOf('day');
    let previousEnd = moment().subtract(2, 'month').endOf('month').endOf('day');

    return {
      startDate: lastStart,
      endDate: lastEnd,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1mo',
      text: dateFilterTypes.LAST_MONTH
    };

  },

  getPersonalizedLastMonth: function (date) {
    const result = {
      value: '1mo',
      text: dateFilterTypes.LAST_MONTH
    };
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);
      let lastStart = moment().subtract(1, 'month').startOf('month').startOf('day');
      lastStart.hours(hours);
      lastStart.minutes(minutes);
      lastStart.seconds(seconds);
      let lastEnd = moment().subtract(1, 'month').endOf('month').endOf('day');

      if (!(hours === 0 && minutes === 0)) {
        lastEnd = moment().subtract(1, 'month').startOf('month');
        lastEnd.hours(hours);
        lastEnd.minutes(minutes);
        lastEnd.seconds(seconds);
        lastEnd.add({month: 1});
        lastEnd.subtract({seconds: 1});
      }

      let previousStart = moment().subtract(2, 'month').startOf('month').endOf('day');
      previousStart.hours(hours);
      previousStart.minutes(minutes);
      previousStart.seconds(seconds);
      let previousEnd = moment().subtract(2, 'month').endOf('month').endOf('day');
      previousEnd.hours(hours);
      previousEnd.minutes(minutes);
      previousEnd.seconds(seconds);

      return {
        ...result,
        startDate: lastStart,
        endDate: lastEnd,
        previousStartDate: previousStart,
        previousEndDate: previousEnd
      };
    } else {
      const startDate =moment().subtract(1, 'month').startOf('month').startOf('day');
      const endDate = moment().subtract(1, 'month').endOf('month').endOf('day');
      const previousStartDate = moment().subtract(2, 'month').startOf('month').endOf('day');
      const previousEndDate = moment().subtract(2, 'month').endOf('month').endOf('day');
      return {
        ...result,
        startDate,
        endDate,
        previousStartDate,
        previousEndDate
      };
    }

  },

  getLastYear: function () {

    let start = moment().startOf('year').startOf('day');
    let end = moment().endOf('year').endOf('day');
    let lastStart = start.clone().subtract(1, 'year');
    let lastEnd = end.clone().subtract(1, 'year');
    let previousStart = lastStart.clone().subtract(1, 'year');
    let previousEnd = lastEnd.clone().subtract(1, 'year');

    return {
      startDate: lastStart,
      endDate: lastEnd,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '1yr',
      text: dateFilterTypes.LAST_YEAR
    };

  },

  getPersonalizedLastYear: function (date) {
    const result = {
      value: '1yr',
      text: dateFilterTypes.LAST_YEAR
    };
    if (date) {
      const time = date.split(':');
      const hours = parseInt(time[0]);
      const minutes = parseInt(time[1]);
      const seconds = parseInt(time[2]);
      let start = moment().startOf('year').startOf('day');
      start.hours(hours);
      start.minutes(minutes);
      start.seconds(seconds);
      let end = moment().endOf('year').endOf('day');
      if (!(hours === 0 && minutes === 0)) {
        end = moment().startOf('year');
        end.hours(hours);
        end.minutes(minutes);
        end.seconds(seconds);
        end.add({year: 1});
        end.subtract({seconds: 1});
      }
      let lastStart = start.clone().subtract(1, 'year');
      let lastEnd = end.clone().subtract({year: 1});
      let previousStart = lastStart.clone().subtract(1, 'year');
      let previousEnd = lastEnd.clone().subtract(1, 'year');

      return {
        ...result,
        startDate: lastStart,
        endDate: lastEnd,
        previousStartDate: previousStart,
        previousEndDate: previousEnd
      };
    } else {
      const startDate = moment().startOf('year').startOf('day').subtract(1, 'year');
      const endDate = moment().endOf('year').endOf('day').subtract({year: 1});
      return {
        ...result,
        startDate,
        endDate,
        previousStartDate: startDate.clone().subtract(1, 'year'),
        previousEndDate: endDate.clone().subtract(1, 'year')
      };
    }
  },

  getPreviousMonth: function (date) {
    let lastStart = moment(date.startDate).subtract(1, 'month');
    let lastEnd = moment(date.endDate).subtract(1, 'month');
    let previousStart = lastStart.clone().subtract(1, 'month');
    let previousEnd = lastEnd.clone().subtract(1, 'month');

    return {
      startDate: lastStart,
      endDate: lastEnd,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '-1mo',
      text: dateFilterTypes.PREVIOUS_MONTH
    };
  },

  getPreviousThreeMonths: function (date) {
    let lastStart = moment(date.startDate).subtract(3, 'month');
    let lastEnd = moment(date.endDate).subtract(3, 'month');
    let previousStart = lastStart.clone().subtract(3, 'month');
    let previousEnd = lastEnd.clone().subtract(3, 'month');

    return {
      startDate: lastStart,
      endDate: lastEnd,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '-3mo',
      text: dateFilterTypes.PREVIOUS_THREE_MONTHS
    };
  },

  getPreviousSixMonths: function (date) {
    let lastStart = moment(date.startDate).subtract(6, 'month');
    let lastEnd = moment(date.endDate).subtract(6, 'month');
    let previousStart = lastStart.clone().subtract(6, 'month');
    let previousEnd = lastEnd.clone().subtract(6, 'month');

    return {
      startDate: lastStart,
      endDate: lastEnd,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: '-6mo',
      text: dateFilterTypes.PREVIOUS_SIX_MONTHS
    };
  },

  getDifference: function (startDate, endDate, type) {
    const startDateValue = moment(startDate);
    const endDateValue = moment(endDate);

    return endDateValue.diff(startDateValue, type);
  },

  extractTime: function (date) {

    return moment(date).format('hh:mm a');

  },

  extractUTCDate: function (date, format = 'MM/DD/YYYY') {

    return moment.utc(date).format(format);

  },
  extractDate: function (date) {

    return moment(date).format('MM/DD/YYYY');

  },

  extractDateTime: function (date) {

    return moment(date).format('MM/DD/YYYY hh:mm a');

  },

  extractDateProfile: function (date) {

    return moment(date).format('MM/DD/YY');

  },

  getCreditCardExpirationDates: function(expDate, splitChar = '/') {
    const [expMonth, expYear] = expDate.split(splitChar);
    const initialDate = [expMonth, expYear].join(splitChar);
    const initialFormat = `MM${splitChar}YY`;
    const finalFormat = `MM${splitChar}YYYY`;

    const date = moment.utc(initialDate, initialFormat).format(finalFormat);
    return date.split(splitChar);
  },

  startOfDayTime: function () {

    return moment().startOf('day').format('hh:mm a');

  },

  endOfDayTime: function () {

    return moment().endOf('day').format('hh:mm a');

  },

  getCustomDateRange: function (startDate, endDate, previousStartDate, previousEndDate) {

    let start = moment(new Date(startDate));
    let end = moment(new Date(endDate));
    let previousStart = previousStartDate ? moment(new Date(previousStartDate)) : null;
    let previousEnd = previousEndDate ? moment(new Date(previousEndDate)) : null;

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: 'custom',
      text: dateFilterTypes.CUSTOM_RANGE
    };

  },

  getSearchPreviousRange: function () {

    // NOTE: Temp fix - only search transactions in the last year

    const start = moment().tz('America/New_York').subtract(1, 'year').format();
    const end = moment().tz('America/New_York').format();
    const previousStart = null;
    const previousEnd = null;

    return {
      startDate: start,
      endDate: end,
      previousStartDate: previousStart,
      previousEndDate: previousEnd,
      value: 'custom',
      text: dateFilterTypes.CUSTOM_RANGE
    };

  },

  getAllTime: function () {

    return {startDate: null, endDate: null, value: 'all', text: dateFilterTypes.ALL_TIME };

  },

  getMinDate: function () {

    return moment([2009]).startOf('year').toDate();

  },

  getDateRange: function (dateText) {

    switch (dateText) {
      case dateFilterTypes.TODAY:
        return this.getToday();
      case dateFilterTypes.YESTERDAY:
        return this.getYesterday();
      case dateFilterTypes.THIS_WEEK:
        return this.getThisWeek();
      case dateFilterTypes.THIS_MONTH:
        return this.getThisMonth();
      case dateFilterTypes.THIS_YEAR:
        return this.getThisYear();
      case dateFilterTypes.LAST_WEEK:
        return this.getLastWeek();
      case dateFilterTypes.LAST_ACTIVE_WEEK:
        return this.getLastActiveWeek();
      case dateFilterTypes.LAST_MONTH:
        return this.getLastMonth();
      case dateFilterTypes.LAST_YEAR:
        return this.getLastYear();
      case dateFilterTypes.ALL_TIME:
        return this.getAllTime();
      default:
        return;
    }
  },

  getPersonalizedDateRange: function (dateText, date) {

    switch (dateText) {
      case dateFilterTypes.TODAY:
        return this.getPersonalizedToday(date);
      case dateFilterTypes.YESTERDAY:
        return this.getPersonalizedYesterday(date);
      case dateFilterTypes.THIS_WEEK:
        return this.getPersonalizedThisWeek(date);
      case dateFilterTypes.THIS_MONTH:
        return this.getPersonalizedThisMonth(date);
      case dateFilterTypes.THIS_YEAR:
        return this.getPersonalizedThisYear(date);
      case dateFilterTypes.LAST_WEEK:
        return this.getPersonalizedLastWeek(date);
      case dateFilterTypes.LAST_ACTIVE_WEEK:
        return this.getPersonalizedLastActiveWeek(date);
      case dateFilterTypes.LAST_MONTH:
        return this.getPersonalizedLastMonth(date);
      case dateFilterTypes.LAST_YEAR:
        return this.getPersonalizedLastYear(date);
      case dateFilterTypes.ALL_TIME:
        return this.getAllTime();
      default:
        return;
    }
  },

  getFormattedDates: function (date) {

    const time = date?.split(':') || [];
    const hours = parseInt(time[0]);
    const minutes = parseInt(time[1]);

    const fromTime = moment()?.startOf('day');
    fromTime?.hours(hours);
    fromTime?.minutes(minutes);

    const toTime = fromTime?.clone()?.add(1, 'day')?.subtract({second: 1});

    return {
      fromTime,
      toTime
    };

  },

  addTimeToToday: (amount, timeMeasure) => {
    return moment().add(amount, timeMeasure).startOf('day').toDate();
  },

  getEastToLocalTime: (time, fullDate = false) => {
    if (typeof time !== 'string') {
      return time;
    }

    const currentTimezone = momentTimezone.tz.guess();
    const newDate = moment().format(`YYYY-MM-DD ${time}`);
    const est = momentTimezone.tz(newDate, 'America/New_York').format();
    const localTime = momentTimezone(est).clone().tz(currentTimezone).format();
    return fullDate ? moment(localTime).toDate() : moment(localTime).format('HH:mm:00');
  },

  localToEastTime: (time) => {
    const currentTimezone = momentTimezone.tz.guess();
    const newDate = moment(time).format('YYYY-MM-DD HH:mm:ss');
    const localTime = momentTimezone(newDate).clone().tz(currentTimezone).format();
    return momentTimezone(localTime).clone().tz('America/New_York').format('HH:mm:00');
  },

  formatDate: (date, format = 'LL') => {
    return moment.utc(date).format(format);
  },

  getEndOfDay: (date) => {
    return moment(date).clone().set({hours: 23, minutes: 59, seconds: 59});
  },

  getMomentTextFromValue: (dateText) => {
    switch (dateText) {
      case dateFilterTypes.TODAY:
        return 'day';
      case dateFilterTypes.YESTERDAY:
        return 'day';
      case dateFilterTypes.THIS_WEEK:
        return 'week';
      case dateFilterTypes.THIS_MONTH:
        return 'month';
      case dateFilterTypes.THIS_YEAR:
        return 'year';
      case dateFilterTypes.LAST_WEEK:
        return 'week';
      case dateFilterTypes.LAST_MONTH:
        return 'month';
      case dateFilterTypes.LAST_YEAR:
        return 'year';
      default:
        return 'day';
    }
  },

};

export default DateUtils;
