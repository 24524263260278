/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Field, FormSection } from 'redux-form';
import TextField from '../shared/TextField';
import { Box } from '@mui/material';
import TextUtil from '../util/FormatTextUtil';
import {
  setVisibilityFilter
} from '../../actions/userExperienceActions';
import Button from '../shared/Button';
import { advancedSearchForm, inputContainer, largeInput, smallInput, clearButton, button, searchButton } from '../../jss/advancedSearchStyles';
import { reduxForm, getFormValues, resetSection, reset } from 'redux-form';
import Validator from '../util/Validator';
import { connect } from 'react-redux';
import { toggleShowAdvancedSearchMoreFields } from '../../actions/userExperienceActions'

export const getPlainValues = (values) => {
  let plainValues = values;
  if (values?.moreFields) {
    plainValues = {
      ...values,
      ...values.moreFields
    };
    delete plainValues.moreFields;
  }
  return plainValues;
}

export const validate = (values) => Validator.validateAdvancedSearchForm(values);

export class AdvancedSearch extends Component {

  handleSearch = (ev) => {
    this.props.dispatch(setVisibilityFilter(getPlainValues(this.props.currentValues), this.props.filterType, 'Advanced Search'));
    if (this.props.onFilter) {
      this.props.onFilter();
    }
  }

  handleShowMoreFields = () => {
    this.props.dispatch(resetSection('AdvancedSearchForm', 'moreFields'));
    this.props.dispatch(toggleShowAdvancedSearchMoreFields(!this.props.showAdvancedSearchMoreFields));
  }

  handleClearFields = () => {
    this.props.dispatch(reset('AdvancedSearchForm'));
  }

  render() {
    const { showAdvancedSearchMoreFields, t } = this.props;

    return (
      <Box id='advancedSearch' component='form' sx={advancedSearchForm}>
        <Box sx={clearButton}>
          <Box sx={largeInput}>
            <h3 className='title'>{t('Advanced Search')}</h3>
          </Box>
          <Button
            label={t('ClearFields')}
            onClick={this.handleClearFields}
            shouldUseRaisedButton={false}
            variant='text'
            sx={button}
          />
        </Box>
        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <p className='title'>{t('TransactionDateRange')}</p>
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('From')}
              component={TextField}
              name='dateRangeFrom'
              normalize={TextUtil.formatDOB}
              hintText={t('OnlinePaymentForm.PaidDateFormat')}
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('To')}
              component={TextField}
              hintText={t('OnlinePaymentForm.PaidDateFormat')}
              name='dateRangeTo'
              normalize={TextUtil.formatDOB}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <p className='title'>{t('TotalAmount')}</p>
          </Box>
          <Box sx={smallInput}>
            <Field 
              label={t('From')}
              component={TextField}
              name='amountFrom'
              hintText={t('AmountHint')}
              normalize={TextUtil.formatCurrencyWithMaxDigit}
            />
          </Box>
          <Box sx={smallInput}>
            <Field 
              label={t('To')}
              hintText={t('AmountHint')}
              component={TextField}
              name='amountTo'
              normalize={TextUtil.formatCurrencyWithMaxDigit}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field 
              label={t('PaymentConfirmation')}
              component={TextField}
              normalize={TextUtil.formatWholeNumber}
              name='confirmationNumber'
            />
          </Box>
          <Box sx={smallInput}>
            <Field 
              label={t('PaymentMethod')}
              component={TextField}
              normalize={TextUtil.formatAlpha}
              name='paymentMethod'
            />
          </Box>

          <Box sx={smallInput}>
            <Field 
              label={t('Last4')}
              component={TextField}
              normalize={TextUtil.formatWholeNumber}
              inputProps={{ maxLength: 4 }}
              name='cc_last4'
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field 
              label={t('Name')}
              component={TextField}
              normalize={TextUtil.formatAlpha}
              name='name'
            />
          </Box>
          <Box sx={smallInput}>
            <Field 
              label={t('PaymentLinksColumns.Type')}
              component={TextField}
              normalize={TextUtil.formatAlpha}
              name='type'
            />
          </Box>
          <Box sx={smallInput}>
            <Field 
              label={t('TransactionType')}
              component={TextField}
              normalize={TextUtil.formatAlpha}
              name='transactionType'
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Button
            label={showAdvancedSearchMoreFields ? t('LessFields') : t('MoreFields')}
            onClick={this.handleShowMoreFields}
            shouldUseRaisedButton={false}
            variant='text'
            sx={button}
          />
        </Box>

          {showAdvancedSearchMoreFields && (
            <FormSection name='moreFields'>
              <Box sx={inputContainer}>
                <Box sx={largeInput}>
                  <p className='title'>{t('TipAmount')}</p>
                </Box>
                <Box sx={smallInput}>
                  <Field
                    label={t('From')}
                    hintText={t('AmountHint')}
                    component={TextField}
                    normalize={TextUtil.formatCurrencyWithMaxDigit}
                    name='tipAmountFrom'
                  />
                </Box>

                <Box sx={smallInput}>
                  <Field
                    label={t('To')}
                    hintText={t('AmountHint')}
                    component={TextField}
                    normalize={TextUtil.formatCurrencyWithMaxDigit}
                    name='tipAmountTo'
                  />
                </Box>
              </Box>

              <Box sx={inputContainer}>
                <Box sx={largeInput}>
                    <p className='title'>{t('AuthAmount')}</p>
                  </Box>
                <Box sx={smallInput}>
                  <Field
                    label={t('From')}
                    hintText={t('AmountHint')}
                    component={TextField}
                    normalize={TextUtil.formatCurrencyWithMaxDigit}
                    name='authAmountFrom'
                  />
                </Box>
                <Box sx={smallInput}>
                  <Field
                    label={t('To')}
                    hintText={t('AmountHint')}
                    component={TextField}
                    normalize={TextUtil.formatCurrencyWithMaxDigit}
                    name='authAmountTo'
                  />
                </Box>
              </Box>

              <Box sx={inputContainer}>
                <Box sx={largeInput}>
                  <Field
                    label={t('SoldByEmployee')}
                    component={TextField}
                    name='soldByEmployee'
                  />
                </Box>
                <Box sx={smallInput}>
                  <Field
                    label={t('MID')}
                    component={TextField}
                    normalize={TextUtil.formatWholeNumber}
                    name='mid'
                  />
                </Box>

                <Box sx={smallInput}>
                  <Field
                    label={t('AuthNumber')}
                    component={TextField}
                    name='auth'
                    normalize={TextUtil.formatWholeNumber}
                  />
                </Box>           
              </Box>

              <Box sx={inputContainer}>
                <Box sx={largeInput}>
                  <Field
                    label={t('BRIC')}
                    component={TextField}
                    normalize={TextUtil.formatAlphaNumeric}
                    name='bric'
                  />
                </Box>
              </Box>
            </FormSection>
          )}
        <Box sx={inputContainer}>
          <Button
            label={t('Search')}
            onClick={this.handleSearch}
            sx={searchButton}
          />
        </Box>
      </Box>
    );
  }
}



const AdvancedSearchForm = reduxForm({
  form: 'AdvancedSearchForm',
  validate,
  fields: [
    'dateRangeFrom',
    'dateRangeTo',
    'type',
    'amountTo',
    'amountFrom',
    'name',
    'paymentMethod',
    'cc_last4',
    'transactionType',
    'confirmationNumber',
    'moreFields'
  ],
  enableReinitialize: true,
  destroyOnUnmount: false
})(AdvancedSearch);

export function mapStateToProps(state) {
  return {
    currentValues: getFormValues('AdvancedSearchForm')(state),
    showAdvancedSearchMoreFields: state.userExperience?.showAdvancedSearchMoreFields
  }
}

export default connect(mapStateToProps)(AdvancedSearchForm);
