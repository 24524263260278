/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import { Box } from '@mui/material';
import React from 'react';
import IconUtils from '../util/IconUtil';
import mobileUtil from '../util/MobileUtil';
import ActivateButton from '../activate/ActivateButton';
import { northDashboardBannerStyles } from '../../jss/dashboardStyles';

const NorthDashboardBanner = (
  { 
    t, 
    dispatch,
    onActivateAccount,
    demoPayment = false,
    addBusinessInfo = false,
    highRiskApplication = false,
    addHardware = false,
    isApproved = false,
    isDeclined = false
  }) => {

  const checkedIcon = IconUtils.getIcon('CheckmarkIcon');
  const alertIcon = IconUtils.getIcon('Warning');
  const isMobile = mobileUtil.isMobileDevice();

  const shouldCheckDemoPayment = demoPayment || addBusinessInfo || addHardware;
  const demoPaymentCard = {title: t('NorthDashboardBanner.DemoPaymentCard'), checked: shouldCheckDemoPayment};

  const shouldCheckAddBusinessInfo = addBusinessInfo || addHardware;
  const addBusinessInfoCard = {title: highRiskApplication ? t('NorthDashboardBanner.ChooseAPlan') : t('NorthDashboardBanner.AddBusinessInfo') , checked: shouldCheckAddBusinessInfo};

  const addHardwareCard = {title: t('NorthDashboardBanner.AddHardware'), checked: addHardware};

  const statusCards = [demoPaymentCard, addBusinessInfoCard, addHardwareCard];

  const isFirstLanding = !shouldCheckDemoPayment && !highRiskApplication;
  const isDemoPayment = demoPayment && !shouldCheckAddBusinessInfo;
  const isIncompleteApplication = addBusinessInfo && !highRiskApplication && !addHardware;
  const isHighRiskApplication = !addBusinessInfo && highRiskApplication && !addHardware;
  const isPendingApproval = addHardware;

  let bannerTitle = '';
  let bannerDescription = '';
  if (isFirstLanding) {
    bannerTitle = t('NorthDashboardBanner.FirstLanding.Title');
    bannerDescription = t('NorthDashboardBanner.FirstLanding.Description');
  } else if (isDemoPayment) {
    bannerTitle = t('NorthDashboardBanner.DemoPayment.Title');
    bannerDescription = t('NorthDashboardBanner.DemoPayment.Description');
  } else if (isIncompleteApplication) {
    bannerTitle = t('NorthDashboardBanner.IncompleteApplication.Title');
    bannerDescription = t('NorthDashboardBanner.IncompleteApplication.Description');
  } else if (isHighRiskApplication) {
    bannerTitle = t('NorthDashboardBanner.HighRiskApplication.Title');
    bannerDescription = t('NorthDashboardBanner.HighRiskApplication.Description');
  } else {
    bannerTitle = t('NorthDashboardBanner.PendingApproval.Title');
    bannerDescription = t('NorthDashboardBanner.PendingApproval.Description');
  }

  const appStoreImage = (
    <a
      href='https://apps.apple.com/us/app/payanywhere-point-of-sale-pos/id404066296'
      target='_blank'
    >
      <img
        src={`${serverDomainUrl}images/stores/app_store.svg`}
        alt='App Store'
      />
    </a>
  );

  const googlePlayStoreImage = (
    <a
      href='https://play.google.com/store/apps/details?id=ban.card.payanywhere&hl=en_US&gl=US'
      target='_blank'
    >
      <img
        src={`${serverDomainUrl}images/stores/google_play.svg`}
        alt='Google Play Store'
      />
    </a>
  );

  return (
    <Box
      component={'section'}
      sx={northDashboardBannerStyles}
    >
      <div className={`${isDeclined ? 'declinedApplicationContainer' : 'approvedApplicationContainer'}`}>
        {
          isApproved || isDeclined ?
          <>
            {
              isApproved ?
                <div className='approvedApplication'>
                  <img src={`${serverDomainUrl}images/north/logoSmallWhite.svg`} alt='North Logo'/>
                  <div className='applicationResult'>
                    <h1>{t('NorthDashboardBanner.Approved.Title')}</h1>
                    <p>{t('NorthDashboardBanner.Approved.Description')}</p>
                  </div>
                  <div className='bannerActionButton'>
                    <p>{t('NorthDashboardBanner.Approved.Action')}</p>
                  </div>
                </div>
              :
                <div className='declinedApplication'>
                  <div className='applicationAlert'>
                    {alertIcon}
                  </div>
                  <div className='applicationResult'>
                    <h1>{t('NorthDashboardBanner.Declined.Title')}</h1>
                    <p>{t('NorthDashboardBanner.Declined.Description')}</p>
                  </div>
                  <a href='mailto:accountstatus@appundewriting.com'>
                    <div className='bannerActionButton'>
                      <p>{t('NorthDashboardBanner.Declined.Action')}</p>
                    </div>
                  </a>
                </div>
            }
          </>
          :
          <div className='applicationInProgress'>
            <div className='bannerDescription'>
              <h1>{bannerTitle}</h1>
              <p>{bannerDescription}</p>
              {
                !isHighRiskApplication && !isPendingApproval ?
                  <div className='activateAccountButtonContainer'>
                    <ActivateButton
                      dispatch={dispatch}
                      onClick={onActivateAccount}
                      label={t('Activation.Button.ActivateAccount')}
                      light
                    />
                  </div>
                :
                  null
              }
            </div>
            <div className='statusCards'>
              {
                statusCards.map((card) => {
                  return (
                    <div className='statusCard' key={card.title}>
                      {
                        card.checked ?
                          <div className='stepStatus checked'>
                            {checkedIcon}
                          </div>
                        :
                          <div className='stepStatus' />
                      }
                      <p>{card.title}</p>
                    </div>
                  )
                })
              }
              {
                isMobile ?
                  <div className='mobileStores'>
                    <p>{t('NorthDashboardBanner.GetPayanywhere')}</p>
                    <div className='mobileStoresContainer'>
                      {appStoreImage}
                      {googlePlayStoreImage}
                    </div>
                  </div>
                :
                  <div className='statusCard storesCard'>
                    <div className='storesContainer'>
                      {appStoreImage}
                      {googlePlayStoreImage}
                    </div>
                    <p>{t('NorthDashboardBanner.GetPayanywhere')}</p>
                  </div>
              }
            </div>
          </div>
        }
      </div>
    </Box>
  );
}

export default NorthDashboardBanner;