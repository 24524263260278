/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import LanguageSelector from './shared/LanguageSelector';

const PrivacyPolicy = (props) => {

  return (
    <div className='privacyPolicy'>
    
      <h1 className='title'>Payments Hub Website Privacy Policy</h1>

      <div className='subsection'>
        <p><span className='inlineSubtitle'>Effective date:</span> 9.15.20</p>
        <p>This Privacy Policy applies to information about you North American Bancard, LLC  (“NAB,” “we”, “our”, “us”) may obtain when you visit <a href='https://www.paymentshub.com' target='_blank'>www.paymentshub.com</a> (the “Site”). This Privacy Policy describes how we may collect, use, or share information about you; your choices regarding our use of your information; how we safeguard such information; and how you may contact us regarding our privacy practices.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Collection of personal information</h2>
        <p><span className='inlineSubtitle'>Information you provide:</span> We may obtain information that you provide directly to us when you interact with the Site (e.g., when you create an account or contact support). This information may include personal information, which is information that can be used to identify you individually, such as your name, date of birth, social security number, email address, phone number, company information, and payment information.</p>
        <p>If you submit to us someone else’s personal information (e.g., their contact information), you represent that you have the authorization to provide this information to us.</p>
        <p><span className='inlineSubtitle'>Information collected automatically:</span>  We may collect certain information automatically including IP addresses, device identifiers, browser characteristics, operating system details, language preferences, referring URLs, length of visits, and pages viewed using various tools and technologies such as cookies and web server logs.</p>
        <p>A cookie is a piece of data that a website can send to your browser, which may then be stored on your computer as an anonymous tag that identifies your computer but not you. Many web browsers are set to accept cookies by default, but you may be able to set your browser to notify you before you receive a cookie, or to remove or reject cookies. Please note that if you disable cookies, you may not be able to use certain features of this Site or other websites and disabling cookies may invalidate opt outs that rely on cookies to function.</p>
        <p>We may combine certain automatically-collected information with other information we obtain about you, which may include data we obtain from third parties.</p>
        <p>Please note that we or third parties may collect personal information about your online activities over time and across different devices and online properties when you use the Site.</p>
        <p>Your web browser may have settings that allow you to transmit a “Do Not Track” signal when you visit various websites or use online services. Like many websites, the Site is not designed to respond to “Do Not Track” signals received from browsers. To learn more about “Do Not Track” signals, visit <a href='https://www.allaboutdnt.com/' target='_blank'>https://www.allaboutdnt.com/</a>.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Use of personal information</h2>
        <p>We may use personal information we collect through the Site for:</p>
        <ul>
          <li>Facilitating and personalizing your user experience;</li>
          <li>Responding to your requests;</li>
          <li>Processing your transactions;</li>
          <li>Communicating or marketing to you;</li>
          <li>Monitoring and analyzing trends, usage, and activities of visitors and users of the Site;</li>
          <li>Improving the Site;</li>
          <li>Complying with applicable laws, regulations, or legal process as well as industry standards and our company policies;</li>
          <li>Preventing, investigating, identifying, or taking any other action with regard to suspected or actual fraudulent or illegal activity, or any activity that violates our policies; or</li>
          <li>Any other purpose you consent to.</li>
        </ul>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Sharing of personal information</h2>
        <p>We may share your personal information with our subsidiaries, affiliates, and business partners. To the extent we grant these parties access to your personal information, they will follow privacy practices no less protective than our practices to the extent allowed by applicable law.</p>
        <p>We may also share your personal information with third-party service providers assisting us in carrying out our services. Third-party service providers are not meant to use personal information except as necessary to provide the relevant services to us.</p>
        <p>In the event of a business transaction, such as if we sell or transfer all or a portion of our business or assets (e.g., further to a merger, reorganization, liquidation, or any other business transaction, including negotiations of such transactions), we reserve the right to disclose any information we obtain through the Site. You acknowledge that such transfers may occur and are permitted by and subject to this Privacy Policy.</p>
        <p>Additionally, NAB may disclose personal information when required by subpoena, search warrant, or other legal processes, or in response to activities that are unlawful or a violation of NAB's rules for use of the Site, or to protect and defend the rights or property of NAB.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Third-party analytics and advertising</h2>
        <p>We may use third-party technology to collect and use data in connection with interest-based advertising. These third-party ad technology companies and advertisers may use, store, or access cookies, web beacons, and other storage technologies to collect or receive information from the Site and elsewhere on the Internet and use that information to provide advertising services to us, including interest-based advertising or advertisements targeted to your interests.</p>
        <p>We may use certain third-party web analytics services, including Google Analytics, to help us understand and analyze how visitors use the Site and to serve ads on our behalf across the Internet. We’ve implemented Google Analytics Advertising features such as remarketing with analytics, interest-based advertising, demographics and interests reporting, technology and device reporting, user segment analysis, and impression reporting. We and third-party vendors may use first-party cookies or other first-party identifiers as well as third-party cookies or other third-party identifiers to deliver advertisements, create a profile of you, measure your interests, detect your demographics, detect your location, personalize content.</p>
        <p>To find out more about how these third-party analytics services manage the privacy of information in conjunction with delivering ads online, and how to opt-out of information collection by these networks, please visit: <a href='https://www.youradchoices.com' target='_blank'>https://www.youradchoices.com</a>, <a href='https://www.aboutads.info/appchoices' target='_blank'>https://www.aboutads.info/appchoices</a>, or <a href='https://www.networkadvertising.org' target='_blank'>https://www.networkadvertising.org</a>.</p>
        <p>For more information on how Google Analytics uses data collected through the Site, visit: <a href='https://www.google.com/policies/privacy/partners/' target='_blank'>www.google.com/policies/privacy/partners/</a>. To opt out of Google Analytics cookies, visit: <a href='https://www.google.com/settings/ads' target='_blank'>https://www.google.com/settings/ads</a> and <a href='https://tools.google.com/dlpage/gaoptout/' target='_blank'>https://tools.google.com/dlpage/gaoptout/</a>.</p>
        <p>Please note that we do not control how third parties manage their opt-out processes.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Third-party features</h2>
        <p>We may provide third-party interfaces or links on the Site to facilitate your use of the Site. For example, we may enable third party interfaces to help you create an account with us or login to your existing account. These third parties may collect and retain any information used or provided in connection with these interactions, and these third parties’ practices are not subject to our Privacy Policy.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Linked websites</h2>
        <p>The Site may include links to other websites that are owned and/or operated by third parties. This Privacy Policy does not apply to those websites, which may have their own privacy policies or notices that you should review to understand how they may collect, use, or disclose your personal information. NAB is not responsible for the content or privacy practices of any linked websites that are operated by third parties.</p>
        <p>The Site may also provide links to other websites operated by NAB or our subsidiaries or affiliates. Unless indicated otherwise, these websites are not governed by this Privacy Policy. For information on how these websites may collect, use, or disclose your personal information, we encourage you to review the privacy policies posted on the linked websites.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Security</h2>
        <p>We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. Nevertheless, transmission via the Internet and online digital storage are not completely secure, and we cannot guarantee the security of your information collected through the Site.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Your choices</h2>
        <p><span className='inlineSubtitle'>Communication preferences and marketing:</span> If you do not wish to receive marketing emails from us, you can opt out of future marketing emails by following the instructions provided in the marketing emails. Opting out of marketing emails will not affect our administrative emails to you (e.g., emails about your transactions or policy changes).</p>
        <p><span className='inlineSubtitle'>Accessing, reviewing, and changing your personal information:</span> Registered users can review and change certain personal information (e.g., email, password, address, phone number) at any time by accessing their account on the Site.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>California consumers</h2>
        <p>At this time, NAB is not a “business” subject to the California Consumer Privacy Act. NAB does not disclose “customer” “personal information” to third parties for those third parties’ own direct marketing purposes as defined in California’s “Shine the Light” law. California residents may contact us for further information regarding our privacy practices by emailing <a href='mailto:privacy@nabancard.com' target='_blank'>privacy@nabancard.com.</a></p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Information for users outside the United States</h2>
        <p>If you are visiting the Site from outside the United States, please be aware that information we obtain about you will be processed in the United States or in other jurisdictions. By using the Site, you acknowledge your personal information may be transferred to and processed in jurisdictions outside your own as described in this Privacy Policy. Please be aware that the data protection laws and regulations that apply to your personal information transferred to the United States or other countries may be different from the laws in your country of residence.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Dispute resolution</h2>
        <p>If a dispute arises between you and NAB, you and NAB agree, as permitted by applicable law, to resolve any claim or controversy at law or equity arising out of, relating to, or connected in any way with the Site through binding arbitration or as we and you otherwise agree in writing. Before resorting to these alternatives, we strongly encourage you to first contact us directly to seek a resolution. We will consider reasonable requests to resolve the dispute through alternative dispute resolution procedures, such as mediation or arbitration, as alternatives to litigation.</p>
        <p>Certain portions of this section are deemed to be a “written agreement to arbitrate” pursuant to the Federal Arbitration Act. You and NAB agree that we intend that this section satisfies the “writing” requirement of the Federal Arbitration Act. This section can only be amended by mutual agreement.</p>
        <p><span className='inlineSubtitle'>Binding arbitration.</span>  If any controversy, allegation, or claim relates in any way to your use of the Site (collectively, “Dispute”), then you agree the Dispute shall be submitted to confidential arbitration in Michigan except that we may seek injunctive or other appropriate relief in any state or federal court. You hereby consent to, and waive, all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in the state and federal courts of Michigan. Arbitration under these Terms shall be conducted pursuant to the Commercial Arbitration Rules then prevailing at the American Arbitration Association; any dispute shall otherwise be governed by the internal laws of the State of Michigan without regard to Michigan choice of law principles, except that the provisions of this agreement concerning arbitration shall be governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. The arbitrability of disputes shall be determined by the arbitrator. The arbitrator’s award shall be final and binding and may be entered as a judgment in any court of competent jurisdiction.</p>
        <p><span className='inlineSubtitle'>Limited time to file claims.</span> You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of, related to or connected with the use of the Site must be filed within one year after such claim or cause of action arose or be forever banned.</p>
        <p><span className='inlineSubtitle'>Class action waiver.</span> DISPUTES WILL BE ARBITRATED, LITIGATED, OR OTHERWISE RESOLVED ONLY ON AN INDIVIDUAL BASIS AND WILL NOT BE JOINED OR CONSOLIDATED WITH ANY OTHER ARBITRATIONS, LAWSUITS, OR OTHER PROCEEDINGS THAT INVOLVE ANY CLAIM OR CONTROVERSY OF ANY OTHER PARTY. YOU AND NAB AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF, COUNTER-PLAINTIFF, OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Revisions</h2>
        <p>This Privacy Policy is subject to change at our discretion. We will indicate changes, including material changes, to the Privacy Policy by updating the “Effective Date” at the top of this page. Your continued use of this Site after any update to this Privacy Policy will constitute your acceptance of the changes.</p>
      </div>

      <div className='subsection'>
        <h2 className='subtitle'>Contact information</h2>
        <p>You may direct comments or questions regarding this Privacy Policy via email to <a href='mailto:privacy@nabancard.com' target='_blank'>privacy@nabancard.com</a>.</p>
      </div>
      <LanguageSelector  {...props} />
    </div>
  );

};

export default PrivacyPolicy;
