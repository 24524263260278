/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

export const dashboardHeaderStyle = {
  display: 'flex',
  flexDirection: { xs: 'column', md:'row' },
  alignItems: { xs: 'flex-start', md:'center' },
  justifyContent: 'space-between',
  paddingTop: '25px',
  width: '100%',
  maxWidth: '2260px',
  px: { xs: '10px', md: '30px', lg: '50px' },
  boxSizing: 'border-box',
  '& p:first-of-type': { fontSize: {xs: 22, md: 37}, m: 0, color: 'common.black', fontFamily: 'centuryGothicBold' },
  '& p:last-of-type': { fontSize: 14, m: 0, mt: {xs: 1, md: 0}, color: 'common.black2' },
};

export const dashboardFooterStyle = {
  justifyContent: 'flex-end',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '30px auto',
  width: '100%',
  maxWidth: '2260px',
  px: { xs: '10px', md: '30px', lg: '50px' },
  boxSizing: 'border-box',
  '.northLogoIcon': {
    width: '100px',
    height: '28px'
  },
  '.paymentsHubLogo': {
    width: '148px',
    height: '23px'
  }
};

export const dashboardStyles = quickReportsVisible => theme => ({
  px: { xs: '10px', md: '30px', lg: '50px' },
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '2260px',
  display: 'grid',
  gap: { xs: '20px', md: '30px', lg: '40px' },
  ...defineDashboardLayout(5, quickReportsVisible ? 2 : 0),

  '.Status': { gridArea: 'Status' },
  '.Links': { gridArea: 'Links' },
  '.Reports': { gridArea: 'Reports' },

  [theme.breakpoints.down('dashboardXl')]: {
    ...defineDashboardLayout(4, quickReportsVisible ? 1 : 0),
  },
  [theme.breakpoints.down('dashboardLg')]: {
    ...defineDashboardLayout(3, quickReportsVisible ? 1 : 0),
  },
  [theme.breakpoints.down('dashboardMd')]: {
    ...defineDashboardLayout(2, quickReportsVisible ? 1 : 0),
  },
  [theme.breakpoints.down('dashboardSm')]: {
    ...defineDashboardLayout(1, quickReportsVisible ? 1 : 0),
  }
});

const defineDashboardLayout = (columns, reportColumns) => ({
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gridTemplateAreas: `
        "${'Status '.repeat(columns)}"
        ${reportColumns > 0 ? columns > 1 ? `"${'Links '.repeat(columns - reportColumns)} ${'Reports '.repeat(reportColumns)}"` : '"Links" "Reports"' : ''}
        ${reportColumns === 0 ? `"${'Links '.repeat(columns)}"` : ''}
      `
});

export const featureStyles = theme => ({
  alignSelf: 'end',

  '.titleContainer': {
    display: 'inline-block',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    height: '27px !important',
    width: '100%',

    '.title': {
      fontFamily: 'interBold',
      marginTop: '5px',
      paddingLeft: '26px',
      fontSize: '13px',
      color: 'common.black2'
    }
  },

  '.noHeadingFeature': {
    mt: '38px'
  },

  '.feature': {
    backgroundColor: 'common.featureBgcolor',
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '35px',
    height: '315px',
    userSelect: 'none',

    'svg': {
      mt: '25px'
    },

    'h3': {
      color: 'common.black'
    },

    'p': {
      color: '#8a8c8c',
      fontSize: '15px',
      lineHeight: '20px',
      textAlign: 'center'
    }
  },

  '&.northBackground': {
    '.feature': {
      backgroundColor: '#222529',
      gap: '40px'
    },

    'h3, p': {
      color: 'common.white'
    }
  },

  '&.powerUserFeature .feature': {
    gap: '0',
    backgroundColor: 'common.black3',

    'h3, p': {
      color: 'common.white',
    }
  },

  '&.integrationsFeature': {
    '.icon img': {
      width: '100%',
    },
    '.feature': {
      padding: '35px 0',
      backgroundColor: 'common.black2',
      color: 'common.white',

      '.icon': {
        width: { sm: '300px', md: '100%' },
        height: 'auto'
      },

      'h3, p': {
        padding: '0 35px',
        color: 'common.white',
      }
    }
  },

  '&.initialRow': {
    gridColumnStart: 1,
  },

  '&.northBackgroundImg': {

    '.feature': {
      background:  'transparent linear-gradient(160deg, #1147D1 0%, #9919F7 100%) 0% 0% no-repeat padding-box',
      gap: '40px'
    },

    'h3, p': {
      color: 'common.white'
    }
  },

  '&.phBackgroundImg': {
    '.feature': {
      background: 'transparent linear-gradient(161deg, #1CD1BE 0%, #0EC4FA 100%) 0% 0% no-repeat padding-box',
      gap: '40px'
    },

    'h3, p': {
      color: 'common.white'
    }
  },

  'a': {
    color: 'primary.main',
    textDecoration: 'none'
  }
});

export const northDashboardBannerStyles = {
  '.approvedApplicationContainer, .declinedApplicationContainer': {
    borderRadius: 5,
    p: {xs: 3, md: 5},
    mt: 3,

    '& h1': {color: 'common.white', fontSize: 28, m: 0},

    '& p': {color: 'common.white', fontSize: 15, fontFamily: 'Inter Regular', m: 0, mt: 1},

    '.activateAccountButtonContainer': {
      mt: {xs: 4, md: 2},
      'button': {
        height: 65,
        width: {xs: '100%', md: 240},
      }
    },

    '.bannerActionButton': {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'common.white',
      cursor: 'pointer',
      fontWeight: 'bold',
      borderRadius: 8,
      width: {xs: '100%', md: 240},
      height: 65,
      gap: 2,

      '& p': {color: 'primary.main', fontSize: 16, m: 0},

      'svg': {color: 'primary.main'}
    }
  },

  '.approvedApplicationContainer': {
    backgroundColor: 'primary.main',
  },

  '.declinedApplicationContainer': {
    backgroundColor: '#2F3339'
  },

  '.applicationInProgress': {
    display: 'flex',
    flexDirection: {xs: 'column', md: 'row'},
    justifyContent: 'space-between',
    columnGap: 8,
    rowGap: 4,

    '.bannerDescription': {
      width: {xs: '100%', md: 3/7},
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },

    '.statusCards': {
      width: {xs: '100%', md: 4/7},
      display: 'flex',
      flexDirection: {xs: 'column', md: 'row'},
      columnGap: 3,
      rowGap: 2,
      justifyContent: 'space-between',

      '.statusCard': {
        width: {xs: '100%', md: 1/4},
        minHeight: {xs: 'unset', md: 150},
        borderRadius: 5,
        display: 'flex',
        flexDirection: {xs: 'row', md: 'column'},
        justifyContent: {xs: 'start', md: 'center'},
        alignItems: 'center',
        backgroundColor: '#2b69f0',

        'svg': {color: 'common.white', width: 58},

        '& p': {color: 'common.white', fontSize: 15, pt: {xs: 0, md: 2.5}, px: 1, mt: 0, textAlign: 'center', fontWeight: 'bold', fontFamily: 'Inter Medium'},

        '.stepStatus': {border: '2px dashed', borderColor: 'common.white', width: 40, height: 40, borderRadius: 35, m: {xs: 2.5, md: 0}, mb: 1.5},

        '.checked': {
          display: 'flex',
          alignItems: 'center',
          border: '3px solid',
          borderColor: 'common.white',

          'svg': {height: 18, fill: 'white'},
        },

        '.storesContainer': {
          display: 'flex',
          flexDirection: 'column',
          rowGap: 0.5,

          '& img': {cursor: 'pointer'}
        }
      },

      '.storesCard': {
        backgroundColor: {sm: 'unset', md: '#2b69f0'},
        justifyContent: {sm: 'space-between', md: 'center'},

        '.storesContainer': {
          flexDirection: {sm: 'row', md: 'column'},
          columnGap: {sm: 3, md: 0}
        }
      }
    },

    '.mobileStores': {
      display: 'flex',
      justifyContent: 'space-between',

      '.mobileStoresContainer': {
        display: 'flex',
        columnGap: 1
      }
    }
  },

  '.approvedApplication, .declinedApplication': {
    display: 'flex',
    flexDirection: {xs: 'column', md: 'row'},
    justifyContent: 'center',

    'img': {color: 'common.white', maxWidth: 75},

    '.applicationResult': {
      flex: 1,
      ml: {xs: 0, md: 4},
      my: {xs: 2, md: 0}
    }
  },
  '.declinedApplication': {

    'a': {
      textDecoration: 'none'
    },

    '.applicationAlert': {
      height: 70,
      width: 70,
      backgroundColor: '#FF0D0D',
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      'svg': {color: 'common.white', width: 40, mt: -1}
    }
  }
}

export const historicHardwareModeBannerStyles = (theme) => ({

  '.historicHardwareBannerContainer': {

    borderRadius: 5,
    p: {xs: 3, md: 5},
    marginTop: 3,
    marginBottom: '250px',
    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)] : {
      marginBottom: '200px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)] : {
      marginBottom: '100px',
    },
    background: `url(/images/terminalView.svg) no-repeat 90% 0%, linear-gradient(132deg, ${theme.labelData.gradientStart} 0%, ${theme.labelData.gradientStop} 100%) 0% 0% no-repeat padding-box`,

    '& h1': {color: 'common.white', fontSize: 28, m: 0, mb: '16px'},

    '& p': {color: 'common.white', fontSize: 15, fontFamily: 'Inter Regular', m: 0},

    '.bannerButton': {

      margin: '44px 0 0',
      width: '22em',
      display: 'flex',
      justifyContent: 'space-between',
      background: 'none',
      border: '1px solid #fff !important',

      svg: {
        background: '#fff',
        fill: theme.labelData.gradientStart,
        borderRadius: '50%',
        padding: '5px',
        width: '22px',
        position: 'relative',
        right: '-15px',
      }
    }

  }

});

export const quickActionsStyles = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
  gap: {xs: '10px', md: '20px'},

  '&.withTopReports': {
    justifyContent: {xs: 'space-between', md: 'flex-start'},
  },

  '.quickActionsWidget': {
    width: {xs: '48%', md: '175px'},
    height: '164px',
    backgroundColor: '#F7F7F7',
    borderRadius: '18px',

    '.widgetLink': {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },

    '.widgetIcon': {
      marginBottom: '34px',
      width: '45px',
      maxHeight: '37px',

      'svg': {
        width: '100%',
        height: '100%',
        overflow: 'visible',
      }
    },
  }

}

export const dashboardCarouselStyles = {
  overflow: 'hidden',
  height: '440px',
  mb: '-25px'
}
