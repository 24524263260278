/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {Box} from '@mui/material';

import _ from 'lodash';

import TodayDataUtil from '../util/TodayDataUtil';
import IconUtil from '../util/IconUtil';
import DateUtil from '../util/DateUtil';
import UserUtil from '../util/UserUtil';
import Image from '../shared/Image';
import { buttonText } from '../../constants/planInformation';

import NorthDashboardBanner from './NorthDashboardBanner';
import HistoricHardwareModeBanner from './HistoricHardwareModeBanner';
import North from '../whitelabel/North';
import { isNorth as isNorthLabel } from '../util/WhiteLabelUtil';
import Feature from './cards/Feature';
import TopReports from './cards/TopReports';
import QuickActions from './cards/QuickActions';
import IntegrationsCarousel from './cards/IntegrationsCarousel';
import routes from './../../constants/routes';
import {
  dashboardFooterStyle,
  dashboardHeaderStyle,
  dashboardStyles
} from '../../jss/dashboardStyles';
import Page from '../shared/Page';
import classNames from 'classnames';
import {getDavoStatus} from '../../actions/accountProfileActions';

export default class Dashboard extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {merchantSettings, user, dispatch} = this.props;

    const merchantSettingsFetching = merchantSettings.isProcessing !== prevProps.merchantSettings.isProcessing;

    if (!merchantSettings.isProcessing && merchantSettingsFetching && !_.isNull(merchantSettings.merchantSettings)) {
      merchantSettings.merchantSettings.davo_enabled && dispatch(getDavoStatus(user));
    }
  }

  render() {

    const {
      accountProfile,
      dispatch,
      employees,
      history,
      merchantSettings,
      t,
      user,
      userExperience
    } = this.props;

    const { showHistoricHardwareModeNotice, historicHardwareMode } = userExperience;

    const role = TodayDataUtil.getRole(user?.data?.merchantAccounts, user?.selectedMerchantAccount);
    const currentUserRole = t(`EmployeeDetailForm.Roles.${_.capitalize(role)}`);

    const selectedMerchantAccount = _.find(user?.data?.merchantAccounts, { mea_id: Number(user?.selectedMerchantAccount) });
    const employeeData = employees ? _.find(employees.data, { id: Number(selectedMerchantAccount?.mid_user_id) }) : null;
    const employeeName = employeeData?.first_name;

    const currentPlanAccount = UserUtil.accountCurrentPlan(user);

    const todayClass = classNames('pageScrollableArea',
      { todayWithNotice: showHistoricHardwareModeNotice },
      { today: !showHistoricHardwareModeNotice }
    );

    const isNorth = isNorthLabel();

    const tapToPayIcon = IconUtil.getIcon('TapToPay');
    const loyaltyIcon = IconUtil.getIcon('Loyalty');
    const reputationManagementIcon = IconUtil.getIcon('ReputationManagement');

    const premiumPlusIcon = IconUtil.getIcon('PremiumPlus');
    const integrationsIcon = <img src={'/images/IntegrationsDashboard.png'}/>;
    const yelpIntegrationImage = (<Image alt={t('YelpIntegrationDescription')}
        src={'/images/yelp/yelpTile.svg'}
                                  />);
    const yelpIntegrationIcon = (<Image
        alt={'Yelp'}
        src={'/images/yelp/yelpLogo.png'}
        sx={{ width: '168px' }}
                                 />);

    const navIntegrationImage = (
      <Image
        alt={t('IntegrationsPage.Nav.IntegrationTile.Text')}
        src={'/images/nav/navTile.svg'}
      />
    );

    const navIntegrationIcon = (
      <Image
        alt={'Nav'}
        src={'/images/nav/navSquareLogo.svg'}
        sx={{ width: '168px' }}
      />
    );

    const davoIntegrationImage = (
      <Image
        alt={t('DAVOIntegration.Title')}
        src={'/images/davo/davoTile.svg'}
      />
    );

    const davoIntegrationIcon = (
      <Image
        alt={'Nav'}
        src={'/images/davo/davoSquareLogo.svg'}
        sx={{ width: '168px' }}
      />
    );

    const nextIntegrationImage = (
      <Image
        alt={t('IntegrationsPage.Next.IntegrationTile.Text')}
        src={'/images/next/nextTile.png'}
      />
    );

    const nextIntegrationIcon = (
      <Image
        alt={'Next'}
        src={'/images/next/nextSquareLogo.png'}
        sx={{ width: '168px' }}
      />
    );

    const shouldShowYelpTile = !merchantSettings?.merchantSettings?.yelp_clicked;
    const shouldShowNavTile = !merchantSettings?.merchantSettings?.nav_clicked;
    const shouldShowNextTile = !merchantSettings?.merchantSettings?.next_clicked;
    const shouldShowDavoTile = !accountProfile.davo?.is_integrated;

    const exploreIntegrationsTile = (
      <Feature
        customClass='integrationsFeature'
        icon={integrationsIcon}
        isNorth={isNorth}
        t={t}
        text='IntegrationsDescription'
        title='ExploreIntegrations'
        titlePos='up'
      />
    );

    const yelpTile = (
      <Feature
        customClass='integrationsFeature'
        icon={yelpIntegrationImage}
        integrationIcon={yelpIntegrationIcon}
        linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
        linkText='IntegrationsPage.Yelp.TileText'
        t={t}
        text='YelpIntegrationDescription'
        title='IntegrationsPage.FeaturedIntegration'
        titlePos='up'
      />
    );

    const navTile = (
      <Feature
        customClass='integrationsFeature'
        icon={navIntegrationImage}
        integrationIcon={navIntegrationIcon}
        linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
        linkText='IntegrationsPage.Nav.IntegrationTile.Action'
        t={t}
        text='IntegrationsPage.Nav.IntegrationTile.Text'
        title='IntegrationsPage.FeaturedIntegration'
        titlePos='up'
      />
    );

    const davoTile = (
      <Feature
          customClass='integrationsFeature'
          disclaimer='DAVOIntegration.Disclaimer'
          icon={davoIntegrationImage}
          integrationIcon={davoIntegrationIcon}
          linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
          linkText='DAVOIntegration.LinkText'
          t={t}
          text='DAVOIntegration.Title'
          title='IntegrationsPage.FeaturedIntegration'
          titlePos='up'
      />
    );

    const nextTile = (
      <Feature
        customClass='integrationsFeature'
        icon={nextIntegrationImage}
        integrationIcon={nextIntegrationIcon}
        linkRoute={`${globalApplicationLabel.path}${routes.account.root}${routes.account.integrations}`}
        linkText='IntegrationsPage.Next.IntegrationTile.Action'
        t={t}
        text='IntegrationsPage.Next.IntegrationTile.Text'
        title='IntegrationsPage.FeaturedIntegration'
        titlePos='up'
      />
    );

    const shouldShowCarousel = shouldShowYelpTile || shouldShowNavTile || shouldShowNextTile || shouldShowDavoTile;
    const carouselTiles = shouldShowCarousel ? [] : null;
    if (shouldShowCarousel) carouselTiles.push(exploreIntegrationsTile);
    if (shouldShowYelpTile) carouselTiles.push(yelpTile);
    if (shouldShowNextTile) carouselTiles.push(nextTile);
    if (shouldShowNavTile) carouselTiles.push(navTile);
    if (shouldShowDavoTile) carouselTiles.push(davoTile);

    const logo = isNorth ? IconUtil.getIcon('NorthLogo') : IconUtil.getIcon('PaymentsHubLogo');
    const powerUserIcon = IconUtil.getIcon('PowerUser', isNorth ? '#1857F7' : '#00C9BA', '215px');

    const isOwnerAdmin = role === 'owner' || role === 'admin';
    const isManager = role === 'manager';
    const quickReportsVisible = isOwnerAdmin || (isManager && selectedMerchantAccount.manager_activity_access_enabled);
    const defaultHeading = isNorth ? 'GoFurtherNorthFeature' : 'GoFurtherPaymentsHubFeature';
    const isDemo = UserUtil.isDemoAccount(user);
    const isMBTorHBT = UserUtil.isMybizperks(user) || UserUtil.isHbt(user)

    return (
      <>
        <Page
          title={'Dashboard'}
          hideBreadcrumbs
        >
          <section className={todayClass}>

          <Box className='dashboardHeaderBox' sx={dashboardHeaderStyle}>
            <p>{t('welcome')}{employeeName ? `, ${employeeName}` : null}</p>
            <p>{currentUserRole} | {buttonText[currentPlanAccount]} | {DateUtil.getTodayWithoutTime().format('MMMM D, YYYY')}</p>
          </Box>

          <Box sx={dashboardStyles(quickReportsVisible)}>

            { historicHardwareMode ?

              <Box className='Status historicHardwareModeBanner'>
                <HistoricHardwareModeBanner dispatch={dispatch} t={t} />
              </Box>

              : <>
                <Box className='Status'>
                  {isDemo && (
                    <North>
                      {/* TODO: wire up application state with API */}
                      <NorthDashboardBanner dispatch={dispatch}
                                            onActivateAccount={() => {
                                            }}
                                            t={t}
                      />
                    </North>
                  )}
                </Box>

                <Box className='Links'>
                  <QuickActions history={history}
                      role={role}
                      isMBTorHBT={isMBTorHBT}
                      user={user}
                      selectedMerchantAccount={selectedMerchantAccount}
                      t={t}
                      merchantSettings={merchantSettings}
              />
                </Box>

                {quickReportsVisible && (
                  <Box className='Reports'>
                    <TopReports {...this.props}
                        isNorth={isNorth}
                        role={role}
                        isMBTorHBT={isMBTorHBT}
                        selectedMerchantAccount={selectedMerchantAccount}
                        t={t}
                    />
                  </Box>
                )}

                { !isMBTorHBT &&
                  <>
                    <Feature
                      heading='MoreFeatures'
                      icon={tapToPayIcon}
                      link='https://www.payanywhere.com/why-payanywhere/tap-to-pay-on-iphone'
                      t={t}
                      text='TapToPay.Text'
                      title='TapToPayLabel'
                    />
                    <Feature
                      icon={loyaltyIcon}
                      t={t}
                      text='LoyaltyDescription'
                      title={isNorth ? 'LoyaltyNorth' : 'LoyaltyPayAnywhere'}
                    />
                    <Feature
                      icon={reputationManagementIcon}
                      t={t}
                      text='ReputationManagementDescription'
                      title='ReputationScreen.ReputationManagement'
                    />
                    <Feature
                      customClass={'initialRow'}
                      background='gradient'
                      heading={defaultHeading}
                      icon={premiumPlusIcon}
                      isNorth={isNorth}
                      t={t}
                      text='ReputationManagementDescription'
                      title={isNorth ? 'GoFurtherNorthFeature' : 'GoFurtherPaymentsHubFeature'}
                      titlePos='up'
                    />
                    {
                      shouldShowCarousel ?
                        <IntegrationsCarousel>
                          {
                            carouselTiles.map((tile) => (<Box key={tile.props.text}>{tile}</Box>))
                          }
                        </IntegrationsCarousel>
                        :
                        <Box>
                          {exploreIntegrationsTile}
                        </Box>
                    }
                    <Feature
                      customClass='powerUserFeature'
                      icon={powerUserIcon}
                      isNorth={isNorth}
                      link='https://vimeo.com/showcase/5592900'
                      t={t}
                      text='PowerUserText'
                      title='PowerUser'
                      titlePos='up'
                    />
                  </>
                }
              </>
            }

          </Box>

          <Box className='dashboardFooterBox' sx={dashboardFooterStyle}>
            {logo}
            <p>{t(isNorth ? 'DashboardNorthDisclaimer' : 'DashboardDisclaimer')}</p>
          </Box>

        </section>
        </Page>
      </>
    );
  }
}

Dashboard.defaultProps = {
  isFetching: true,
};
