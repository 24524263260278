/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {MenuItem} from '@mui/material';
import { connect } from 'react-redux';
import moment from 'moment';
import { change, Field, reduxForm } from 'redux-form';
import TextUtil from '../../util/FormatTextUtil';
import LabelUtil from '../../util/LabelUtil';
import Validator from '../../util/Validator';
import states from '../../../constants/states';
import Checkbox from '../../shared/Checkbox';
import CustomTextField from '../../shared/TextField';
import Select from '../../shared/Select';
import CustomDatePicker from '../../shared/DatePicker';


const styles = {
  labelStyle: {color: '#FFFFFF'},
  inputStyle: {color: '#393A3F'},
  birth: {
    display: 'inline-block',
    width: '100%'
  },
  checkbox: {
    color: '#888C8D'
  }
};

const label = LabelUtil.getLabel();

export const validate = values => {
  if (!_.isEmpty(values, label.abbreviation)) {
    return Validator.validateAddOwnerForm(values);
  } else {
    return {};
  }
};

export class OwnerFormContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {handleSubmit, pristine, reset, submitting, t, initialValues} = this.props;
    const todayMomentObject = moment();

    return (
      <form onSubmit={handleSubmit}>
        <div className='ownerForm'>
          <div className='flexRow'>
            <Field
              component={CustomTextField}
              name='first_name'
              label={t('OwnerForm.FirstNameLabel')}
              className={'thirdScreen'}
            />
            <Field
              component={CustomTextField}
              name='last_name'
              label={t('OwnerForm.LastNameLabel')}
              className={'thirdScreen'}
            />
            <Field
              component={CustomTextField}
              name='owner_ssn'
              label={t('Ssn')}
              className={'thirdScreen'}
              normalize={TextUtil.formatFullSSN}
            />
          </div>
          <div className='flexRow'>
            <Field
              label={t('OwnerForm.DateOfBirthLabel')}
              className='thirdScreen'
              component={CustomDatePicker}
              name='owner_dob'
              style={styles.birth}
              maxDate={todayMomentObject}
              input={{
                value: moment(initialValues.owner_dob),
                onChange: (date) => this.props.dispatch(change('ownerForm', 'owner_dob', date)),
              }}
              t={t}
            />

            <Field
              component={CustomTextField}
              name='contact_priority'
              label={t('OwnerForm.ContactPriorityLabel')}
              className={'thirdScreen'}
              normalize={TextUtil.formatWholeNumber}
              maxLength='3'
            />
            <Field
              component={CustomTextField}
              name='percentage_of_ownership'
              label={t('OwnerForm.PercentageOfOwnership')}
              className={'thirdScreen'}
              normalize={TextUtil.formatWholeNumber}
              maxLength='3'
            />

          </div>
          <div className='flexRow'>
            <Field
              label={t('OwnerForm.TitleLabel')}
              component={Select}
              styles={{maxHeight: 300, height: 48}}
              className='halfScreen'
              name='contact_type'
              ref='contact_type'
              t={t}
            >
              <MenuItem value='owner'>
                {t('OwnerForm.OwnerLabel')}
              </MenuItem>
              <MenuItem value='president'>
                {t('OwnerForm.PresidentLabel')}
              </MenuItem>
              <MenuItem value='ceo'>
                {t('OwnerForm.CeoLabel')}
              </MenuItem>
              <MenuItem value='vice_president'>
                {t('OwnerForm.VicePresidentLabel')}
              </MenuItem>
              <MenuItem value='partner'>
                {t('OwnerForm.PartnerLabel')}
              </MenuItem>
              <MenuItem value='manager'>
                {t('OwnerForm.ManagerLabel')}
              </MenuItem>
            </Field>
            <Field
              label={t('OwnerForm.EmailLabel')}
              component={CustomTextField}
              className='halfScreen'
              name='email'
            />
          </div>
          <div className='flexRow'>
            <Field
              label={t('OwnerForm.PhoneNumberLabel')}
              component={CustomTextField}
              className='halfScreen'
              normalize={TextUtil.formatPhoneNumber}
              name='phone_number'
            />
            <Field
              label={t('OwnerForm.PhoneExtensionLabel')}
              component={CustomTextField}
              className='halfScreen'
              normalize={TextUtil.formatWholeNumber}
              name='phone_ext'
              maxLength='4'
            />
          </div>
          <div className='flexRow'>
            <Field
              label={t('OwnerForm.AddressLabel')}
              component={CustomTextField}
              className='halfScreen'
              name='address'
              maxLength='30'
            />
            <Field
              label={t('OwnerForm.SuitAptLabel')}
              component={CustomTextField}
              className='halfScreen'
              name='address_2'
              maxLength='30'
            />
          </div>
          <div className='flexRow'>
            <Field
              label={t('OwnerForm.CityLabel')}
              component={CustomTextField}
              className='thirdScreen'
              name='city'
            />
            <Field
              label={t('OwnerForm.StateLabel')}
              disabled={this.props.disableFields}
              component={Select}
              styles={{maxHeight: 300, height: 48}}
              className='thirdScreen'
              name='state'
              ref='state'
              t={t}
            >
              {states.content.map((state, i) => {
                return <MenuItem value={state} key={i}>{state}</MenuItem>;
              })}
            </Field>
            <Field
              label={t('OwnerForm.ZipLabel')}
              component={CustomTextField}
              disabled={this.props.disableFields}
              className='thirdScreen '
              normalize={TextUtil.formatZip}
              name='zip'
            />
          </div>
          <div className='checkboxContainer'>
            <Field
              name='is_personal_guarantor'
              component={Checkbox}
              label={t('OwnerForm.IsPersonalGuarantorLabel')}
              labelStyle={styles.checkbox}
              checked={!!this.value}
              disabled={submitting}
              inputStyle={styles.inputStyle}
            />
            <Field
              name='is_primary_contact'
              component={Checkbox}
              labelStyle={styles.checkbox}
              label={t('OwnerForm.IsPrimaryContactLabel')}
              checked={!!this.value}
              disabled={submitting}
              inputStyle={styles.inputStyle}
            />
          </div>
        </div>
      </form>
    );
  }
}

let OwnerForm = reduxForm({
  form: 'ownerForm',
  validate,
  enableReinitialize: true
})(OwnerFormContainer);

function mapStateToProps(state, ownProps) {
  let initialValues = {};
  let extraOwners = Boolean(state.accountProfile.data && state.accountProfile.data.extraOwners);

  let priorityValues = extraOwners ? state.accountProfile.data.extraOwners.map(owner => owner.contact_priority) : [];

  if (state.accountProfile.data) {
    priorityValues.push(state.accountProfile.data.contact_priority);
  }
  priorityValues = ownProps.selectedOwner && ownProps.selectedOwner.contact_priority ? priorityValues.filter(e => e !== ownProps.selectedOwner.contact_priority) : priorityValues;

  let ownershipPercentage = extraOwners ? state.accountProfile.data.extraOwners.reduce((prev, next) => {
    return prev + next.percentage_of_ownership;
  }, state.accountProfile.data.percentage_of_ownership) : 0;

  let first_name = (ownProps.selectedOwner && ownProps.selectedOwner.principal) ? ownProps.selectedOwner.principal.substr(0, ownProps.selectedOwner.principal.indexOf(' ')) : '';
  let last_name = (ownProps.selectedOwner && ownProps.selectedOwner.principal) ? ownProps.selectedOwner.principal.substr(ownProps.selectedOwner.principal.indexOf(' ') + 1) : '';

  initialValues.ownership_allocated = ownProps.selectedOwner && ownProps.selectedOwner.percentage_of_ownership ? ownershipPercentage - ownProps.selectedOwner.percentage_of_ownership : ownershipPercentage;
  initialValues.priority_array = priorityValues;
  initialValues.is_personal_guarantor = ownProps.selectedOwner && ownProps.selectedOwner.is_personal_guarantor || false;
  initialValues.is_primary_contact = ownProps.selectedOwner && ownProps.selectedOwner.is_primary_contact || false;
  initialValues.first_name = first_name || '';
  initialValues.last_name = last_name || '';
  initialValues.owner_ssn = ownProps.selectedOwner && ownProps.selectedOwner.principal_ssn ? TextUtil.formatSSN(ownProps.selectedOwner.principal_ssn) : '';
  initialValues.contact_priority = ownProps.selectedOwner && ownProps.selectedOwner.contact_priority || '';
  initialValues.percentage_of_ownership = ownProps.selectedOwner && ownProps.selectedOwner.percentage_of_ownership || '';
  initialValues.contact_type = ownProps?.selectedOwner?.principal_title?.toLowerCase() || '';
  initialValues.email = ownProps.selectedOwner && ownProps.selectedOwner.principal_email || '';
  initialValues.phone_number = ownProps.selectedOwner && ownProps.selectedOwner.principal_phone ? TextUtil.formatPhoneNumber(ownProps.selectedOwner.principal_phone) : '';
  initialValues.address = ownProps.selectedOwner && ownProps.selectedOwner.principal_address || '';
  initialValues.city = ownProps.selectedOwner && ownProps.selectedOwner.principal_city || '';
  initialValues.state = ownProps.selectedOwner && ownProps.selectedOwner.principal_state || '';
  initialValues.zip = ownProps.selectedOwner && ownProps.selectedOwner.principal_zip || '';
  initialValues.owner_dob = ownProps?.selectedOwner?.owner_dob;
  return {initialValues};
}

OwnerForm = connect(mapStateToProps)(OwnerForm);

export default OwnerForm;
