'use strict';

/* istanbul ignore else  */
if (process.env.RUNNING_TESTS === 'true') {
  console.log('Running tests is ' + process.env.RUNNING_TESTS);
  require('../tests/localStorageHelper');
}

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import apiAccessReducer from './apiAccessReducer';
import authReducer from './authReducer';
import cardPresentReducer from './cardPresentReducer';
import batchesReducer from './batchesReducer';
import userReducer from './userReducer';
import transactionReducer from './transactionReducer';
import activitiesReducer from './activitiesReducer';
import todayReducer from './todayReducer';
import signatureImageReducer from './signatureImageReducer';
import salesItemImagesReducer from './salesItemImagesReducer';
import itemsReducer from './itemsReducer';
import customersReducer from './customersReducer';
import employeesReducer from './employeesReducer';
import virtualTerminalReducer from './virtualTerminalReducer';
import merchantSettingsReducer from './merchantSettingsReducer';
import reportsReducer from './reportsReducer';
import accountProfileReducer from './accountProfileReducer';
import alertsReducer from './alertsReducer';
import depositsReducer from './depositsReducer';
import visibilityFilterReducer from './visibilityFilterReducer';
import userExperienceReducer from './userExperienceReducer';
import formsReducer from './formsReducer';
import equipmentOrderReducer from './equipmentOrderReducer';
import feedbackReducer from './feedbackReducer';
import fingerprintReducer from './fingerprintReducer';
import smsReceiptReducer from './smsReceiptReducer';
import taxesReducer from './taxesReducer';
import validationHistoryReducer from './validationHistoryReducer';
import statementsReducer from './statementsReducer';
import disputesReducer from './disputesReducer';
import invoicesReducer from './invoicesReducer';
import shoppingCart from './shoppingCartReducer';
import verificationReducer from './verificationReducer';
import communicationReducer from './communicationsReducer';
import devicesReducer from './devicesReducer';
import languageReducer from './languageReducer';
import reputationManagementReducer from './reputationManagementReducer';
import loyaltyVpcReducer from './loyaltyVpcReducer';
import paymentLinksReducer from './paymentLinksReducer';
import activationReducer from './activationReducer';
import bulkImportsReducer from './bulkImportsReducer';

const reducers = (history) => ({
  accountProfile: accountProfileReducer,
  activities: activitiesReducer,
  alerts: alertsReducer,
  apiAccess: apiAccessReducer,
  auth: authReducer,
  batches: batchesReducer,
  bulkImports: bulkImportsReducer,
  cardPresent: cardPresentReducer,
  communications: communicationReducer,
  customers: customersReducer,
  deposits: depositsReducer,
  disputes: disputesReducer,
  equipmentOrder: equipmentOrderReducer,
  employees: employeesReducer,
  feedback: feedbackReducer,
  fingerprint: fingerprintReducer,
  forms: formsReducer,
  invoices: invoicesReducer,
  items: itemsReducer,
  merchantSettings: merchantSettingsReducer,
  reports: reportsReducer,
  salesItemImages: salesItemImagesReducer,
  signatureImage: signatureImageReducer,
  smsReceipt: smsReceiptReducer,
  statements: statementsReducer,
  shoppingCart: shoppingCart,
  taxes: taxesReducer,
  transactions: transactionReducer,
  today: todayReducer,
  user: userReducer,
  userExperience: userExperienceReducer,
  validationHistory: validationHistoryReducer,
  verification: verificationReducer,
  virtualTerminal: virtualTerminalReducer,
  visibilityFilter: visibilityFilterReducer,
  form: formReducer,
  toastr: toastrReducer,
  devices: devicesReducer,
  language: languageReducer,
  reputationManagement: reputationManagementReducer,
  loyaltyVpc: loyaltyVpcReducer,
  paymentLinks: paymentLinksReducer,
  activation: activationReducer,
  router: connectRouter(process.env.RUNNING_TESTS === 'true' ? history ? history : {} : history),
});

const portalAppReducer = (history) => combineReducers(reducers(history));

export default portalAppReducer;

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

