const styles = (theme) => ({

  '.paymenthubs': {
    fontFamily: theme.typography.primaryFontFamily,

    '.grecaptcha-badge': {
      display: 'none',
    },
    '& .today': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.grecaptcha-protection': {
      margin: '20px 20px 35px',
      fontSize: '11px',
      textAlign: 'center',
      color: '#333',
      fontFamily: theme.typography.primaryFontFamilyRegular,
      '& a': {
        margin: '0 3px',
        color: 'inherit',
      },
    },
    '.login, .clientService': {
      '.signUpContainer, .requestTokenContainer, .forgotPasswordContainer': {
        '& a': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
      '.forgotPasswordContainer': {
        '.arrowIcon': {
          '& svg': {
            fill: theme.palette.labelColor.main,
          },
        },
      },
    },
    '.customTooltip': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
      '&.place-right': {
        '&:after': {
          borderRightColor: `${theme.palette.labelColor.main} !important`,
          borderRightStyle: 'solid !important',
          borderRightWidth: '6px !important',
        },
      },
    },
    '.isPrimaryColor': {
      fill: `${theme.palette.labelColor.main} !important`,
      color: `${theme.palette.labelColor.main} !important`,
    },
    'header': {
      '.navSelected': {
        color: theme.palette.labelColor.main,
      },
      'nav': {
        '& a:hover': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '.linkButton:hover': {
      color: `${theme.palette.labelColor.main} !important`,
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.pageWrap, .pageWrapWithNotice': {
      '.detail': {
        '.bar': {
          '.barButton': {
            '& span': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
    },
    '.inventoryItems': {
      '.activeTab': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '.inactiveTab': {
        color: `${theme.palette.common.regularColor} !important`,
      },
    },
    'div.stock .stockAlert svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '.subMenuContainer': {
      '.active': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '.subMenuItem.active': {
        color: `${theme.palette.labelColor.main} !important`,
        '& svg': {
          fill: theme.palette.labelColor.main,
        },
      },
      '.subMenuIconContainer.active': {
        color: theme.palette.labelColor.main,
      },
    },
    '.searchBar': {
      '& > div': {
        '.addButton': {
          backgroundColor: theme.palette.labelColor.main,
        },
      },
    },
    '.material-icons.active svg': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '.portalCheckbox.disabledCheckbox': {
      '& svg + svg': {
        fill: `${theme.palette.labelColor.main} !important`,
        stroke: 'none !important',
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.portalCheckbox': {
      '& svg + svg': {
        fill: `${theme.palette.labelColor.main} !important`,
        stroke: 'none !important',
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.addButton': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
    },
    '.login': {
      '.loginContainer': {
        '.signUpContainer, .forgotPasswordContainer, .requestTokenContainer': {
          '& a': {
            color: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '.activitySales, .activitySalesWithNotice': {
      '.salesSummaryRow': {
        '.summaryPanel': {
          '.summaryPanelHeader': {
            '.summaryPanelCount': {
              backgroundColor: theme.palette.labelColor.main,
            },
          },
        },
      },
    },
    '.activityReports': {
      '.reportRow': {
        '.reportActions': {
          '.exportButtonContainer': {
            color: `${theme.palette.labelColor.main} !important`,
            '& svg': {
              fill: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
    },
    '.exportSelector': {
      color: `${theme.palette.labelColor.main} !important`,
      '.exportButtons': {
        '.label': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
      '.exportIconWrapper': {
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    'svg.customImportIcon path': {
      fill: `${theme.palette.labelColor.main}`,
    },
    '.statements': {
      '.statementsLink': {
        color: `${theme.palette.labelColor.main} !important`,
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '.userAccountMenuItem': {
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
      '&:hover': {
        background: `${theme.palette.labelColor.main} !important`,
        '& svg': {
          fill: `${theme.palette.text.primary} !important`,
        },
      },
    },
    '.accountProfile': {
      '.pageWrap': {
        '.membershipInfo': {
          '.membershipContainer': {
            '.membershipButton': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
    },
    '.updatePassword, .updateEmail, .updateTwoFactor, .updateProfile, .showHide, .linkLike, .relatedTransactions, .editButton, .membershipButton, .toastrButton, .updateLanguage, .updateManageRememberedDevices': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '.merchantAccountRow.active': {
      color: `${theme.palette.labelColor.main} !important`,
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.accountMenuIcon svg': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '.employeeReportMenuItem': {
      fill: `${theme.palette.labelColor.main} !important`,
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .fraudExport': {
      top: 0,
    },
    '& .exportContainer .downloadLink': {
      ...theme.sharedStyles.link(theme),
      'color': `${theme.palette.labelColor.main} !important`,
      'fill': `${theme.palette.labelColor.main} !important`,
    },
    '& .depositTransactionsDetail .depositTransactionLink': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .formLinks a, & .taxLinks a, & .secondaryText a, & .equipmentLinks a': {
      ...theme.sharedStyles.link(theme),
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .inventoryItems .tab': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .customCheckBox svg': {
      'fill': '#ffffff !important',
      'stroke': 'common.gray !important',
      'color': 'common.gray !important',
    },
    '& .customCheckBox svg + svg': {
      'stroke': 'none !important',
    },
    '& .mobileNavigationItem.active a': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .mobileContainer .titlePanel .titlePanelSubText, & .testDriveContainer .titlePanel .titlePanelSubText': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .mobileFooterToggle .active': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .reportsToolbar .backButton:hover, & .reportsToolbar .mobileExportButton:hover': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .loginButton .loginFront, & .submitButton .submitFront': {
      'backgroundColor': `${theme.palette.labelColor.main} !important`,
    },
    '& .loginButton .loginArrow, & .submitButton .submitArrow': {
      'backgroundColor': `${theme.palette.labelColor.main} !important`,
    },
    '& .businessForm .editButton span': {
      color: `${theme.palette.labelColor.main} !important`,
      cursor: 'pointer',
    },
    '& .resetTokenButton': {
      'width': '150px !important',
      '& .loginFront, & .submitFront': {
        'backgroundColor': `${theme.palette.labelColor.main} !important`,
      },
      '& .loginArrow, & .submitArrow': {
        'backgroundColor': `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .niceLink a': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .closedAccountContainer .closedAccountIndicator': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .closedAccountContainer .closedAccountIcon svg': {
      'fill': `${theme.palette.labelColor.main} !important`,
    },
    '& .isLockedIcon': {
      'fill': `${theme.palette.labelColor.main} !important`,
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .isArchivedIcon': {
      'fill': `${theme.palette.labelColor.main} !important`,
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .resendInviteContainer .resendIconContainer svg': {
      'fill': `${theme.palette.labelColor.main} !important`,
    },
    '& .buttonInverse': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .businessOnlinePayments .formHeading .action': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .businessOnlinePayments .formDescription .formDescriptionLink': {
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .businessOnlinePayments .paymentInformation .cardPresentIndicator a, & .businessOnlinePayments .paymentInformation .selectPaymentMethodButton a': {
      ...theme.sharedStyles.link(theme),
      'color': `${theme.palette.labelColor.main} !important`,
    },
    '& .customerRatingContainer .footer': {
      'background': `${theme.palette.labelColor.main} !important`,
      '& .branding': {
        'visibility': 'hidden',
      },
    },
    '.today, .todayWithNotice': {
      '.gridContainer': {
        '.grid': {
          '.col': {
            '&Tips': {
              '.tips': {
                backgroundImage: 'url(\'/images/paymenthubs/today_tips_ph_navigating.png\')'
              },
              '.tipsRow': {
                '.heading': {
                  paddingRight: '15%',
                },
                '.todayLink': {
                  marginTop: '57px',
                },
              },
            },
            '&Feedback': {
              '.tips': {
                '&:before': {
                  background: 'url(\'/images/paymenthubs/today_feedback.png\') no-repeat 170px 175px/170px 170px ' + theme.palette.common.white,
                },
                '.tipsRow': {
                  alignItems: 'flex-start !important',
                  zIndex: 1,
                  '.heading': {
                    paddingTop: '15px',
                    color: theme.palette.text.primary,
                    fontFamily: theme.typography.primaryFontFamilySemiBold,
                    fontSize: '22px !important',
                    paddingRight: '10%',
                    margin: '10% 0 0 0',
                    paddingBottom: '30px',
                    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
                      marginTop: '20px',
                      fontSize: '23px !important',
                    },
                  },
                  '.text': {
                    ...theme.sharedStyles.bodyText(theme),
                    color: theme.palette.text.primary,
                  },
                  '.reverseLinkAsButton': {
                    boxShadow: 'none !important',
                    alignSelf: 'flex-start',
                    marginTop: '15px',
                    height: '15px !important',
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                    'svg': {
                      width: '18px !important',
                    },
                  },
                },
              },
            },
            '.titleContainer': {
              '.title': {
                '.redirect': {
                  color: theme.palette.labelColor.main,
                  '&:visited': {
                    color: theme.palette.labelColor.main,
                  },
                  'svg': {
                    fill: theme.palette.labelColor.main,
                  },
                },
              },
            },
          },
          '.deposits': {
            backgroundColor: theme.palette.labelColor.main,
          },
          '.trends': {
            '.trendsRow': {
              '.statisticsContainer': {
                '.stars': {
                  'svg': {
                    fill: theme.palette.labelColor.main,
                  },
                },
              },
            },
          },
        },
      },
      '.todayLink': {
        backgroundColor: theme.palette.labelColor.main,
        '.iconLink': {
          backgroundColor: theme.palette.labelColor.main,
        },
      },
      '.action a': {
        color: theme.palette.labelColor.main,
      },
    },
    '.endSeriesButton, .invoiceDetail .barButton, .linkButton, .markInvoicePaidButton, .removeAutopayButton, .removeCardButton, .resendInvoiceButton, .seeTransactionsButton, .viewInvoiceSeriesButton, .viewSeriesButton': {
      '& span': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.recurringDetails': {
      '& button': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.clearButton': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '.barButton': {
      '& span': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.buttonWrapper': {
      '& .buttonColor': {
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '.openTransactionsHeader, .incompleteTransactionsFilter': {
      '& .filterIconContainer': {
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '.detail': {
      '& .bar': {
        '& svg:hover': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
        '& .barButton': {
          '& span': {
            color: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '.contactFooter': {
      '& p': {
        ...theme.sharedStyles.contentLabel(theme),
        textAlign: 'center',
        color: theme.palette.labelColor.main,
        padding: 0,
        '& a': {
          textDecoration: 'none',
          color: theme.palette.labelColor.main,
        },
      },
      '& .divider': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.addMerchantAccount': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '.addAccountDialog': {
      '& .activeField label': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .errorField.activeField label': {
        color: `${theme.palette.errorColor} !important`,
      },
      '& hr:first-of-type, & .activeField hr:first-of-type': {
        borderBottom: `1px solid ${theme.palette.labelColor.main} !important`,
      },
      '& hr + hr, & .activeField hr + hr': {
        borderBottom: `2px solid ${theme.palette.labelColor.main} !important`,
      },
      '& .errorField hr:first-of-type': {
        borderBottom: `1px solid ${theme.palette.errorColor} !important`,
      },
      '& .errorField hr + hr': {
        borderBottom: `2px solid ${theme.palette.errorColor} !important`,
      },
    },
    '.cornerCheckMark': {
      '& .st0': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.mainMenuItem': {
      '& .st0': {
        fill: `${theme.palette.text.primary} !important`,
      },
      '&.selected': {
        '& .st0': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '.integrationsPage': {
      '& .accountIntegrations': {
        '& .integrationContainer': {
          '& .integrationInformationContainer': {
            '& .learnMoreHomebase, & .disconnectQuickBooks': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
      '& .integrationDetails .integrationInformationDetailsContainer .integrationStatusDetails .integrationStatusDetailsAndActions .integrationStatusDetailsActions .integrationStatusDetailsButtons .launchButtonContainer span': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .returnToIntegrations': {
        color: `${theme.palette.labelColor.main} !important`,
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
      '& .imageGallery': {
        '& .image-gallery-left-nav:hover::before, & .image-gallery-right-nav:hover::before': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '.midSelector': {
      '& .active': {
        color: theme.palette.labelColor.main,
        '& span': {
          '& svg': {
            fill: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '.cardReaderStatusWell': {
      '& .cardReaderStatusAction': {
        '& a': {
          ...theme.sharedStyles.link(theme),
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '.settings': {
      '& .banner': {
        backgroundImage: 'url(\'/images/paymenthubs/settings.png\')',
      },
      '& .input, & .receiptButton': {
        '& .button': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '.openBatchButton.selected': {
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '._elevio_launcher': {
      '& button': {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
    },
    '._elevio_navbar': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
    },
    '._elevio_module_icon:hover': {
      '& > div:first-of-type': {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.batchesDetailTransactions': {
      '& .buttonHolder': {
        display: 'flex',
        '& .showButton': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '.linkAsButton': {
      background: `linear-gradient(to top right, ${theme.palette.common.phGradientOne}, ${theme.palette.common.phGradientTwo}) !important`,
    },
    '.reverseLinkAsButton': {
      background: `${theme.palette.common.white} !important`,
      color: `${theme.palette.labelColor.main} !important`,
      'svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.raisedButton': {
      'button:disabled, button[disabled]': {
        background: `${theme.palette.common.disabledGray} !important`,
      },
      'button': {
        background: `linear-gradient(to top right, ${theme.palette.common.phGradientOne}, ${theme.palette.common.phGradientTwo}) !important`,
        'span': {
          color: `${theme.palette.common.white} !important`,
        },
        'div': {
          'div': {
            'span': {
              color: `${theme.palette.common.white} !important`,
            },
          },
        },
      },
    },
    '.optionsList': {
      fontFamily: theme.typography.primaryFontFamilyMedium,
      listStyle: 'none',
      padding: 0,
      '&Item': {
        color: theme.palette.labelColor.main,
        '.optionsHolder': {
          span: {
            color: theme.palette.labelColor.main,
            svg: {
              fill: theme.palette.labelColor.main,
            },
            a: {
              color: theme.palette.labelColor.main,
            },
          },
        },
      },
    },
    '.optionsContainer .favoriteButton label': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '.filter': {
      '&Logo': {
        backgroundImage: 'url(\'/images/paymenthubs/logoSmall.svg\')',
        backgroundSize: '35px',
        width: '45px',
        height: '35px',
      },
      '&Item': {
        '&Selected': {
          color: `${theme.palette.labelColor.main}`,
          '&.filterItemDisabled': {
            fontFamily: `${theme.typography.primaryFontFamilyMedium} !important`,
          },
        },
        '&Selected .filterItemDisabled, &Disabled': {
          color: `${theme.palette.common.gray} !important`,
          cursor: 'auto'
        },
      },
    },
    '.masterTable': {
      'table': {
        'tbody': {
          'tr': {
            '.activeHeader': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
    },
    '.accountSupplies': {
      '.accountSuppliesWrapper': {
        '.itemsList': {
          '.itemHeader': {
            '.activeHeader': {
              color: `${theme.palette.labelColor.main} !important`,
            },
            '.iconHeader': {
              'svg': {
                fill: `${theme.palette.labelColor.main} !important`,
              },
            },
          },
        },
      },
      '.supplyContainer': {
        '.suppliesGridContainer': {
          '.suppliesGrid': {
            '.supplyActions': {
              '.addButtonContainer': {
                color: `${theme.palette.labelColor.main} !important`,
              },
            },
          },
        },
      },
    },
    '.orderSummaryDialog': {
      '.orderSummary': {
        '.orderSummaryClearButton': {
          '.clearButton': {
            color: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '.tableRow, .ReactVirtualized__Table__headerRow, .ReactVirtualized__Table__row': {
      'th, .ReactVirtualized__Table__headerColumn': {
        '.activeHeader': {
          color: `${theme.palette.labelColor.main} !important`,
        },
        '.iconHeader': {
          'svg': {
            fill: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '.toolbar': {
      '.logoBar': {
        'h1': {
          margin: '0',
          '.logo': {
            backgroundImage: 'url(\'/images/paymenthubs/logoSmall.png\')',
          },
        },
      },
    },
    '.detailPanel': {
      '.viewTransactionButton': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '.dueDate': {
        'button': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
      '.sendDateSelect': {
        'button': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '.w9': {
      'a': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .assistanceDialog .formCopyLink .textWithLink .copyOfForm a, & .assistanceDialog .formCopyLink .textWithLink .correctionOfForm a': {
      textDecoration: 'none',
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .customCheckbox > div svg:last-child': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .mobileTable .tableFirst': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .accountProfile .pageWrap .flatButtonLink span, & .accountProfile .pageWrapWithNotice .flatButtonLink span': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& div.merchantApplicationSection .merchantApplicationBody .merchantApplicationIcon svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& div.merchantApplicationSection .merchantApplicationBody .merchantApplicationDescriptionTitle': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .failedPaymentInfo .failureButtonContainer .failureResendButton': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .settings .settingsFormContainer .formHeading.banking span.edit': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .editButtonContainer span': {
      color: theme.palette.labelColor.main,
    },
    '& .pagination li.active': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .paymentPortal .sideContainer .panel .list .active': {
      color: theme.palette.labelColor.main,
    },
    '& .paymentPortal .sideContainer .panel .footer': {
      marginTop: 'auto',
      '& p': {
        fontSize: '16px',
        lineHeight: '1.5',
        marginBottom: '0',
        '& b': {
          fontWeight: '800',
        },
        '& span': {
          color: theme.palette.paymentAddressColor,
        },
      },
      '& .branding .img': {
        width: '30px',
        height: '33px',
        backgroundImage: 'url(\'/images/paymenthubs/logoSmall.png\')',
      },
    },

  },
  '.mobileDevice': {
    '._elevio_launcher': {
      div: {
        display: 'none !important', // hides elevio needs help button
      },
    },
  },
});


export default styles;
