import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import numeral from 'numeral';
import {toastr} from 'react-redux-toastr';

export function getMerchantSettings(user) {

  let endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/settings';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.merchantSettingsRequest, actionTypes.merchantSettingsSuccess, actionTypes.merchantSettingsFailure]
    }
  };
}

export function getCsSettings(user) {

  let endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/settings';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.csSettingsRequest, actionTypes.csSettingsSuccess, actionTypes.csSettingsFailure]
    }
  };
}

export function getMerchantStatus(user) {
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/status`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.merchantStatusRequest, actionTypes.merchantStatusSuccess, actionTypes.merchantStatusFailure]
    }
  }
}

export function partialUpdateSettings(body, user) {
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/settings`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      types: [actionTypes.merchantSettingsUpdateRequest, actionTypes.merchantSettingsUpdateSuccess, actionTypes.merchantSettingsUpdateFailure],
      body: body
    }
  };

}

export function updateSettings(body, user) {
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/settings`;

  const whiteListBody = {
    merchant_settings: _.omit(body.merchant_settings, ['created_date', 'logo_image_id']),
    user_settings: _.omit(body.user_settings, 'created_date'),
    loyalty_vpc: body.loyalty_vpc,
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      types: [actionTypes.merchantSettingsUpdateRequest, actionTypes.merchantSettingsUpdateSuccess, actionTypes.merchantSettingsUpdateFailure],
      body: whiteListBody
    }
  };

}

export function updateMerchantSettings(user, values, type) {

  return (dispatch, getState) => {

    const body = {};

    const { merchantSettings } = getState();

    body.merchant_settings = merchantSettings.merchantSettings || {};
    body.user_settings = merchantSettings.userSettings;

    if (type === 'Cashier') {

      let limit = numeral(values.refundVoidLimitField);
      limit = (values.refundVoidEnabledField) ? limit._value : 0;

      body.merchant_settings.cashier_refund_void_enabled = values.refundVoidEnabledField;
      body.merchant_settings.cashier_refund_void_limit = limit;
      body.merchant_settings.express_item_taxable = values.taxItemEnabledField;
      body.merchant_settings.barcode_search_enabled = values.barcodeSearchEnabledField;
      body.merchant_settings.express_category_enabled = values.categoryEnabledField;

      body.merchant_settings.ignore_avs_failure = !values.avsMismatchAlertEnabledField;

    } else {
      // Manager Role Settings
      body.merchant_settings.manager_activity_access_enabled = values.managerActivityAccess;
      body.merchant_settings.manager_online_payments_access_enabled = values.managerOnlinePaymentAccess;
      body.merchant_settings.manager_can_access_invoices = values.managerInvoiceAccess;
      body.merchant_settings.manager_can_access_payment_links = values.managerPaymentLinkAccess;
      body.merchant_settings.manager_can_access_reputation_mgmt = values.managerReputationAccess;

      if(body.merchant_settings.street_address?.address1 === null && body.merchant_settings.street_address?.city === null){
        delete body.merchant_settings.street_address;
      }

    }

    if(values.vt_fields){
      body.merchant_settings.custom_fields = {
        ...body.merchant_settings.custom_fields,
        vt_fields: values.vt_fields
      };
    }

    return dispatch(updateSettings(body, user));

  }
}

export function createCustomReports(user, value) {
  return (dispatch, getState) => {

    const body = {}, { merchantSettings } = getState();
    body.merchant_settings = merchantSettings.merchantSettings;
    let reportNameAvailable;
    if (body.merchant_settings?.custom_fields?.custom_report) {
      reportNameAvailable = body.merchant_settings.custom_fields.custom_report.filter(customReport => customReport.name.trim().toLowerCase() === value.name.trim().toLowerCase());
      if (!reportNameAvailable.length) {
        body.merchant_settings.custom_fields.custom_report = [
          ...body.merchant_settings.custom_fields.custom_report,
          value
        ];
      } else {
        toastr.error('Error', 'Report name already exists');
      }
    } else {
      body.merchant_settings['custom_fields'] = {
        ...body.merchant_settings['custom_fields'],
        custom_report: [value]
      };
    }
    if (!reportNameAvailable?.length) {
      body.user_settings = merchantSettings.userSettings;

      return dispatch(updateSettings(body, user)).catch(error => toastr.error('Error', error));
    }
  };
}

export function deleteCustomReportSelected(user) {
  return (dispatch, getState) => {

    const body = {}, { merchantSettings, reports } = getState();
    body.merchant_settings = merchantSettings.merchantSettings;
    if (body.merchant_settings?.custom_fields?.custom_report) {
      body.merchant_settings.custom_fields.custom_report = body.merchant_settings.custom_fields.custom_report.filter(customReport => customReport.name !== reports.customReportSelected.name);
    }
    body.user_settings = merchantSettings.userSettings;

    return dispatch(updateSettings(body, user));
  };
}

export function togglePushNotifications(user, value) {
  return (dispatch, getState) => {

    const body = {}, { merchantSettings } = getState();
    body.merchant_settings = merchantSettings.merchantSettings;
    body.user_settings = merchantSettings.userSettings;
    body.merchant_settings.push_notifications_enabled = value;

    return dispatch(updateSettings(body, user));
  };
}

export function toggleIsISV(user, value) {
  return (dispatch, getState) => {

    const body = {}, { merchantSettings } = getState();
    body.merchant_settings = merchantSettings.merchantSettings;
    body.merchant_settings.is_isv = value;
    body.merchant_settings = _.pick(body.merchant_settings, 'is_isv');

    return dispatch(updateSettings(body, user));
  };
}

export function toggleDavoFeatureEnabled(user, value) {
  return (dispatch, getState) => {
    const body = {}, { merchantSettings } = getState();
    body.merchant_settings = merchantSettings.merchantSettings;
    body.merchant_settings.davo_enabled = value;
    body.merchant_settings = _.pick(body.merchant_settings, 'davo_enabled');

    return dispatch(updateSettings(body, user));
  }
}

export function toggleAutoReportingEnabled(user, value) {
  return (dispatch, getState) => {

    const body = {}, { merchantSettings } = getState();
    body.merchant_settings = merchantSettings.merchantSettings;
    body.merchant_settings.auto_reporting_enabled = value;
    body.merchant_settings = _.pick(body.merchant_settings, 'auto_reporting_enabled');

    return dispatch(updateSettings(body, user));
  };
}

export function toggleLoyaltyFeatureEnabled(user, value) {
  return (dispatch, getState) => {
    const body = {}, { merchantSettings } = getState();

    body.merchant_settings = merchantSettings.merchantSettings;
    body.merchant_settings.loyalty_status = value;
    body.merchant_settings = _.pick(body.merchant_settings, 'loyalty_status');

    return dispatch(updateSettings(body, user));
  };
}

export function updateShowPersonalInfoMerchantSetting(user, value) {

  return (dispatch) => {

    const body = {
      merchant_settings: {
        show_personal_info: !!value
      }
    };
    return dispatch(updateSettings(body, user));
  }
}

export function updateCustomizedReportSettings(user, values) {

  return (dispatch, getState) => {

    const body = {};

    body['merchant_settings'] = {
      report_start : values?.fromTime ?? '' ,
     report_end : values?.toTime ?? ''
     }

    return dispatch(updateSettings(body, user));

  }
}

export function setDummyReportStart(value) {
  return {
    type: actionTypes.setDummyReportStart,
    value
  };
}

export function updateLoyaltyVpcSettings(user, value) {
  return (dispatch) => {
    const body = {};

    if(typeof value.tc_accepted === 'boolean') {
      body.merchant_settings = {loyalty_tc_accepted: value.tc_accepted}
      delete value.tc_accepted;
    }

    if(typeof value.loyalty_card_sms_enabled === 'boolean') {
      body.merchant_settings = {loyalty_card_sms_enabled: value.loyalty_card_sms_enabled};

      if(value.website) {
        body.merchant_settings.website = value.website;
        delete value.website;
      }

      delete value.loyalty_card_sms_enabled;
    }

    body.loyalty_vpc = value;
    return dispatch(updateSettings(body, user));
  };
}

export function getAddOns(user) {
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/add-ons`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.getAddOnsRequest, actionTypes.getAddOnsSuccess, actionTypes.getAddOnsFailure]
    }
  }
}

export function updateAddOn(user, type, enabled) {
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/add-ons`;

  const body = {
    type,
    enabled
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'PUT',
      authenticated: true,
      types: [actionTypes.updateAddOnRequest, actionTypes.updateAddOnSuccess, actionTypes.updateAddOnFailure],
      body
    }
  }
}
/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

