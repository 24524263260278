/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import {updateInvoice, sendInvoice} from '../../actions/invoicesActions';
import {processCreditCardPayment} from '../../actions/transactionsActions';
import {saveCustomer, setCustomer, updateCustomer} from '../../actions/customerActions';
import countryList from '../../constants/countryStateList';
import numeral from 'numeral';
import moment from 'moment';
import IconUtils from './IconUtil';
import FormatTextUtil from './FormatTextUtil';
import DateUtils from './DateUtil';
import { roundToTwoDecimals, handleNegative } from './CommonUtil';
import _ from 'lodash';
import FilterUtil from './FilterUtil';
import LabelUtil from './LabelUtil';
import i18n from '../../locales/i18n';
import FeatureFlagsUtil from './FeatureFlagsUtil';
import {getMomentDateFromUnix} from './DateUtil';

const {t} = i18n;

export const UNSELECTED_INDEX = -1;
export const CUSTOM_DATE_VALUE = 999;
export const INVOICE_FORM_ID = 'invoiceForm';
export const RECEIPT_FORM_ID = 'receiptSettingsForm';
export const CUSTOM_SEND_DATE_VALUE = 'customSendDate';
export const CUSTOM_DUE_DATE_VALUE = 'customDueDate';
export const CUSTOM_END_DATE_VALUE = 'customEndDate';

export const InvoicesStatus = {
  UNKNOWN: 'unknown',
  PAID: 'paid',
  PARTIALLY_PAID: 'partially_paid',
  OVERDUE: 'overdue',
  SCHEDULED: 'scheduled',
  UNPAID: 'unpaid',
  DRAFT: 'draft',
  FAILED: 'failed',
  CANCELED: 'canceled',
  SERIES: 'series'
};

export const InvoiceFilterTypes = {
  INVOICES: 'All Invoices',
  SERIES: 'Series',
  CANCELED: 'Canceled',
  PAID: 'Paid',
  OVERDUE: 'Overdue',
  SCHEDULED: 'Scheduled',
  UNPAID: 'Unpaid',
  DRAFT: 'Drafts',
  FAILED: 'Failed payments',
  BULK: 'Bulk Invoicing'
};

export const CustomerPaymentFilterTypes = {
  OPEN_INVOICES: 'PaymentPortalPreview.OpenInvoices',
  PAYMENT_METHODS: 'PaymentPortalPreview.PaymentMethods',
  PAYMENT_HISTORY: 'PaymentPortalPreview.PaymentHistory',
  SESSION_EXPIRED: 'PaymentPortalPreview.SessionExpired'
};

export const CustomerPaymentFilterOptions = [
  {
    name: CustomerPaymentFilterTypes.OPEN_INVOICES,
    showCountOnSelection: true,
    count: 0
  },
  {
    name: CustomerPaymentFilterTypes.PAYMENT_METHODS
  },
  {
    name: CustomerPaymentFilterTypes.PAYMENT_HISTORY
  }
];

export const InvoiceFormTypes = {
  CREATE: 'create',
  DRAFT: 'draft'
};

export const InvoiceTypeIcons = {
  [InvoicesStatus.PAID]: 'DoneIcon',
  [InvoicesStatus.OVERDUE]: 'PriorityIcon',
  [InvoicesStatus.SCHEDULED]: 'ScheduleIcon',
  [InvoicesStatus.UNPAID]: 'MinusIcon',
  [InvoicesStatus.FAILED]: 'Warning',
  [InvoicesStatus.CANCELED]: 'CancelIcon',
  [InvoicesStatus.DRAFT]: 'EditIcon',
  [InvoicesStatus.SERIES]: 'RecurringIcon'
};

export const CreditCardProcessors = {
  ['American Express']: 'AX',
  ['Discover']: 'DI',
  ['Mastercard']: 'MC',
  ['Visa']: 'VI',
  ['AX']: 'American Express',
  ['DI']: 'Discover',
  ['MC']: 'Mastercard',
  ['VI']: 'Visa'
};

export const FormType = {
  CUSTOM_AMOUNT: 'customAmount',
  ITEMIZED: 'itemized'
};

export const PaymentFrequency = {
  INVOICE: 'invoice',
  SERIES: 'series'
};

export const PaymentMethod = {
  SEND_TO_CUSTOMER: 'sendToCustomer',
  BANKING_ACCOUNT: 'bankingAccount',
  CREDIT_CARD: 'creditCard',
  CARD_PRESENT: 'cardPresent'
};

export const RecurringEnd = {
  NEVER: 'never',
  ON_DATE: 'onDate',
  AFTER_NUMBER_OF_PAYMENTS: 'numberOfPayments'
};

export const EditPaymentDuration = {
  NEXT_ONLY: 'next',
  ALL_FUTURE: 'all'
};

export const FrequencyTimeMeasure = {
  days: 'day(s)',
  weeks: 'week(s)',
  months: 'month(s)',
  years: 'year(s)'
};

export const PriceName = {
  BASE_PRICE: 'Base Price'
}

export const FormDefaultOptions = {
  invoiceFormTypeOptions: [
    { text: 'Custom amount', value: FormType.CUSTOM_AMOUNT },
    { text: 'Itemized invoice', value: FormType.ITEMIZED }
  ],
  paymentFrequencyOptions: [
    { text: 'One time', value: PaymentFrequency.INVOICE },
    { text: 'Recurring', value: PaymentFrequency.SERIES }
  ],
  paymentMethodOptions: [
    { text: 'Send to customer', value: PaymentMethod.SEND_TO_CUSTOMER },
  ],
  sendDateOptions: [
    { text: 'Immediately', value: 0 },
    { text: '7 Days', value: 7 },
    { text: '14 Days', value: 14 },
    { text: '30 Days', value: 30 }
  ],
  dueDateOptions: [
    { text: 'Upon Receipt', value: 0 },
    { text: '7 Days after Send Date', value: 7 },
    { text: '14 Days after Send Date', value: 14 },
    { text: '30 Days after Send Date', value: 30 }
  ],
  endsOptions: [
    { text: 'Never', value: RecurringEnd.NEVER },
    { text: 'On date', value: RecurringEnd.ON_DATE },
    { text: 'After # of payments', value: RecurringEnd.AFTER_NUMBER_OF_PAYMENTS }
  ],
  timeMeasureOptions: [
    { text: 'Day(s)', value: 'days' },
    { text: 'Week(s)', value: 'weeks' },
    { text: 'Month(s)', value: 'months' },
    { text: 'Year(s)', value: 'years' }
  ],
  editPaymentDurationOptions: [
    { text: 'Apply to next invoice only.', value: EditPaymentDuration.NEXT_ONLY },
    { text: 'Apply to all future invoices.', value: EditPaymentDuration.ALL_FUTURE }
  ]
};

export const invoicesColorHashes = {
  [InvoicesStatus.PAID]: '#0BDE8C',
  [InvoicesStatus.UNPAID]: '#FFC20A',
  [InvoicesStatus.OVERDUE]: '#FF3F68',
  [InvoicesStatus.FAILED]: '#e20303',
  [InvoicesStatus.SCHEDULED]: '#00A5F2',
  [InvoicesStatus.DRAFT]: '#888C8D',
  [InvoicesStatus.SERIES]: '#404143'
};

const columnsForSeries = [
  {attribute: 'status', name: 'ColumnsGeneral.Status'},
  {attribute: 'name', name: 'ColumnsForSeries.SeriesName'},
  {attribute: 'last_name', name: 'ColumnsGeneral.LastName'},
  {attribute: 'start_date', name: 'ColumnsGeneral.StartDate'},
  {attribute: 'end_date', name: 'ColumnsGeneral.EndDate'},
  {attribute: 'frequency', name: 'ColumnsGeneral.Frequency'},
  {attribute: 'total_amt', name: 'ColumnsGeneral.Amount'},
];

const columnsForDraft = [
  {attribute: 'status', name: 'ColumnsGeneral.Status'},
  {attribute: 'invoice', name: 'ColumnsGeneral.Invoice'},
  {attribute: 'name', name: 'ColumnsGeneral.InvoiceName'},
  {attribute: 'created_date', name: 'ColumnsGeneral.Created'},
  {attribute: 'last_name', name: 'ColumnsGeneral.LastName'},
  {attribute: 'frequency', name: 'ColumnsGeneral.Frequency'},
  {attribute: 'total_amt', name: 'ColumnsGeneral.Amount'},
];

const columnsForCanceled = [
  {attribute: 'status', name: 'ColumnsGeneral.Status'},
  {attribute: 'invoice', name: 'ColumnsGeneral.Invoice'},
  {attribute: 'name', name: 'ColumnsGeneral.InvoiceName'},
  {attribute: 'created_date', name: 'ColumnsGeneral.Created'},
  {attribute: 'last_name', name: 'ColumnsGeneral.LastName'},
  {attribute: 'frequency', name: 'ColumnsGeneral.Frequency'},
  {attribute: 'canceled_date', name: 'ColumnsForCanceled.Canceled'},
  {attribute: 'total_amt', name: 'ColumnsGeneral.Amount'},
];

const columnsForInvoices = [
  {attribute: 'status', name: 'ColumnsGeneral.Status'},
  {attribute: 'invoice', name: 'ColumnsGeneral.Invoice'},
  {attribute: 'name', name: 'ColumnsGeneral.InvoiceName'},
  {attribute: 'last_name', name: 'ColumnsGeneral.LastName'},
  {attribute: 'sent_date', name: 'ColumnsGeneral.EndDate'},
  {attribute: 'due_date', name: 'ColumnsForInvoices.Due'},
  {attribute: 'total_amt', name: 'ColumnsGeneral.Amount'},
];

const invoiceFilterOptions = [
  {
    name: InvoiceFilterTypes.INVOICES,
    icon: 'DotIcon',
    color: '888C8D',
    height: '12px',
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.PAID,
    icon: 'DotIcon',
    color: '0BDE8C',
    height: '12px',
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.UNPAID,
    icon: 'DotIcon',
    color: 'FFC20A',
    height: '12px',
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.OVERDUE,
    icon: 'DotIcon',
    color: 'FF3F68',
    height: '12px',
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.FAILED,
    icon: 'DotIcon',
    color: 'FF3F68',
    height: '12px',
    border: true,
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.SCHEDULED,
    icon: 'DotIcon',
    color: '00A5F2',
    height: '12px',
    border: true,
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.SERIES,
    icon: 'RepeatIcon',
    color: '888C8D',
    height: '16px',
    action: 'recurring',
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.BULK,
    icon: 'ArchiveIcon',
    color: '888C8D',
    height: '16px',
    showCountOnSelection: false,
    border: true
  },
  {
    name: InvoiceFilterTypes.DRAFT,
    icon: 'EmailIcon',
    color: '888C8D',
    height: '16px',
    border: true,
    showCountOnSelection: true,
    count: 0
  },
  {
    name: InvoiceFilterTypes.CANCELED,
    icon: 'ArchiveIcon',
    color: '888C8D',
    height: '16px',
    showCountOnSelection: true,
    count: 0,
    border: false,
  },
  { /* spacer */},
];

export const invoiceActivity = {
  PAID_CLP: 'INVOICE_PAYMENT_PAID_CLP',
  PAID_MARKED: 'INVOICE_MARKED_AS_PAID',
  PAID_PORTAL: 'INVOICE_PAYMENT_PAID_PORTAL',
  PAID_AUTO: 'INVOICE_PAYMENT_PAID_AUTO'
}

const InvoiceUtil = {

  checkCustomer(values, props) {

    const { customers, dispatch } = props;
    const selectedCustomer = customers?.selectedCustomer ?? {};

    const paymentValues = { payment_methods: [] };

    if (selectedCustomer?.id && selectedCustomer?.payment_methods?.length) {
      paymentValues.payment_methods = selectedCustomer?.payment_methods;
    }

    const addressFields = (values?.customer_street_address_1 || values?.customer_street_address_2 || values?.customer_city || values?.customer_state || values?.customer_zip) && {
      street_address_1: values?.customer_street_address_1 ?? '',
      street_address_2: values?.customer_street_address_2 ?? '',
      city: values?.customer_city ?? '',
      state: values?.customer_state ?? '',
      zip: values?.customer_zip ?? ''
    };

    const contactValues = {
      first_name: values?.customer_first ?? '',
      last_name: values?.customer_last ?? '',
      email_addresses: values?.customer_email_addresses ?? [],
      phone_number: values?.customer_phone ? FormatTextUtil.formatWholeNumber(values.customer_phone) : '',
      ...(addressFields ?? {}),
      ...(paymentValues)
    };

    return new Promise((resolve, reject) => {

      const saveOperation = (customerType) => {
        const [contactEmail] = contactValues.email_addresses;
        const isCustomerPresent = !!customers?.data.find(customer => {
          return customer.email_addresses?.some(email => email === contactEmail);
        });

        const customerApiCall = isCustomerPresent ? updateCustomer : saveCustomer;
        const customer = isCustomerPresent ? selectedCustomer : customerType;

        dispatch(customerApiCall(props.user, customer, contactValues))
          .then((saveCustomerResponse) => {
            if (saveCustomerResponse?.response?.id) {
              resolve({paCustomerId: saveCustomerResponse.response.id});
            } else {
              resolve((customers.selectedCustomer.is_pa_customer
                  ? {paCustomerId: customers.selectedCustomer.id}
                  : {customerId: customers.selectedCustomer.id}
              ));
            }
          })
          .catch((saveCustomerError) => reject(saveCustomerError));
      };

      if (
        customers?.selectedCustomer?.first_name !== contactValues.first_name ||
        customers?.selectedCustomer?.last_name !== contactValues.last_name ||
        _.head(customers?.selectedCustomer?.email_addresses) !== _.head(contactValues.email_addresses) ||
        ((customers?.selectedCustomer?.phone_number !== null && contactValues.phone_number !== '') && customers?.selectedCustomer?.phone_number !== contactValues.phone_number) ||
        !!addressFields
      ) {

        if (!!customers?.selectedCustomer?.id ) {
          saveOperation(customers.selectedCustomer.id)
        } else {
          resolve({
            customer: {
              first_name: contactValues.first_name,
              last_name: contactValues.last_name,
              email: contactValues.email_addresses[0],
              phone: contactValues.phone_number
            }
          });
        }

      } else {
        // No customer changes made
        resolve({
          ...(customers.selectedCustomer.is_pa_customer
            ? {paCustomerId: customers.selectedCustomer.id}
            : {customerId: customers.selectedCustomer.id}
          )
        });
      }
    });

  },

  parseRecurringInvoice(values) {

    let repeat = _.get(values, 'recurring_repeat.repeat');
    let period = _.get(values, 'recurring_repeat.recurringInterval');
    let occurrences = _.get(values, 'recurring_end.occurrences');

    let startDate = moment(values.recurring_start).format('YYYY-MM-DD');

    let recurringInvoice = {
      start_date: startDate,
      length: repeat,
      period: period,
      end_date: null,
    };

    if (occurrences) {
      let singularPeriod = period.slice(0, -1); // day, week, month, year

      occurrences = occurrences - 1; //Because the first invoice sent on recurring_start also counts as an occurence, needs to be considered for endDate

      let calculatedEndDate = moment(values.recurring_start).add(occurrences * parseInt(repeat), singularPeriod).format('YYYY-MM-DD');

      recurringInvoice.end_amount_payments = parseFloat(values.numberOfPayments);
      recurringInvoice.end_date = calculatedEndDate;
    }
    else if (values.recurring_end) {
      // End date check. When no end date given (never ending) we pass null as value
      recurringInvoice.end_date = moment(values.recurring_end).format('YYYY-MM-DD');
    }

    return recurringInvoice;

  },

  checkFormValues(values, itemizedCart) {

    const formValues = {
      ...values,
      taxable: Boolean(values?.taxRate?.length)
    };

    const isSeries = values?.frequency === PaymentFrequency.SERIES;
    const isItemizedInvoice = values?.type === FormType.ITEMIZED;

    if (isSeries) {
      const recurring_start = values.selectedSendDateValue === CUSTOM_DATE_VALUE
        ? values[CUSTOM_SEND_DATE_VALUE]
        : DateUtils.addTimeToToday(values.selectedSendDateValue, 'days');

      formValues.recurring = isSeries;
      formValues.recurring_start = recurring_start;

      if (values.ends !== RecurringEnd.NEVER) {
        formValues.recurring_end = values.ends === RecurringEnd.AFTER_NUMBER_OF_PAYMENTS
          ? { occurrences: values.numberOfPayments }
          : values.selectedEndDateValue === CUSTOM_DATE_VALUE
            ? values[CUSTOM_END_DATE_VALUE]
            : moment(recurring_start).add(values.selectedEndDateValue, 'days');
      }

    }

    if(isItemizedInvoice){
      formValues.itemizedCart = itemizedCart;
    }

    return formValues;

  },

  processInvoice(formValues, itemizedCart, props, sendInvoice) {

    numeral.defaultFormat('0.00');
    const values = this.checkFormValues(formValues, itemizedCart);

    const isItemizedTransaction = !!(values.itemizedCart?.item_ids?.length > 0);
    const { subTotal, subTotalWithoutLoyalty, total, cashDiscountAmount, taxAmount, rewardDiscount } = this.getCartAmounts(isItemizedTransaction, values);
    values.subTotalWithoutLoyalty = subTotalWithoutLoyalty;
    values.total = total;
    values.cashDiscountAmount = cashDiscountAmount;
    values.rewardDiscount = rewardDiscount;

    const transactionTaxes = {
      taxAmount,
      taxRate: Number(String(values.taxRate).replace(/%/g, '')),
      geoLocation: props.taxes.geoLocation
    };

    return new Promise((resolve, reject) => {

      InvoiceUtil.checkCustomer(values, props).then((customerValues) => {

        const invoiceValues = {
          isInvoice: true,
          isSeries: values.recurring,
          invoiceName: values.name,
          invoiceDescription: values.description,
          invoiceNumber: values.invoice_number,
          sendInvoice: sendInvoice,
          isDraft: values.isDraft,
          markAsPaid: total === 0 && !values.isDraft,
          ...customerValues
        };

        if (values.recurring) {
          const recurringInvoice = InvoiceUtil.parseRecurringInvoice(values);
          invoiceValues.recurringInvoice = recurringInvoice;
          invoiceValues.sendDate = recurringInvoice.start_date;
        } else {
          invoiceValues.sendDate = this.getSendDate(values);
        }

        invoiceValues.dueDate = this.getDueDate({...values, sendDate: invoiceValues.sendDate });

        const categories = [];

        if (props.merchantSettings.express_category_enabled) {
          const category = _.find(props.items.categories, ['id', values.selectedCategory]);
          if (category) {
            categories.push(category);
          }
        }

        invoiceValues.invoiceAllowTip = values.inv_allow_tip;

        invoiceValues.allowTip = values.allow_tip;
        props.dispatch(processCreditCardPayment(transactionTaxes, values, props.user, subTotal, categories, invoiceValues, values.itemizedCart, true)).then(function (paymentResponse) {
          props.dispatch(setCustomer(null));
          resolve(paymentResponse);
        }).catch(error => {
          reject(t('CreateInvoiceError', { errorMessage: error.message }));
        });
      });

    });

  },

  sendInvoice(props, invoice) {
    return new Promise((resolve, reject) => {
      props.dispatch(sendInvoice(props.user, invoice.id)).then((sendInvoiceResponse) => {
        resolve(sendInvoiceResponse);
      }).catch((error) => {
        reject(t('SendInvoiceError'));
      });
    })
  },

  updateDraftInvoice(formValues, initialValues, itemizedCart, props, invoice) {

    numeral.defaultFormat('0.00');
    const values = this.checkFormValues(formValues, itemizedCart);

    const isItemizedTransaction = !!values.itemizedCart;
    const { subTotal, total, cashDiscountAmount, taxAmount, rewardDiscount } = this.getCartAmounts(isItemizedTransaction, values);
    const subTotalPreCashDiscount = _.round(numeral(values.amount).value(), 2);

    const transactionTaxes = {
      taxRate: Number(String(values.taxRate).replace(/%/g, '')),
      geoLocation: props.taxes.geoLocation
    };

    return new Promise((resolve, reject) => {
      this.checkCustomer(values, props).then((customerValues) => {

        const untouchedTaxRate = _.round((parseFloat(invoice.tax_amt) * 100) / parseFloat(invoice.sub_total_amt), 3);

        const payload = {
          type: 'invoice',
          invoice: {
            transaction_source: LabelUtil.getLabel().transactionSource,
            mark_as_paid: total === 0 && !values.isDraft,
            ...(initialValues.name !== values.name && {name: values.name}),
            ...(initialValues.description !== values.description && {description: values.description}),
            ...(initialValues.invoice_number !== values.invoice_number && {number: values.invoice_number}),
            ...(invoice.latitude !== transactionTaxes.geoLocation?.latitude  && {latitude: transactionTaxes.geoLocation?.latitude}),
            ...(invoice.longitude !== transactionTaxes.geoLocation?.longitude  && {longitude: transactionTaxes.geoLocation?.longitude}),
            ...(initialValues.selectedDueDateValue !== values.selectedDueDateValue && {due_date: this.getDueDate(values)}),
            ...(Object.hasOwn(initialValues, 'allow_tip') && {allow_tip: values.allow_tip})
          }
        };

        if (isItemizedTransaction) {
          const untouchedItemizedCart = this.initialItemizedCartObjectFromInvoice(invoice, props.items)

          if (!_.isEqual(itemizedCart, untouchedItemizedCart)
            || initialValues.type !== values.type
          ) {
            payload.invoice.items = this.getLineItems(values.itemizedCart);
            payload.invoice.express_line_items = this.getExpressLineItems(values.itemizedCart, values.description, subTotalPreCashDiscount, transactionTaxes.taxRate, cashDiscountAmount);
            payload.invoice.discounts = this.getDiscounts(values.itemizedCart);
            payload.invoice.sub_total_amount = subTotal;
            payload.invoice.service_fee_amount = cashDiscountAmount?.toFixed(2);
            payload.invoice.amount = total;
            payload.invoice.tax_rate = transactionTaxes.taxRate;
            payload.invoice.tax_amount = taxAmount;
          }
        } else if (untouchedTaxRate !== _.round(transactionTaxes.taxRate, 3)
          || parseFloat(invoice.sub_total_amt) !== subTotal
          || parseFloat(invoice.service_fee_amt) !== cashDiscountAmount
          || initialValues.description !== values.description
          || initialValues.type !== values.type
        ) {
          payload.invoice.express_line_items = this.getExpressLineItems(values.itemizedCart, values.description, subTotalPreCashDiscount, transactionTaxes.taxRate, cashDiscountAmount);
          payload.invoice.sub_total_amount = subTotal;
          payload.invoice.service_fee_amount = cashDiscountAmount.toFixed(2);
          payload.invoice.amount = total;
          payload.invoice.tax_rate = transactionTaxes.taxRate;
          payload.invoice.tax_amount = taxAmount;
        }

        if (values.paymentMethod !== PaymentMethod.SEND_TO_CUSTOMER) {
          if (!!values.selectedPaymentMethod) {
            payload.payment_method = values.selectedPaymentMethod.id
          } else {
            const expirationMonth = moment(values.edate, 'MM-YY').format('MM');
            const expirationYear = moment(values.edate, 'MM-YY').format('YYYY');
            const zipCode = values.zipPlus4 ? values.zip + values.zipPlus4 : values.zip;

            payload.save_payment_method = !!values.saveCreditCard;
            payload.card = {
              exp_month: expirationMonth,
              exp_year: expirationYear,
              number: FormatTextUtil.parseCardNumberDigits(values.cdigits),
              cvv: values.cvv,
              input_type: 'KEYED',
              ...(!!values.street_number && {address: values.street_number}),
              ...(!!zipCode && {zip_code: zipCode})
            };
          }
        }

        if (values.recurring) {
          const recurringInvoice = InvoiceUtil.parseRecurringInvoice(values);

          payload.type = 'series';
          payload.auto_pay = values.paymentMethod !== PaymentMethod.SEND_TO_CUSTOMER;
          payload.is_draft = values.isDraft;

          Object.assign(payload, customerValues);

          if (initialValues.name !== values.name) {
            payload.name = values.name;
          }

          if (initialValues.selectedSendDateValue !== values.selectedSendDateValue) {
            payload.invoice.send_date = values.recurring_start;
            payload.start_date = recurringInvoice.start_date;
          }

          if (initialValues.recurring_repeat.repeat !== values.recurring_repeat.repeat
            || initialValues.recurring_repeat.recurringInterval !== values.recurring_repeat.recurringInterval
          ) {
            payload.frequency = `${recurringInvoice.length} ${recurringInvoice.period}`;
          }

          if (initialValues.selectedEndDateValue !== values.selectedEndDateValue
            || initialValues.ends !== values.ends
          ) {
            payload.end_date = recurringInvoice.end_date;
          }

          if (!!recurringInvoice.end_amount_payments &&
            (initialValues.numberOfPayments !== values.numberOfPayments || initialValues.ends !== values.ends)
          ) {
            payload.end_amount_payments = recurringInvoice.end_amount_payments;
            payload.end_date = recurringInvoice.end_date;
          }

          if (values.isDraft && formValues.cashDiscounting) {
            payload.invoice.useCd40 = true;
          }

        } else {
          payload.invoice = {
            ...payload.invoice,
            ...customerValues,
            ...(initialValues.name !== values.name && {name: values.name}),
            ...(initialValues.selectedSendDateValue !== values.selectedSendDateValue && {send_date: this.getSendDate(values)}),
            is_draft: values.isDraft
          };
        }

        if(values.rewardCode && values.rewardCodeInformation) {
          payload.invoice.express_discounts = [
            {
              type: 'loyalty',
              code: values.rewardCode,
              amount: rewardDiscount,
              name: 'Loyalty Reward'
            }
          ];
        }

        props.dispatch(updateInvoice(props.user, invoice.id, payload)).then(function (paymentResponse) {
          resolve(paymentResponse);
        }).catch(error => {
          reject(t('UpdateDraftError', { errorMessage: error.message }));
        });
      });
    });
  },

  checkIsZeroAmountInvoice(invoice) {
    return Boolean(invoice && roundToTwoDecimals(invoice.total_amt) === 0);
  },

  checkIsOneTimeInvoice(invoice) {
    return Boolean(invoice && !invoice.isSeries && !invoice.isInvoiceFromSeries && invoice.isOneTimeInvoice);
  },

  checkIsInvoiceFromSeries(invoice) {
    return Boolean(invoice && !invoice.isSeries && !invoice.isOneTimeInvoice && invoice.isInvoiceFromSeries);
  },

  checkIsSeries(invoice) {
    return Boolean(invoice && !invoice.isInvoiceFromSeries && !invoice.isOneTimeInvoice && invoice.isSeries);
  },

  checkIsDraft(invoice) {
    return Boolean(invoice?.statusList?.isDraft);
  },

  filterOpenInvoices(invoice) {
    const { isDraft, isUnknown, isCanceled } = invoice?.statusList;
    return Boolean(!isDraft && !isUnknown && !isCanceled);
  },

  filterIsAllInvoices(invoice) {
    const { isOneTimeInvoice, isInvoiceFromSeries } = invoice?.statusList;
    return Boolean(isOneTimeInvoice || isInvoiceFromSeries) && InvoiceUtil.filterOpenInvoices(invoice);
  },

  filterIsSeries(invoice) {
    const { isSeries } = invoice?.statusList;
    return Boolean(isSeries) && InvoiceUtil.filterOpenInvoices(invoice);
  },

  invoiceStatus(invoice) {
    const isActive = invoice.isSeries ? invoice.active : invoice.is_active;
    const hasPaidDate = invoice.paid_date !== null;
    const hasSentDate = invoice.sent_date !== null;
    const hasLoyaltyVpcDiscount = invoice.receipt_discount_name?.includes('Loyalty Reward');
    const isPartiallyPaid = roundToTwoDecimals(invoice.unpaid_amount) !== roundToTwoDecimals(invoice.total_amt);
    const isZeroAmount = roundToTwoDecimals(invoice.total_amt) === 0;

    const today = moment();
    const todayEndOfDay = today.endOf('day');
    let dueDate = moment(invoice.due_date).endOf('day');
    let sentDate = moment(invoice.sent_date).endOf('day');

    if (moment().utcOffset() < 0) {
      dueDate = dueDate.add(1, 'days'); // If local timezone is west of UTC-0, we need to add 24 hours so the due date will still be the correct date
    }

    let type = InvoicesStatus.UNKNOWN;

    if (!isActive || invoice.archivedType !== null) {
      type = InvoicesStatus.CANCELED
    } else if (invoice.is_draft){
      type = InvoicesStatus.DRAFT;
    } else if (invoice.isSeries) {
      type = InvoicesStatus.SERIES;
    } else if (invoice.failure_array?.length) {
      type = InvoicesStatus.FAILED
    } else if (hasPaidDate || (isZeroAmount && hasSentDate && !hasLoyaltyVpcDiscount)) {
      type = InvoicesStatus.PAID;
    } else if (isPartiallyPaid) {
      type = InvoicesStatus.PARTIALLY_PAID;
    } else if (dueDate.isBefore(todayEndOfDay) && !hasPaidDate) {
      type = InvoicesStatus.OVERDUE;
    } else if (!hasSentDate) {
      type = InvoicesStatus.SCHEDULED;
    } else if ((sentDate.isBefore(dueDate) || sentDate.isSame(dueDate)) && isActive) {
      type = InvoicesStatus.UNPAID;
    }

    return type;

  },

  invoiceTypeAccessoryDot(invoice, merchantSettings = null) {



    let type = invoice.status;

    const displayWarning = (
      merchantSettings &&
      merchantSettings.merchantSettings &&
      !merchantSettings.merchantSettings.ignore_avs_failure
    ) && (invoice.failure_array && invoice.failure_array.length > 2);

    const { icon, color, height } = displayWarning
      ? { icon: 'Warning', color: invoicesColorHashes[InvoicesStatus.FAILED] }
      : { icon: 'DotIcon', color: invoicesColorHashes[type], height: '12px' }

    return IconUtils.getIcon(icon, color, height);
  },


  addStatusToInvoices: (invoicesData) => {
    return invoicesData?.map((invoice) => {
      invoice.status = InvoiceUtil.invoiceStatus(invoice);
      invoice.statusList = InvoiceUtil.getInvoiceStatusList(invoice);
      if (invoice.series) {
        invoice.series.status = InvoiceUtil.invoiceStatus(invoice.series);
      }
      return invoice;
    });
  },

  addInvoiceStatusToSeries: (seriesData) => {

    seriesData?.forEach((series) => {

      if (series.invoicesFromSeries?.length) {
        series.invoicesFromSeries = InvoiceUtil.addStatusToInvoices(series.invoicesFromSeries)
        series.invoicesStatusList = series.invoicesFromSeries.map((invoice) => invoice.statusList);
      }

      series.status = InvoiceUtil.invoiceStatus(series);
      series.statusList = InvoiceUtil.getInvoiceStatusList(series)

    });

    return seriesData;
  },

  getInvoiceStatusList: (invoice) => {
    const invoiceType = invoice.status;

    return {
      // status
      isDraft: invoiceType === InvoicesStatus.DRAFT,
      isCanceled: invoiceType === InvoicesStatus.CANCELED,
      isPaid: invoiceType === InvoicesStatus.PAID,
      isPartiallyPaid: invoiceType === InvoicesStatus.PARTIALLY_PAID,
      isUnpaid: invoiceType === InvoicesStatus.UNPAID,
      isFailed: invoiceType === InvoicesStatus.FAILED,
      isOverdue: invoiceType === InvoicesStatus.OVERDUE,
      isScheduled: invoiceType === InvoicesStatus.SCHEDULED,
      isUnknown: invoiceType === InvoicesStatus.UNKNOWN,

      // kind of data
      isSeries: InvoiceUtil.checkIsSeries(invoice),
      isInvoiceFromSeries: InvoiceUtil.checkIsInvoiceFromSeries(invoice),
      isOneTimeInvoice: InvoiceUtil.checkIsOneTimeInvoice(invoice),
      isZeroAmountInvoice: InvoiceUtil.checkIsZeroAmountInvoice(invoice)
    };
  },

  getFilteredSections: (selectedFilter) => ({
    isAllInvoicesSection: selectedFilter === InvoiceFilterTypes.INVOICES,
    isSeriesSection: selectedFilter === InvoiceFilterTypes.SERIES,
    isDraftSection: selectedFilter === InvoiceFilterTypes.DRAFT,
    isCanceledSection: selectedFilter === InvoiceFilterTypes.CANCELED,
    isBulkingInvoice: selectedFilter === InvoiceFilterTypes.BULK
  }),

  getOptions: (invoicesFilterCount) => {
    const isBulkInvoicingEnabled = FeatureFlagsUtil.isFeatureFlagEnabled('bulkInvoiceEnabled');

    for (let index = invoiceFilterOptions.length - 1; index > -1; index--) {
      const currentItem = invoiceFilterOptions[index];
      if (currentItem?.name) {
        currentItem.count = invoicesFilterCount?.[currentItem?.name] || 0;
      }

      if (currentItem?.name === InvoiceFilterTypes.BULK) {
        isBulkInvoicingEnabled ? currentItem.count = -1 : invoiceFilterOptions.splice(index, 1);
      }

    }

    return invoiceFilterOptions;
  },

  getColumns: (selectedFilter) => {
    const { isSeriesSection, isDraftSection, isCanceledSection} =
      InvoiceUtil.getFilteredSections(selectedFilter);

    if (isSeriesSection) {
      return columnsForSeries;
    } else if (isDraftSection) {
      return columnsForDraft;
    } else if (isCanceledSection) {
      return columnsForCanceled;
    }

    return columnsForInvoices;
  },

  getDataFormat: (merchantSettings, selectedFilter, seriesData) => (invoice, i) => {
    const { isSeriesSection, isDraftSection, isCanceledSection } =
      InvoiceUtil.getFilteredSections(selectedFilter);

    const { isInvoiceFromSeries, isOneTimeInvoice, isScheduled } = invoice.statusList;

    const noneLabel = <span className={'noneLabel'}>{t('None')}</span>;
    const paCustomer = (invoice?.pa_customer && Object.keys(invoice.pa_customer).length > 0) ? invoice.pa_customer : invoice;

    const customerName = FormatTextUtil.formatName(paCustomer?.first_name, paCustomer?.last_name, t('UnnamedCustomer'));

    const invoiceName = invoice.name || `${FormatTextUtil.upperCaseFirstLowerCaseRest(invoice.status)} ${t('Invoice')}`;

    const startDate = invoice.start_date ? moment.utc(invoice.start_date).format('MM/DD/YY') : noneLabel;
    let sentDate = invoice.sent_date ? moment.utc(invoice.sent_date).format('MM/DD/YY') : noneLabel;
    let dueDate = invoice.due_date ? moment.utc(invoice.due_date).format('MM/DD/YY') : noneLabel;
    const endDate = invoice.end_date ? moment.utc(invoice.end_date).format('MM/DD/YY') : noneLabel;
    const canceled = invoice.updated_date ? moment.utc(invoice.updated_date).format('MM/DD/YY') : noneLabel;
    const createdDate = invoice.created_date ? moment.utc(invoice.created_date).format('MM/DD/YY') : noneLabel;

    if (isScheduled && isOneTimeInvoice) {
      sentDate = dueDate;
    }

    if (isInvoiceFromSeries && seriesData?.length) {
      const series = seriesData.find((data) => data.id === invoice.series_details_id);
      sentDate = series?.end_date ? moment.utc(series.end_date).format('MM/DD/YY') : noneLabel;
    }

    let frequency = noneLabel;

    if (invoice.frequency) {
      const [quantity, period] = invoice.frequency.split(' ');
      frequency = (quantity && period) &&
        `${FormatTextUtil.upperCaseFirstLowerCaseRest(t('Every'))} ${quantity} ${t(FrequencyTimeMeasure?.[period])}`;
    }

    const typeDot = isSeriesSection
      ? IconUtils.getIcon('RecurringIcon', '#404143', '24px')
      : InvoiceUtil.invoiceTypeAccessoryDot(invoice, merchantSettings);

    const totalAmount = numeral(invoice.total_amt ? roundToTwoDecimals(roundToTwoDecimals(invoice.total_amt)) : 0).format('$0,0.00')

    if (isSeriesSection) {
      return [typeDot, invoiceName, customerName, startDate, endDate, frequency, totalAmount];
    } else if (isDraftSection) {
      return [typeDot, invoice.invoice, invoiceName, createdDate, customerName, frequency, totalAmount];
    } else if (isCanceledSection) {
      return [typeDot, invoice.invoice, invoiceName, createdDate, customerName, frequency, canceled, totalAmount];
    }

    return [typeDot, invoice.invoice, invoiceName, customerName, sentDate, dueDate, totalAmount];
  },

  getFilterCount: (invoicesData, value) => {
    const filter = { filter: { value } };
    return FilterUtil.filterInvoices(invoicesData, filter).filteredData.length;
  },

  getItemizedSummary: (currentValues, itemizedCart) => {
    const cartRecalculated = InvoiceUtil.recalculateCart(itemizedCart, currentValues, currentValues?.rewardCodeInformation);

    const subTotal = numeral(cartRecalculated?.sub_total_amt).value();
    const taxAmount = numeral(cartRecalculated?.tax_amt).value();
    const total = numeral(roundToTwoDecimals(cartRecalculated?.total_amt)).value();

    return { subTotal, total, taxAmount };
  },

  getCashDiscountTotal: (currentValues, amount) => {

    return currentValues?.cashDiscounting && currentValues?.cashDiscountingAmount
      ? currentValues?.cashDiscountingAmount.includes('%')
        ? numeral(currentValues?.cashDiscountingAmount).value() * amount
        : numeral(currentValues?.cashDiscountingAmount).value()
      : 0;
  },

  getCartAmounts(isItemizedTransaction, values) {
    if (isItemizedTransaction) {
      return {
        subTotal: values.itemizedCart.sub_total_amt,
        subTotalWithoutLoyalty: values.itemizedCart.sub_total_pre_discounts,
        cashDiscountAmount: values.itemizedCart.service_fee_amt,
        rewardDiscount: values.itemizedCart.loyalty_discount_amt,
        taxAmount: values.itemizedCart.tax_amt,
        total: values.itemizedCart.total_amt
      };
    }

    return this.recalculateCartCustomAmount(values, values.rewardCodeInformation);
  },

  getDisableSendInvoice: (itemizedCart, isItemizedInvoice) => {
    let disableSendInvoice = false

    if (isItemizedInvoice) {
      const noItems = itemizedCart?.item_ids.length === 0;
      const haveDiscounts = Boolean(itemizedCart?.receipt_discount_id.length);

      disableSendInvoice = (haveDiscounts && noItems) || noItems ;
    }

    return disableSendInvoice;
  },

  normalizeTaxRate: (taxRate) => FormatTextUtil.formatTaxRate(taxRate),

  normalizeTaxRateValue: (taxRate) => FormatTextUtil.formatPercentageWithoutSymbol(taxRate),

  normalizeCurrency: (value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 20),

  initialItemizedCartObject() {
    return ({
      express_options: [],
      modifier_sets: [],
      modifier_ids: [],
      modifier_amounts: [],
      item_ids: [],
      item_discount_rate: [],
      item_discount_amt: [],
      item_service_fee_amt: [],
      item_names: [],
      item_quantity: [],
      item_unit_price: [],
      item_image_id: [],
      item_price_id: [],
      item_tax_rate: [],
      item_subtotal: [],
      item_category: [],
      receipt_discount_id: [],
      receipt_discount_color: [],
      receipt_discount_amt: [], //Discount amount will be saved here for all cart discounts, even the percentage-based (calculated based on subtotal before any cart discounts)
      receipt_discount_sys_amt: [], // this array will hold the preset receipt amount for each discount, that way we can recalculate after deleting/adding
      receipt_discount_percentage: [],
      receipt_discount_name: [],
      receipt_discount_type: [],
      service_fee_amt: 0,
      sub_total_pre_discounts: 0, //Subtotal without cart discounts
      sub_total_amt: 0, //Doesn't include cash discounting
      tax_amt: 0,
      total_amt: 0, //Also doesn't include cash discounting
    });
  },

  initialItemizedCartObjectFromInvoice(invoice, items) {
    let itemizedCart = this.initialItemizedCartObject();
    const invoiceAmountType = this.checkInvoiceAmountType(invoice, items);

    if (invoiceAmountType === FormType.ITEMIZED || invoiceAmountType === FormType.CUSTOM_AMOUNT) {
      for (let i = 0; i < invoice.item_ids.length; i++) {
        const selectedItem = items.filteredItems?.length > 0 && items.filteredItems.find(item => item.id === invoice.item_ids[i]);
        const selectedItemIndex = !!selectedItem ? i : -1;

        const taxRate = !!invoice.item_tax_rate[i] ? invoice.item_tax_rate[i] : 0;

        const mappedModifiers = invoice.item_modifiers.map(this.mapInvoiceModifiers);
        let modifierTotal = 0;
        if (mappedModifiers[i]?.length > 0) {

          mappedModifiers[i].map(mod => modifierTotal += parseFloat(mod.price));
        }

        const itemPrice = invoice.item_unit_price[i] + modifierTotal;
        const discount = !!invoice.item_discount_rate[i]
          ? invoice.item_discount_rate[i] / 100
          : invoice.item_discount_amt[i] / invoice.item_quantity[i];
        const subTotal = !!invoice.item_discount_rate[i]
          ? itemPrice * (1 - discount) * invoice.item_quantity[i]
          : (itemPrice - discount) * invoice.item_quantity[i];

        const formValues = {
          itemDiscountType: !!invoice.item_discount_rate[i] && 'percent',
          itemDiscountValue: discount,
          itemQuantity: invoice.item_quantity[i],
          itemPriceId: selectedItem?.details?.prices?.find(price => price.price === invoice.item_unit_price[i])?.id,
          expressItemName: FormatTextUtil.formatItemName(invoice.item_names[i]).trim(),
          expressItemPrice: invoice.item_unit_price[i],
          expressItemCategory: null,
          expressItemTaxable: !!invoice.item_tax_rate[i],
          expressItemTaxRate: taxRate,
          expressModifiers: this.getExpressModifiers(mappedModifiers[i])
        };

        const addToOrderAdditionalInfo = {
          selectedModifiers: this.getSelectedModifiers(mappedModifiers[i], items, selectedItem),
          itemCurrentSubtotal: roundToTwoDecimals(subTotal)
        };

        itemizedCart = this.handleAddItemToOrder(formValues, addToOrderAdditionalInfo, itemizedCart, selectedItemIndex, false, selectedItem, taxRate);
      }

      if (invoice?.receipt_discount_name?.length > 0) {
        for (let i = 0; i < invoice.receipt_discount_name.length; i++) {
          let discountInfo;
          try {
            discountInfo = JSON.parse(invoice.receipt_discount_info[i]);
          } catch (e) {
            discountInfo = {
              percentage: '0'
            };
          }

          const discountIndex = items.filteredDiscounts.findIndex(item => {
            if (item.name === invoice.receipt_discount_name[i]) {
              if (item.type === 'flat'
                && invoice.receipt_discount_type[i] === 'Flat Rate'
                && parseFloat(item.amount) === invoice.receipt_discount_amt[i]
              ) {
                return item;
              }

              if (item.type === 'percent'
                && invoice.receipt_discount_type[i] === 'Percentage'
                && parseFloat(item.percentage) === parseFloat(discountInfo.percentage)
              ) {
                return item;
              }
            }
          });

          if (discountIndex !== -1) {
            itemizedCart = this.addDiscountToCart(discountIndex, items, itemizedCart);
          }
        }
      }
    }

    return itemizedCart;
  },

  checkInvoiceAmountType(invoice, items) {
    if (!invoice?.item_ids?.length) {
      return FormType.CUSTOM_AMOUNT;
    }

    if (invoice?.item_ids?.length === 1
      && invoice.item_quantity[0] === 1
      && !(items?.filteredItems?.length > 0 && items.filteredItems.find(item => item.id === invoice.item_ids[0]))
    ) {
      const description = invoice.description || t('ExpressItemDescription');
      const item = invoice.item_names[0];
      if (item === `${description} - ${t('BasePrice')}`) {
        return FormType.CUSTOM_AMOUNT;
      }
    }

    return FormType.ITEMIZED;
  },

  mapModifiers(modifiers) {
    return modifiers?.map(modifier => {
      if (!modifier) {
       return {
         id: '',
         name: '',
         price: 0,
         total_amt: 0,
         service_fee_total_amt: 0
       };
      }

      const id = modifier.split(',')[0];

      const reversed = modifier.split('').reverse().join('');

      const splitted = reversed.split(',');

      const service_fee_total_amt = splitted[0].split('').reverse().join('');

      const total_amt = splitted[1].split('').reverse().join('');

      const price = splitted[2].split('').reverse().join('');

      const nameIndexEnd = modifier.length - (service_fee_total_amt.length + total_amt.length + price.length + 3); // +3 due to 3 commas

      const name = modifier.substring(id.length + 1, nameIndexEnd); // +1 due to comma

      return { id, name, price, total_amt, service_fee_total_amt };
    });
  },

  mapItemModifiers(modifiers) {
    return modifiers?.map(modifier => {
      if (!modifier) {
        return {
          id: '',
          name: '',
          price: 0
        };
      }

      const id = modifier.split(',')[0];

      const reversed = modifier.split('').reverse().join('');

      const splitted = reversed.split(',');

      const price = splitted[0].split('').reverse().join('');

      const nameIndexEnd = modifier.length - (price.length + 1); // +1 due to comma

      const name = modifier.substring(id.length + 1, nameIndexEnd); // +1 due to comma

      return { id, name, price };
    });
  },

  mapInvoiceModifiers(modifiers) {
    return modifiers?.map(modifier => {
      if (!modifier) {
        return {
          option_id: '',
          item_id: '',
          name: '',
          price: 0,
          total_amt: 0,
          service_fee_total_amt: 0,
          id: ''
        };
      }

      const option_id = modifier.split(',')[0];

      const item_id = modifier.split(',')[1];

      const reversed = modifier.split('').reverse().join('');

      const splitted = reversed.split(',');

      const id = splitted[0].split('').reverse().join('');

      const service_fee_total_amt = splitted[1].split('').reverse().join('');

      const total_amt = splitted[2].split('').reverse().join('');

      const price = splitted[3].split('').reverse().join('');

      const nameIndexEnd = modifier.length - (service_fee_total_amt.length + total_amt.length + price.length + id.length + 4); // +4 due to 4 commas

      const name = modifier.substring(option_id.length + item_id.length + 2, nameIndexEnd); // +2 due to 2 commas

      return { option_id, item_id, name, price, total_amt, service_fee_total_amt, id };
    });
  },

  getExpressModifiers(modifiers) {
    let expressModifiers = [];

    if (modifiers?.length > 0) {
      expressModifiers = modifiers
        .filter(modifier => parseFloat(modifier?.option_id) === 0)
        .map(modifier => (
          {
            name: modifier.name,
            price: numeral(modifier.price).format('$0,0.00')
          }
        ));
    }

    return expressModifiers;
  },

  getSelectedModifiers(modifiers, items, selectedItem) {
    let selectedModifiers = {};

    if (modifiers?.length > 0 && !!selectedItem?.option_set_ids) {
      const modifierSets = items.filteredModifiers.filter(modifiersSet => selectedItem.option_set_ids.includes(modifiersSet.id));

      const modifierOptionIds = modifiers.map(modifier => parseFloat(modifier?.option_id));
      const modifierIds = modifiers.map(modifier => parseFloat(modifier?.id));

      const selectedModifierSets = modifierSets.filter(modifierSet =>
        !!modifierSet.modifiers.find(modifier =>
          modifierOptionIds.indexOf(modifier.option_id) !== -1
        )
      );

      selectedModifierSets.map(modifierSet =>
        selectedModifiers[modifierSet.id] = modifierSet.modifiers
          .filter(modifier =>
            modifierOptionIds.indexOf(modifier.option_id) !== -1 && modifierIds.indexOf(modifier.id) !== -1
          )
          .map(modifier => modifier.id)
      );
    }

    return selectedModifiers;
  },

  getEndsOption(invoice) {
    if (!!invoice?.end_amount_payments) {
      return FormDefaultOptions.endsOptions[2].value;
    }

    if (!!invoice?.end_date) {
      return FormDefaultOptions.endsOptions[1].value;
    }

    return FormDefaultOptions.endsOptions[0].value
  },

  getDateOption(date, createdDate, dateOptions) {
    const extractedDate = moment(date).format('YYYY-MM-DD');
    const extractedCreatedDate = moment(createdDate).format('YYYY-MM-DD');
    const dateDifferenceInDays = DateUtils.getDifference(extractedCreatedDate, extractedDate, 'days');

    switch (dateDifferenceInDays) {
      case 0:
        return dateOptions[0].value;
      case 7:
        return dateOptions[1].value;
      case 14:
        return dateOptions[2].value;
      case 30:
        return dateOptions[3].value;
      default:
        return CUSTOM_DATE_VALUE;
    }
  },

  recalculateCartDiscounts(newCart, shouldUpdateDiscountValues) {
    return newCart.receipt_discount_type.reduce((total, discountType, index) => {
      if (discountType === 'percent' && shouldUpdateDiscountValues) {
        newCart.receipt_discount_amt[index] = roundToTwoDecimals(newCart.sub_total_pre_discounts * newCart.receipt_discount_percentage[index] / 100);
      }
      return roundToTwoDecimals(total + Number(newCart.receipt_discount_amt[index]));
    }, 0);
  },

  changeItemQuantity(itemIndex, isSubtracting, itemizedCart) {
    const variation = isSubtracting ? -1 : 1;
    const newCart = _.cloneDeep(itemizedCart);
    const originalQuantity = Number(newCart.item_quantity[itemIndex]);

    newCart.item_quantity[itemIndex] = originalQuantity + variation;

    if (!newCart.item_discount_rate[itemIndex] && newCart.item_discount_amt[itemIndex] && originalQuantity > 0) {
      newCart.item_discount_amt[itemIndex] = newCart.item_discount_amt[itemIndex] + (variation * (newCart.item_discount_amt[itemIndex] / originalQuantity))
    }

    newCart.item_subtotal[itemIndex] = isSubtracting ? newCart.item_subtotal[itemIndex] - newCart.item_unit_price[itemIndex] : newCart.item_subtotal[itemIndex];

    return newCart;

  },

  recalculateCartCustomAmount(currentValues, activeRewardCode) {
    const amount = numeral(currentValues?.amount).value();
    let cashDiscountAmount = this.getCashDiscountTotal(currentValues, amount);
    let subTotalWithoutLoyalty = amount + cashDiscountAmount;
    let rewardDiscount = 0;

    if (activeRewardCode) {
      if (activeRewardCode.type === 'dollar') {
        const effectiveLoyaltyDiscountAmount = amount - numeral(activeRewardCode.amount).value();
        if ((effectiveLoyaltyDiscountAmount) <= 0) {
          rewardDiscount = amount;
          subTotalWithoutLoyalty = amount;
          cashDiscountAmount = 0;
        } else {
          rewardDiscount = numeral(activeRewardCode.amount).value();
        }
      } else {
        const effectiveLoyaltyDiscountAmount = amount - (numeral(activeRewardCode.amount).value() * amount / 100);
        if (roundToTwoDecimals(effectiveLoyaltyDiscountAmount) <= 0) {
          rewardDiscount = amount;
          subTotalWithoutLoyalty = amount;
          cashDiscountAmount = 0;
        } else {
          rewardDiscount = numeral(activeRewardCode.amount).value() * subTotalWithoutLoyalty / 100;
        }
      }
    }

    const subTotal = handleNegative(roundToTwoDecimals(subTotalWithoutLoyalty - rewardDiscount));
    const taxAmount = handleNegative(roundToTwoDecimals((subTotal * currentValues.taxRate) / 100));
    const total = handleNegative(roundToTwoDecimals(subTotal + taxAmount));

    return {
      rewardDiscount: roundToTwoDecimals(rewardDiscount),
      amount: roundToTwoDecimals(amount),
      cashDiscountAmount: roundToTwoDecimals(cashDiscountAmount),
      subTotal: roundToTwoDecimals(subTotal),
      subTotalWithoutLoyalty: roundToTwoDecimals(subTotalWithoutLoyalty),
      taxAmount,
      total
    };
  },
  getIndividualDiscountAmount(itemizedCart, itemCost, itemIndex) {
    if (itemizedCart.item_discount_rate?.length && itemizedCart.item_discount_rate[itemIndex]) {
      return roundToTwoDecimals((itemCost * itemizedCart.item_discount_rate[itemIndex]) / 100);
    } else if (itemizedCart.item_discount_amt?.length && itemizedCart.item_discount_amt[itemIndex]) {
      return itemizedCart.item_discount_amt[itemIndex];
    } else {
      return 0;
    }
  },
  getLoyaltyDiscountAmount(activeRewardCode, subTotalPreCartDiscounts) {
    return activeRewardCode ?
    activeRewardCode.type === 'dollar' ?
      numeral(activeRewardCode.amount).value()
    :
      numeral(activeRewardCode.amount).value() * subTotalPreCartDiscounts / 100
  :
    0;
  },

  calculateSubTotalWithoutCashDiscounting(itemizedCart) {
    let subTotal = 0;

    if (itemizedCart.item_unit_price?.length > 0) {
      const itemSubTotals = itemizedCart.item_unit_price.map((price, index) => {
        const modifierAmount = itemizedCart.modifier_amounts[index] || 0;
        const itemSubTotalPreDiscounts = (price + modifierAmount) * itemizedCart.item_quantity[index];
        return itemSubTotalPreDiscounts - this.getIndividualDiscountAmount(itemizedCart, itemSubTotalPreDiscounts, index);
      });

      subTotal = itemSubTotals.reduce((partialSum, itemSubTotal) => partialSum + itemSubTotal, 0);
    }

    return subTotal;
  },

  applyWeightedCashDiscount(amount, cashDiscountingAmount, subTotal, cashDiscountType) {
    let cdAppliedAmount = amount;

    if (cashDiscountType === 'flat') {
      const cartRatioCD = cashDiscountingAmount / subTotal;
      cdAppliedAmount = roundToTwoDecimals(amount + (amount * cartRatioCD));
    }

    if (cashDiscountType === 'percent') {
      cdAppliedAmount = roundToTwoDecimals(amount + (amount * cashDiscountingAmount));
    }

    return handleNegative(cdAppliedAmount);
  },

  applyWeightedCartDiscount(amount, cartDiscountAmount, subTotal) {
    const cartRatioCD = cartDiscountAmount / subTotal;
    return handleNegative(roundToTwoDecimals(amount - (amount * cartRatioCD)));
  },

  applyPercentageCartDiscount(amount, cartDiscountPercentages, activeRewardCode) {
    let rollingAmount = roundToTwoDecimals(amount);

    cartDiscountPercentages.map(discount => {
      rollingAmount = rollingAmount - roundToTwoDecimals(rollingAmount * discount);
    });

    if (!!activeRewardCode && activeRewardCode.type !== 'dollar') {
      const loyaltyDiscount = numeral(activeRewardCode.amount).value() / 100;
      rollingAmount = rollingAmount - roundToTwoDecimals(rollingAmount * loyaltyDiscount);
    }

    return handleNegative(roundToTwoDecimals(rollingAmount));
  },

  calculateCartDiscountFromPercentage(subTotalsPerItem, cartDiscountPercentages) {
    let currentSubTotalsPerItem = subTotalsPerItem;

    return cartDiscountPercentages.map(discount => {
      let discountAmount = 0;

      currentSubTotalsPerItem = currentSubTotalsPerItem.map(subTotal => {
        const currentDiscountAmount = subTotal * discount;
        discountAmount = discountAmount + currentDiscountAmount;

        return subTotal - currentDiscountAmount;
      });

      return roundToTwoDecimals(discountAmount);
    });
  },

  findMostExpensiveItemIndex(amountsPerItem) {
    const subTotalsPerItem = amountsPerItem.map(total => total.item_subtotal);
    const mostExpensiveItem = Math.max(...subTotalsPerItem);

    return subTotalsPerItem.indexOf(mostExpensiveItem);
  },

  applyRemainderAmount(amountsPerItem, subTotal, isCashDiscountRemainder = false) {
    const updatedAmountsPerItem = amountsPerItem;
    const roundedSubTotal = amountsPerItem?.reduce((partialSum, amount) => partialSum + amount.item_subtotal, 0);
    const remainder = subTotal - roundedSubTotal;

    if (remainder) {
      const mostExpensiveItemIndex = this.findMostExpensiveItemIndex(amountsPerItem);

      if (mostExpensiveItemIndex >= 0) {
        updatedAmountsPerItem[mostExpensiveItemIndex].item_subtotal = handleNegative(roundToTwoDecimals(amountsPerItem[mostExpensiveItemIndex].item_subtotal + remainder));

        if (isCashDiscountRemainder) {
          updatedAmountsPerItem[mostExpensiveItemIndex].cd_item_price = handleNegative(roundToTwoDecimals(amountsPerItem[mostExpensiveItemIndex].cd_item_price + remainder));
          updatedAmountsPerItem[mostExpensiveItemIndex].item_subtotal_pre_cart_discounts = handleNegative(roundToTwoDecimals(amountsPerItem[mostExpensiveItemIndex].item_subtotal_pre_cart_discounts + remainder));
        }
      }
    }

    return updatedAmountsPerItem;
  },

  getCartDiscountAmounts(itemizedCart, cartPercentDiscountAmounts) {
    let currentCartPercentDiscountAmountIndex = 0;
    let subtotal = itemizedCart.sub_total_pre_discounts;

    return itemizedCart.receipt_discount_type?.map((type, index) => {
      let cartDiscountAmount;

      if (type === 'percent') {
        cartDiscountAmount = cartPercentDiscountAmounts[currentCartPercentDiscountAmountIndex];
        currentCartPercentDiscountAmountIndex = currentCartPercentDiscountAmountIndex + 1;
        subtotal = subtotal - cartDiscountAmount;
      } else {
        const discountsToApply = itemizedCart?.receipt_discount_sys_amt || itemizedCart?.receipt_discount_amt;
        cartDiscountAmount = this.getDiscountEffectiveAmount(subtotal, discountsToApply[index]);
        subtotal = subtotal - cartDiscountAmount;
      }
      return cartDiscountAmount;
    });
  },

  recalculateCart(itemizedCart, currentValues = { cashDiscounting: false }, activeRewardCode) {

    const newCart = _.cloneDeep(itemizedCart);

    let cashDiscountType = null;

    if (currentValues?.cashDiscounting && currentValues?.cashDiscountingAmount) {
      cashDiscountType = currentValues?.cashDiscountingAmount.includes('%') ? 'percent' : 'flat';
    }

    let cashDiscountingAmount = numeral(currentValues?.cashDiscountingAmount).value() || 0;
    const subTotalWithoutCD = this.calculateSubTotalWithoutCashDiscounting(itemizedCart);

    // get total flat cart discount amount and array of cart discount percentages
    let cartDiscountFixed = 0;
    let cartDiscountPercentages = [];
    let loyaltyDiscountAmount = activeRewardCode?.type === 'dollar' ? numeral(activeRewardCode?.amount).value() : 0;

    itemizedCart?.receipt_discount_type?.map((type, index) => {
      if (type === 'percent') {
        cartDiscountPercentages.push(itemizedCart.receipt_discount_percentage[index] / 100);
      } else {
        cartDiscountFixed = cartDiscountFixed + numeral(itemizedCart.receipt_discount_sys_amt[index]).value();
      }
    });

    let cartMinusDiscounts = subTotalWithoutCD - cartDiscountFixed - loyaltyDiscountAmount;
    cartMinusDiscounts = this.applyPercentageCartDiscount(cartMinusDiscounts, cartDiscountPercentages, activeRewardCode);

    // remove service fee if cart with discounts is less than or equal to zero dollars
    if (cartMinusDiscounts <= 0) {
      cashDiscountType = null;
      cashDiscountingAmount = 0;
    }

    let amountsPerItem = itemizedCart?.item_ids?.map((item, index) => {
      // calculate price, modifiers, and individual discounts without cash discounting, but with quantity applied
      const itemPrice = itemizedCart.item_unit_price[index] * itemizedCart.item_quantity[index];

      const mappedModifiers = itemizedCart?.modifier_sets?.map(this.mapItemModifiers);
      const itemModifierPrices = mappedModifiers?.[index]?.map(modifier => roundToTwoDecimals(modifier?.price) * itemizedCart.item_quantity[index]);
      const itemModifierAmount = itemModifierPrices?.reduce((partialSum, modifier) => partialSum + modifier, 0);

      const itemExpressModifierPrices = itemizedCart.express_options?.[index]?.map(modifier => roundToTwoDecimals(numeral(modifier.price).value()) * itemizedCart.item_quantity[index]);
      const itemExpressModifierAmount = itemExpressModifierPrices?.reduce((partialSum, modifier) => partialSum + modifier, 0);

      const itemSubTotalPreDiscounts = (itemizedCart.item_unit_price[index] + (itemizedCart.modifier_amounts[index] || 0)) * itemizedCart.item_quantity[index];
      const itemDiscountAmount = this.getIndividualDiscountAmount(itemizedCart, itemSubTotalPreDiscounts, index);

      // calculate price, modifiers, and individual discounts with cash discounting and with quantity applied
      const cdAppliedItemPrice = this.applyWeightedCashDiscount(itemPrice, cashDiscountingAmount, subTotalWithoutCD, cashDiscountType);

      const cdAppliedItemModifierPrices = itemModifierPrices?.map(modifier => this.applyWeightedCashDiscount(modifier, cashDiscountingAmount, subTotalWithoutCD, cashDiscountType));
      const cdAppliedItemModifierAmount = cdAppliedItemModifierPrices?.reduce((partialSum, modifier) => partialSum + modifier, 0);

      const cdAppliedItemExpressModifierPrices = itemExpressModifierPrices?.map(modifier => this.applyWeightedCashDiscount(modifier, cashDiscountingAmount, subTotalWithoutCD, cashDiscountType));
      const cdAppliedItemExpressModifierAmount = cdAppliedItemExpressModifierPrices?.reduce((partialSum, modifier) => partialSum + modifier, 0);

      const cdAppliedItemDiscountAmount = this.applyWeightedCashDiscount(itemDiscountAmount, cashDiscountingAmount, subTotalWithoutCD, cashDiscountType);

      // calculate cash discount amount per item by taking difference of item subtotal with cash discounting to item subtotal without cash discounting
      const itemSubTotal = roundToTwoDecimals(roundToTwoDecimals(cdAppliedItemPrice) + roundToTwoDecimals(cdAppliedItemModifierAmount || 0) + roundToTwoDecimals(cdAppliedItemExpressModifierAmount || 0) - roundToTwoDecimals(cdAppliedItemDiscountAmount));
      const itemSubTotalNoCD = itemPrice + (itemModifierAmount || 0) + (itemExpressModifierAmount || 0) - itemDiscountAmount;
      const itemCashDiscountAmount = roundToTwoDecimals(itemSubTotal - itemSubTotalNoCD);

      // return object array containing applied cash discount amounts
      return {
        cd_item_price: cdAppliedItemPrice,
        cd_item_modifier_prices: cdAppliedItemModifierPrices,
        cd_item_express_modifier_prices: cdAppliedItemExpressModifierPrices,
        cd_item_discount_amt: cdAppliedItemDiscountAmount,
        item_subtotal_pre_cart_discounts: itemSubTotal,
        item_subtotal: itemSubTotal,
        item_discount_amt: itemDiscountAmount,
        item_service_fee_amt: itemCashDiscountAmount
      };
    });

    // get subtotal with cash discounts and apply remainder to the most expensive item
    let subTotalPreCartDiscounts = amountsPerItem?.reduce((acc, item) => acc + item.item_subtotal, 0);

    if (cashDiscountType === 'flat') {
      subTotalPreCartDiscounts = this.applyWeightedCashDiscount(subTotalWithoutCD, cashDiscountingAmount, subTotalWithoutCD, cashDiscountType);
      amountsPerItem = this.applyRemainderAmount(amountsPerItem, subTotalPreCartDiscounts, true);
    }

    //since flat discount amounts are calculated first, we check if reward code covers whole purchase amount
    let loyaltyDiscountEffectiveAmount = loyaltyDiscountAmount;
    const cartMinusFixedDiscounts = subTotalWithoutCD - cartDiscountFixed;

    const discountsApplied = itemizedCart?.receipt_discount_amt?.length !== 0;
    let loyaltyCustomAmount = false;

    if (((cartMinusFixedDiscounts > 0) && cartMinusFixedDiscounts - loyaltyDiscountAmount) < 0) {
      loyaltyCustomAmount = true;
      loyaltyDiscountEffectiveAmount = loyaltyDiscountAmount - Math.abs((cartMinusFixedDiscounts - loyaltyDiscountAmount));
    } else {
      if (cartMinusFixedDiscounts <= 0 && discountsApplied) {
        loyaltyCustomAmount = true;
        loyaltyDiscountEffectiveAmount = 0;
      }
    }

    if (loyaltyCustomAmount) {
      //remove percentage cart discounts amounts
      itemizedCart.receipt_discount_type.forEach((discount, index) => {
        if (itemizedCart.receipt_discount_type[index] === 'percent') {
          itemizedCart.receipt_discount_amt[index] = 0;
        }
      });

      if (cartDiscountPercentages.length) {
        cartDiscountPercentages = cartDiscountPercentages.map(() => 0);
      }
    }

    cartDiscountFixed = cartDiscountFixed + loyaltyDiscountEffectiveAmount;

    // apply cart discounts to item subtotals
    amountsPerItem = amountsPerItem?.map(amount => ({
      ...amount,
      item_subtotal: this.applyWeightedCartDiscount(amount.item_subtotal, cartDiscountFixed, subTotalPreCartDiscounts)
    }));

    // get subtotal with cart discounts and apply remainder to the most expensive item
    const subTotalPrePercentCartDiscounts = subTotalPreCartDiscounts - cartDiscountFixed;
    amountsPerItem = this.applyRemainderAmount(amountsPerItem, subTotalPrePercentCartDiscounts);

    const subTotalsPerItemPrePercentCartDiscounts = amountsPerItem?.map(amount => amount.item_subtotal);
    amountsPerItem = amountsPerItem?.map(amount => ({
      ...amount,
      item_subtotal: this.applyPercentageCartDiscount(amount.item_subtotal, cartDiscountPercentages, activeRewardCode)
    }));
    const cartPercentDiscountAmounts = this.calculateCartDiscountFromPercentage(subTotalsPerItemPrePercentCartDiscounts, cartDiscountPercentages);

    const subTotal = this.applyPercentageCartDiscount(subTotalPrePercentCartDiscounts, cartDiscountPercentages, activeRewardCode);
    amountsPerItem = this.applyRemainderAmount(amountsPerItem, subTotal);

    if (activeRewardCode && activeRewardCode.type !== 'dollar') {
      const loyaltyDiscountPercentage = numeral(activeRewardCode.amount).value() / 100;
      const subTotalPreLoyaltyDiscount = subTotal / (1 - loyaltyDiscountPercentage);
      loyaltyDiscountEffectiveAmount = roundToTwoDecimals(subTotalPreLoyaltyDiscount - subTotal);
    }

    // calculate tax and total amount for each item
    amountsPerItem = amountsPerItem?.map((amount, index) => {
      const itemTaxAmount = (amount.item_subtotal * itemizedCart.item_tax_rate[index]) / 100;
      const itemTotal = amount.item_subtotal + itemTaxAmount;

      const itemTaxPreCartDiscounts = roundToTwoDecimals((amount.item_subtotal_pre_cart_discounts * itemizedCart.item_tax_rate[index]) / 100);
      const itemTotalPreCartDiscounts = roundToTwoDecimals(amount.item_subtotal_pre_cart_discounts + itemTaxPreCartDiscounts);

      return {
        ...amount,
        item_tax_amt: itemTaxAmount,
        item_total: itemTotal,
        item_tax_pre_cart_discounts: itemTaxPreCartDiscounts,
        item_total_pre_cart_discounts: itemTotalPreCartDiscounts,
      };
    });

    // add cash discount applied amounts to cart object
    newCart.cd_item_price = amountsPerItem?.map(amount => handleNegative(amount.cd_item_price));
    newCart.cd_item_modifier_prices = amountsPerItem?.map(amount => amount.cd_item_modifier_prices);
    newCart.cd_item_express_modifier_prices = amountsPerItem?.map(amount => amount.cd_item_express_modifier_prices);
    newCart.cd_item_discount_amt = amountsPerItem?.map(amount => handleNegative(amount.cd_item_discount_amt));
    newCart.item_discount_amt = amountsPerItem?.map(amount => handleNegative(amount.item_discount_amt));
    newCart.item_service_fee_amt = amountsPerItem?.map(amount => handleNegative(amount.item_service_fee_amt));
    newCart.item_subtotal = amountsPerItem?.map(amount => handleNegative(roundToTwoDecimals(amount.item_subtotal_pre_cart_discounts)));
    newCart.item_tax_amt = amountsPerItem?.map(amount => handleNegative(amount.item_tax_pre_cart_discounts));
    newCart.item_total = amountsPerItem?.map(amount => handleNegative(amount.item_total_pre_cart_discounts));
    newCart.tax_amt = handleNegative(roundToTwoDecimals(amountsPerItem?.reduce((partialSum, item) => partialSum + item.item_tax_amt, 0)));
    newCart.service_fee_amt = handleNegative(roundToTwoDecimals(subTotalPreCartDiscounts - subTotalWithoutCD));
    newCart.sub_total_pre_discounts = handleNegative(subTotalPreCartDiscounts);
    newCart.sub_total_amt = handleNegative(subTotal);
    newCart.total_amt = handleNegative(roundToTwoDecimals(amountsPerItem?.reduce((partialSum, item) => partialSum + item.item_total, 0)));
    newCart.receipt_discount_amt = this.getCartDiscountAmounts(newCart, cartPercentDiscountAmounts);

    if (!!activeRewardCode) {
      newCart.loyalty_discount_amt = handleNegative(loyaltyDiscountEffectiveAmount);
    }
    return newCart;
  },

  previewCart(itemizedCart, currentValues, activeRewardCode) {
    const isItemizedInvoice = currentValues?.type === FormType.ITEMIZED;
    const cart = InvoiceUtil.recalculateCart(itemizedCart, currentValues, activeRewardCode);

    if (isItemizedInvoice) return cart;

    const customAmountSummaryValues = InvoiceUtil.recalculateCartCustomAmount(currentValues, activeRewardCode);
    cart.loyalty_discount_amt = customAmountSummaryValues.rewardDiscount;

    return cart;
  },

  deleteItem(itemIndex, itemizedCart) {

    const newCart = _.cloneDeep(itemizedCart);

    newCart.item_category.splice(itemIndex, 1);
    newCart.item_discount_amt.splice(itemIndex, 1);
    newCart.item_discount_rate.splice(itemIndex, 1);
    newCart.item_service_fee_amt.splice(itemIndex, 1);
    newCart.item_ids.splice(itemIndex, 1);
    newCart.item_image_id.splice(itemIndex, 1);
    newCart.item_names.splice(itemIndex, 1);
    newCart.item_price_id.splice(itemIndex, 1);
    newCart.item_quantity.splice(itemIndex, 1);
    newCart.item_subtotal.splice(itemIndex, 1);
    newCart.item_tax_rate.splice(itemIndex, 1);
    newCart.item_unit_price.splice(itemIndex, 1);
    newCart.modifier_ids.splice(itemIndex, 1);
    newCart.modifier_sets.splice(itemIndex, 1);
    newCart.modifier_amounts.splice(itemIndex, 1);
    newCart.express_options.splice(itemIndex, 1);

    if (newCart.item_ids.length === 0) {
      return this.initialItemizedCartObject();
    }
    return newCart;
  },

  editItem(itemIndex, items, itemizedCart) {

    const selectedItemId = itemizedCart.item_ids[itemIndex];
    const isExpressItem = selectedItemId === 'express';

    const selectedItem = isExpressItem ? null : items?.salesItems.filter(item => item.id === selectedItemId)[0];

    return {
      selectedItem
    };
  },

  deleteDiscount(discountIndex, itemizedCart) {

    const newCart = _.cloneDeep(itemizedCart);

    if (discountIndex >= 0) {
      newCart.receipt_discount_amt.splice(discountIndex, 1);
      newCart.receipt_discount_name.splice(discountIndex, 1);
      newCart.receipt_discount_percentage.splice(discountIndex, 1);
      newCart.receipt_discount_type.splice(discountIndex, 1);
      newCart.receipt_discount_color.splice(discountIndex, 1);
      newCart.receipt_discount_id.splice(discountIndex, 1);

      newCart.receipt_discount_sys_amt.splice(discountIndex, 1);
    }

    return newCart;

  },

  getDiscountEffectiveAmount(subTotal, discountAmount) {
    const subtotal = numeral(subTotal).value();
    let effectiveAmount = numeral(discountAmount).value();
    if (subtotal - effectiveAmount < 0) {
      effectiveAmount = subtotal;
    }
    return effectiveAmount;
  },

  addDiscountToCart(discountIndex, items, itemizedCart) {
    const newCart = _.cloneDeep(itemizedCart);
    const selectedDiscount = items.filteredDiscounts[discountIndex];
    let discountAmount;

    if (selectedDiscount.type === 'percent') {

      let currentDiscounts = [];
      let selectedDiscountAdded = false;
      let selectedAmount = roundToTwoDecimals(newCart.sub_total_pre_discounts * selectedDiscount.percentage / 100);

      newCart.receipt_discount_amt.forEach((amount, index) => {
        if (newCart.receipt_discount_percentage[index] && !selectedDiscountAdded && selectedAmount > amount) {

          currentDiscounts = [...currentDiscounts, {
            id: selectedDiscount.id,
            name: selectedDiscount.name,
            type: selectedDiscount.type,
            amt: selectedAmount,
            color: selectedDiscount.color,
            percentage: selectedDiscount.percentage,
          }];
          selectedDiscountAdded = true;
        }

        currentDiscounts = [... currentDiscounts, {
          id: newCart.receipt_discount_id[index],
          name: newCart.receipt_discount_name[index],
          type: newCart.receipt_discount_type[index],
          amt: amount,
          color: newCart.receipt_discount_color[index],
          percentage: newCart.receipt_discount_percentage[index],
        }];
      });

      if (!selectedDiscountAdded) {
        currentDiscounts = [...currentDiscounts, {
          id: selectedDiscount.id,
          name: selectedDiscount.name,
          type: selectedDiscount.type,
          amt: selectedAmount,
          color: selectedDiscount.color,
          percentage: selectedDiscount.percentage,
        }];
      }

      newCart.receipt_discount_percentage = [];
      newCart.receipt_discount_amt = [];
      newCart.receipt_discount_sys_amt = [];
      newCart.receipt_discount_type = [];
      newCart.receipt_discount_name = [];
      newCart.receipt_discount_color = [];
      newCart.receipt_discount_id = [];

      currentDiscounts.forEach((discount) => {
        newCart.receipt_discount_percentage = [...newCart.receipt_discount_percentage, discount.percentage];
        newCart.receipt_discount_amt = [...newCart.receipt_discount_amt, discount.amt];
        newCart.receipt_discount_sys_amt = [...newCart.receipt_discount_sys_amt, discount.amt];
        newCart.receipt_discount_type = [...newCart.receipt_discount_type, discount.type];
        newCart.receipt_discount_name = [...newCart.receipt_discount_name, discount.name];
        newCart.receipt_discount_color = [...newCart.receipt_discount_color, discount.color];
        newCart.receipt_discount_id = [...newCart.receipt_discount_id, discount.id];
      });

      discountAmount = roundToTwoDecimals(newCart.sub_total_pre_discounts * selectedDiscount.percentage / 100);


    } else {

      newCart.receipt_discount_percentage = [null, ...newCart.receipt_discount_percentage];
      newCart.receipt_discount_amt = [selectedDiscount.amount, ...newCart.receipt_discount_amt];
      newCart.receipt_discount_sys_amt = [selectedDiscount.amount, ...newCart.receipt_discount_sys_amt];
      newCart.receipt_discount_type = [selectedDiscount.type, ...newCart.receipt_discount_type];
      newCart.receipt_discount_name = [selectedDiscount.name, ...newCart.receipt_discount_name];
      newCart.receipt_discount_color = [selectedDiscount.color, ...newCart.receipt_discount_color];
      newCart.receipt_discount_id = [selectedDiscount.id, ...newCart.receipt_discount_id];
      discountAmount = selectedDiscount.amount;

    }

    return newCart;
  },

  getReceiptDiscounts(invoice) {
    const fixedDiscounts = [];
    const percentageDiscounts = [];
    const loyaltyDiscounts = [];

    invoice?.receipt_discount_type?.forEach((type, index) => {
      const name = invoice?.receipt_discount_name[index];
      const discount = {
        name,
        letters: name?.charAt()?.toUpperCase(),
        receiptDiscountInfo: invoice?.receipt_discount_info[index],
        receiptDiscountAmt: invoice?.receipt_discount_amt[index],
        type,
        index,
      };
      if (type === 'Percentage') {
        percentageDiscounts.push(discount);
      } else if (type.includes('Loyalty')) {
        loyaltyDiscounts.push(discount);
      } else {
        fixedDiscounts.push(discount);
      }
    });

    return [...loyaltyDiscounts, ...fixedDiscounts, ...percentageDiscounts.sort((a, b) => b.receiptDiscountAmt - a.receiptDiscountAmt)];

  },

  handleAddItemToOrder(formValues, addToOrderAdditionalInfo, itemizedCart, selectedItemIndex, isEditingItem, selectedItem, taxRate) {

    const newCart = _.cloneDeep(itemizedCart);
    const cartItem = {};
    let itemSubtotal, itemTaxAmount;

    const isExpressItemSelected = isEditingItem
      ? itemizedCart.item_ids[selectedItemIndex] === 'express'
      : selectedItemIndex === -1;

    cartItem.express_options = formValues.expressModifiers || [];
    const expressModifierAmount = cartItem.express_options.map(mod => numeral(mod.price).value());
    cartItem.modifier_amounts = expressModifierAmount.reduce((partialSum, a) => partialSum + a, 0);

    if (!isExpressItemSelected) {

      const discount = numeral(formValues.itemDiscountValue).value();
      const price = selectedItem?.details?.prices?.find(price =>  price?.id === formValues.itemPriceId);

      cartItem.item_ids = (selectedItem?.id);

      if (formValues.itemDiscountType === 'percent') {

        cartItem.item_discount_rate = roundToTwoDecimals(discount * 100);
        cartItem.item_discount_amt = null;
      } else {
        cartItem.item_discount_rate = null;
        cartItem.item_discount_amt = discount * formValues.itemQuantity;
      }

      cartItem.item_names = (selectedItem?.name + ' - ' + price?.name);
      cartItem.item_quantity = formValues.itemQuantity;
      cartItem.item_unit_price = roundToTwoDecimals(price?.price);
      cartItem.item_price_id = formValues.itemPriceId;
      cartItem.item_image_id = selectedItem?.image_id;
      cartItem.item_category = selectedItem?.categories ? selectedItem.categories[0]?.id : null;
      cartItem.item_service_fee_amt = null;

      const arrayOfSelectedModifiers = [];
      const modifiersIds = [];

      for (let modifierSetId in addToOrderAdditionalInfo.selectedModifiers) {
        addToOrderAdditionalInfo.selectedModifiers[modifierSetId]?.map(modifierId => {

          const modifierSet = selectedItem?.details?.modifierSets.find(modifierSet => modifierSet?.id === parseInt(modifierSetId));
          const specificModifier = modifierSet?.modifiers.find(modifier => modifier?.id === modifierId);

          modifiersIds.push({setId: modifierSet?.id, modifierId: specificModifier?.id, price: specificModifier?.price});
          const formattedModifier = `${selectedItem?.id},${specificModifier?.name},${specificModifier?.price}`;
          arrayOfSelectedModifiers.push(formattedModifier);
        });
      }
      cartItem.modifier_sets = (arrayOfSelectedModifiers);
      cartItem.modifier_ids = (modifiersIds);

      itemSubtotal = numeral(addToOrderAdditionalInfo.itemCurrentSubtotal).value();
      cartItem.item_subtotal = itemSubtotal;

      const itemTaxRate = selectedItem?.is_taxable
        ? taxRate
        : 0;
      cartItem.item_tax_rate = itemTaxRate;

      const modifiersAmount = this.mapItemModifiers(cartItem.modifier_sets).map((mod) => Number(mod.price));
      cartItem.modifier_amounts = modifiersAmount.reduce((partialSum, a) => partialSum + a, cartItem.modifier_amounts);
      itemTaxAmount = itemSubtotal * itemTaxRate / 100;

    } else {

      cartItem.item_ids = 'express';
      cartItem.item_discount_rate = null;
      cartItem.item_discount_amt = null;
      cartItem.item_service_fee_amt = null;
      cartItem.item_names = formValues.expressItemName;
      cartItem.item_quantity = formValues.itemQuantity;
      cartItem.item_unit_price = roundToTwoDecimals(numeral(formValues.expressItemPrice).value());
      cartItem.item_price_id = null;
      cartItem.item_image_id = null;
      cartItem.modifier_sets = [];
      cartItem.modifier_ids = [];
      cartItem.item_category = formValues.expressItemCategory;

      itemSubtotal = numeral(addToOrderAdditionalInfo.itemCurrentSubtotal).value()
      cartItem.item_subtotal = itemSubtotal;

      const expressItemTaxRate = formValues.expressItemTaxable
        ? Number(FormatTextUtil.formatNumberToThreeDecimals(formValues.expressItemTaxRate))
        : 0;
      cartItem.item_tax_rate = expressItemTaxRate;

      itemTaxAmount = itemSubtotal * expressItemTaxRate / 100;

    }

    for (const key in cartItem) {
      if (!isEditingItem) {
        newCart[key].push(cartItem[key]);
      } else {
        newCart[key][selectedItemIndex] = cartItem[key];
      }
    }

    return newCart;

  },

  getCustomerState(state) {
    let customerState = '';

    if (!!state) {
      const index = countryList[0].states.indexOf(state);

      if (index !== -1) {
        customerState = countryList[0].stateCodes[index];
      }
    }

    return customerState;
  },

  getSubTotal(invoice, type) {
    return type === FormType.CUSTOM_AMOUNT
      ? invoice?.item_unit_price?.[0]
      : invoice?.sub_total_amt;
  },

  getTaxRate(invoice, type) {
    return type === FormType.CUSTOM_AMOUNT && invoice?.item_tax_rate?.length > 0
      ? invoice.item_tax_rate[0]
      : 0;
  },

  getSendDate: (values) => {
    const hasCustomSendDate = values.selectedSendDateValue === CUSTOM_DATE_VALUE;
    const sendDate = hasCustomSendDate ? values[CUSTOM_SEND_DATE_VALUE] : DateUtils.addTimeToToday(values.selectedSendDateValue, 'days');
    return moment(sendDate).format('YYYY-MM-DD');
  },

  getDueDate: (values) => {
    let dueDate;
    const hasCustomDueDate = values.selectedDueDateValue === CUSTOM_DATE_VALUE;
    const hasCustomSendDate = values.selectedSendDateValue === CUSTOM_DATE_VALUE;

    if (values.recurring) {
      dueDate = hasCustomDueDate ? values[CUSTOM_DUE_DATE_VALUE] :
        moment(values.recurring_start).add(values.selectedDueDateValue, 'days');
    } else if (!hasCustomDueDate && !hasCustomSendDate) {
      dueDate = moment(values.sendDate)
        .add(values.selectedDueDateValue, 'days').startOf('day');
    } else {
      dueDate = hasCustomDueDate ? values[CUSTOM_DUE_DATE_VALUE] :
        moment(values[CUSTOM_SEND_DATE_VALUE]).add(values.selectedDueDateValue, 'days').startOf('day');
    }

    return moment(dueDate).format('YYYY-MM-DD');
  },

  getLineItems: (itemizedCart, isClp = false) => {
    const lineItems = [];

    if (itemizedCart?.item_ids?.length > 0) {
      itemizedCart.item_ids.map((item, index) => {
        if ((item !== 'express' && isClp === false ) || (isClp && itemizedCart.is_one_time_use[index] === false)) {
          const lineItem = {
            price_id: itemizedCart.item_price_id[index],
            price_at_time: itemizedCart.item_unit_price[index],
            price_total_amt: itemizedCart.cd_item_price[index],
            quantity: itemizedCart.item_quantity[index],
            itemId: item,
            amount: itemizedCart.item_unit_price[index],
            sub_total_amt: itemizedCart.item_subtotal[index],
            tax_amt: itemizedCart.item_tax_amt[index],
            total_amt: itemizedCart.item_total[index],
            tax_rate: itemizedCart.item_tax_rate[index] > 0.001 ? itemizedCart.item_tax_rate[index] : 0
          };

          if (itemizedCart.item_discount_amt[index]) {
            lineItem.discount_amount = itemizedCart.item_discount_amt[index];
            lineItem.discount_total_amt = itemizedCart.cd_item_discount_amt[index];
          }

          if (itemizedCart.item_discount_rate[index]) {
            lineItem.discount_rate = itemizedCart.item_discount_rate[index];
            lineItem.discount_total_amt = itemizedCart.cd_item_discount_amt[index];
          }

          if (itemizedCart.item_service_fee_amt[index]) {
            lineItem.service_fee_amt = itemizedCart.item_service_fee_amt[index];
          }

          if (itemizedCart.modifier_ids[index]?.length > 0) {
            lineItem.option_members = [];

            itemizedCart.modifier_ids[index].map((modifier, modifierIndex) => {
              const modifierCashDiscountAmount = roundToTwoDecimals(
                itemizedCart.cd_item_modifier_prices[index][modifierIndex] - (numeral(modifier.price).value() * itemizedCart.item_quantity[index])
              );

              lineItem.option_members.push({
                id: modifier.modifierId,
                amount: modifier.price,
                total_amt: itemizedCart.cd_item_modifier_prices[index][modifierIndex],
                service_fee_amt: modifierCashDiscountAmount
              });
            });
          }

          if (itemizedCart.express_options[index]?.length > 0) {
            lineItem.express_options = [];

            itemizedCart.express_options[index]?.map((modifier, modifierIndex) => {
              const modifierCashDiscountAmount = roundToTwoDecimals(
                itemizedCart.cd_item_express_modifier_prices[index][modifierIndex] - (numeral(modifier.price).value() * itemizedCart.item_quantity[index])
              );

              lineItem.express_options.push({
                name: modifier.name,
                price: numeral(modifier.price).value().toFixed(2),
                total_amt: itemizedCart.cd_item_express_modifier_prices[index][modifierIndex],
                service_fee_amt: modifierCashDiscountAmount
              });
            });
          }

          lineItems.push(lineItem);
        }
      });
    }

    return lineItems;
  },

  getExpressLineItems: (itemizedCart, description, subTotal, taxRate, cashDiscountAmount, isClp = false) => {
    const expressLineItems = [];
    if (itemizedCart?.item_ids?.length > 0) {
      itemizedCart.item_ids.map((item, index) => {
        if (item === 'express' || (isClp && itemizedCart.is_one_time_use[index] === true)) {
          const expressTaxRate = itemizedCart.item_tax_rate[index] > 0.001 ? itemizedCart.item_tax_rate[index] : 0;
          const expressOptions = itemizedCart.express_options[index]?.length > 0 && itemizedCart.express_options[index].map((modifier, modifierIndex) => {
            const modifierCashDiscountAmount = roundToTwoDecimals(
              itemizedCart.cd_item_express_modifier_prices[index][modifierIndex] - (numeral(modifier.price).value() * itemizedCart.item_quantity[index])
            );

            return {
              name: modifier.name,
              price: numeral(modifier.price).value().toFixed(2),
              total_amt: itemizedCart.cd_item_express_modifier_prices[index][modifierIndex],
              service_fee_amt: modifierCashDiscountAmount
            };
          });

          expressLineItems.push({
            is_one_time_use: true,
            is_taxable: !!expressTaxRate,
            tax_rate: expressTaxRate,
            name: itemizedCart.item_names[index]?.split('-')[0].trim() || t('ExpressItemDescription'),
            is_favorite: false,
            is_trackable: false,
            is_active: false,
            price_total_amt: itemizedCart.cd_item_price[index],
            sub_total_amt: itemizedCart.item_subtotal[index],
            ...(itemizedCart.item_service_fee_amt[index] && { service_fee_amt: itemizedCart.item_service_fee_amt[index] }),
            tax_amt: itemizedCart.item_tax_amt[index],
            total_amt: itemizedCart.item_total[index],
            service_fee_amt: itemizedCart.item_service_fee_amt[index],
            prices: [{
              name: t('BasePrice'),
              price: itemizedCart.item_unit_price[index],
              quantity: itemizedCart.item_quantity[index]
            }],
            ...(itemizedCart.express_options[index]?.length > 0 && { express_options: expressOptions })
          });
        }
      });
    } else if (!itemizedCart) {
      const expressTaxRate = taxRate > 0.001 ? taxRate : 0;
      const expressSubTotal = subTotal + cashDiscountAmount;
      const expressTaxAmount =  _.round(expressSubTotal * expressTaxRate / 100, 2);
      const expressTotalAmount = _.round(expressSubTotal + expressTaxAmount, 2);

      expressLineItems.push({
        is_one_time_use: true,
        is_taxable: !!expressTaxRate,
        tax_rate: expressTaxRate,
        name: description || t('ExpressItemDescription'),
        is_favorite: false,
        is_trackable: false,
        is_active: false,
        sub_total_amt: expressSubTotal,
        service_fee_amt: cashDiscountAmount,
        tax_amt: expressTaxAmount,
        total_amt: expressTotalAmount,
        prices: [{
          name: t('BasePrice'),
          price: subTotal,
          quantity: 1
        }]
      });
    }

    return expressLineItems;
  },

  getDiscounts(itemizedCart) {
    const discounts = [];

    if (itemizedCart?.receipt_discount_id?.length > 0) {
      itemizedCart.receipt_discount_id.map((discountId, index)=> {
        discountId !== null && discounts.push({
          id: discountId,
          amount: itemizedCart.receipt_discount_amt[index]
        });
      });
    }

    return discounts;
  },

  buildsCartFromInvoice(selectedInvoice) {
    const cart = this.initialItemizedCartObject();
    //logic start express_options
    const mappedModifiers = selectedInvoice.item_modifiers.map(this.mapInvoiceModifiers);
    cart.express_options = mappedModifiers.map(modifiersArray => {
      const expressModifiers = modifiersArray && modifiersArray.length ? [modifiersArray.filter(modifier => modifier.option_id === '0')] : null;
      return expressModifiers?.map((expressModifier) => {
        if (expressModifier?.length) {
          const name = expressModifier[0].name;
          const price = expressModifier[0].price;
          return [{name, price}];
        } else {
          return []
        }
      })
    }).flat();
    const systemModifiers = mappedModifiers.map(modifiersArray => {
      const modifiersWithoutExpress = modifiersArray && modifiersArray.length ? [modifiersArray.filter(modifier => modifier.option_id !== '0')] : null;
      return modifiersWithoutExpress;
    }).flat();
    cart.item_discount_amt = selectedInvoice.item_discount_amt;
    cart.item_discount_rate = selectedInvoice.item_discount_rate;
    cart.item_ids = selectedInvoice.item_ids;
    cart.item_image_id = selectedInvoice.item_image_id;
    cart.item_names = selectedInvoice.item_names;
    cart.item_price_id = selectedInvoice.item_price_id;
    cart.item_quantity = selectedInvoice.item_quantity;
    cart.item_tax_rate = selectedInvoice.item_tax_rate;
    cart.item_unit_price = selectedInvoice.item_unit_price;
    if (selectedInvoice?.is_one_time_use?.length === 1 && selectedInvoice?.is_one_time_use[0]) {
      cart.item_service_fee_amt = [numeral(selectedInvoice?.service_fee_amt).value()]
    }
    //logic start modifier_amounts
    cart.modifier_amounts = mappedModifiers.map((modifiers) => modifiers?.reduce((acc, current) => acc + Number(current.price), 0));
    //logic start modifier_sets
    cart.modifier_sets = systemModifiers.map((modifiers) => modifiers?.map((singleModifier) => `${singleModifier.item_id},${singleModifier.name},${singleModifier.price}`));
    //logic start modifier_ids
    cart.modifier_ids = systemModifiers.map(modifiers => {
      return modifiers?.map((singleModifier) => {
        return {
          modifierId: Number(singleModifier?.id),
          price: Number(singleModifier?.price)
        }
      });
    }).map((modifiersArray) => modifiersArray?.filter((singleModifier) => singleModifier?.modifierId != 0));
    cart.receipt_discount_amt = selectedInvoice.receipt_discount_amt || [];
    cart.receipt_discount_sys_amt = selectedInvoice.receipt_discount_amt || [];
    cart.receipt_discount_id = selectedInvoice.receipt_discount_id;
    cart.receipt_discount_name = selectedInvoice.receipt_discount_name;
    cart.receipt_discount_percentage = selectedInvoice.receipt_discount_info.map((discount) => discount?.includes('percentage') ? JSON.parse(discount).percentage: null);
    cart.receipt_discount_type = selectedInvoice.receipt_discount_type.map((discount) => discount?.toLowerCase().includes('percentage') ? 'percent': 'flat');
    cart.receipt_discount_info = selectedInvoice.receipt_discount_info;
    cart.service_fee_amt = selectedInvoice.service_fee_amt;
    cart.sub_total_amt = selectedInvoice.sub_total_amt;
    cart.item_price_id = selectedInvoice.price_id || [];
    cart.is_one_time_use = selectedInvoice?.is_one_time_use || [];
    return cart;
  },

  checkPaidActivity(invoice) {
    return invoice?.activity?.some(item =>
      [invoiceActivity.PAID_AUTO, invoiceActivity.PAID_CLP, invoiceActivity.PAID_MARKED, invoiceActivity.PAID_PORTAL]
        .includes(item.action));
  },

  formatNamePriceQuantity(itemName, priceName, quantity) {
    const itemNamePortion = itemName?.includes(PriceName.BASE_PRICE) ? itemName?.split(' - ')[0] : itemName;
    const priceNamePortion = priceName?.includes(PriceName.BASE_PRICE) ? '' : ` - ${priceName}`;
    return `${itemNamePortion}${priceNamePortion} (${quantity})`;
  },
  obfuscatePayload(payload){
    const jsonString = JSON.stringify(payload);
    const base64String = btoa(jsonString);
    const obfuscatedString = base64String.split('').map(char => {
      return String.fromCharCode(char.charCodeAt(0) + 3);
    }).join('');
    return obfuscatedString;
  },
  formatBulkInvoicesDetails(data) {
    const totalImports = (data.series?.length ?? 0) + (data.invoices?.length ?? 0);
    const totalErrors = data.import_errors?.rows?.length ?? 0;
    const errorHeaders = data.import_errors?.headers ?? []

    const invoicesDataTable = data.invoices?.length ? data.invoices.map((invoice) => {
      return (
        {
          customer: `${invoice.first_name} ${invoice.last_name}`,
          emailAddress: invoice.email,
          phoneNumber: invoice.phone ?? '',
          description: invoice.description,
          invoiceName: invoice.invoice_name,
          invoiceNumber: invoice.invoice_number,
          frequency: 'N/A',
          startDate: 'N/A',
          endDate: 'N/A',
          amount: numeral(invoice.amount).format('$0,0.00'),
          tax: `${roundToTwoDecimals(invoice.tax_rate)}%`,
          sendDate: getMomentDateFromUnix(invoice.send_date).format('MM/DD/YYYY'),
          dueDate: moment.utc(invoice.due_date).format('MM/DD/YYYY'),
          paymentMethod: invoice.payment_method,
          tipEnabled: invoice.tip_enabled?.toString() ?? false,
          cdEnabled: invoice.cash_discount_enabled?.toString() ?? false
        }
      )
    }) : [];

    const seriesDataTable = data.series?.length ? data.series.map((invoice) => {

      const [quantity, period] = invoice.repeat_every.split(' ');
        const frequency = (quantity && period) &&
          `${FormatTextUtil.upperCaseFirstLowerCaseRest(t('Every'))} ${quantity} ${t(FrequencyTimeMeasure?.[period])}`;

      return (
        {
          customer: `${invoice.first_name} ${invoice.last_name}`,
          emailAddress: invoice.email,
          phoneNumber: invoice.phone ?? '',
          description: invoice.description,
          invoiceName: invoice.series_name,
          invoiceNumber: invoice.invoice_number,
          amount: numeral(invoice.amount).format('$0,0.00'),
          tax: `${roundToTwoDecimals(invoice.tax_rate)}%`,
          sendDate: moment.utc(invoice.send_date).format('MM/DD/YYYY'),
          dueDate: '',
          frequency: frequency,
          startDate: moment.utc(invoice.send_date).format('MM/DD/YYYY'),
          endDate: invoice.end_date,
          paymentMethod: invoice.payment_method,
          tipEnabled: invoice.tip_enabled?.toString() ?? false,
          cdEnabled: invoice.cash_discount_enabled?.toString() ?? false
        }
      )
    }) : [];

    const errorMapping = data.import_errors?.rows?.length ? data?.import_errors?.rows.map(row =>
      row.values.map((value, index) => ({
        column: errorHeaders[index],
        value,
        error: row.column === errorHeaders[index],
        validationError: row.column === errorHeaders[index] ? row.message : null
      }))
    ) : [];

    const invoicesRow = [...invoicesDataTable, ...seriesDataTable];

    return {
      totalImports,
      totalErrors,
      invoicesRow,
      errorHeaders,
      errorRows: errorMapping
    }

  },

  formatPayNowInvoiceTotal(invoice)  {

    const itemDiscountTotalAmount = invoice?.item_discount_total_amt?.reduce((total, currentDiscount) => (total + roundToTwoDecimals(currentDiscount)), 0) || 0;
    const itemModifiersTotalAmount = roundToTwoDecimals(invoice?.item_modifiers?.reduce((total, currentModifier) => (total + roundToTwoDecimals(currentModifier?.[0]?.split(',')?.[4])), 0));
    const receiptDiscountsTotalAmount = !invoice.paid_date && !invoice.receipt_updated_date ? invoice?.receipt_discount_amt?.reduce((total, currentDiscount) => (total + roundToTwoDecimals(currentDiscount)), 0) : 0;

    const taxAmount = roundToTwoDecimals(invoice.tax_amt);
    const tipAmount = numeral(invoice.tip_amount).value();

    //Rounding again after subtracting discount to avoid JS math bugs
    const priceTotalAmount = roundToTwoDecimals(invoice?.price_total_amount?.reduce((total, currentPrice) => (total + roundToTwoDecimals(currentPrice)), 0));
    const subTotalAmount = roundToTwoDecimals((priceTotalAmount + itemModifiersTotalAmount) - (receiptDiscountsTotalAmount + itemDiscountTotalAmount));
    const totalAmount =  (subTotalAmount + roundToTwoDecimals(tipAmount) + taxAmount);

    return {
      receiptDiscountsTotalAmount,
      taxAmount,
      tipAmount,
      priceTotalAmount,
      subTotalAmount,
      totalAmount
    }
  }

};

export default InvoiceUtil;
