/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import _ from 'lodash';
import numeral from 'numeral';
import { v4 } from 'uuid';

/**
 * Executes multiple promises sync'd
 * @example
 * // resolves promise
 * chainPromises([promise1, promise2]);
 * @returns {Promise} Returns a promise that will be resolved once all the chained promises were resolved
 */
const chainPromises = c => {
  return new Promise(
    (resolve, reject) => {
      c.push(resolve);
      c.reduce((p, f) => p.then(f).catch(reject), Promise.resolve());
    }
  );
};

/**
 * Handles batch operation by chunks and a certain limit of async operations
 * allows for custom cb to execute after each chunk
 * action has to be a promise
 * @returns {Promise} Returns a promise that will be resolved once all the chunks were processed
 */
const handleBatchByChunks = (action, batch, chunkSize, asyncLimit, cb) => {
  let chunks = [], asyncOps = [], asyncOpsMapped;
  _.each(_.chunk(batch, chunkSize), c => {
    _.each(_.chunk(c, Math.round(chunkSize / asyncLimit)), i => {
      asyncOps.push(() => { return action(i).then(r => { if (cb) cb(r) }) });
    });
    asyncOpsMapped = asyncOps.map(f => { return f() });
    asyncOps = [];
    chunks.push(() => { return Promise.all(asyncOpsMapped) });
  });
  return chainPromises(chunks);
};


/**
 * Merges two arrays by comparing an specific field value on each array
 * Each array could have a different field name for example:
 * array1 = [{id:1}]; array2 = [{ID:1}];
 * @returns [Array] Return an array with all the values merged
 */
const mergeByFields = (a1, a2, f1, f2) => {

  const indexed = {};
  const results = [];

  _.each(a1, i => {
    indexed[i[f1]] = i;
  });

  _.each(a2, i => {
    const match = indexed[i[f2]];
    if (match) results.push(Object.assign(i, match))
  });

  return results;
};

/**
 * Merges two arrays by comparing an specific field value on each array
 * Each array could have a different field name for example:
 * array1 = [{id:1}]; array2 = [{ID:1}];
 * It assumes there could be multiple matches inside the second array, so it addes it to the erged array un the field nameOfMany
 * @returns [Array] Return an array with all the values merged
 */
const mergeByFieldsOneToMany = (a1, a2, f1, f2, nameOfMany) => {

  const indexed = {};
  const holder = {};

  _.each(a1, i => {
    indexed[i[f1]] = i;
  });

  _.each(a2, i => {
    const match = indexed[i[f2]];
    if (match) {
      if (holder[match[f1]]) {
        match[nameOfMany].push(i);
        holder[match[f1]] = match;
      } else {
        match[nameOfMany] = [i];
        holder[match[f1]] = match;
      }
    }
  });

  const results = [];

  _.each(holder, (v, k) => {
    results.push(v);
  });

  return results;
};

const difference = (currentValue, previousValue) => {

  if (currentValue === 0 && previousValue === 0) {
    return 0;
  } else if (currentValue > 0 && previousValue > 0) {
    return (currentValue - previousValue) / previousValue;
  } else if (currentValue === 0 && previousValue > 0) {
    return -previousValue;
  } else if (currentValue > 0 && previousValue === 0) {
    return currentValue;
  } else {
    return 0;
  }
};

const roundToTwoDecimals = value => {
  if (!isNaN(value)) {
    if (typeof value === 'number') {
      return _.round(numeral(value.toFixed(10)).value(), 2);
    }

    if (typeof value === 'string') {
      return _.round(numeral(value).value(), 2);
    }
  }

  return 0;
};
const handleNegative = value => {
  return value > 0 ? value : 0;
};

const allValuesTrue = obj => {

  if(!obj) {
    return false;
  }

  return Object.keys(obj).every((key) => obj[key] === true);
}

const differencePercentage = (currentValue, previousValue) => {

  if (currentValue === 0 && previousValue === 0) {
    return 0;
  } else if (currentValue > 0 && previousValue > 0) {
    return ((currentValue - previousValue) / previousValue) * 100;
  } else if (currentValue === 0 && previousValue > 0) {
    return -previousValue;
  } else if (currentValue > 0 && previousValue === 0) {
    return currentValue;
  } else {
    return 0;
  }
};

/**
 * Retrieves the indices of the matches
 * Each array could have a different field name for example:
 * array1 = [{id:1}]; array2 = [{ID:1}];
 * So you would specify it like getMatchesIndices = (array1, array2, 'id', 'ID')
 * @returns [Array] Return an array of matches, each match contains the index of the match on the first array and the second array
 */
const getMatchesIndices = (a1, a2, f1, f2) => {

  const indexed = {};
  const results = [];

  _.each(a1, (v, k) => {
    indexed[v[f1]] = { v, k };
  });

  _.each(a2, (v, k) => {
    const match = indexed[v[f2]];
    if (match) results.push({ indexFirstArray: match.k, indexSecondArray: k })
  });

  return results;
};

/**
 * Generates a look up table for data iteration
 * @param1 Array - the array to lookup[{Item: Camera, Category:Photo},{Item: Mouse, Category:Electronics}]
 * @param2 String - the key to create the table
 * @return Map with all the keys to look for
 */
const generateLookupTable = (array, keyField) => new Map(array.map((item) => [item[keyField], item]));

const capitalizeFirstLetter = (string) => {
  const trimmedString = string?.trim();
  return trimmedString ? `${trimmedString[0]?.toUpperCase()}${trimmedString?.substring(1)?.toLowerCase()}` : '';
};


const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const calculateDistanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
  lon1 =  lon1 * Math.PI / 180;
  lon2 = lon2 * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;

  // Haversine formula
  const dlon = lon2 - lon1;
  const dlat = lat2 - lat1;
  const a = Math.pow(Math.sin(dlat / 2), 2)
            + Math.cos(lat1) * Math.cos(lat2)
            * Math.pow(Math.sin(dlon / 2),2);

  const c = 2 * Math.asin(Math.sqrt(a));

  const radius = 3956;

  // calculate the result
  return Math.round(c * radius * 10) / 10;
}

const handleEncodeWithUnicode = (value) => {
  return btoa(encodeURIComponent(value).replace(/%([0-9A-F]{2})/g, (match, textValue)=> String.fromCharCode('0x' + textValue)));
}

const isValidAtob = (str)=> {
  try {
    atob(str);
  } catch (e) {
    return false;
  }
  return true;
}

const checkLength = array => (!!array?.length && array);

const uuidUtil = {
  uuid: () => v4()
};

const bodyParamBuilder = (bodyParamsArray) => (
  (
    bodyParamsArray && Array.isArray(bodyParamsArray) ? (
    bodyParamsArray.map((paramArray) => (
        paramArray && Array.isArray(paramArray) ? paramArray.join('=') : ''
    ))
    ) : []
  ).filter(param => param).join('&')
);

const calculateTotal = (array, property) => array.reduce((prev, next) => prev + (next && next[property] ? parseInt(next[property]) : 0), 0);

const commonUtil = {
  chainPromises,
  handleBatchByChunks,
  mergeByFields,
  mergeByFieldsOneToMany,
  difference,
  differencePercentage,
  getMatchesIndices,
  allValuesTrue,
  generateLookupTable,
  roundToTwoDecimals,
  handleNegative,
  capitalizeFirstLetter,
  isJSON,
  calculateDistanceBetweenPoints,
  handleEncodeWithUnicode,
  isValidAtob,
  checkLength,
  uuidUtil,
  bodyParamBuilder,
  calculateTotal
};

export default commonUtil;
