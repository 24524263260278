/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import Grid from '@mui/material/Grid';
import Button from '../shared/Button';
import ReverseButton from '../shared/ReverseButton';
import {
  upgradePlanGridBorderBottomRight,
  upgradePlanGridBorderBottom,
  upgradePlanGridBorderRight
} from '../../jss/inlineStyles';
import {togglePlanListDialog} from '../../actions/userExperienceActions';
import {getUserProfile, updateUser} from '../../actions/userProfileActions';
import UserUtil from '../util/UserUtil';
import LabelUtil from '../util/LabelUtil';
import {
  buttonText, prices, processDowngradeLoyaltyText, processDowngradeText, processUpgradeLoyaltyText,
  processUpgradeText, successDowngradeText,
  successUpgradeText, text
} from '../../constants/planInformation';
import {tierUpgrade} from '../../actions/userActions';
import UpdateSpinner from '../UpdateSpinner';
import MessageDialog from '../shared/MessageDialog';
import planTypes from '../../constants/planTypes';
import actionTypes from '../../constants/actionTypes';

export default class PlanUpgradeOptIn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      successModal: false,
      successText: '',
      processModal: false,
      processText: '',
      oldPlan: '',
      errorModal: false
    }
  }

  handleCloseProcessDialog = () => {
    this.setState({processModal: false})
  }

  handleCloseErrorDialog = () => {
    this.setState({errorModal: false})
  }

  handleCloseSuccessDialog = async () => {
    const {dispatch, user} = this.props;
    await dispatch(togglePlanListDialog(false));
    this.setState({successModal: false});
    await dispatch(getUserProfile(user));
    dispatch(updateUser());
  }

  showPlanModal = () => {
    const {planType, user, hasLoyaltyOption} = this.props;

    const accountCurrentPlan = UserUtil.accountCurrentPlan(user);
    const planToUse = (hasLoyaltyOption && planType === planTypes.premiumPlus) ? planTypes.premiumPlusLoyalty : planType;
    const isUpgradingPlan = UserUtil.isPlanUpgrade(user, planToUse);

    const premiumPlusLoyaltyDowngrade = (accountCurrentPlan === planTypes.premiumPlusLoyalty && planType === planTypes.premiumPlus);
    const planUpgrade = [planTypes.free, planTypes.premium].includes(accountCurrentPlan) && [planTypes.premium,planTypes.premiumPlus,planTypes.premiumPlusLoyalty].includes(planToUse);
    const isAddingLoyalty = accountCurrentPlan === planTypes.premiumPlus && planToUse === planTypes.premiumPlusLoyalty;

    if (isUpgradingPlan) {
      if (planUpgrade) {
        this.setState({processText: processUpgradeText})
      }

      if (isAddingLoyalty) {
        this.setState({processText: processUpgradeLoyaltyText});
      }
    } else {
      if (premiumPlusLoyaltyDowngrade) {
        this.setState({processText: processDowngradeLoyaltyText});
      } else {
        this.setState({processText: processDowngradeText});
      }
    }
    this.setState({processModal: true});
  };

  planUpgrade = async () => {
    const {planType, user, dispatch, hasLoyaltyOption} = this.props;
    const isPaUser = UserUtil.isPayanywhere(user);
    const oldPlan = UserUtil.accountCurrentPlan(user);
    const planToUse = (hasLoyaltyOption && isPaUser) ? planTypes.premiumPlusLoyalty : planType;
    this.setState({processing: true, processModal: false, oldPlan});
    const isUpgradingPlan = UserUtil.isPlanUpgrade(user, planToUse);
    if (isUpgradingPlan) {
      this.setState({successText: successUpgradeText})
    } else {
      this.setState({successText: successDowngradeText})
    }
    const response = await dispatch(tierUpgrade(user, planToUse));
    this.setState({processing: false});
    if (response.type === actionTypes.tierUpgradeSuccess ) {
      this.setState({successModal: true});
    } else {
      this.setState({errorModal: true});
    }
  };

  render() {

    const {planType, data, openPlansModal, feature, t, user, auth, hasLoyaltyOption} = this.props;
    const {processing, processModal, successModal, processText, successText, oldPlan, errorModal} = this.state;

    const isPaUser = UserUtil.isPayanywhere(user);
    const accountCurrentPlan = UserUtil.accountCurrentPlan(user);
    const planToUse = (hasLoyaltyOption && isPaUser && planType === planTypes.premiumPlus) ? planTypes.premiumPlusLoyalty : planType;
    const label = LabelUtil.getLabel();
    const loading = processing ? <UpdateSpinner /> : null;
    const buttonClass = (planType === planTypes.premiumPlus || planType === planTypes.premiumPlusLoyalty) ? 'gradientButton' : 'noShadowButton';

    const isPremiumPlusPlan = (hasLoyaltyOption && isPaUser && accountCurrentPlan === planTypes.premiumPlus);
    const isPremiumPlan= planType === planTypes.premiumPlus;

    const actionButtonText = isPremiumPlusPlan ? 'UpgradePremiumPlus.UpgradeButtonPremiumPlusLoyalty' : isPremiumPlan ? 'UpgradePremiumPlus.UpgradeButtonPremiumPlus' : 'UpgradePremiumPlus.UpgradeButtonPremium';

    const titleText = planType === planTypes.premiumPlus ? 'UpgradePremiumPlus.TitlePremiumPlus' : 'UpgradePremiumPlus.TitlePremium';
    const isOwner = UserUtil.isOwner(user) || auth?.isOwner;
    const isUserCS = UserUtil.isUserCS(user, auth);
    const isUserCSWrite = UserUtil.isUserCSWrite(user);
    const isActiveMerchantAccount = UserUtil.isActive(user);
    const shouldShowButton = (isOwner && isUserCS) || (isOwner && isUserCSWrite) || isOwner;
    const shouldBeDisabled = (isUserCS || isUserCSWrite || !isActiveMerchantAccount);

    const processDialog = (
      <MessageDialog
        confirmText={t(processText['button'])}
        isChoiceRequired={false}
        onConfirm={this.planUpgrade}
        onRequestClose={this.handleCloseProcessDialog}
        open={processModal}
        titleText={t(processText['title'])}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>
            {t(processText['body'], { plan: buttonText[planToUse], price: prices[planToUse] })}
          </p>
        }/>
    );

    const successDialog = (
      <MessageDialog
        confirmText={t('Done')}
        isChoiceRequired={false}
        onConfirm={this.handleCloseSuccessDialog}
        onRequestClose={this.handleCloseSuccessDialog}
        open={successModal}
        titleText={t(successText['title'])}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>
            {t(successText['body'], { plan: buttonText[planToUse], oldPlan: buttonText[oldPlan] })}
          </p>
        }/>
    );

    const errorDialog = (
      <MessageDialog
        confirmText={t('Done')}
        isChoiceRequired={false}
        onConfirm={this.handleCloseErrorDialog}
        onRequestClose={this.handleCloseErrorDialog}
        open={errorModal}
        titleText={t('ErrorPlanModal.Title')}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>
            {t('ErrorPlanModal.Body', {phone: label.phoneNumber, email: label.emailAddress})}
          </p>
        }/>
    );

    return (
      <section className='loyaltyOptIn'>
        <h2>{`${feature} ${t(`${titleText}`)}`}</h2>
        <div className='btnContainer'>
          {shouldShowButton && <Button
            label={t(`${actionButtonText}`)}
            onClick={this.showPlanModal}
            className={buttonClass}
            disabled={shouldBeDisabled}
          />}
          <ReverseButton
            label={t('UpgradePremiumPlus.PlansButton')}
            variant='outlined'
            className='outlinedButton'
            onClick={openPlansModal}
          />
        </div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={6}
                sx={upgradePlanGridBorderBottomRight}>
            <div className='tile'>
              <img src={data[0]?.img} className='topImg'/>
              <h3>{data[0]?.text}</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}
                sx={upgradePlanGridBorderBottom}>
            <div className='tile'>
              <img src={data[1]?.img} className='topImg'/>
              <h3>{data[1]?.text}</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}
                sx={upgradePlanGridBorderRight}>
            <div className='tile'>
              <img src={data[2]?.img} className='bottomImg'/>
              <h3>{data[2]?.text}</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className='tile'>
              <img src={data[3]?.img} className='bottomImg'/>
              <h3>{data[3]?.text}</h3>
            </div>
          </Grid>
        </Grid>
        {processDialog}
        {successDialog}
        {errorDialog}
        {loading}
      </section>);
  }
}
