/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

export default {
  accountRequest: 'ACCOUNT_REQUEST',
  accountSuccess: 'ACCOUNT_SUCCESS',
  accountFailure: 'ACCOUNT_FAILURE',
  accountProfileRequest: 'ACCOUNT_PROFILE_REQUEST',
  accountProfileSuccess: 'ACCOUNT_PROFILE_SUCCESS',
  accountProfileFailure: 'ACCOUNT_PROFILE_FAILURE',
  accountBankingProfileRequest: 'ACCOUNT_BANKING_PROFILE_REQUEST',
  accountBankingProfileSuccess: 'ACCOUNT_BANKING_PROFILE_SUCCESS',
  accountBankingProfileFailure: 'ACCOUNT_BANKING_PROFILE_FAILURE',
  accountFundingProfileRequest: 'ACCOUNT_FUNDING_PROFILE_REQUEST',
  accountFundingProfileSuccess: 'ACCOUNT_FUNDING_PROFILE_SUCCESS',
  accountFundingProfileFailure: 'ACCOUNT_FUNDING_PROFILE_FAILURE',
  activitiesComparisonRequest: 'ACTIVITIES_COMPARISON_REQUEST',
  activitiesComparisonSuccess: 'ACTIVITIES_COMPARISON_SUCCESS',
  activitiesComparisonFailure: 'ACTIVITIES_COMPARISON_FAILURE',
  activitiesSummaryRequest: 'ACTIVITIES_SUMMARY_REQUEST',
  activitiesSummarySuccess: 'ACTIVITIES_SUMMARY_SUCCESS',
  activitiesSummaryFailure: 'ACTIVITIES_SUMMARY_FAILURE',
  activitiesTodayRequest: 'ACTIVITIES_TODAY_REQUEST',
  activitiesTodaySuccess: 'ACTIVITIES_TODAY_SUCCESS',
  activitiesTodayFailure: 'ACTIVITIES_TODAY_FAILURE',
  agentPermissionRequest: 'AGENT_PERMISSION_REQUEST',
  agentPermissionSuccess: 'AGENT_PERMISSION_SUCCESS',
  agentPermissionFailure: 'AGENT_PERMISSION_FAILURE',
  agreementSaveRequest: 'AGREEMENT_SAVE_REQUEST',
  agreementSaveSuccess: 'AGREEMENT_SAVE_SUCCESS',
  agreementSaveFailure: 'AGREEMENT_SAVE_FAILURE',
  alertsRequest: 'ALERTS_REQUEST',
  alertsSuccess: 'ALERTS_SUCCESS',
  alertsFailure: 'ALERTS_FAILURE',
  alertsUpdateRequest: 'ALERTS_UPDATE_REQUEST',
  alertsUpdateSuccess: 'ALERTS_UPDATE_SUCCESS',
  alertsUpdateFailure: 'ALERTS_UPDATE_FAILURE',
  batchesRequest: 'BATCHES_REQUEST',
  batchesSuccess: 'BATCHES_SUCCESS',
  batchesFailure: 'BATCHES_FAILURE',
  batchesPutRequest: 'BATCHES_PUT_REQUEST',
  batchesPutSuccess: 'BATCHES_PUT_SUCCESS',
  batchesPutFailure: 'BATCHES_PUT_FAILURE',
  batchDetailRequest: 'BATCH_DETAIL_REQUEST',
  batchDetailSuccess: 'BATCH_DETAIL_SUCCESS',
  batchDetailFailure: 'BATCH_DETAIL_FAILURE',
  batchSelected: 'BATCH_SELECTED',
  cardPresentStateUpdate: 'CARD_PRESENT_STATE_UPDATE',
  cardPresentStateReset: 'CARD_PRESENT_STATE_RESET',
  cardPresentFinalized: 'CARD_PRESENT_FINALIZED',
  cardPresentRoomIdSet: 'CARD_PRESENT_ROOM_ID_SET',
  cashRefundRequest: 'CASH_REFUND_REQUEST',
  cashRefundSuccess: 'CASH_REFUND_SUCCESS',
  cashRefundFailure: 'CASH_REFUND_FAILURE',
  categoryGetRequest: 'CATEGORY_GET_REQUEST',
  categoryGetSuccess: 'CATEGORY_GET_SUCCESS',
  categoryGetFailure: 'CATEGORY_GET_FAILURE',
  categorySelected: 'CATEGORY_SELECTED',
  categorySaveRequest: 'CATEGORY_SAVE_REQUEST',
  categorySaveSuccess: 'CATEGORY_SAVE_SUCCESS',
  categorySaveFailure: 'CATEGORY_SAVE_FAILURE',
  categoryDeleteRequest: 'CATEGORY_DELETE_REQUEST',
  categoryDeleteSuccess: 'CATEGORY_DELETE_SUCCESS',
  categoryDeleteFailure: 'CATEGORY_DELETE_FAILURE',
  categoryItemsReassignRequest: 'CATEGORY_ITEMS_REASSIGN_REQUEST',
  categoryItemsReassignSuccess: 'CATEGORY_ITEMS_REASSIGN_SUCCESS',
  categoryItemsReassignFailure: 'CATEGORY_ITEMS_REASSIGN_FAILURE',
  changeSelectedMerchantAccount: 'ACCOUNT_CHANGE_MERCHANT_ACCOUNT',
  clearNotification: 'CLEAR_NOTIFICATION',
  clearPayNowCardPresent: 'CLEAR_PAYNOW_CARDPRESENT',
  clearUpdatePasswordError: 'CLEAR_UPDATE_PASSWORD_ERROR',
  clearCustomReportSelected: 'CLEAR_CUSTOM_REPORT_SELECTED',
  communicationsRequest: 'COMMUNICATION_REQUEST',
  communicationsSuccess: 'COMMUNICATION_SUCCESS',
  communicationsFailure: 'COMMUNICATION_FAILURE',
  communicationsPostRequest: 'COMMUNICATION_POST_REQUEST',
  communicationsPostSuccess: 'COMMUNICATION_POST_SUCCESS',
  communicationsPostFailure: 'COMMUNICATION_POST_FAILURE',
  createPaymentLinkRequest: 'CREATE_PAYMENT_LINK_REQUEST',
  createPaymentLinkSuccess: 'CREATE_PAYMENT_LINK_SUCCESS',
  createPaymentLinkFailure: 'CREATE_PAYMENT_LINK_FAILURE',
  creditCardCompleteRequest: 'CREDIT_CARD_COMPLETE_REQUEST',
  creditCardCompleteSuccess: 'CREDIT_CARD_COMPLETE_SUCCESS',
  creditCardCompleteFailure: 'CREDIT_CARD_COMPLETE_FAILURE',
  creditCardCancelRequest: 'CREDIT_CARD_CANCEL_REQUEST',
  creditCardCancelSuccess: 'CREDIT_CARD_CANCEL_SUCCESS',
  creditCardCancelFailure: 'CREDIT_CARD_CANCEL_FAILURE',
  creditCardPaymentRequest: 'CREDIT_CARD_PAYMENT_REQUEST',
  creditCardPaymentSuccess: 'CREDIT_CARD_PAYMENT_SUCCESS',
  creditCardPaymentFailure: 'CREDIT_CARD_PAYMENT_FAILURE',
  creditCardVoidRequest: 'CREDIT_CARD_VOID_REQUEST',
  creditCardVoidSuccess: 'CREDIT_CARD_VOID_SUCCESS',
  creditCardVoidFailure: 'CREDIT_CARD_VOID_FAILURE',
  invoiceMarkAsPaidRequest: 'INVOICE_MARK_AS_PAID_REQUEST',
  invoiceMarkAsPaidSuccess: 'INVOICE_MARK_AS_PAID_SUCCESS',
  invoiceMarkAsPaidFailure: 'INVOICE_MARK_AS_PAID_FAILURE',
  creditCardMerchantVoidRequest: 'CREDIT_CARD_MERCHANT_VOID_REQUEST',
  creditCardMerchantVoidSuccess: 'CREDIT_CARD_MERCHANT_VOID_SUCCESS',
  creditCardMerchantVoidFailure: 'CREDIT_CARD_MERCHANT_VOID_FAILURE',
  creditRefundRequest: 'CREDIT_REFUND_REQUEST',
  creditRefundSuccess: 'CREDIT_REFUND_SUCCESS',
  creditRefundFailure: 'CREDIT_REFUND_FAILURE',
  creditMerchantRefundRequest: 'CREDIT_MERCHANT_REFUND_REQUEST',
  creditMerchantRefundSuccess: 'CREDIT_MERCHANT_REFUND_SUCCESS',
  creditMerchantRefundFailure: 'CREDIT_MERCHANT_REFUND_FAILURE',
  cashSalePaymentRequest: 'CASH_SALE_PAYMENT_REQUEST',
  cashSalePaymentSuccess: 'CASH_SALE_PAYMENT_SUCCESS',
  cashSalePaymentFailure: 'CASH_SALE_PAYMENT_FAILURE',
  customerSelected: 'CUSTOMER_SELECTED',
  customerPaymentMethodSelected: 'CUSTOMER_PAYMENT_METHOD_SELECTED',
  customerSaveRequest: 'CUSTOMER_SAVE_REQUEST',
  customerSaveSuccess: 'CUSTOMER_SAVE_SUCCESS',
  customerSaveFailure: 'CUSTOMER_SAVE_FAILURE',
  customersRequest: 'CUSTOMERS_REQUEST',
  customersSuccess: 'CUSTOMERS_SUCCESS',
  customersFailure: 'CUSTOMERS_FAILURE',
  customerRequest: 'CUSTOMER_REQUEST',
  customerSuccess: 'CUSTOMER_SUCCESS',
  customerFailure: 'CUSTOMER_FAILURE',
  customerInvoicesGetRequest: 'CUSTOMERS_INVOICES_REQUEST',
  customerInvoicesGetSuccess: 'CUSTOMERS_INVOICES_SUCCESS',
  customerInvoicesGetFailure: 'CUSTOMERS_INVOICES_FAILURE',
  customerPaymentMethodsGetRequest: 'CUSTOMERS_GET_PAYMENT_METHODS_REQUEST',
  customerPaymentMethodsGetSuccess: 'CUSTOMERS_GET_PAYMENT_METHODS_SUCCESS',
  customerPaymentMethodsGetFailure: 'CUSTOMERS_GET_PAYMENT_METHODS_FAILURE',
  customerPaymentMethodsPutRequest: 'CUSTOMERS_PUT_PAYMENT_METHODS_REQUEST',
  customerPaymentMethodsPutSuccess: 'CUSTOMERS_PUT_PAYMENT_METHODS_SUCCESS',
  customerPaymentMethodsPutFailure: 'CUSTOMERS_PUT_PAYMENT_METHODS_FAILURE',
  customerInvoicesTransactionsGetRequest: 'CUSTOMERS_GET_INVOICES_TRANSACTIONS_REQUEST',
  customerInvoicesTransactionsGetSuccess: 'CUSTOMERS_GET_INVOICES_TRANSACTIONS_SUCCESS',
  customerInvoicesTransactionsGetFailure: 'CUSTOMERS_GET_INVOICES_TRANSACTIONS_FAILURE',
  customerSendRewardEmailRequest: 'CUSTOMERS_SEND_REWARD_EMAIL_REQUEST',
  customerSendRewardEmailSuccess: 'CUSTOMERS_SEND_REWARD_EMAIL_SUCCESS',
  customerSendRewardEmailFailure: 'CUSTOMERS_SEND_REWARD_EMAIL_FAILURE',
  deleteCustomReportSelected: 'DELETE_CUSTOM_REPORT_SELECTED',
  deleteRememberedDevicesRequest: 'DELETE_REMEMBERED_DEVICES_REQUEST',
  deleteRememberedDevicesSuccess: 'DELETE_REMEMBERED_DEVICES_SUCCESS',
  deleteRememberedDevicesFailure: 'DELETE_REMEMBERED_DEVICES_FAILURE',
  depositSelected: 'DEPOSIT_SELECTED',
  depositTransactionRequest: 'DEPOSIT_TRANSACTION_REQUEST',
  depositTransactionSuccess: 'DEPOSITS_TRANSACTION_SUCCESS',
  depositTransactionFailure: 'DEPOSITS_TRANSACTION_FAILURE',
  depositsRequest: 'DEPOSITS_REQUEST',
  depositsSuccess: 'DEPOSITS_SUCCESS',
  depositsFailure: 'DEPOSITS_FAILURE',
  desktopTokenRequest: 'DESKTOP_TOKEN_REQUEST',
  desktopTokenSuccess: 'DESKTOP_TOKEN_SUCCESS',
  desktopTokenFailure: 'DESKTOP_TOKEN_FAILURE',
  discountGetRequest: 'DISCOUNTS_GET_REQUEST',
  discountGetSuccess: 'DISCOUNTS_GET_SUCCESS',
  discountGetFailure: 'DISCOUNTS_GET_FAILURE',
  discountSelected: 'DISCOUNT_SELECTED',
  discountSaveRequest: 'DISCOUNT_SAVE_REQUEST',
  discountSaveSuccess: 'DISCOUNT_SAVE_SUCCESS',
  discountSaveFailure: 'DISCOUNT_SAVE_FAILURE',
  disputesRequest: 'DISPUTES_REQUEST',
  disputesSuccess: 'DISPUTES_SUCCESS',
  disputesFailure: 'DISPUTES_FAILURE',
  disputeSelected: 'DISPUTE_SELECTED',
  disputeSetFilesComments: 'SET_DISPUTE_FILES_COMMENTS',
  disputeSetAction: 'SET_DISPUTE_ACTION',
  disputeUpdateRequest: 'UPDATE_DISPUTE_REQUEST',
  disputeUpdateSuccess: 'UPDATE_DISPUTE_SUCCESS',
  disputeUpdateFailure: 'UPDATE_DISPUTE_FAILURE',
  disputeDetailsRequest: 'DISPUTE_DETAILS_REQUEST',
  disputeDetailsSuccess: 'DISPUTE_DETAILS_SUCCESS',
  disputeDetailsFailure: 'DISPUTE_DETAILS_FAILURE',
  disputeFileRequest: 'DISPUTE_FILE_REQUEST',
  disputeFileSuccess: 'DISPUTE_FILE_SUCCESS',
  disputeFileFailure: 'DISPUTE_FILE_FAILURE',
  emailValidationRequest: 'EMAIL_VALIDATION_REQUEST',
  emailValidationSuccess: 'EMAIL_VALIDATION_SUCCESS',
  emailValidationFailure: 'EMAIL_VALIDATION_FAILURE',
  emailTokenValidationRequest: 'EMAIL_TOKEN_VALIDATION_REQUEST',
  emailTokenValidationSuccess: 'EMAIL_TOKEN_VALIDATION_SUCCESS',
  emailTokenValidationFailure: 'EMAIL_TOKEN_VALIDATION_FAILURE',
  employeeSelected: 'EMPLOYEE_SELECTED',
  employeeSaveRequest: 'EMPLOYEE_SAVE_REQUEST',
  employeeSaveSuccess: 'EMPLOYEE_SAVE_SUCCESS',
  employeeSaveFailure: 'EMPLOYEE_SAVE_FAILURE',
  employeeSendInviteRequest: 'EMPLOYEE_SEND_INVITE_REQUEST',
  employeeSendInviteSuccess: 'EMPLOYEE_SEND_INVITE_SUCCESS',
  employeeSendInviteFailure: 'EMPLOYEE_SEND_INVITE_FAILURE',
  employeesRequest: 'EMPLOYEES_REQUEST',
  employeesSuccess: 'EMPLOYEES_SUCCESS',
  employeesFailure: 'EMPLOYEES_FAILURE',
  applePaySessionCanMakePayments: 'APPLE_PAY_SESSION_CAN_MAKE_PAYMENTS',
  applePayAbortSession: 'APPLE_PAY_ABORT_SESSION',
  applePayCompletePayment: 'APPLE_PAY_COMPLETE_PAYMENT',
  establishApplePaySessionRequest: 'APPLEPAY_ESTABLISH_SESSION_REQUEST',
  establishApplePaySessionSuccess: 'APPLEPAY_ESTABLISH_SESSION_SUCCESS',
  establishApplePaySessionFailure: 'APPLEPAY_ESTABLISH_SESSION_FAILURE',
  equipmentOrderRequest: 'EQUIPMENT_ORDER_REQUEST',
  equipmentOrderSuccess: 'EQUIPMENT_ORDER_SUCCESS',
  equipmentOrderFailure: 'EQUIPMENT_ORDER_FAILURE',
  expireTokenRequest: 'EXPIRE_TOKEN_REQUEST',
  expireTokenSuccess: 'EXPIRE_TOKEN_SUCCESS',
  expireTokenFailure: 'EXPIRE_TOKEN_FAILURE',
  expireActionRequiredTokenRequest: 'EXPIRE_ACTION_REQUIRED_TOKEN_REQUEST',
  expireActionRequiredTokenSuccess: 'EXPIRE_ACTION_REQUIRED_TOKEN_SUCCESS',
  expireActionRequiredTokenFailure: 'EXPIRE_ACTION_REQUIRED_TOKEN_FAILURE',
  feedbackSaveRequest: 'FEEDBACK_SAVE_REQUEST',
  feedbackSaveSuccess: 'FEEDBACK_SAVE_SUCCESS',
  feedbackSaveFailure: 'FEEDBACK_SAVE_FAILURE',
  fingerprintDataRequest: 'FINGERPRINT_DATA_REQUEST',
  fingerprintDataSuccess: 'FINGERPRINT_DATA_SUCCESS',
  fingerprintDataFailure: 'FINGERPRINT_DATA_FAILURE',
  formsRequest: 'FORMS_REQUEST',
  formsSuccess: 'FORMS_SUCCESS',
  formsFailure: 'FORMS_FAILURE',
  formsReceiptSettingImage: 'FORMS_RECEIPT_SETTING_IMAGE',
  formRequest: 'FORM_REQUEST',
  formSuccess: 'FORM_SUCCESS',
  formFailure: 'FORM_FAILURE',
  generatePdfRequest: 'PDF_REQUEST',
  generatePdfSuccess: 'PDF_SUCCESS',
  generatePdfFailure: 'PDF_FAILURE',
  getPaymentLinkDetailRequest: 'GET_PAYMENT_LINK_DETAIL_REQUEST',
  getPaymentLinkDetailSuccess: 'GET_PAYMENT_LINK_DETAIL_SUCCESS',
  getPaymentLinkDetailFailure: 'GET_PAYMENT_LINK_DETAIL_FAILURE',
  getShippingMethodPremiumRequest: 'GET_SHIPPING_METHOD_PREMIUM_REQUEST',
  getShippingMethodPremiumSuccess: 'GET_SHIPPING_METHOD_PREMIUM_SUCCESS',
  getShippingMethodPremiumFailure: 'GET_SHIPPING_METHOD_PREMIUM_FAILURE',
  giactAccountValidationStatus: 'GIACT_ACCOUNT_VALIDATION_STATUS',
  giactSettlementRequest: 'GIACT_SETTLEMENT_REQUEST',
  giactSettlementSuccess: 'GIACT_SETTLEMENT_SUCCESS',
  giactSettlementFailure: 'GIACT_SETTLEMENT_FAILURE',
  giactChargebackRequest: 'GIACT_CHARGEBACK_REQUEST',
  giactChargebackSuccess: 'GIACT_CHARGEBACK_SUCCESS',
  giactChargebackFailure: 'GIACT_CHARGEBACK_FAILURE',
  giactBillingRequest: 'GIACT_BILLING_REQUEST',
  giactBillingSuccess: 'GIACT_BILLING_SUCCESS',
  giactBillingFailure: 'GIACT_BILLING_FAILURE',
  giactResetValidation: 'GIACT_RESET_VALIDATION',
  giactImgRequest: 'GIACT_IMG_REQUEST',
  giactImgSuccess: 'GIACT_IMG_SUCCESS',
  giactImgFailure: 'GIACT_IMG_FAILURE',
  idologyGetRequest: 'IDOLOGY_GET_REQUEST',
  idologyGetSuccess: 'IDOLOGY_GET_SUCCESS',
  idologyGetFailure: 'IDOLOGY_GET_FAILURE',
  idologyPostRequest: 'IDOLOGY_POST_REQUEST',
  idologyPostSuccess: 'IDOLOGY_POST_SUCCESS',
  idologyPostFailure: 'IDOLOGY_POST_FAILURE',
  invoiceGetRequest: 'INVOICE_GET_REQUEST',
  invoiceGetSuccess: 'INVOICE_GET_SUCCESS',
  invoiceGetFailure: 'INVOICE_GET_FAILURE',
  invoicePayNowGetRequest: 'INVOICE_PAY_NOW_GET_REQUEST',
  invoicePayNowGetSuccess: 'INVOICE_PAY_NOW_GET_SUCCESS',
  invoicePayNowGetFailure: 'INVOICE_PAY_NOW_GET_FAILURE',
  mergeCategoryRequest: 'MERGE_CATEGORY_REQUEST',
  mergeCategorySuccess: 'MERGE_CATEGORY_SUCCESS',
  mergeCategoryFailure: 'MERGE_CATEGORY_FAILURE',
  patchInvoiceRequest: 'INVOICE_PATCH_REQUEST',
  patchInvoiceSuccess: 'INVOICE_PATCH_SUCCESS',
  patchInvoiceFailure: 'INVOICE_PATCH_FAILURE',
  processInvoicePaymentApplePayRequest: 'APPLEPAY_PROCESS_INVOICE_REQUEST',
  processInvoicePaymentApplePaySuccess: 'APPLEPAY_PROCESS_INVOICE_SUCCESS',
  processInvoicePaymentApplePayFailure: 'APPLEPAY_PROCESS_INVOICE_FAILURE',
  identifiersRequest: 'IDENTIFIERS_REQUEST',
  identifiersSuccess: 'IDENTIFIERS_SUCCESS',
  identifiersFailure: 'IDENTIFIERS_FAILURE',
  invoicesRequest: 'INVOICES_REQUEST',
  invoicesSuccess: 'INVOICES_SUCCESS',
  invoicesFailure: 'INVOICES_FAILURE',
  invoiceSelectedRequest: 'INVOICE_SELECTED_REQUEST',
  invoiceSelectedSuccess: 'INVOICE_SELECTED_SUCCESS',
  invoiceSelectedFailure: 'INVOICE_SELECTED_FAILURE',
  invoiceSelected: 'INVOICE_SELECTED',
  invoiceNumberSaveRequest: 'INVOICE_NUMBER_SAVE_REQUEST',
  invoiceNumberSaveSuccess: 'INVOICE_NUMBER_SAVE_SUCCESS',
  invoiceNumberSaveFailure: 'INVOICE_NUMBER_SAVE_FAILURE',
  invoiceForPaymentTipSelected: 'INVOICE_FOR_PAYMENT_TIP_SELECTED',
  issueCreditDialogAcknowledged: 'ISSUE_CREDIT_DIALOG_ACKNOWLEDGED',
  itemReactivateRequest: 'ITEM_REACTIVATE_REQUEST',
  itemReactivateSuccess: 'ITEM_REACTIVATE_SUCCESS',
  itemReactivateFailure: 'ITEM_REACTIVATE_FAILURE',
  itemGetRequest: 'ITEM_GET_REQUEST',
  itemGetSuccess: 'ITEM_GET_SUCCESS',
  itemGetFailure: 'ITEM_GET_FAILURE',
  itemSelected: 'ITEM_SELECTED',
  itemSaveRequest: 'ITEM_SAVE_REQUEST',
  itemSaveSuccess: 'ITEM_SAVE_SUCCESS',
  itemSaveFailure: 'ITEM_SAVE_FAILURE',
  itemDeleteRequest: 'ITEM_DELETE_REQUEST',
  itemDeleteSuccess: 'ITEM_DELETE_SUCCESS',
  itemDeleteFailure: 'ITEM_DELETE_FAILURE',
  itemPermanentlyDeleteRequest: 'ITEM_PERMANENTLY_DELETE_REQUEST',
  itemPermanentlyDeleteSuccess: 'ITEM_PERMANENTLY_DELETE_SUCCESS',
  itemPermanentlyDeleteFailure: 'ITEM_PERMANENTLY_DELETE_FAILURE',
  localUserExperienceSet: 'SET_LOCAL_COMMUNICATION',
  persistentUserExperienceSet: 'SET_PERSISTENT_UX_VALUES',
  vtAccessProcessValuesSet: 'SET_VT_ACCESS_VALUES',
  vtAccessProcessValuesRemove: 'REMOVE_VT_ACCESS_VALUES',
  logAdd: 'LOG_ADD',
  logClean: 'LOG_CLEAN',
  loginRequest: 'LOGIN_REQUEST',
  loginSuccess: 'LOGIN_SUCCESS',
  loginFailure: 'LOGIN_FAILURE',
  logoutSuccess: 'LOGOUT_SUCCESS',
  logoutRequest: 'LOGOUT_REQUEST',
  merchantSettingsUpdateRequest: 'MERCHANT_SETTINGS_UPDATE_REQUEST',
  merchantSettingsUpdateSuccess: 'MERCHANT_SETTINGS_UPDATE_SUCCESS',
  merchantSettingsUpdateFailure: 'MERCHANT_SETTINGS_UPDATE_FAILURE',
  merchantSettingsRequest: 'MERCHANT_SETTINGS_REQUEST',
  merchantSettingsSuccess: 'MERCHANT_SETTINGS_SUCCESS',
  merchantSettingsFailure: 'MERCHANT_SETTINGS_FAILURE',
  csSettingsRequest: 'CS_SETTINGS_REQUEST',
  csSettingsSuccess: 'CS_SETTINGS_SUCCESS',
  csSettingsFailure: 'CS_SETTINGS_FAILURE',
  merchantStatusRequest: 'MERCHANT_STATUS_REQUEST',
  merchantStatusSuccess:  'MERCHANT_STATUS_SUCCESS',
  merchantStatusFailure:  'MERCHANT_STATUS_FAILURE',
  midSelected: 'MID_SELECTED',
  modifierGetRequest: 'MODIFIER_GET_REQUEST',
  modifierGetSuccess: 'MODIFIER_GET_SUCCESS',
  modifierGetFailure: 'MODIFIER_GET_FAILURE',
  modifierSaveRequest: 'MODIFIER_SAVE_REQUEST',
  modifierSaveSuccess: 'MODIFIER_SAVE_SUCCESS',
  modifierSaveFailure: 'MODIFIER_SAVE_FAILURE',
  modifierSetSelected: 'MODIFIERSET_SAVE_FAILURE',
  modifierSetSaveRequest: 'MODIFIERSET_SAVE_REQUEST',
  modifierSetSaveSuccess: 'MODIFIERSET_SAVE_SUCCESS',
  modifierSetSaveFailure: 'MODIFIERSET_SAVE_FAILURE',
  onDropFileFailed: 'ON_DROP_FILE_FAILED',
  paymentMethodsDeleteRequest: 'PAYMENT_METHOD_DELETE_REQUEST',
  paymentMethodsDeleteSuccess: 'PAYMENT_METHOD_DELETE_SUCCESS',
  paymentMethodsDeleteFailure: 'PAYMENT_METHOD_DELETE_FAILURE',
  paymentMethodsPutRequest: 'PAYMENT_METHOD_PUT_REQUEST',
  paymentMethodsPutSuccess: 'PAYMENT_METHOD_PUT_SUCCESS',
  paymentMethodsPutFailure: 'PAYMENT_METHOD_PUT_FAILURE',
  paymentMethodsPostRequest: 'PAYMENT_METHOD_POST_REQUEST',
  paymentMethodsPostSuccess: 'PAYMENT_METHOD_POST_SUCCESS',
  paymentMethodsPostFailure: 'PAYMENT_METHOD_POST_FAILURE',
  pciRequest: 'PCI_REQUEST',
  pciSuccess: 'PCI_SUCCESS',
  pciFailure: 'PCI_FAILURE',
  pciEnvelopeRequest: 'PCI_ENVELOPE_REQUEST',
  pciEnvelopeSuccess: 'PCI_ENVELOPE_SUCCESS',
  pciEnvelopeFailure: 'PCI_ENVELOPE_FAILURE',
  pciDocRequest: 'PCI_DOC_REQUEST',
  pciDocSuccess: 'PCI_DOC_SUCCESS',
  pciDocFailure: 'PCI_DOC_FAILURE',
  printReceiptRequest: 'PRINT_RECEIPT_REQUEST',
  printReceiptSuccess: 'PRINT_RECEIPT_SUCCESS',
  printReceiptFailure: 'PRINT_RECEIPT_FAILURE',
  profileChange: 'PROFILE_CHANGE',
  receiptSelected: 'RECEIPT_SELECTED',
  recurringInvoicesRequest: 'RECURRING_INVOICES_REQUEST',
  recurringInvoicesSuccess: 'RECURRING_INVOICES_SUCCESS',
  recurringInvoicesFailure: 'RECURRING_INVOICES_FAILURE',
  recurringPaymentCancelRequest: 'RECURRING_PAYMENT_CANCEL_REQUEST',
  recurringPaymentCancelSuccess: 'RECURRING_PAYMENT_CANCEL_SUCCESS',
  recurringPaymentCancelFailure: 'RECURRING_PAYMENT_CANCEL_FAILURE',
  recurringPaymentGetRequest: 'RECURRING_PAYMENT_GET_REQUEST',
  recurringPaymentGetSuccess: 'RECURRING_PAYMENT_GET_SUCCESS',
  recurringPaymentGetFailure: 'RECURRING_PAYMENT_GET_FAILURE',
  recurringPaymentUpdateRequest: 'RECURRING_PAYMENT_UPDATE_REQUEST',
  recurringPaymentUpdateSuccess: 'RECURRING_PAYMENT_UPDATE_SUCCESS',
  recurringPaymentUpdateFailure: 'RECURRING_PAYMENT_UPDATE_FAILURE',
  refreshTokenRequest: 'REFRESH_TOKEN_REQUEST',
  refreshTokenSuccess: 'REFRESH_TOKEN_SUCCESS',
  refreshTokenFailure: 'REFRESH_TOKEN_FAILURE',
  refreshInvoiceTokenRequest: 'REFRESH_INVOICE_TOKEN_REQUEST',
  refreshInvoiceTokenSuccess: 'REFRESH_INVOICE_TOKEN_SUCCESS',
  refreshInvoiceTokenFailure: 'REFRESH_INVOICE_TOKEN_FAILURE',
  rememberedDevicesRequest: 'REMEMBERED_DEVICES_REQUEST',
  rememberedDevicesSuccess: 'REMEMBERED_DEVICES_SUCCESS',
  rememberedDevicesFailure: 'REMEMBERED_DEVICES_FAILURE',
  reportRequest: 'REPORT_REQUEST',
  reportSuccess: 'REPORT_SUCCESS',
  reportFailure: 'REPORT_FAILURE',
  loyaltyReportRequest: 'LOYALTY_REPORT_REQUEST',
  loyaltyReportSuccess: 'LOYALTY_REPORT_SUCCESS',
  loyaltyReportFailure: 'LOYALTY_REPORT_FAILURE',
  setReportSummaryAll: 'SET_REPORT_SUMMARY_ALL',
  setCustomReportSelected: 'SET_CUSTOM_REPORT_SELECTED',
  reportComparisonRequest: 'REPORT_COMPARISON_REQUEST',
  reportComparisonSuccess: 'REPORT_COMPARISON_SUCCESS',
  reportComparisonFailure: 'REPORT_COMPARISON_FAILURE',
  reportAdvancedRequest: 'REPORT_ADVANCED_REQUEST',
  reportAdvancedSuccess: 'REPORT_ADVANCED_SUCCESS',
  reportAdvancedFailure: 'REPORT_ADVANCED_FAILURE',
  reportClearError: 'REPORT_CLEAR_ERROR',
  getScheduledReportsRequest: 'GET_SCHEDULED_REPORTS_REQUEST',
  getScheduledReportsSuccess: 'GET_SCHEDULED_REPORTS_SUCCESS',
  getScheduledReportsFailure: 'GET_SCHEDULED_REPORTS_FAILURE',
  getFraudReportsRequest: 'GET_FRAUD_REPORTS_REQUEST',
  getFraudReportsSuccess: 'GET_FRAUD_REPORTS_SUCCESS',
  getFraudReportsFailure: 'GET_FRAUD_REPORTS_FAILURE',
  scheduleReportRequest: 'SCHEDULE_REPORT_REQUEST',
  scheduleReportSuccess: 'SCHEDULE_REPORT_SUCCESS',
  scheduleReportFailure: 'SCHEDULE_REPORT_FAILURE',
  returnLabelsRequest: 'RETURN_LABELS_REQUEST',
  returnLabelsSuccess: 'RETURN_LABELS_SUCCESS',
  returnLabelsFailure: 'RETURN_LABELS_FAILURE',
  returnLabelRequest: 'RETURN_LABEL_REQUEST',
  returnLabelSuccess: 'RETURN_LABEL_SUCCESS',
  returnLabelFailure: 'RETURN_LABEL_FAILURE',
  salesItemImageRequest: 'SALES_ITEM_IMAGE_REQUEST',
  salesItemImageSuccess: 'SALES_ITEM_IMAGE_SUCCESS',
  salesItemImageFailure: 'SALES_ITEM_IMAGE_FAILURE',
  setGraphSelector: 'SET_GRAPH_SELECTOR',
  toggleShowAdvancedSearchMoreFields: 'TOGGLE_SHOW_ADVANCED_SEARCH_MORE_FIELDS',
  resetSalesItemImage: 'SALES_ITEM_IMAGE_RESET',
  geoTaxRequest: 'GEO_TAX_REQUEST',
  geoTaxSuccess: 'GEO_TAX_SUCCESS',
  geoTaxFailure: 'GEO_TAX_FAILURE',
  geoTaxFetching: 'GEO_TAX_FETCHING',
  setGeoTaxRate: 'SET_GEO_TAX_RATE',
  getApiAccessRequest: 'GET_API_ACCESS_REQUEST',
  getApiAccessSuccess: 'GET_API_ACCESS_SUCCESS',
  getApiAccessFailure: 'GET_API_ACCESS_FAILURE',
  payInvoiceRequest: 'PAY_INVOICE_REQUEST',
  payInvoiceSuccess: 'PAY_INVOICE_SUCCESS',
  payInvoiceFailure: 'PAY_INVOICE_FAILURE',
  postApiAccessRequest: 'POST_API_ACCESS_REQUEST',
  postApiAccessSuccess: 'POST_API_ACCESS_SUCCESS',
  postApiAccessFailure: 'POST_API_ACCESS_FAILURE',
  putApiAccessRequest: 'PUT_API_ACCESS_REQUEST',
  putApiAccessSuccess: 'PUT_API_ACCESS_SUCCESS',
  putApiAccessFailure: 'PUT_API_ACCESS_FAILURE',
  getProxyPasswordRequest: 'GET_PROXY_PASSWORD_REQUEST',
  getProxyPasswordSuccess: 'GET_PROXY_PASSWORD_SUCCESS',
  getProxyPasswordFailure: 'GET_PROXY_PASSWORD_FAILURE',
  clearProxyApiPassword: 'CLEAR_PROXY_API_PASSWORD',
  getQuickBooksStatusRequest: 'GET_QUICKBOOKS_STATUS_REQUEST',
  getQuickBooksStatusSuccess: 'GET_QUICKBOOKS_STATUS_SUCCESS',
  getQuickBooksStatusFailed: 'GET_QUICKBOOKS_STATUS_FAILURE',
  getQuickBooksTokenRequest: 'GET_QUICKBOOKS_TOKEN_REQUEST',
  getQuickBooksTokenSuccess: 'GET_QUICKBOOKS_TOKEN_SUCCESS',
  getQuickBooksTokenFailure: 'GET_QUICKBOOKS_TOKEN_FAILURE',
  setQuickBooksTokenRequest: 'SET_QUICKBOOKS_TOKEN_REQUEST',
  setQuickBooksTokenSuccess: 'SET_QUICKBOOKS_TOKEN_SUCCESS',
  setQuickBooksTokenFailure: 'SET_QUICKBOOKS_TOKEN_FAILURE',
  getQuickBooksURIRequest: 'GET_QUICKBOOKS_URI_REQUEST',
  getQuickBooksURISuccess: 'GET_QUICKBOOKS_URI_SUCCESS',
  getQuickBooksURIFailure: 'GET_QUICKBOOKS_URI_FAILURE',
  deleteQuickBooksTokenRequest: 'DELETE_QUICKBOOKS_TOKEN_REQUEST',
  deleteQuickBooksTokenRequestSuccess: 'DELETE_QUICKBOOKS_TOKEN_SUCCESS',
  deleteQuickBooksTokenRequestFailure: 'DELETE_QUICKBOOKS_TOKEN_FAILURE',
  getChargezoomStatusRequest: 'GET_CHARGEZOOM_STATUS_REQUEST',
  getChargezoomStatusSuccess: 'GET_CHARGEZOOM_STATUS_SUCCESS',
  getChargezoomStatusFailure: 'GET_CHARGEZOOM_STATUS_FAILURE',
  activateChargezoomPlanRequest: 'ACTIVATE_CHARGEZOOM_PLAN_REQUEST',
  activateChargezoomPlanSuccess: 'ACTIVATE_CHARGEZOOM_PLAN_SUCCESS',
  activateChargezoomPlanFailure: 'ACTIVATE_CHARGEZOOM_PLAN_FAILURE',
  deactivateChargezoomPlanRequest: 'DEACTIVATE_CHARGEZOOM_PLAN_REQUEST',
  deactivateChargezoomPlanSuccess: 'DEACTIVATE_CHARGEZOOM_PLAN_SUCCESS',
  deactivateChargezoomPlanFailure: 'DEACTIVATE_CHARGEZOOM_PLAN_FAILURE',
  getDavoStatusRequest: 'GET_DAVO_STATUS_REQUEST',
  getDavoStatusSuccess: 'GET_DAVO_STATUS_SUCCESS',
  getDavoStatusFailure: 'GET_DAVO_STATUS_FAILURE',
  activateDavoPlanRequest: 'ACTIVATE_DAVO_PLAN_REQUEST',
  activateDavoPlanSuccess: 'ACTIVATE_DAVO_PLAN_SUCCESS',
  activateDavoPlanFailure: 'ACTIVATE_DAVO_PLAN_FAILURE',
  deactivateDavoPlanRequest: 'DEACTIVATE_DAVO_PLAN_REQUEST',
  deactivateDavoPlanSuccess: 'DEACTIVATE_DAVO_PLAN_SUCCESS',
  deactivateDavoPlanFailure: 'DEACTIVATE_DAVO_PLAN_FAILURE',
  getNextSignatureRequest: 'GET_NEXT_SIGNATURE_REQUEST',
  getNextSignatureSuccess: 'GET_NEXT_SIGNATURE_SUCCESS',
  getNextSignatureFailure: 'GET_NEXT_SIGNATURE_FAILURE',
  setResponsiveMenu: 'SET_MENU_VISIBILITY',
  setActiveHeader: 'SET_ACTIVE_HEADER',
  setGeoLocation: 'SET_GEO_LOCATION',
  setTokenAutoRenew: 'SET_TOKEN_AUTO_RENEW',
  setTaxRate: 'SET_TAX_RATE',
  setLinkEnabled: 'SET_LINK_ENABLED',
  setSelectedReport: 'SET_SELECTED_REPORT',
  setSubmitButtonEnabled: 'SET_SUBMIT_BUTTON_ENABLED',
  setEditButtonEnabled: 'SET_EDIT_BUTTON_ENABLED',
  setSelectedDateRange: 'SET_SELECTED_DATE_RANGE',
  setStopMIDConfirmation: 'SET_STOP_MID-CONFIRMATION',
  setSelectedEmployeeFilter: 'SET_SELECTED_EMPLOYEE_FILTER',
  setSelectedItemTab: 'SET_SELECTED_ITEM_TAB',
  setOpenAccountSwitchDialog: 'SET_OPEN_ACCOUNT_SWITCH_DIALOG',
  setHeaderHeight: 'SET_HEADER_HEIGHT',
  setOpenRelatedMidsDialog: 'SET_OPEN_RELATED_MIDS_DIALOG',
  setOpenAddAccountDialog: 'SET_OPEN_ADD_ACCOUNT_DIALOG',
  setOpenDepositTransactionsDialog: 'SET_OPEN_DEPOSIT_TRANSACTIONS_DIALOG',
  setOpenMessageDialog: 'SET_OPEN_MESSAGE_DIALOG',
  setMessageDialogContent: 'SET_MESSAGE_DIALOG_CONTENT',
  setOpenDynamicMessageDialog: 'SET_OPEN_DYNAMIC_MESSAGE_DIALOG',
  setOpenChangePasswordDialog: 'SET_OPEN_CHANGE_PASSWORD_DIALOG',
  setDynamicMessageDialogContent: 'SET_DYNAMIC_MESSAGE_DIALOG_CONTENT',
  setOpenPaymentResultDialog: 'SET_OPEN_PAYMENT_RESULT_DIALOG',
  setOpenReportsDialog: 'SET_OPEN_REPORTS_DIALOG',
  setOpenExpirePasswordMessage: 'SET_OPEN_EXPIRE_PASSWORD_MESSAGE',
  setCloseExpirePasswordMessage: 'SET_CLOSE_EXPIRE_PASSWORD_MESSAGE',
  setVisibilityFilter: 'SET_VISIBILITY_FILTER',
  setVisibilitySearchFilter: 'SET_VISIBILITY_SEARCH_FILTER',
  setAdvancedSearchVisibilityFilter: 'SET_ADVANCED_SEARCH_VISIBILITY_FILTER',
  setModalVisibilitySearchFilter: 'SET_MODAL_VISIBILITY_SEARCH_FILTER',
  setOrderVisibilityFilter: 'SET_ORDER_VISIBILITY_FILTER',
  setPaginationVisibilityFilter: 'SET_PAGINATION_VISIBILITY_FILTER',
  setSecondaryVisibilityFilter: 'SET_SECONDARY_VISIBILITY_FILTER',
  setModalVisibilityTypePostPayment: 'SET_MODAL_VISIBILITY_TYPE_POST_PAYMENT',
  setModalVisibilityTypeHidden: 'SET_MODAL_VISIBILITY_TYPE_HIDDEN',
  setModalVisibilityTypeTransaction: 'SET_MODAL_VISIBILITY_TYPE_TRANSACTION',
  setModalVisibilityTypeVT: 'SET_MODAL_VISIBILITY_TYPE_VT',
  setModalVisibilityTypePartialPayment: 'SET_MODAL_VISIBILITY_TYPE_PARTIAL_PAYMENT',
  setModalVisibilityTypeVTInvoice: 'SET_MODAL_VISIBILITY_TYPE_VT_INVOICE',
  setModalVisibilityTypeCardError: 'SET_MODAL_VISIBILITY_TYPE_CARD_ERROR',
  setModalVisibilityTypeCashPaidInvoice: 'SET_MODAL_VISIBILITY_TYPE_CASH_PAID_INVOICE',
  setModalVisibilityTypeApiPasswordDialog: 'SET_MODAL_VISIBILITY_TYPE_API_PASSWORD_DIALOG',
  setModalVisibilityTypeApiPasswordResetConfirmDialog: 'SET_MODAL_VISIBILITY_TYPE_API_PASSWORD_RESET_CONFIRM_DIALOG',
  setModalVisibilityTypeSwitchMIDConfirmDialog: 'SET_MODAL_VISIBILITY_TYPE_SWITCH_MID_CONFIRM_DIALOG',
  setModalVisibilityTypeWebHookFormDialog: 'SET_MODAL_VISIBILITY_TYPE_WEBHOOK_FORM_DIALOG',
  setModalVisibilityTypeJsSdkFormDialog: 'SET_MODAL_VISIBILITY_TYPE_JS_SDK_DIALOG',
  setModalVisibilityTypeTwoFactorFailedVerificationDialog: 'SET_MODAL_VISIBILITY_TYPE_TWO_FACTOR_FAILED_VERIFICATION_DIALOG',
  setModalVisibilityTypeFailedIdentityVerificationDialog: 'SET_MODAL_VISIBILITY_TYPE_FAILED_IDENTITY_VERIFICATION_DIALOG',
  setModalVisibilityTypeTwoFactorDialog: 'SET_MODAL_VISIBILITY_TYPE_TWO_FACTOR_DIALOG',
  setModalVisibilityTypeTwoFactorSetupDialog: 'SET_MODAL_VISIBILITY_TYPE_TWO_FACTOR_SETUP_DIALOG',
  setModalVisibilityTypeManageRememberedDevicesDialog: 'SET_MODAL_VISIBILITY_TYPE_MANAGE_REMEMBERED_DEVICES_DIALOG',
  setModalVisibilityTypeDuplicate: 'SET_MODAL_VISIBILITY_TYPE_DUPLICATE',
  setModalVisibilityTypeInvoicePostPayment: 'SET_MODAL_VISIBILITY_TYPE_INVOICE_POST_PAYMENT',
  setModalVisibilityTypePlanDetailsFree: 'SET_MODAL_VISIBILITY_TYPE_PLAN_DETAILS_FREE',
  setModalVisibilityTypePlanDetailsPremium: 'SET_MODAL_VISIBILITY_TYPE_PLAN_DETAILS_PREMIUM',
  setModalVisibilityTypePlanDetailsPremiumPlus: 'SET_MODAL_VISIBILITY_TYPE_PLAN_DETAILS_PREMIUM_PLUS',
  setModalVisibilityTypePlanComparison: 'SET_MODAL_VISIBILITY_PLAN_COMPARISON',
  setModalVisibilityTypeLoyaltyAddOnActivate: 'SET_MODAL_VISIBILITY_TYPE_LOYALTY_ADD_ON_ACTIVATE',
  setModalVisibilityTypeLoyaltyAddOnDeactivate: 'SET_MODAL_VISIBILITY_TYPE_LOYALTY_ADD_ON_DEACTIVATE',
  setModalVisibilityTypeThreeAvsError: 'SET_MODAL_VISIBILITY_THREE_AVS_ERROR',
  setModalVisibilityUnableToAddPaymentMethod: 'SET_MODAL_VISIBILITY_UNABLE_TO_ADD_PAYMENT_METHOD',
  setModalVisibilityHighTransactionAmount: 'SET_MODAL_VISIBILITY_HIGH_TRANSACTION_AMOUNT',
  setModalVisibilityChargezoomDisconnect: 'SET_MODAL_VISIBILITY_CHARGEZOOM_DISCONNECT',
  sendInvoiceRequest: 'SEND_INVOICE_REQUEST',
  sendInvoiceSuccess: 'SEND_INVOICE_SUCCESS',
  sendInvoiceFailure: 'SEND_INVOICE_FAILURE',
  createInvoiceReceiptRequest: 'CREATE_INVOICE_RECEIPT_REQUEST',
  createInvoiceReceiptSuccess: 'CREATE_INVOICE_RECEIPT_SUCCESS',
  createInvoiceReceiptFailure: 'CREATE_INVOICE_RECEIPT_FAILURE',
  setSelectedChartItems: 'SET_SELECTED_CHART_ITEMS',
  sendMaintenanceMessage: 'SEND_MAINTENANCE_MESSAGE',
  sendNotification: 'SEND_NOTIFICATION',
  sendNotificationEmailRequest: 'SEND_NOTIFICATION_EMAIL_REQUEST',
  sendNotificationEmailSuccess: 'SEND_NOTIFICATION_EMAIL_SUCCESS',
  sendNotificationEmailFailure: 'SEND_NOTIFICATION_EMAIL_FAILURE',
  sendReceiptRequest: 'SEND_RECEIPT_REQUEST',
  sendReceiptSuccess: 'SEND_RECEIPT_SUCCESS',
  sendReceiptFailure: 'SEND_RECEIPT_FAILURE',
  signatureImageRequest: 'SIGNATURE_IMAGE_REQUEST',
  signatureImageSuccess: 'SIGNATURE_IMAGE_SUCCESS',
  signatureImageFailure: 'SIGNATURE_IMAGE_FAILURE',
  smsReceiptRequest: 'SMS_RECEIPT_REQUEST',
  smsReceiptSuccess: 'SMS_RECEIPT_SUCCESS',
  smsReceiptFailure: 'SMS_RECEIPT_FAILURE',
  statementsRequest: 'STATEMENTS_REQUEST',
  statementsSuccess: 'STATEMENTS_SUCCESS',
  statementsFailure: 'STATEMENTS_FAILURE',
  statementsPdfRequest: 'STATEMENTS_PDF_REQUEST',
  statementsPdfSuccess: 'STATEMENTS_PDF_SUCCESS',
  statementsPdfFailure: 'STATEMENTS_PDF_FAILURE',
  suppressAppError: 'SUPPRESS_APP_ERROR_TOGGLE',
  tinValidationRequest: 'TIN_VALIDATION_REQUEST',
  tinValidationSuccess: 'TIN_VALIDATION_SUCCESS',
  tinValidationFailure: 'TIN_VALIDATION_FAILURE',
  comparedReceiptsRequest: 'TRANSACTION_RECEIPTS_COMPARED_REQUEST',
  comparedReceiptsSuccess: 'TRANSACTION_RECEIPTS_COMPARED_SUCCESS',
  comparedReceiptsFailure: 'TRANSACTION_RECEIPTS_COMPARED_FAILURE',
  transactionCsvExportRequest: 'TRANSACTION_EXPORT_REQUEST',
  transactionCsvExportSuccess: 'TRANSACTION_EXPORT_SUCCESS',
  transactionCsvExportFailure: 'TRANSACTION_EXPORT_FAILURE',
  transactionReceiptsRequest: 'TRANSACTION_RECEIPTS_REQUEST',
  transactionReceiptsSuccess: 'TRANSACTION_RECEIPTS_SUCCESS',
  transactionReceiptsFailure: 'TRANSACTION_RECEIPTS_FAILURE',
  transactionVerboseRequest: 'TRANSACTION_VERBOSE_REQUEST',
  transactionVerboseSuccess: 'TRANSACTION_VERBOSE_SUCCESS',
  transactionVerboseFailure: 'TRANSACTION_VERBOSE_FAILURE',
  transactionDeclinedReceiptsRequest: 'TRANSACTION_DECLINED_RECEIPTS_REQUEST',
  transactionDeclinedReceiptsSuccess: 'TRANSACTION_DECLINED_RECEIPTS_SUCCESS',
  transactionDeclinedReceiptsFailure: 'TRANSACTION_DECLINED_RECEIPTS_FAILURE',
  transactionOpenReceiptsRequest: 'TRANSACTION_OPEN_RECEIPTS_REQUEST',
  transactionOpenReceiptsSuccess: 'TRANSACTION_OPEN_RECEIPTS_SUCCESS',
  transactionOpenReceiptsFailure: 'TRANSACTION_OPEN_RECEIPTS_FAILURE',
  toggleAllAccounts: 'TOGGLE_ALL_ACCOUNTS',
  toggleNoReportDataModal: 'TOGGLE_NO_REPORT_DATA_MODAL',
  toggleDeleteReportModal: 'TOGGLE_DELETE_REPORT_MODAL',
  toggleSavingAgreement: 'TOGGLE_SAVING_AGREEMENT',
  toggleShowGiftCardProgIntegration: 'TOGGLE_GIFT_CARD_PROG_DETAILS_INTEGRATION',
  toggleShowIntegratedAccessKeysIntegration: 'TOGGLE_INTEGRATED_ACCESS_KEYS_INTEGRATION',
  toggleShowTransactionsWebhookIntegration: 'TOGGLE_TRANSACTIONS_WEBHOOK_INTEGRATION',
  toggleRefreshTokenDialog: 'TOGGLE_REFRESH_TOKEN_DIALOG',
  toggleOpenBatchTransactions: 'TOGGLE_OPEN_BATCH_TRANSACTIONS',
  toggleInventoryPopover: 'TOGGLE_INVENTORY_POPOVER',
  toggleHistoricHardwareMode: 'TOGGLE_HISTORIC_HARDWARE_MODE',
  toggleSalesPartnerNotificationAccepted: 'TOGGLE_SALES_PARTNER_NOTIFICATION_ACCEPTED',
  closeHistoricHardwareModeNotice: 'CLOSE_HISTORIC_HARDWARE_MODE_NOTICE',
  tokenExpired: 'TOKEN_EXPIRED',
  tokenExpiring: 'TOKEN_EXPIRING',
  tokenValid: 'TOKEN_VALID',
  tokenAutoRenew: 'TOKEN_AUTO_RENEW',
  transactionUpdateRequest: 'TRANSACTION_UPDATE_REQUEST',
  transactionUpdateSuccess: 'TRANSACTION_UPDATE_SUCCESS',
  transactionUpdateFailure: 'TRANSACTION_UPDATE_FAILURE',
  force2FASetupRequired: 'FORCE_2FA_SETUP_REQUIRED',
  forceResetPasswordRequired: 'FORCE_RESET_PASSWORD_REQUIRED',
  forceResetPasswordLoginRequest: 'FORCE_RESET_PASSWORD_LOGIN_REQUEST',
  forceResetPasswordLoginSuccess: 'FORCE_RESET_PASSWORD_LOGIN_SUCCESS',
  forceResetPasswordLoginError: 'FORCE_RESET_PASSWORD_LOGIN_ERROR',
  setup2faLoginRequest: 'SETUP_2FA_LOGIN_REQUEST',
  setup2faLoginSuccess: 'SETUP_2FA_LOGIN_SUCCESS',
  setup2faLoginError: 'SETUP_2FA_LOGIN_ERROR',
  setup2faVerifyTokenSuccess: 'VERIFY_SETUP_2FA_TOKEN_SUCCESS',
  setActionRequiredSession: 'SET_ACTION_REQUIRED_SESSION',
  cleanExpiredPasswordSession: 'CLEAN_EXPIRED_PASSWORD_SESSION',
  twoFactorTokenRequired: 'TWO_FACTOR_TOKEN_REQUIRED',
  twoFactorTokenValidating: 'TWO_FACTOR_TOKEN_VALIDATING',
  twoFactorTokenValid: 'TWO_FACTOR_TOKEN_VALID',
  twoFactorSendTokenRequest: 'SEND_TWO_FACTOR_TOKEN_REQUEST',
  twoFactorSendTokenSuccess: 'SEND_TWO_FACTOR_TOKEN_SUCCESS',
  twoFactorSendTokenFailure: 'SEND_TWO_FACTOR_TOKEN_FAILURE',
  twoFactorVerifyTokenRequest: 'VERIFY_TWO_FACTOR_TOKEN_REQUEST',
  twoFactorVerifyTokenSuccess: 'VERIFY_TWO_FACTOR_TOKEN_SUCCESS',
  twoFactorVerifyTokenFailure: 'VERIFY_TWO_FACTOR_TOKEN_FAILURE',
  twoFactorVerifyRegistrationTokenRequest: 'VERIFY_TWO_FACTOR_REGISTRATION_TOKEN_REQUEST',
  twoFactorVerifyRegistrationTokenSuccess: 'VERIFY_TWO_FACTOR_REGISTRATION_TOKEN_SUCCESS',
  twoFactorVerifyRegistrationTokenFailure: 'VERIFY_TWO_FACTOR_REGISTRATION_TOKEN_FAILURE',
  twoFactorModifySettingsRequest: 'TWO_FACTOR_MODIFY_SETTINGS_REQUEST',
  twoFactorModifySettingsSuccess: 'TWO_FACTOR_MODIFY_SETTINGS_SUCCESS',
  twoFactorModifySettingsFailure: 'TWO_FACTOR_MODIFY_SETTINGS_FAILURE',
  twoFactorPhoneCodeRequest: 'TWO_FACTOR_PHONE_CODE_REQUEST',
  twoFactorPhoneCodeSuccess: 'TWO_FACTOR_PHONE_CODE_SUCCESS',
  twoFactorPhoneCodeFailure: 'TWO_FACTOR_PHONE_CODE_FAILURE',
  twoFactorPhoneVerifyRequest: 'TWO_FACTOR_PHONE_VERIFY_REQUEST',
  twoFactorPhoneVerifySuccess: 'TWO_FACTOR_PHONE_VERIFY_SUCCESS',
  twoFactorPhoneVerifyFailure: 'TWO_FACTOR_PHONE_VERIFY_FAILURE',
  twoFactorBlock2FA: 'TWO_FACTOR_BLOCK_2FA',
  twoFactorUnblock2FA: 'TWO_FACTOR_UNBLOCK_2FA',
  updateInvoiceRequest: 'UPDATE_INVOICE_REQUEST',
  updateInvoiceSuccess: 'UPDATE_INVOICE_SUCCESS',
  updateInvoiceFailure: 'UPDATE_INVOICE_FAILURE',
  userExperienceFetching: 'USER_EXPERIENCE_FETCHING',
  userForgotPasswordRequest: 'FORGOT_PASSWORD_REQUEST',
  userForgotPasswordSuccess: 'FORGOT_PASSWORD_SUCCESS',
  userForgotPasswordFailure: 'FORGOT_PASSWORD_FAILURE',
  userMerchantApplicationRequest: 'USER_MERCHANT_APPLICATION_REQUEST',
  userMerchantApplicationSuccess: 'USER_MERCHANT_APPLICATION_SUCCESS',
  userMerchantApplicationFailure: 'USER_MERCHANT_APPLICATION_FAILURE',
  userProfileRequest: 'USER_PROFILE_REQUEST',
  userProfileSuccess: 'USER_PROFILE_SUCCESS',
  userProfileFailure: 'USER_PROFILE_FAILURE',
  userResetPasswordRequest: 'RESET_PASSWORD_REQUEST',
  userResetPasswordSuccess: 'RESET_PASSWORD_SUCCESS',
  userResetPasswordFailure: 'RESET_PASSWORD_FAILURE',
  recurringPaymentsRequest: 'RECURRING_PAYMENTS_REQUEST',
  recurringPaymentsSuccess: 'RECURRING_PAYMENTS_SUCCESS',
  recurringPaymentsFailure: 'RECURRING_PAYMENTS_FAILURE',
  addAccountsRequest: 'ADD_ACCOUNTS_REQUEST',
  addAccountsSuccess: 'ADD_ACCOUNTS_SUCCESS',
  addAccountsFailure: 'ADD_ACCOUNTS_FAILURE',
  addMidForUI: 'ADD_MID_FOR_UI',
  removeMidForUI: 'REMOVE_MID_FOR_UI',
  addMidErrorForUI: 'ADD_MID_ERROR_FOR_UI',
  clearMidMessagesForUI: 'CLEAR_MID_MESSAGES_FOR_UI',
  clearForgotPasswordSent: 'CLEAR_FORGOT_PASSWORD_SENT',
  userSetPasswordRequest: 'SET_PASSWORD_REQUEST',
  userSetPasswordSuccess: 'SET_PASSWORD_SUCCESS',
  userSetPasswordFailure: 'SET_PASSWORD_FAILURE',
  userSaveRequest: 'USER_SAVE_REQUEST',
  userSaveSuccess: 'USER_SAVE_SUCCESS',
  userSaveFailure: 'USER_SAVE_FAILURE',
  userUpdateLoginInfoRequest: 'UPDATE_LOGIN_INFO_REQUEST',
  userUpdateLoginInfoSuccess: 'UPDATE_LOGIN_INFO_SUCCESS',
  userUpdateLoginInfoFailure: 'UPDATE_LOGIN_INFO_FAILURE',
  validateResetPasswordTokenRequest: 'VALIDATE_CHANGE_PASSWORD_TOKEN_REQUEST',
  validateResetPasswordTokenSuccess: 'VALIDATE_CHANGE_PASSWORD_TOKEN_SUCCESS',
  validateResetPasswordTokenFailure: 'VALIDATE_CHANGE_PASSWORD_TOKEN_FAILURE',
  validateSetPasswordTokenRequest: 'VALIDATE_SET_PASSWORD_TOKEN_REQUEST',
  validateSetPasswordTokenSuccess: 'VALIDATE_SET_PASSWORD_TOKEN_SUCCESS',
  validateSetPasswordTokenFailure: 'VALIDATE_SET_PASSWORD_TOKEN_FAILURE',
  validateCSRequest: 'CS_REQUEST',
  validateCSSuccess: 'CS_SUCCESS',
  validateCSFailure: 'CS_FAILURE',
  userTerminalsRequest: 'USER_TERMINALS_REQUEST',
  userTerminalsSuccess: 'USER_TERMINALS_SUCCESS',
  userTerminalsFailure: 'USER_TERMINALS_FAILURE',
  userSuppliesRequest: 'USER_SUPPLIES_REQUEST',
  userSuppliesSuccess: 'USER_SUPPLIES_SUCCESS',
  userSuppliesFailure: 'USER_SUPPLIES_FAILURE',
  userOrderRequest: 'USER_ORDERS_REQUEST',
  userOrderSuccess: 'USER_ORDERS_SUCCESS',
  userOrderFailure: 'USER_ORDERS_FAILURE',
  clearShippingMethod: 'CLEAR_SHIPPING_METHOD',
  getShippingMethodRequest: 'GET_SHIPPING_METHOD_REQUEST',
  getShippingMethodSuccess: 'GET_SHIPPING_METHOD_SUCCESS',
  getShippingMethodFailure: 'GET_SHIPPING_METHOD_FAILURE',
  setSelectedShippingMethod: 'SET_SELECTED_SHIPPING_METHOD',
  terminalSelected: 'TERMINAL_SELECTED',
  addToCart: 'ADD_TO_CART',
  removeFromCart: 'REMOVE_FROM_CART',
  updateCart: 'UPDATE_CART',
  clearCart: 'CLEAR_CART',
  orderHistoryRequest: 'ORDER_HISTORY_REQUEST',
  orderHistorySuccess: 'ORDER_HISTORY_SUCCESS',
  orderHistoryFailure: 'ORDER_HISTORY_FAILURE',
  calculateOrderAmounts: 'CALCULATE_ORDER_AMOUNTS',
  setDummyReportStart: 'SET_DUMMY_REPORT_START',
  submitOrderRequest: 'SUBMIT_ORDER_REQUEST',
  submitOrderSuccess: 'SUBMIT_ORDER_SUCCESS',
  submitOrderFailure: 'SUBMIT_ORDER_FAILURE',
  viewOrderSummary: 'VIEW_ORDER_SUMMARY',
  viewRecurringFields: 'VIEW_RECURRING_FIELDS',
  setLoaderPercentage: 'SET_LOADER_PERCENTAGE',
  generateTransactionCsvRequest: 'GENERATE_TRANSACTION_CSV_REQUEST',
  generateTransactionCsvSuccess: 'GENERATE_TRANSCATION_CSV_SUCCESS',
  generateTransactionCsvFailure: 'GENERATE_TRANSACTION_CSV_FAILURE',
  showReportLoader: 'SHOW_REPORT_LOADER',
  hideReportLoader: 'HIDE_REPORT_LOADER',
  deleteOwnerByContactIdRequest: 'DELETE_OWNER_BY_CONTACT_ID_REQUEST',
  deleteOwnerByContactIdSuccess: 'DELETE_OWNER_BY_CONTACT_ID_SUCCESS',
  deleteOwnerByContactIdFailure: 'DELETE_OWNER_BY_CONTACT_ID_FAILURE',
  addOwnerContactApiRequest: 'ADD_OWNER_CONTACT_API_REQUEST',
  addOwnerContactApiSuccess: 'ADD_OWNER_CONTACT_API_SUCCESS',
  addOwnerContactApiFailure: 'ADD_OWNER_CONTACT_API_FAILURE',
  updateOwnerContactApiRequest: 'UPDATE_OWNER_CONTACT_API_REQUEST',
  updateOwnerContactApiSuccess: 'UPDATE_OWNER_CONTACT_API_SUCCESS',
  updateOwnerContactApiFailure: 'UPDATE_OWNER_CONTACT_API_FAILURE',
  tierUpgradeRequest: 'TIER_UPGRADE_REQUEST',
  tierUpgradeSuccess: 'TIER_UPGRADE_SUCCESS',
  tierUpgradeFailure: 'TIER_UPGRADE_FAILURE',
  clearForgotPasswordData: 'CLEAR_FORGOT_PASSWORD_DATA',
  updateItemsChart: 'UPDATE_ITEMS_CHART',
  toggleAgentPortalMsg: 'TOGGLE_AGENT_PORTAL_MSG',
  toggleMainMenu: 'TOGGLE_MAIN_MENU',
  closeMainMenu: 'ClOSE_MAIN_MENU',
  locationChange: '@@router/LOCATION_CHANGE',
  loyaltyVpcRequest: 'LOYALTY_VPC_REQUEST',
  loyaltyVpcSuccess: 'LOYALTY_VPC_SUCCESS',
  loyaltyVpcFailure: 'LOYALTY_VPC_FAILURE',
  loyaltyVpcReset: 'LOYALTY_VPC_RESET',
  loyaltyVpcUpdateRequest: 'LOYALTY_VPC_UPDATE_REQUEST',
  loyaltyVpcUpdateSuccess: 'LOYALTY_VPC_UPDATE_SUCCESS',
  loyaltyVpcUpdateFailure: 'LOYALTY_VPC_UPDATE_FAILURE',
  getDevicesRequest: 'GET_DEVICES_REQUEST',
  getDevicesSuccess: 'GET_DEVICES_SUCCESS',
  getDevicesFailure: 'GET_DEVICES_FAILURE',
  deviceSelected: 'DEVICE_SELECTED',
  storeFirstFive: 'STORE_FIRST_FIVE',
  postIdentityVerificationRequest:'POST_IDENTITY_VERIFICATION_REQUEST',
  postIdentityVerificationSuccess:'POST_IDENTITY_VERIFICATION_SUCCESS',
  postIdentityVerificationFailure:'POST_IDENTITY_VERIFICATION_FAILURE',
  saveVerification: 'SAVE_VERIFICATION',
  expireVerification: 'EXPIRE_VERIFICATION',
  resetTwoFactorAuthFlag: 'RESET_TWO_FACTOR_AUTH_FLAG',
  giactSaveError: 'GIACT_SAVE_ERROR',
  giactSaveErrorReset: 'GIACT_SAVE_ERROR_RESET',
  loginFormError:'LOGIN_FORM_ERROR',
  toggleTerms2FADialog: 'TOGGLE_TERMS_2FA_DIALOG',
  update2FAForm: 'TWO_FACTOR_FORM_UPDATE',
  reset2FACodeStatus: 'TWO_FACTOR_AUTHENTICATION_CODE_STATUS_RESET',
  reset2FAPhoneStatus: 'TWO_FACTOR_AUTHENTICATION_PHONE_STATUS_RESET',
  giftCardEnableRequest: 'GIFT_CARD_ENABLE_PROVIDER_REQUEST',
  giftCardEnableSuccess: 'GIFT_CARD_ENABLE_PROVIDER_SUCCESS',
  giftCardEnableFailure: 'GIFT_CARD_ENABLE_PROVIDER_FAILURE',
  giftCardDisableRequest: 'GIFT_CARD_DISABLE_PROVIDER_REQUEST',
  giftCardDisableSuccess: 'GIFT_CARD_DISABLE_PROVIDER_SUCCESS',
  giftCardDisableFailure: 'GIFT_CARD_DISABLE_PROVIDER_FAILURE',
  toggleMerchanConfirmByCSDialog: 'TOGGLE_MERCHANT_VERIFIED_BY_CS_DIALOG',
  userNeedsInfoRequest: 'USER_NEEDS_INFO_REQUEST',
  userNeedsInfoSuccess: 'USER_NEEDS_INFO_SUCCESS',
  userNeedsInfofailure: 'USER_NEEDS_INFO_FAILURE',
  compareToPeriod: 'COMPARED_TO_PERIOD',
  paymentMethodsToggleDeleteDialog: 'TOGGLE_PAYMENT_METHODS_DELETE_DIALOG',
  paymentMethodsToggleEditDialog: 'TOGGLE_PAYMENT_METHODS_EDIT_DIALOG',
  paymentMethodsToggleAddDialog: 'TOGGLE_PAYMENT_METHODS_ADD_DIALOG',
  planListToggleDialog: 'TOGGLE_PLAN_LIST_DIALOG',
  languageSelected: 'LANGUAGE_SELECTED',
  getGoogleURIRequest: 'GET_GOOGLE_URI_REQUEST',
  getGoogleURISuccess: 'GET_GOOGLE_URI_SUCCESS',
  getGoogleURIFailure: 'GET_GOOGLE_URI_FAILURE',
  postReputationsRequest: 'POST_REPUTATIONS_REQUEST',
  postReputationsSuccess: 'POST_REPUTATIONS_SUCCESS',
  postReputationsFailure: 'POST_REPUTATIONS_FAILURE',
  getReputationsRequest: 'GET_REPUTATIONS_REQUEST',
  getReputationsSuccess: 'GET_REPUTATIONS_SUCCESS',
  getReputationsFailure: 'GET_REPUTATIONS_FAILURE',
  deleteReputationsRequest: 'DELETE_REPUTATIONS_REQUEST',
  deleteReputationsSuccess: 'DELETE_REPUTATIONS_SUCCESS',
  deleteReputationsFailure: 'DELETE_REPUTATIONS_FAILURE',
  postReputationsReplyRequest: 'POST_REPUTATIONS_REPLY_REQUEST',
  postReputationsReplySuccess: 'POST_REPUTATIONS_REPLY_SUCCESS',
  postReputationsReplyFailure: 'POST_REPUTATIONS_REPLY_FAILURE',
  deleteReputationsReplyRequest: 'DELETE_REPUTATIONS_REPLY_REQUEST',
  deleteReputationsReplySuccess: 'DELETE_REPUTATIONS_REPLY_SUCCESS',
  deleteReputationsReplyFailure: 'DELETE_REPUTATIONS_REPLY_FAILURE',
  getCompetitorsRequest: 'GET_COMPETITORS_REQUEST',
  getCompetitorsSuccess: 'GET_COMPETITORS_SUCCESS',
  getCompetitorsFailure: 'GET_COMPETITORS_FAILURE',
  resetCompetitorsSearch: 'RESET_COMPETITORS_SEARCH',
  postCompetitorRequest: 'POST_COMPETITOR_REQUEST',
  postCompetitorSuccess: 'POST_COMPETITOR_SUCCESS',
  postCompetitorFailure: 'POST_COMPETITOR_FAILURE',
  removeCompetitorRequest: 'REMOVE_COMPETITOR_REQUEST',
  removeCompetitorSuccess: 'REMOVE_COMPETITOR_SUCCESS',
  removeCompetitorFailure: 'REMOVE_COMPETITOR_FAILURE',
  addReviewReplyRequest: 'ADD_REVIEW_REPLY_REQUEST',
  addReviewReplySuccess: 'ADD_REVIEW_REPLY_SUCCESS',
  addReviewReplyFailure: 'ADD_REVIEW_REPLY_FAILURE',
  getLocationsRequest: 'GET_LOCATIONS_REQUEST',
  getLocationsSuccess: 'GET_LOCATIONS_SUCCESS',
  getLocationsFailure: 'GET_LOCATIONS_FAILURE',
  putLocationsRequest: 'PUT_LOCATIONS_REQUEST',
  putLocationsSuccess: 'PUT_LOCATIONS_SUCCESS',
  putLocationsFailure: 'PUT_LOCATIONS_FAILURE',
  getInternalPaymentLinksRequest: 'GET_INTERNAL_PAYMENT_LINKS_REQUEST',
  getInternalPaymentLinksSuccess: 'GET_INTERNAL_PAYMENT_LINKS_SUCCESS',
  getInternalPaymentLinksFailure: 'GET_INTERNAL_PAYMENT_LINKS_FAILURE',
  getPaymentLinkRequest: 'GET_PAYMENT_LINK_REQUEST',
  getPaymentLinkSuccess: 'GET_PAYMENT_LINK_SUCCESS',
  getPaymentLinkFailure: 'GET_PAYMENT_LINK_FAILURE',
  payPaymentLinkRequest: 'PAY_PAYMENT_LINK_REQUEST',
  payPaymentLinkSuccess: 'PAY_PAYMENT_LINK_SUCCESS',
  payPaymentLinkFailure: 'PAY_PAYMENT_LINK_FAILURE',
  payPaymentLinkApplePayRequest: 'PAY_PAYMENT_LINK_APPLEPAY_REQUEST',
  payPaymentLinkApplePaySuccess: 'PAY_PAYMENT_LINK_APPLEPAY_SUCCESS',
  payPaymentLinkApplePayFailure: 'PAY_PAYMENT_LINK_APPLEPAY_FAILURE',
  updatePaymentLinkRequest: 'UPDATE_PAYMENT_LINK_REQUEST',
  updatePaymentLinkSuccess: 'UPDATE_PAYMENT_LINK_SUCCESS',
  updatePaymentLinkFailure: 'UPDATE_PAYMENT_LINK_FAILURE',
  getLoyaltyActivitiesRequest: 'GET_LOYALTY_ACTIVITIES_REQUEST',
  getLoyaltyActivitiesSuccess: 'GET_LOYALTY_ACTIVITIES_SUCCESS',
  getLoyaltyActivitiesFailure: 'GET_LOYALTY_ACTIVITIES_FAILURE',
  postLogRequest: 'POST_LOG_REQUEST',
  postLogSuccess: 'POST_LOG_SUCCESS',
  postLogFailure: 'POST_LOG_FAILURE',
  getAddOnsRequest: 'GET_ADD_ONS_REQUEST',
  getAddOnsSuccess: 'GET_ADD_ONS_SUCCESS',
  getAddOnsFailure: 'GET_ADD_ONS_FAILURE',
  updateAddOnRequest: 'UPDATE_ADD_ON_REQUEST',
  updateAddOnSuccess: 'UPDATE_ADD_ON_SUCCESS',
  updateAddOnFailure: 'UPDATE_ADD_ON_FAILURE',
  closedAccount: 'CLOSED_ACCOUNT',
  leadSignupRequest: 'LEAD_SIGNUP_REQUEST',
  leadSignupSuccess: 'LEAD_SIGNUP_SUCCESS',
  leadSignupFailure: 'LEAD_SIGNUP_FAILURE',
  clearLeadSignup: 'CLEAR_LEAD_SIGNUP',
  setLeadError: 'SET_LEAD_ERROR',
  leadSignupMfaRequest: 'LEAD_SIGNUP_MFA_REQUEST',
  leadSignupMfaSuccess: 'LEAD_SIGNUP_MFA_SUCCESS',
  leadSignupMfaFailure: 'LEAD_SIGNUP_MFA_FAILURE',
  prevActivationStep: 'PREVIOUS_ACTIVATION_STEP',
  nextActivationStep: 'NEXT_ACTIVATION_STEP',
  getApplicationState: 'GET_APPLICATION_STATE',
  saveOwnersForm: 'SAVE_OWNERS_FORM',
  saveBusinessForm: 'SAVE_BUSINESS_FORM',
  postBulkInvoicesRequest: 'POST_BULK_INVOICES_REQUEST',
  postBulkInvoicesSuccess: 'POST_BULK_INVOICES_SUCCESS',
  postBulkInvoicesFailure: 'POST_BULK_INVOICES_FAILURE',
  getBulkInvoicesRequest: 'GET_BULK_INVOICES_REQUEST',
  getBulkInvoicesSuccess: 'GET_BULK_INVOICES_SUCCESS',
  getBulkInvoicesFailure: 'GET_BULK_INVOICES_FAILURE',
  getBulkInvoiceRequest: 'GET_BULK_INVOICE_REQUEST',
  getBulkInvoiceSuccess: 'GET_BULK_INVOICE_SUCCESS',
  getBulkInvoiceFailure: 'GET_BULK_INVOICE_FAILURE',
  cancelBulkInvoiceRequest: 'CANCEL_BULK_INVOICE_REQUEST',
  cancelBulkInvoiceSuccess: 'CANCEL_BULK_INVOICE_SUCCESS',
  cancelBulkInvoiceFailure: 'CANCEL_BULK_INVOICE_FAILURE',
  sendBulkInvoicesRequest: 'SEND_BULK_INVOICES_REQUEST',
  sendBulkInvoicesSuccess: 'SEND_BULK_INVOICES_SUCCESS',
  sendBulkInvoicesFailure: 'SEND_BULK_INVOICES_FAILURE',
  completeBulkImport: 'COMPLETE_BULK_IMPORT_REQUEST',
};
