/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import { submit } from 'redux-form';
import {forgotCSPassword, forgotPassword, clearForgotPasswordData} from '../actions/authActions';
import {Link} from 'react-router-dom';
import LabelUtil from './util/LabelUtil';
import Loading from './Loading';
import PropTypes from 'prop-types';
import ForgotPasswordForm from './ForgotPasswordForm';
import routes from '../constants/routes';
import Button from './shared/Button';
import LanguageSelector from './shared/LanguageSelector';
import Bugsnag from '@bugsnag/js';

const clientRoute = routes;

export default class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.handleEnterPressed = this.handleEnterPressed.bind(this);
    this.forgetPasswordRequest = this.forgetPasswordRequest.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('keydown', this.handleEnterPressed);
    this.props.dispatch(clearForgotPasswordData());
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEnterPressed);
  }

  handleEnterPressed(event) {

    if (event.key == 'Enter' || event.keyCode == 13) {
      this.forgetPasswordRequest();
    }
  }

  onSaveClick() {
    this.props.dispatch(submit('forgetPasswordForm'));
  }

  forgetPasswordRequest(values) {
    if (!this.props.auth.isFetching) {
      const { location } = this.props;
      const pathName = location.pathname;
      const label = LabelUtil.getLabel();
      const appRoutePrefix = globalApplicationLabel.path;
      const isCsRoute = pathName.includes(appRoutePrefix + clientRoute.clientService.root);
      const forgetPasswordInfo = {
        email: values && values.emailAddress ? values.emailAddress.trim() : null,
        captcharesponse: values ? values.captcharesponse : null,
        brand: label.abbreviation
      };

      try {
        isCsRoute ? this.props.dispatch(forgotCSPassword(forgetPasswordInfo)) : this.props.dispatch(forgotPassword(forgetPasswordInfo));
      } catch (error) {
        Bugsnag.notify(new Error(error));
      }
    }
  }

  render() {
    const { errorMessage, location, userExperience, auth, t } = this.props;
    const submitButtonDisabled = !userExperience.enableFormSubmitButton;

    const appRoutePrefix = globalApplicationLabel.path;
    const pathName = location.pathname;
    const isCsRoute = pathName.includes(appRoutePrefix + clientRoute.clientService.root);
    const redirectToHome = isCsRoute ? appRoutePrefix + clientRoute.clientService.root : appRoutePrefix;

    const loadingIndicator = auth.isFetching && <Loading />;

    const forgetPasswordContainer = (<div className='loginContainer'>
      <div className='resetHeader'>
        {t ? t('CreateResetPassword') : 'Create or reset password.'}
      </div>
      <div className='forgotPasswordDescription'>
        {t ? t('ForgotPasswordDescription') : 'Enter your email address to get started. You’ll receive an email with further instructions on how to create or reset your password for North, Payments Hub, and Payanywhere products.'}
      </div><br /><br />
      {loadingIndicator}
      <ForgotPasswordForm
         onSubmit={this.forgetPasswordRequest}
         onRequestToken={this.requestToken}
         {...this.props}
      />
      <br />
      {
        errorMessage && <p className='loginErrorMessage' style={{color: 'red'}}>{t ? t(errorMessage) : errorMessage}</p>
      }

      <Button
        className='loginButton'
        tabIndex={3}
        onClick={this.onSaveClick}
        disabled={submitButtonDisabled}
        label={t ? t('Submit') : 'Submit'} />
      <br />
      <br />
      <br />
      <LanguageSelector  {...this.props} />

    </div>);

    const backToLoginButton = !this.props.fromMobileDevice ?
      (<Link to={redirectToHome} >{t ? t('BackToLogin') : 'Back to Login'}</Link>) : null;

    const successContainer = (
      <div className='loginContainer successContainer'>
        <div className='resetHeader'>
          {t ? t('ForgotPasswordForm.SuccessHeader') : 'Check your inbox.'}
        </div>
        <div className='instructionText'>
          {t ? t('ForgotPasswordForm.SuccessText') : 'We\'ve sent instructions for creating or resetting your password to your email address.'}
        </div>
          <div className='forgotPasswordContainer'>
          {backToLoginButton}
        </div>
      </div>
    );

    return (
      <div className='login forgotPassword'>
        {auth.forgotPasswordSent && !errorMessage ? successContainer : forgetPasswordContainer}
      </div>

    );
  }

}

ForgotPassword.propTypes = {
  errorMessage: PropTypes.string
};
