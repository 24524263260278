import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import CustomerUtil from '../components/util/CustomerUtil';
import _ from 'lodash';

export function getCustomers(user) {

  let endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/customers';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.customersRequest, actionTypes.customersSuccess, actionTypes.customersFailure]
    }
  };
}

export function getCustomer(user, customerId, isPaCustomer = true) {

  let endpoint = `users/accounts/${user.selectedMerchantAccount}/customers/${customerId}`;

  if (isPaCustomer) {
    endpoint = `${endpoint}?type=pa`;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.customerRequest, actionTypes.customerSuccess, actionTypes.customerFailure]
    }
  };
}

export function setCustomer(customer) {
  return {
    type: actionTypes.customerSelected,
    selectedCustomer: customer
  };
}

export function deleteCustomer(user, selectedCustomer) {

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/customers/' + selectedCustomer.id;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [actionTypes.customerSaveRequest, actionTypes.customerSaveSuccess, actionTypes.customerSaveFailure]
    }
  };
}

export function updateCustomer(user, selectedCustomer, values) {
  const method = 'PUT';
  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/customers/' + selectedCustomer.id;

  const body = {
    ...values,
    email: !!values?.email_addresses?.[0] ? values.email_addresses[0] : null
  };

  delete body.active_invoices;
  delete body.payment_methods;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: method,
      authenticated: true,
      encrypted: true,
      types: [actionTypes.customerSaveRequest, actionTypes.customerSaveSuccess, actionTypes.customerSaveFailure],
      body
    }
  };
}

export function saveCustomer(user, selectedIndex, values) {

  const method = 'POST';
  const customerId = values?.id ? values.id : selectedIndex;
  const recordPath = selectedIndex !== 'new' ? customerId : null;

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/customers/' + recordPath

  let body = {};
  body.first_name = values.first_name || ' ';
  body.last_name = values.last_name || ' ';
  body.email = values.email_addresses && values.email_addresses.length > 0 && values.email_addresses[0] || null;
  body.phone = values.phone_number || null;
  body.account_number = values.account_number || null;
  body.payment_methods = CustomerUtil.formatPaymentMethodsBody(values);

  if (values.street_address_1 || values.street_address_2 || values.city || values.zip || values.state) {
    body.address = {};
    body.address.street_address_1 = values.street_address_1;
    body.address.street_address_2 = values.street_address_2;
    body.address.city = values.city;
    body.address.zip = values.zip;
    body.address.state = values.state;
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: method,
      authenticated: true,
      encrypted: true,
      types: [actionTypes.customerSaveRequest, actionTypes.customerSaveSuccess, actionTypes.customerSaveFailure],
      body
    }
  };
}

export function deletePaymentMethod(user, selectedCustomer, paymentMethodId, type) {
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/customers/${selectedCustomer.id}/paymethods/${paymentMethodId}/type/${type}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [actionTypes.paymentMethodsDeleteRequest, actionTypes.paymentMethodsDeleteSuccess, actionTypes.paymentMethodsDeleteFailure]
    }
  };
}

export function postPaymentMethod(user, values) {
  const method = 'POST';
  const customerId = values?.id;
  const endpoint = `users/accounts/${user.selectedMerchantAccount}/customers/${customerId}/paymethods`;
  const [paymentMethods] = values.payment_methods || [{}];
  const body = CustomerUtil.formatPaymentMethod(paymentMethods);

  return {
    [CALL_API]: {
      endpoint,
      method,
      authenticated: true,
      encrypted: true,
      types: [actionTypes.paymentMethodsPostRequest, actionTypes.paymentMethodsPostSuccess, actionTypes.paymentMethodsPostFailure],
      body
    }
  };
}

export function postPaymentMethodWithToken(paymentMethod, token) {

  const query = `?token=${encodeURIComponent(token)}`;

  const method = 'POST';
  const endpoint = `customer/paymethods${query}`;
  const body =  CustomerUtil.formatPaymentMethod(paymentMethod);

  return {
    [CALL_API]: {
      endpoint,
      method,
      authenticated: false,
      encrypted: true,
      types: [actionTypes.paymentMethodsPostRequest, actionTypes.paymentMethodsPostSuccess, actionTypes.paymentMethodsPostFailure],
      body
    }
  };
}

export function putPaymentMethodWithToken(paymentMethodToUpdate, paymentMethodId, token) {

  const query = `?token=${encodeURIComponent(token)}`;

  const method = 'PUT';
  const endpoint = `customer/paymethods/${paymentMethodId}${query}`;
  let body = {};

  if (Object.keys(paymentMethodToUpdate).length === 1) {
    body = paymentMethodToUpdate;
  } else {
    body = CustomerUtil.formatPaymentMethod(paymentMethodToUpdate);
  }

  return {
    [CALL_API]: {
      endpoint,
      method,
      authenticated: false,
      encrypted: true,
      types: [actionTypes.paymentMethodsPutRequest, actionTypes.paymentMethodsPutSuccess, actionTypes.paymentMethodsPutFailure],
      body
    }
  };
}

export function deletePaymentMethodWithToken(paymentMethodId, token, type) {

  const query = `?token=${encodeURIComponent(token)}&type=${type}`;

  const method = 'DELETE';
  const endpoint = `customer/paymethods/${paymentMethodId}${query}`;

  return {
    [CALL_API]: {
      endpoint,
      method,
      authenticated: false,
      types: [actionTypes.paymentMethodsDeleteRequest, actionTypes.paymentMethodsDeleteSuccess, actionTypes.paymentMethodsDeleteFailure]
    }
  };
}

export function getCustomerPaymentMethods(token) {

  const query = `?token=${encodeURIComponent(token)}`;

  const endpoint = `customer/payment-methods${query}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.customerPaymentMethodsGetRequest, actionTypes.customerPaymentMethodsGetSuccess, actionTypes.customerPaymentMethodsGetFailure]
    }
  };
}

export function getPayNowCustomerPaymentMethods(token, customerId) {

  const query = `?token=${encodeURIComponent(token)}`;

  const endpoint = `customers/${customerId}/payment-methods/pay-now${query}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.customerPaymentMethodsGetRequest, actionTypes.customerPaymentMethodsGetSuccess, actionTypes.customerPaymentMethodsGetFailure]
    }
  };
}

export function addCustomerPaymentMethod(token, customer) {

  const query = `?token=${encodeURIComponent(token)}`;

  const endpoint = `customer/payment-methods${query}`;
  const objProps = ['email_addresses', 'id', 'payment_methods', 'email', 'first_name', 'last_name', 'phone_number'];
  const body = _.pick(customer, objProps);
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.customerPaymentMethodsPutRequest, actionTypes.customerPaymentMethodsPutSuccess, actionTypes.customerPaymentMethodsPutFailure],
      body
    }
  };
}

export function updateCustomerDataToken(token, customer) {

  const query = `?token=${encodeURIComponent(token)}`;

  const endpoint = `customer/settings${query}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.customerSaveRequest, actionTypes.customerSaveSuccess, actionTypes.customerSaveFailure],
      body: customer
    }
  };
}

export function sendRewardEmail(user, customerId) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/rewards-email`;
  const body = {
    type: 'reward_notification',
    customerId
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: false,
      types: [actionTypes.customerSendRewardEmailRequest, actionTypes.customerSendRewardEmailSuccess, actionTypes.customerSendRewardEmailFailure],
      body
    }
  };
}

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
