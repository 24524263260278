/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import query from 'query-string';
import {submit} from 'redux-form';
import moment from 'moment';
import Loading from '../Loading';
import UpdateSpinner from '../UpdateSpinner';
import PayInvoiceForm from './PayInvoiceForm';
import {
  setViewOrderSummary,
  setModalVisibility
} from '../../actions/userExperienceActions';
import {getInvoiceToPay} from '../../actions/invoicesActions';
import {roundToTwoDecimals} from '../util/CommonUtil';
import PaymentUtil from '../util/PaymentUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import numeral from 'numeral';
import NoData from '../NoData';
import MerchantLogo from '../MerchantLogo';
import messages from '../../constants/messages';
import Button from '../shared/Button';
import MessageDialog from '../shared/MessageDialog';
import IconUtil from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Modal from '../shared/Modal';
import InvoiceUtil from '../util/InvoiceUtil';
import _ from 'lodash';

export default class Invoice extends Component {

  constructor(props) {
    super(props);

    this.process = this.process.bind(this);
    this.processApplePay = this.processApplePay.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onPayClick = this.onPayClick.bind(this);
    this.hideOrderSummary = this.hideOrderSummary.bind(this);
    this.handleCloseLockedDialog = this.handleCloseLockedDialog.bind(this);
    this.formatFrequency = this.formatFrequency.bind(this);

    this.state = {
      paymentCompleted: false,
      paymentError: null,
      loading: false,
      avsAttempts: 0,
      paymentLocked: false
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData() {
    let that = this;
    const queryString = query.parse(this.props.location.search);
    let token = queryString.token;

    return this.props.dispatch(getInvoiceToPay(token)).then(payload => {
      const response = _.head(payload.response?.invoices);

      if (response && response.paid_date && InvoiceUtil.invoiceStatus(response) === 'paid') {
        that.setState({paymentCompleted: true});
      }

      if (response && response.failure_array && response.failure_array.length > 0) {
        that.setState({
          avsAttempts: response.failure_array.length,
        });
      }

    });
  }

  onPayClick() {

    const {invoices} = this.props;

    this.props.dispatch(submit('payInvoiceForm'));

  }

  hideOrderSummary() {
    this.props.dispatch(setViewOrderSummary(false));
  }

  process(values) {
    const { t } = this.props;
    if (this.state.avsAttempts >= 3 && this.props.invoices && this.props.invoices.invoiceForPayment && !this.props.invoices.invoiceForPayment.ignore_avs_failure) {
      this.setState({paymentLocked: true})
      return;
    }

    this.setState({loading: true, paymentCompleted: false, paymentError: null});

    const queryString = query.parse(this.props.location.search);

    let that = this;
    let token = queryString.token;

    return new Promise(function (resolve, reject) {
      PaymentUtil.processInvoice(values, token, that.props).then(function (paymentResponse) {

        if (paymentResponse.postPaymentError && (paymentResponse.payment.response.response_code === 'DCL' && paymentResponse.postPaymentError.type === 'avs')) {
          that.props.dispatch(setModalVisibility('avs', {
            ...paymentResponse,
            postPaymentError: paymentResponse.postPaymentError
          }));
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: paymentResponse.postPaymentError.message
          });
        } else if (paymentResponse.payment && paymentResponse.payment.error) {
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: paymentResponse.payment.error
          });
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response.response_code !== 'APR') {
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: 'Error processing payment: ' + paymentResponse.payment.response.status_message
          });
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response.response_code === 'APR') {

          if (paymentResponse.payment.response.is_partial_auth) {
            that.setState({
              paymentCompleted: false,
              loading: false,
              paymentError: t(messages.errors.partialPayment.message)
            });
          } else {
            that.setState({paymentCompleted: true, loading: false});
          }

        } else {
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: 'Sorry, something went wrong, please try again'
          });
        }
        resolve();
      }).catch(error => {
        that.setState({
          loading: false,
          paymentError: t(messages.errors.invoiceApiError)
        });
        reject('Error: ' + error.message);
      });

    })

  }

  /**
   *  Passes arguments received directly onto processInvoiceWithApplePay
   *  If there's any error it rejects the promise in order to inform Apple's Apple Pay UI window, so that it can display an appropriate status to the user
   */
  processApplePay(user, applePaymentData, paymentData, tipAmount) {

    this.setState({loading: true, paymentCompleted: false, paymentError: null});

    const queryString = query.parse(this.props.location.search);

    const merchantToken = {
      type: 'clp',
      token: queryString.token
    };

    let that = this;
    let errorMessage;

    return new Promise(function (resolve, reject) {
      PaymentUtil.processInvoiceWithApplePay(user, applePaymentData, paymentData, tipAmount, that.props, merchantToken).then(function (paymentResponse) {

        if (paymentResponse.payment && paymentResponse.payment.error) {
          errorMessage = paymentResponse.payment.error;
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: errorMessage
          });
          reject('Error: ' + errorMessage);
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response.response_code !== 'APR') {
          errorMessage = paymentResponse.payment.response.status_message;
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: 'Apple Pay cannot be used for this transaction. Please try another card.'
          });
          reject('Error: ' + errorMessage);
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response.response_code === 'APR') {

          if (paymentResponse.payment.response.is_partial_auth) {
            errorMessage = that.props.t(messages.errors.partialPayment.message);
            that.setState({
              paymentCompleted: false,
              loading: false,
              paymentError: errorMessage
            });
            reject('Error: ' + errorMessage);
          } else {
            that.setState({paymentCompleted: true, loading: false});
            resolve();
          }

        } else {
          errorMessage = 'Sorry, something went wrong, please try again';
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: errorMessage
          });
          reject('Error: ' + errorMessage);
        }
        resolve();
      }).catch(error => {
        that.setState({loading: false, paymentError: error});
        reject('Error: ' + error.message);
      });

    });

  }

  handleCloseLockedDialog() {
    this.setState({paymentLocked: false});
  }

  formatFrequency(frequency) {
    const {t} = this.props;
    const firstHalf = `${t('PayInvoiceForm.RepeatsEvery')} `;
    let secondHalf;

    switch (frequency) {
      case '1 days':
        secondHalf = t('PayInvoiceForm.Day');
        break;
      case '1 weeks':
        secondHalf = t('PayInvoiceForm.Week');
        break;
      case '1 months':
        secondHalf = t('PayInvoiceForm.Month');
        break;
      case '1 years':
        secondHalf = t('PayInvoiceForm.Year');
        break;
      default:
        secondHalf = frequency;
    }
    return firstHalf + secondHalf;
  }

  render() {

    const {invoices, t} = this.props;

    const label = globalApplicationLabel;
    const labelLogoSource = '/images/' + label.assetIdentifier;

    const disabledPayNowButton = invoices.isProcessing;

    const buttonDisabledStyle = {
      opacity: .5,
      cursor: 'not-allowed'
    };

    const payNowButton = (
      <Button
        id='submitButton'
        className='submitButton'
        label={t('PayInvoiceForm.PayNow')}
        disabled={disabledPayNowButton}
        style={disabledPayNowButton ? buttonDisabledStyle : null}
        onClick={() => !disabledPayNowButton && this.onPayClick()}
      />
    );

    const isEmpty = !invoices.invoiceForPayment;

    if (invoices.isFetching) {
      return (
        <Loading/>
      )
    } else if (isEmpty) {
      return (
        <NoData text={t('PayInvoiceForm.InvoiceNotFound')}/>
      )
    } else {

      const orderDetails = invoices.invoiceForPayment;

      const hasItems = !!(orderDetails.item_names && orderDetails.item_names.length);
      const hasModifiers = !!(orderDetails.item_modifiers && orderDetails.item_modifiers.length && !_.isEmpty(orderDetails.item_modifiers.filter(item => !!item)));
      let mappedModifiers = []; //Will be an array of arrays. One array of modifiers for each item

      if (hasItems && hasModifiers) {
        const isModifiersSimpleArray = _.isEmpty(orderDetails.item_modifiers.filter(modifier => Array.isArray(modifier))); //Simple array without nested arrays

        mappedModifiers = !isModifiersSimpleArray
          ? orderDetails.item_modifiers :
          orderDetails.item_ids.map(itemId => orderDetails.item_modifiers.filter(modifier => modifier && modifier.includes(itemId)));

        mappedModifiers = mappedModifiers.map(InvoiceUtil.mapInvoiceModifiers);
      }

      let orderRows = hasItems ?
        orderDetails.item_names.map((orderName, key) => {

          const cashDiscountPerItem = FormatTextUtil.formatCashDiscountPerItem(orderDetails.item_quantity.length, orderDetails.item_quantity[key], key, orderDetails.service_fee_amt);

          const itemAmount = orderDetails.item_quantity[key] ? orderDetails.item_quantity[key] * (orderDetails.item_unit_price[key] + cashDiscountPerItem) :
            orderDetails.item_unit_price[key];

          if (orderDetails.item_quantity[key] > 1) {
            orderName = orderName + ` (${orderDetails.item_quantity[key]})`;
          }

          const itemDiscountAmount = orderDetails.item_discount_amt[key];
          const itemDiscountRate = orderDetails.item_discount_rate[key];

          return (
            <div key={key} className='orderRow' style={{alignItems: 'flex-start'}}>
              <div className='orderRowInfo'>
                <div className='orderRowName'>{orderName}</div>

                {hasModifiers &&
                  <div className='orderModifiersContainer'>
                    {mappedModifiers[key] && mappedModifiers[key].map((modifier, modifierIndex) => (
                      <div className='itemModifierName'
                           key={modifierIndex}>{modifier?.name}</div>
                    ))}
                  </div>}

                {(itemDiscountAmount || itemDiscountRate) &&
                <div className='orderRowDiscount' data-test-id='item-discount'>{`${t('Discount')}${itemDiscountRate ? ` ${itemDiscountRate}%:` : ':'}`}</div>}
              </div>
              <div className='orderRowAmounts'>
                <div
                  className='orderRowValue'>{numeral(itemAmount).format('$0,0.00')}</div>

                {hasModifiers &&
                  <div className='orderModifiersContainer'>
                    {mappedModifiers[key] && mappedModifiers[key].map((modifier, modifierIndex) => (
                      <div className='itemModifierAmount'
                           key={modifierIndex}>{numeral(modifier?.price * orderDetails.item_quantity[key]).format('$0,0.00')}</div>
                    ))}
                  </div>}

                {itemDiscountAmount && <div
                  className='orderRowDiscount'>{numeral(0 - itemDiscountAmount).format('$0,0.00')}</div>}
                
              </div>
            </div>
          )
        }) : null;

      const cartDiscounts = orderDetails.receipt_discount_amt && orderDetails.receipt_discount_amt.length > 0 ?
        <div className='cartDiscountRows'>
          {orderDetails.receipt_discount_amt.map((discount, index) => {

            let percentageProp;
            try {
              percentageProp = (orderDetails.receipt_discount_type[index] === 'Percentage') && orderDetails.receipt_discount_info[index]
                ? orderDetails.receipt_discount_info[index].percentage
                  ? orderDetails.receipt_discount_info[index]
                  : JSON.parse(orderDetails.receipt_discount_info[index])
                : null;
            } catch (error) {
              percentageProp = null;
            }

            const percentageText = percentageProp && percentageProp.percentage
              ? `(${percentageProp.percentage}% ${t('PayInvoiceForm.Off')})`
              : '';

            return (
              <div className='orderRow cartDiscount' key={'discount' + index}>
                <div
                  className='orderRowInfo'>{`Discount ${percentageText}`}</div>
                <div
                  className='orderRowValue'>{'-' + numeral(roundToTwoDecimals(discount)).format('$0,0.00')}</div>
              </div>
            );
          })}
        </div>
        : null;

      const { taxAmount, tipAmount, subTotalAmount, totalAmount } = InvoiceUtil.formatPayNowInvoiceTotal(orderDetails);
      
      const billTo = orderDetails.customer_id ? `${t('PayInvoiceForm.BillTo')} ${FormatTextUtil.formatName(orderDetails.first_name, orderDetails.last_name, 'Unnamed Customer')}` : '';

      const orderSummary = (<div className='orderSummaryContainer'>
        <div className='orderSummaryItemsContainer'>

          <div className='orderSummaryHeaderContainer'>
            <div className='orderSummaryHeader'>{orderDetails.business_name || ''}</div>
            <div className='orderSummaryDetails'>
              <div className='summaryDetailsLine billTo'>{billTo}</div>
              <div className='summaryDetailsLine'>{`${t('Invoice')} #${orderDetails.invoice}`}</div>
              <div className='summaryDetailsLine'>{moment.utc(orderDetails.receipt_date).format('MMMM DD, YYYY')}</div>


            </div>
          </div>

          <div className='orderSummaryItems'>
            {orderRows}
          </div>
        </div>
        <div className='orderSummaryTotalsContainer'>
          {cartDiscounts}
          <div className='orderRow subtotal'>
            <div className='orderRowInfo'>{'Subtotal'}</div>
            <div
              className='orderRowValue'>{numeral(subTotalAmount).format('$0,0.00')}</div>
          </div>
          <div className='orderRow tax'>
            <div className='orderRowInfo'>{`${t('Tax')}`}</div>
            <div
              className='orderRowValue'>{numeral(taxAmount).format('$0,0.00')}</div>
          </div>

          {!!tipAmount && (
            <div className='orderRow tip'>
              <div className='orderRowInfo'>{`${t('Tip')}`}</div>
              <div
                className='orderRowValue'>{numeral(tipAmount).format('$0,0.00')}</div>
            </div>
          )}

          <div className='orderRow total'>
            <div
              className='orderRowInfo infoBold'>{this.state.paymentCompleted ? 'Total' : 'Total'}</div>
            <div
              className='orderRowValue infoBold'>{numeral(totalAmount).format('$0,0.00')}</div>
          </div>
        </div>
      </div>);

      const orderSummaryResponsiveDialog = (<MessageDialog
          open={this.props.userExperience.viewOrderSummary}
          bodyText={orderSummary}
          onRequestClose={this.hideOrderSummary}
          confirmText={`${t('Close')}`}
          onConfirm={this.hideOrderSummary}
          isChoiceRequired={false}
          titleText={`${t('PayInvoiceForm.OrderSummary')}`}
          externalClassName={'orderSummaryDialog'}/>
      );

      const archivedInvoiceDialog = (<MessageDialog
          open={!orderDetails.is_active}
          bodyText={`${t('PayInvoiceForm.InvoiceCancelled')}`}
          confirmText={''}
          onConfirm={null}
          isChoiceRequired={true}
          titleText={`${t('PayInvoiceForm.CancelledInvoice')}`}
          externalClassName={'archivedInvoiceDialog'}
          hideCloseIcon={true}
          hideConfirmButton={true}/>
      );

      const paymentLockedMessage = (
        <div className='declinedWrapper'>
          {IconUtil.getIcon('Error', LabelUtil.getLabelColor())}
          <br/>
          <div>
            <h1>{`${t('PayInvoiceForm.TooManyAttempts')}`}</h1>
            <span>{`${t('PayInvoiceForm.PaymentNotProcessed')} ${this.props.invoices && this.props.invoices.invoiceForPayment.business_name ? this.props.invoices.invoiceForPayment.business_name : '[DBA]'} ${t('PayInvoiceForm.DirectlyInformation')}`}</span>
          </div>
        </div>
      );

      const paymentLockedDialog = (
        <Modal
          open={this.state.paymentLocked}
          confirmText='Okay'
          onConfirm={this.handleCloseLockedDialog}
          hideCancelButton
        >
          {paymentLockedMessage}
        </Modal>
      );

      return (
        <div className='payInvoiceContainer' style={{overflowY: 'auto'}}>

          <div className='content'>

            <div className='mainContent'>
              <div className='payInvoiceFormContainer'>

                <MerchantLogo
                  logoBase64={orderDetails.business_logo}
                  size={100}
                  additionalClass='invoiceLogo'
                  altText={orderDetails.business_name}
                />

                <div
                  className='errorText invoicePaymentError'>{this.state.paymentError ? this.state.paymentError : ''}</div>

                <PayInvoiceForm
                  {...this.props}
                  className={'payInvoiceForm'}
                  paymentCompleted={this.state.paymentCompleted}
                  onSubmit={this.process}
                  handleSubmitApplePay={this.processApplePay}
                />

                {!this.state.paymentCompleted ? payNowButton : null}

              </div>

              <div className='invoiceFooter'>
                <div
                  className='address'>{`${orderDetails.business_address} | ${orderDetails.business_city}, ${orderDetails.business_state} ${orderDetails.business_zip} | ${orderDetails.business_phone} ${orderDetails.business_website ? '| ' + orderDetails.business_website : ''}`}</div>
                <img src={labelLogoSource + '/poweredBy.png'}
                     className='poweredBy'/>
              </div>

            </div>

            {orderSummary}
            {orderSummaryResponsiveDialog}
            {archivedInvoiceDialog}
          </div>
          {this.state.loading ? (<UpdateSpinner/>) : null}
          {paymentLockedDialog}
        </div>
      )
    }
  }
}
