/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import TextField from '../shared/TextField';
import FormatTextUtil from '../util/FormatTextUtil';
import numeral from 'numeral';
import messages from '../../constants/messages';
import LabelUtil from '../util/LabelUtil';
import CustomCheckbox from '../shared/Checkbox';
import UserUtil from '../util/UserUtil';
import { Trans } from 'react-i18next';

export class RoleSettingsComponent extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {handleSubmit, user, isManagerRole, isCashierRole, auth, readOnly, t} = this.props;
    const label = LabelUtil.getLabel();
    const labelText = label.text;

    const roleSettings = isCashierRole ? (
      <div className='cashierRoleSettings'>
        <div className='detailsHeader'>{t('RoleSettingsDetailHeader')}</div>
        <div className='detailsCheckBox'>
          <Field
            component={CustomCheckbox}
            disabled={auth.isSpr}
            label={t('RoleSettingsFields.RefundVoidEnabledFieldLabel')}
            name='refundVoidEnabledField'
            disabled={readOnly} />
        </div>
        <div className='detailsRow refundVoidLimitField'>
          <Field
            label={t('RoleSettingsFields.RefundVoidEnabledFieldLabel')}
            format={(value) => (this.props.voidRefundEnabled) ? numeral(value).format('$0,0.00') : numeral(0).format('$0,0.00')}
            normalize={(value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 10)}
            component={TextField}
            name='refundVoidLimitField'
            disabled={!this.props.voidRefundEnabled || auth.isSpr || readOnly}
          />
          <div className='infoText'>
            {t('RoleSettingsFields.RefundVoidLimitFieldInfoText')}
          </div>
        </div>
      </div>
    ) : isManagerRole ? (
      <div className='managerRoleSettings'>
        <div className='detailsHeader'>{t('RoleSettingsDetailHeaderManager')}</div>
        <div className='detailsCheckBox roleSettingCheckbox'>
          <Field
            component={CustomCheckbox}
            disabled={auth.isSpr || readOnly}
            label={t(messages.employee.manager.options.activity.title)}
            name='managerActivityAccess'/>
          <div
            className='infoText checkboxMargin roleManagerActivityDescription'>
              <Trans i18nKey={messages.employee.manager.options.activity.description}>
                Allow Managers to access Sales, Deposits, Reports, and Statements in {{labelText}} and view Sales Trends in the Payanywhere app.
              </Trans>
          </div>
        </div>
        <div className='detailsCheckBox roleSettingCheckbox'>
          <Field
            component={CustomCheckbox}
            disabled={auth.isSpr || readOnly}
            label={t(messages.employee.manager.options.onlinePayments.title)}
            name='managerOnlinePaymentAccess'/>
          <div
            className='infoText checkboxMargin roleManagerVTDescription'>
              <Trans i18nKey={messages.employee.manager.options.onlinePayments.description}>
                Allow Managers to run credit card transactions using the virtual terminal in {{labelText}}.
              </Trans>
          </div>
        </div>
        <div className='detailsCheckBox roleSettingCheckbox'>
          <Field
            disabled={auth.isSpr || readOnly}
            component={CustomCheckbox}
            label={t(messages.employee.manager.options.invoiceActions.title)}
            name='managerInvoiceAccess'/>
          <div
            className='infoText checkboxMargin roleManagerInvoiceDescription'>
              <Trans i18nKey={messages.employee.manager.options.invoiceActions.description}>
                Allow Managers to create and manage invoices in {{labelText}} and in the Payanywhere app.
              </Trans>
          </div>
        </div>
        <div className='detailsCheckBox roleSettingCheckbox'>
          <Field
            disabled={auth.isSpr || readOnly}
            component={CustomCheckbox}
            label={t(messages.employee.manager.options.paymentLinkActions.title)}
            name='managerPaymentLinkAccess'/>
          <div
            className='infoText checkboxMargin roleManagerPaymentLinkDescription'>
            <Trans i18nKey={messages.employee.manager.options.paymentLinkActions.description}>
              Allow Managers to create and manage payment links in {{labelText}}.
            </Trans>
          </div>
        </div>
        {!UserUtil.isHbt(user) && <div className='detailsCheckBox roleSettingCheckbox'>
          <Field
            disabled={auth.isSpr || readOnly}
            component={CustomCheckbox}
            label={t(messages.employee.manager.options.reputationActions.title)}
            name='managerReputationAccess'/>
          <div
            className='infoText checkboxMargin roleManagerInvoiceDescription'>
              <Trans i18nKey={messages.employee.manager.options.reputationActions.description}>
                Allow Managers to view customer reviews and draft responses that will be sent to your customers.
              </Trans>
          </div>
        </div>}
      </div>) : null;

    return (
      <form onSubmit={handleSubmit}>
        <div className='roleSettings'>
          {roleSettings}
        </div>
      </form>)
  }

}

let RoleSettings = reduxForm({
  form: 'roleSettingsForm',
  fields: [
    'refundVoidEnabledField',
    'refundVoidLimitField',
    'managerActivityAccess',
    'managerOnlinePaymentAccess',
    'managerInvoiceAccess',
    'managerPaymentLinkAccess',
    'managerReputationAccess',
  ],
  enableReinitialize: true,
})(RoleSettingsComponent);

const selector = formValueSelector('roleSettingsForm');

function mapStateToProps(state) {

  const merchantSettings = state.employees?.merchantSettings ?? {};

  const initialValues = {
    refundVoidEnabledField: merchantSettings.cashier_refund_void_enabled ?? false,
    refundVoidLimitField: merchantSettings.cashier_refund_void_limit ?? 0,
    managerActivityAccess: merchantSettings.manager_activity_access_enabled ?? false,
    managerOnlinePaymentAccess: merchantSettings.manager_online_payments_access_enabled ?? false,
    managerInvoiceAccess: merchantSettings.manager_can_access_invoices ?? false,
    managerPaymentLinkAccess: merchantSettings.manager_can_access_payment_links ?? false,
    managerReputationAccess: merchantSettings.manager_can_access_reputation_mgmt ?? false
  };

  const voidRefundEnabled = selector(state, 'refundVoidEnabledField');

  return {
    initialValues,
    voidRefundEnabled
  }
}

RoleSettings = connect(mapStateToProps)(RoleSettings);

export default RoleSettings;
