/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import {
  Box, Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link
} from '@mui/material'
import { FaqStyles } from '../../jss/inlineStyles';
import { CustomExpandIcon } from '../../components/shared/accordion/CustomExpandIcon';

const DisputesFaq = ({ contactEmail, contactText }) => {
  const SAQList = [
    'Install and maintain a firewall configuration to protect cardholder data',
    'Do not use vendor-supplied defaults for system passwords and other security parameters',
    'Protect stored cardholder data',
    'Encrypt transmission of cardholder data across open, public networks',
    'Protect all systems against malware and regularly update anti-virus software programs',
    'Develop and maintain secure systems and applications',
    'Restrict access to cardholder data by business need to know',
    'Identify and authenticate access to system components',
    'Restrict physical access to cardholder data',
    'Track and monitor all access to network resources and cardholder data',
    'Regularly test security systems and processes',
    'Maintain a policy that addresses information security for all personnel'
  ];

  return (
    <Box sx={{ ...FaqStyles }} className='pciFaq'>
      <Accordion>
        <AccordionSummary
            aria-controls='panel1a-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel1a-header'
        >
          <Typography marginY={0}>
            What is PCI DSS?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <Typography fontSize={13}>The Payment Card Industry Data Security
            Standards (PCI DSS) is a
            global security standard for all entities that process, store, or
            transmit cardholder data. These standards are applicable to all
            merchants regardless of size or volume and helps reduce fraud and
            protect the payment card ecosystem.</Typography>
          <Typography fontSize={13}>The goal of the PCI Data Security Standard
            (PCI DSS) is to
            protect cardholder data and sensitive authentication data wherever
            it is processed, stored, or transmitted. The security controls and
            processes required by PCI DSS are vital for protecting all payment
            card data.</Typography>
          <Typography fontSize={13}>Compliance with PCI DSS is enforced by the
            major payment card
            brands: American Express, Discover Financial Services, JCB
            International, Mastercard Worldwide and Visa Inc.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel2-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel2-header'
        >
          <Typography marginY={0}>
            What is an SAQ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <Typography fontSize={13}>The Self-Assessment Questionnaire (SAQ) is a
            validation tool for
            merchants and service providers targeted for your business
            specific environment. It is comprised of 12 requirements, covering
            both technical and non-technical security controls. Here are the
            PCI DSS requirements at a glance:</Typography>
          <List sx={{ listStyle: 'decimal', paddingLeft: 4, fontSize: 13 }}>
            {SAQList.map((item, index) => (
              <ListItem
                  key={index}
                  sx={{ display: 'list-item', paddingY: 0 }}
              >
                <ListItemText
                    primary={item}
                    primaryTypographyProps={{ fontSize: 13 }}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel3-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel3-header'
        >
          <Typography marginY={0}>
            How to Assess PCI DSS Security:
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <p><b><i>Review the PCI DSS Requirements:</i></b> Learn what the PCI
            standard requires of your business. </p>
          <p><b><i>Inventory IT Assets and Processes:</i></b> Identify all
            systems, personnel and processes involved in the transmission,
            processing or storing of cardholder data.</p>
          <p><b><i>Find Vulnerabilities:</i></b> Use the appropriate SAQ to
            guide the assessment, and appropriate technologies to locate
            insecure systems.</p>
          <p><b><i>Validate with Third-Party Experts:</i></b> Your
            environment's complexity may require a Qualified Security Assessor
            and/or Approved Scanning Vendor.</p>
          <p><i><a
              href='https://www.pcisecuritystandards.org/assessors_and_solutions/qualified_security_assessors'
              target='_blank'
              className='linkLike'
                >Qualified Security Assessors (QSAs)</a></i></p>
          <p><i><a
              href='https://www.pcisecuritystandards.org/assessors_and_solutions/approved_scanning_vendors'
              target='_blank'
              className='linkLike'
                >Approved Scanning Vendors (ASVs)</a></i></p>
          <p>Achieving and maintaining a secure and robust compliance
            environment includes the following:</p>
          <ul>
            <li><b>Assessing:</b> Test and verify
              controls in place to protect cardholder data during storage,
              processing, and transmission. Systems and data must be available
              for analysis.
            </li>
            <li><b>Reporting:</b> Validate compliance and present evidence
              that data protection controls are in place.
            </li>
            <li><b>Monitoring & Alerting:</b> Implement systems that provide
              auto alerting to
              constantly monitor access and usage of data. Extend system
              controls to include collection and storage of log data.
            </li>
          </ul>
          <p>To ensure
            security controls continue to be properly implemented, PCI DSS
            should be implemented into business-as-usual (BAU) activities as
            part of an entity’s overall security strategy. This enables an
            entity to monitor the effectiveness of its security controls on an
            ongoing basis, and maintain its PCI DSS compliant environment in
            between PCI DSS assessments.
          </p>
          <table>
            <tbody>
            <tr>
              <th>Level</th>
              <th>Criteria</th>
              <th>Requirements</th>
            </tr>
            <tr>
              <td>Level 1</td>
              <td>Merchants processing over 6 million transactions annually
                across all channels.
              </td>
              <td>
                <p>Annual:</p>
                <ul>
                  <li>Report on Compliance (ROC) by a Qualified Security
                    Assessor (QSA)
                  </li>
                  <li>Submit an Attestation of Compliance (AOC)</li>
                </ul>
                <p>Quarterly:</p>
                <ul>
                  <li>Network scan by an Approved Scan Vendor (ASV)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Level 2</td>
              <td>1 to 6 million transactions annually across all channels
              </td>
              <td>
                <p>Annual:</p>
                <ul>
                  <li>Self-Assessment Questionnaire (SAQ) completed by a
                    certified Internal Security Assessor (ISA) or engage a QSA
                    for an Onsite Audit
                  </li>
                  <li>Submit an Attestation of Compliance (AOC)</li>
                </ul>
                <p>Quarterly:</p>
                <ul>
                  <li>Network scan by an Approved Scan Vendor (ASV)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Level 3</td>
              <td>20,000 to 1 million e-commerce transactions annually</td>
              <td>
                <p>Annual:</p>
                <ul>
                  <li>Report on Compliance (ROC) by a Qualified Security
                    Assessor (QSA)
                  </li>
                  <li>Submit an Attestation of Compliance (AOC)</li>
                </ul>
                <p>Quarterly:</p>
                <ul>
                  <li>Quarterly network scan by an Approved Scan Vendor (
                    ASV)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Level 4</td>
              <td>Merchants processing less than 20,000 ecommerce transactions
                annually and all other merchants processing up to 1 million
                transactions annually.
              </td>
              <td>
                <p>Annual:</p>
                <ul>
                  <li>Report on Compliance (ROC) by a Qualified Security
                    Assessor (QSA)
                  </li>
                  <li>Submit an Attestation of Compliance (AOC)</li>
                </ul>
                <p>Quarterly:</p>
                <ul>
                  <li>Quarterly network scan by an Approved Scan Vendor ( ASV)
                    (if applicable)
                  </li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel4-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel4-header'
        >
          <Typography marginY={0}>
            Consequences of Non-Compliance
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <List sx={{ listStyle: 'disc', paddingLeft: 4, fontSize: 13 }}>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary='Account closure and/or loss of privilege to offer payment card'
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary='Brand/Market Reputational Damage'
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary='Card Brand Assessments'
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary='Account Data Breach'
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel5-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel5-header'
        >
          <Typography marginY={0}>
            What To Do When Breached
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
          <Typography fontSize={13}>Breaches happen every day, data is
            compromised around the clock,
            all over the world, so it is important to know what to do about
            it. You should understand that there is a potential for negative
            fallout in any breach where sensitive customer information is
            stolen. This negative fallout can be at the very least financial,
            reputational, or any combination of the three. </Typography>
          <Typography fontSize={13}>Regardless of the size of your business, the
            amount of data that
            is compromised <b>can be small and manageable</b> just as easily
            as it
            can be massive and catastrophic. In fact, what many business
            owners don’t realize is that 90% of data breaches involving
            payment information happen with small businesses.</Typography>
          <Typography fontSize={13}>Large company breaches that haunt the news
            do not happen nearly
            as often as breaches of small and medium sized businesses who have
            fewer resources available to defend themselves with.</Typography>
          <Typography fontSize={13}>Please contact us immediately regarding the
            breach forgiveness
            program if you believe you have experienced a data compromise and
            take the following actions:</Typography>
          <List sx={{ listStyle: 'disc', paddingLeft: 4, fontSize: 13 }}>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText>
                <Typography>
                  <Typography
                      component='span'
                      fontSize={13}
                      id='contactText'
                  >{contactText}</Typography>
                  <Link
                      fontSize={13}
                      href={`mailto:${contactEmail}`}
                      id='contactEmail'
                  >{` ${contactEmail} `}</Link>
                  <Typography
                      component='span'
                      fontSize={13}
                  >and include the information below:</Typography>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary='All MIDs you believe could be impacted'
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary='The month and year during which you believe the incident occurred'
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingY: 0 }}>
              <ListItemText
                  primary='Name, contact information, and related correspondence from any person or entity that informed you of the potential incident'
                  primaryTypographyProps={{ fontSize: 13 }}
              />
            </ListItem>
          </List>
          <p><i>What happens next?</i></p>
          <p>A member of the PCI Team will contact you and review with you the
            program criteria and exclusions, and the incident reported to
            confirm eligibility for the forgiveness program. Once eligibility
            is confirmed, your PCI Team representative will coordinate with
            you responses and actions needed, including:</p>
          <ul>
            <li>Completion with you of reporting requirements such as
              information that needs to be reported to the card brands.
            </li>
            <li>Engagement of forensics specialists to conduct forensics</li>
            <li>Coordination of re-certification if required</li>
          </ul>
          <p>Remember that we will cover the costs of the forensics and other
            expenses that are covered under the benefits of the program and
            will not seek reimbursement from you as long as you remain
            eligible.</p>
          <p><i>Criteria</i></p>
          <p>The breach forgiveness program provides the benefits listed below
            for merchants based on meeting the criteria identified, as long as
            no exclusions apply. Enrollment in the program is renewed each
            month based on merchant qualifications to continue in the
            program.</p>
          <ul>
            <li>Must be a Level 3 or Level 4 merchant, or level 2 if certain
              criteria is met*
            </li>
            <li><b>Cannot have a previous card brand fine or penalty</b> (PCI
              Assessment) unless you have been reinstated based on completion
              of PCI Certification (e.g. Self-Assessment Questionnaire)
            </li>
            <li>Not suffered a <b>prior breach</b> without PCI recertification
            </li>
            <li><b>Incident occurs, is discovered, and is reported</b> during
              the breach forgiveness program period (All expenses arising from
              the same incident or chain of related incidents at a merchant
              shall be considered a single incident). Note that the breach
              forgiveness program period renews each month based on continuing
              to meet criteria and as long as no exclusion applies.
            </li>
          </ul>
          <p><i>Exclusions</i></p>
          <ul>
            <li>Any incident that occurred prior to December 1, 2019</li>
            <li>Any incident known or discovered prior to December 1, 2019
            </li>
            <li>An incident discovered before the effective date of your
              merchant agreement
            </li>
            <li>Any incident relating to a failure of security that is
              specifically known and that existed before the breach
              forgiveness program was provided to you.
            </li>
            <li>Any costs or expenses incurred or required for a merchant to
              become PCI compliant in the first instance prior to the
              occurrence of an incident.
            </li>
            <li>Any incident where you have experienced a prior incident
              unless your business was recertified to an eligible PCI
              Compliance Level by a Qualified Security Assessor prior to the
              current incident
            </li>
            <li>Any software not within your control</li>
            <li>Any incident that occurs in any computer system in which
              multiple merchants with no legal relationship to one another
              have hosted accounts or share a common database, operating
              system, or software applications
            </li>
            <li>Any incident without <br/>
              <ol>
                <li>a formal written notification by the card network to
                  either the acquiring bank, ISO, or merchant of an incident,
                </li>
                <li>a contractually enforceable demand by the card network for
                  expense reimbursement due to an incident (does not apply to
                  hardware or software upgrades required to avoid a PCI
                  assessment)
                </li>
              </ol>
            </li>
          </ul>
          <p><i>Additional Reference Information:</i></p>
          <p><a
              href='https://usa.visa.com/dam/VCOM/download/merchants/cisp-what-to-do-if-compromised.pdf'
              target='_blank'
              className='linkLike'
             >Visa
            Supplement - What To Do If Compromised</a></p>
          <p><a
              href='https://www.pcisecuritystandards.org/assessors_and_solutions/pci_forensic_investigators'
              target='_blank'
              className='linkLike'
             >PCI
            Forensic Investigators (PFIs)</a></p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
            aria-controls='panel6-content'
            expandIcon={<CustomExpandIcon/>}
            id='panel6-header'
        >
          <Typography marginY={0}>
            What Do We Do to Help Merchants?
          </Typography>
        </AccordionSummary>
        <AccordionDetails
            className='content'
        >
        <Typography fontSize={13}>
          We understand that PCI DSS can be a challenging process. Our goal
          is to simplify this for you by making compliance easier for any
          merchants who use our proprietary credit card processing
          solutions. We will use a combination of technology and compliance
          team expertise to determine PCI requirements and utilize data
          analytics to provide greater support in identifying, achieving,
          and maintaining PCI compliance. In addition, we offer exciting
          benefits such as no non-compliance fees, breach forgiveness for
          qualified merchants, and included vulnerability scans if necessary
          - up to 3 IP addresses!
        </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DisputesFaq;

