
const styles = (theme) => ({

  '.phoneswipe': {
    fontFamily: theme.typography.primaryFontFamily,

    '& .customTooltip': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
      '&.place-right': {
        '&:after': {
          borderRightColor: `${theme.palette.labelColor.main} !important`,
          borderRightStyle: 'solid !important',
          borderRightWidth: '6px !important',
        },
      },
    },
    '& header': {
      '& .navSelected': {
        color: theme.palette.labelColor.main,
      },
      '& nav': {
        '& a:hover': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '& .linkButton:hover': {
      color: `${theme.palette.labelColor.main} !important`,
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .pageWrap, & .pageWrapWithNotice': {
      '& .detail': {
        '& .bar': {
          '& .barButton': {
            '& span': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
    },
    '& .inventoryItems': {
      '& .activeTab': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .inactiveTab': {
        color: `${theme.palette.common.regularColor} !important`,
      },
    },
    '& div.stock .stockAlert svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .subMenuContainer': {
      '& .active': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .subMenuItem.active': {
        color: `${theme.palette.labelColor.main} !important`,
        '& svg': {
          fill: theme.palette.labelColor.main,
        },
      },
      '& .subMenuIconContainer.active': {
        color: theme.palette.labelColor.main,
      },
    },
    '& .searchBar': {
      '& > div': {
        '& .addButton': {
          backgroundColor: theme.palette.labelColor.main,
        },
      },
    },
    '& .material-icons.active svg': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .portalCheckbox.disabledCheckbox': {
      '& svg + svg': {
        fill: `${theme.palette.labelColor.main} !important`,
        stroke: 'none !important',
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .portalCheckbox': {
      '& svg + svg': {
        fill: `${theme.palette.labelColor.main} !important`,
        stroke: 'none !important',
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .addButton': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
    },
    '& .login': {
      '& .loginContainer': {
        '& .signUpContainer, & .forgotPasswordContainer, & .requestTokenContainer': {
          '& a': {
            color: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '& .activitySales .salesSummaryRow .summaryPanel .summaryPanelHeader .summaryPanelCount': {
      backgroundColor: theme.palette.labelColor.main,
    },
    '& .updatePassword, & .updateEmail, & .updateTwoFactor, & .showHide, & .linkLike, & .relatedTransactions, & .membershipButton, & .editButton': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .merchantAccountRow.active': {
      color: `${theme.palette.labelColor.main} !important`,
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .accountMenuIcon svg': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .exportContainer .downloadLink': {
      ...theme.sharedStyles.link(theme),
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .formLinks, & .taxLinks, & .secondaryText, & .equipmentLinks': {
      '& a': {
        ...theme.sharedStyles.link(theme),
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .inventoryItems .tab': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .mobileNavigationContainer .mobileNavigationItem.active a': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .mobileContainer, & .testDriveContainer .titlePanel .titlePanelSubText, & .titlePanelDetailsText': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .mobileFooterToggle .active': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .reportsToolbar .backButton, & .mobileExportButton': {
      '&:hover': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .loginButton, & .submitButton .loginFront, & .submitFront': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
    },
    '& .loginArrow, & .submitArrow': {
      backgroundColor: `${theme.palette.common.phoneSwipeColorAlternativeColor} !important`,
    },
    '& .niceLink a': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .closedAccountContainer .closedAccountIndicator, & .closedAccountText': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .closedAccountIcon svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .isLockedIcon': {
      fill: `${theme.palette.labelColor.main} !important`,
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .resendInviteContainer .resendIconContainer svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .buttonInverse': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .businessOnlinePayments .formHeading .action': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .allAccountsContainer .allAccountsCheckbox.selected label': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .customerRatingContainer .footer': {
      background: `${theme.palette.labelColor.main} !important`,
      '& .branding': {
        visibility: 'hidden',
      },
    },
    '& .today .gridContainer .grid .col': {
      '&Tips': {
        backgroundImage: 'url(\'/images/phoneswipe/today_tips.png\')',
      },
      '& .deposits': {
        backgroundColor: theme.palette.labelColor.main,
      },
      '& .trends .trendsRow': {
        height: '115px',
        '& .statisticsContainer .stars svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .today .todayLink': {
      backgroundColor: theme.palette.labelColor.main,
      '& .iconLink': {
        backgroundColor: theme.palette.common.phoneSwipeColorAlternativeColor,
      },
    },
    '& .mobileNavigationItem .active': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .viewTransactionButton, & .resendInvoiceButton, & .viewOrderSummaryButton, & .markInvoicePaidButton, & .invoiceDetail .barButton, & .viewSeriesButton, & .seeTransactionsButton': {
      '& span': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .clearButton': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .buttonWrapper .buttonColor svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .openTransactionsHeader, & .incompleteTransactionsFilter .filterIconContainer svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .detail .bar': {
      '& svg:hover': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
      '& .barButton span': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .contactFooter p': {
      color: theme.palette.labelColor.main,
      '& a': {
        color: theme.palette.labelColor.main,
      },
    },
    '& .contactFooter .divider': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .addMerchantAccount': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .addAccountDialog': {
      '& .activeField label': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .errorField.activeField label': {
        color: `${theme.palette.error.main} !important`,
      },
      '& hr:first-of-type, & .activeField hr:first-of-type': {
        borderBottom: `1px solid ${theme.palette.labelColor.main} !important`,
      },
      '& hr + hr, & .activeField hr + hr': {
        borderBottom: `2px solid ${theme.palette.labelColor.main} !important`,
      },
      '& .errorField hr:first-of-type': {
        borderBottom: `1px solid ${theme.palette.error.main} !important`,
      },
      '& .errorField hr + hr': {
        borderBottom: `2px solid ${theme.palette.error.main} !important`,
      },
    },
    '& .cornerCheckMark .st0': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .integrationsPage': {
      '& .returnToIntegrations svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
      '& .imageGallery': {
        '& .image-gallery-left-nav:hover::before, & .image-gallery-right-nav:hover::before': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
  },
  '.mobileDevice': {
    '._elevio_launcher': {
      div: {
        display: 'none !important', // hides elevio needs help button
      },
    },
  },

});


export default styles;
