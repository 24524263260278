/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { useCallback, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import {
  addHardwareDeviceCardContainerStyles,
  addHardwareDeviceCardStyles, productDetailsStyles
} from '../../jss/activationStyles';
import classnames from 'classnames';
import IconUtil from '../util/IconUtil';
import IncreaseDecreaseButton from '../shared/IncreaseDecreaseButton';
import ReverseButton from '../shared/ReverseButton';

const DeviceCard = (props) => {
  const maxDevices = 5;
  const { product, t, updateItemQuantity, totalDevices } = props;
  const [currentQuantity, setCurrentQuantity] = useState(product?.quantity ?? 0);

  const handleOnClickIncreaseDecreaseButton = useCallback((quantity) => {
    updateItemQuantity(product, quantity);
    setCurrentQuantity(quantity);
  }, [updateItemQuantity, product]);

  const remove = (product) => {
    updateItemQuantity(product, 0);
    setCurrentQuantity(0);
  };
  return (
    <Box
      sx={addHardwareDeviceCardContainerStyles}
      className={classnames('deviceCard', { selected: product.selected })}
    >
      <Card
        sx={addHardwareDeviceCardStyles}
      >
        <CardMedia image={product.image} alt={product.title} className='deviceCardMedia' />
        <CardContent
          className={classnames('deviceCardContent', { hasError: (currentQuantity === maxDevices) })}
        >
          <Box className='deviceCardContentHeader'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={0.5}
            >
              <Typography
                variant='h4'
                className='title'
              >
                {t(product.title)}
              </Typography>
              <Typography
                className='price'
              >
                {t(product.price)}
              </Typography>
            </Stack>
            { product?.fee && (
              <Stack direction='row' justifyContent='flex-end' alignItems='center'>
                <Typography
                  className='fee'
                >
                  {t(product.fee)}
                </Typography>
              </Stack>
            )}
          </Box>
          <Box sx={productDetailsStyles}>
            <List className='productDetails'>
              {product.details.map(detail => (
                <ListItem
                  key={detail}
                  className='productDetail'
                >
                  <ListItemIcon
                    className='productIcon'
                  >
                    {IconUtil.getIcon('CheckIcon', 'black')}
                  </ListItemIcon>
                  <ListItemText className='productDetailText'>{t(detail)}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </CardContent>
        <CardActions className='deviceCardActions'>
          <IncreaseDecreaseButton
            t={t}
            maxCount={maxDevices}
            errorMessage={t('Activation.MaxDevices', { maxDevices: maxDevices })}
            currentQuantity={currentQuantity}
            handleOnClick={handleOnClickIncreaseDecreaseButton}
            totalDevices={totalDevices}
          >
            { product.quantity > 0 && (
              <ReverseButton
                variant='outline'
                label={t('Remove')}
                className='removeHardware'
                onClick={() => remove(product)}
              />
            )}
          </IncreaseDecreaseButton>
        </CardActions>
      </Card>
    </Box>
  );
};

export default DeviceCard;
