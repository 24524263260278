/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import LabelUtil from './../util/LabelUtil';
import UserUtil from './UserUtil';
import {printReceipt, sendReceipt} from '../../actions/transactionsActions';
import emailValidator from 'email-validator';
import Validator from '../util/Validator';
import _ from 'lodash';

const ReceiptUtil = {

  base64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  },

  getPrintReceipt(props, selectedReceipt) {

    let that = this;
    const errorMessage = 'Receipt could not be generated.';

    return new Promise((resolve, reject) => {

      let label = LabelUtil.getLabel();

      let fileName = 'SelectedReceipt.pdf';

      if (!selectedReceipt.id || !selectedReceipt.type) {
        reject(errorMessage);
      } else {
        return props.dispatch(printReceipt(selectedReceipt, label.brand, props.user)).then(async (resp) => {

          if (!(resp && resp.response && resp.response.receipt && resp.response.receipt.pdf)) {
            reject(errorMessage);
          } else {
            try {
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // IE Need to create blob and save using msSaveOrOpenBlob
                let dataBlob = that.base64toBlob(resp.response.receipt.pdf, 'application/pdf');
                window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
              } else {
                let tempLink= document.createElement('a');
                let url = 'data:application/pdf;base64, ' + encodeURI(resp.response.receipt.pdf);
                let clickEvent = new MouseEvent('click', {
                  'view': window,
                  'bubbles': true,
                  'cancelable': false
                });
                tempLink.href = url;
                tempLink.download = fileName;
                document.body.appendChild(tempLink);
                tempLink.dispatchEvent(clickEvent);

                await new Promise(resolveTimeout =>
                  setTimeout(() => {
                    document?.body?.removeChild(tempLink);
                    resolveTimeout();
                  }, 1000)
                );

              }

              resolve();
            } catch {
              reject(errorMessage);
            }
          }

        });
      }

    });

  },

  sendReceipt(props, selectedReceipt, emails, phoneNumber, shouldSave = false) {
    return new Promise(function (resolve, reject) {

      if(!selectedReceipt) {
        reject('No receipt selected');
        return;
      }

      const label = LabelUtil.getLabel();

      let validEmails = _.filter(emails, contact => emailValidator.validate(contact));
      let validPhoneNumbers = [];

      if (Array.isArray(phoneNumber)) {
        validPhoneNumbers = phoneNumber.filter(Validator.isPhoneNumber);
      } else {
        const isValidPhone = Validator.isPhoneNumber(phoneNumber);
        validPhoneNumbers = isValidPhone ? [phoneNumber] : [];
      }

      const validMobileNumber = validPhoneNumbers.length > 0;

      if (!!validMobileNumber && UserUtil.isSmsEnabled(props.user)) {
        const isEmail = validEmails.length > 0;
        validEmails = isEmail ? validEmails : [];
        props.dispatch(sendReceipt(selectedReceipt, validEmails, validPhoneNumbers, label.brand, props.user, isEmail));
      } else if (validEmails.length > 0) {
        props.dispatch(sendReceipt(selectedReceipt, validEmails, null, label.brand, props.user, true));
      }
    
      resolve();

    });
  }

};

export default ReceiptUtil;
