/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import Button from '../../shared/Button';
import ReverseButton from '../../shared/ReverseButton';
import UpdateSpinner from '../../UpdateSpinner';
import UserUtil from '../../util/UserUtil';
import LabelUtil  from '../../util/LabelUtil';
import planTypes from '../../../constants/planTypes';
import {
  text,
  buttonText,
  prices,
  processUpgradeText,
  processDowngradeText,
  successDowngradeText,
  successUpgradeText,
  processUpgradeLoyaltyText,
  processDowngradeLoyaltyText} from '../../../constants/planInformation';
import {tierUpgrade} from '../../../actions/userActions';
import {getUserProfile, updateUser} from '../../../actions/userProfileActions';
import {togglePlanListDialog} from '../../../actions/userExperienceActions';
import MessageDialog from '../../shared/MessageDialog';
import actionTypes from '../../../constants/actionTypes';
import IconUtil from '../../util/IconUtil';
import Modal from '../../shared/Modal';
import PlanDetails from './PlanDetails';
import {setModalVisibility} from '../../../actions/userExperienceActions';
import { getMerchantSettings } from '../../../actions/merchantSettingsActions';

export default class PlanCard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      successModal: false,
      successText: '',
      processModal: false,
      processText: '',
      oldPlan: '',
      errorModal: false,
      loyalty: false,
      details: null
    }
  }

  componentDidMount() {
    const {user} = this.props;
    const accountCurrentPlan = UserUtil.accountCurrentPlan(user);
    this.setState({loyalty: accountCurrentPlan === planTypes.premiumPlusLoyalty});
  }

  handleCloseProcessDialog = () => {
    this.setState({processModal: false})
  }

  handleCloseErrorDialog = () => {
    this.setState({errorModal: false})
  }

  handleCloseSuccessDialog = async () => {
    const {dispatch, user} = this.props;
    await dispatch(togglePlanListDialog(false));
    this.setState({successModal: false});
    await dispatch(getUserProfile(user));
    dispatch(updateUser());
    await dispatch(getMerchantSettings(user));
  }
  
  openInfoModal = () => {
    const {planType} = this.props;
    const modalType = {
      [planTypes.free]: 'planDetailsFreeDialog',
      [planTypes.premium]: 'planDetailsPremiumDialog',
      [planTypes.premiumPlus]: 'planDetailsPremiumPlusDialog',
    }
    const modal = modalType[planType];
    this.setState({details: modal});
    this.props.dispatch(setModalVisibility(modal));
  }
  
  closeInfoModal = () => {
    this.props.dispatch(setModalVisibility('hidden'));
  }

  showPlanModal = () => {
    const {loyalty} = this.state;
    const {planType, user} = this.props;
    const accountCurrentPlan = UserUtil.accountCurrentPlan(user);
    const planToUse = loyalty ? planTypes.premiumPlusLoyalty : planType;
    const isUpgradingPlan = UserUtil.isPlanUpgrade(user, planToUse);
    const premiumPlusLoyaltyDowngrade = (accountCurrentPlan === planTypes.premiumPlusLoyalty && planType === planTypes.premiumPlus);
    const planUpgrade = [planTypes.free, planTypes.premium].includes(accountCurrentPlan) && [planTypes.premium,planTypes.premiumPlus,planTypes.premiumPlusLoyalty].includes(planToUse);
    const isAddingLoyalty = accountCurrentPlan === planTypes.premiumPlus && planToUse === planTypes.premiumPlusLoyalty;

    if (isUpgradingPlan) {

      if (planUpgrade) {
        this.setState({processText: processUpgradeText})
      }

      if (isAddingLoyalty) {
        this.setState({processText: processUpgradeLoyaltyText});
      }

    } else {
      if (premiumPlusLoyaltyDowngrade) {
        this.setState({processText: processDowngradeLoyaltyText});
      } else {
        this.setState({processText: processDowngradeText});
      }
    }
    this.setState({processModal: true});
  };

  planUpgrade = async () => {
    const {planType, user, dispatch} = this.props;
    const {loyalty} = this.state;
    const oldPlan = UserUtil.accountCurrentPlan(user);
    const planToUse = (loyalty && planType === planTypes.premiumPlus) ? planTypes.premiumPlusLoyalty : planType;
    this.setState({processing: true, processModal: false, oldPlan});
    const isUpgradingPlan = UserUtil.isPlanUpgrade(user, planToUse);
    if (isUpgradingPlan) {
      this.setState({successText: successUpgradeText})
    } else {
      this.setState({successText: successDowngradeText})
    }
    const response = await dispatch(tierUpgrade(user, planToUse));
    this.setState({processing: false});
    if (response.type === actionTypes.tierUpgradeSuccess ) {
      this.setState({successModal: true});
    } else {
      this.setState({errorModal: true});
    }
  };

  handleLoyaltyChange = (event) => {
    this.setState({loyalty: event.target.checked});
  }

  render() {

    const {planType, user, auth, t, variant = 'elevation', userExperience} = this.props;
    const {processing, processModal, successModal, processText, successText, oldPlan, errorModal, loyalty, details} = this.state;

    // set up logic
    const isPaUser = UserUtil.isPayanywhere(user);
    const planToUse = (isPaUser && loyalty && planType === planTypes.premiumPlus) ? planTypes.premiumPlusLoyalty : planType;
    const plan = text[planToUse];
    const label = LabelUtil.getLabel();
    const isPh = label.abbreviation === 'ph';
    const accountCurrentPlan = UserUtil.accountCurrentPlan(user);
    const isOwner = UserUtil.isOwner(user) || auth?.isOwner;
    const isUserCS = UserUtil.isUserCS(user, auth);
    const isUserCSWrite = UserUtil.isUserCSWrite(user);

    // styling logic
    const accent = planToUse === planTypes.premiumPlus || planToUse === planTypes.premiumPlusLoyalty;
    const selected = planToUse === accountCurrentPlan;
    
    const checkIcon = IconUtil.getIcon('CheckIcon');

    //button logic
    const btnText = buttonText[planToUse];
    const isActiveMerchantAccount = UserUtil.isActive(user);
    const shouldBeDisabled = (isUserCS || isUserCSWrite || !isActiveMerchantAccount) ;
    const shouldShowButton = (isOwner && isUserCS) || (isOwner && isUserCSWrite) || isOwner;
    
    const loyaltyAccentButton = shouldShowButton && (
      <Button
        data-test-id='accent-button-update'
        fullWidth={true}
        label={`${t('UpgradePremiumPlus.UpdateButton')}`}
        onClick={this.showPlanModal}
        sx={{
          background: `transparent linear-gradient(306deg, ${label.planGradient1} 0%, ${label.planGradient2} 59%, ${label.planGradient3} 100%) 0% 0% no-repeat padding-box`,
        }}
        disabled={shouldBeDisabled}
      />
    );
    const accentButton = shouldShowButton && (
      <Button
        data-test-id='accent-button-choose'
        fullWidth={true}
        label={`${t('UpgradePremiumPlus.ChooseButton')} ${btnText}`}
        onClick={this.showPlanModal}
        sx={{
          background: `transparent linear-gradient(306deg, ${label.planGradient1} 0%, ${label.planGradient2} 59%, ${label.planGradient3} 100%) 0% 0% no-repeat padding-box`,
        }}
        disabled={shouldBeDisabled}
      />
    );
    const regularButton = shouldShowButton && (
      <Button
        data-test-id='regular-button'
        fullWidth={true}
        label={`${t('UpgradePremiumPlus.ChooseButton')} ${btnText}`}
        onClick={this.showPlanModal}
        disabled={shouldBeDisabled}
      />
    );
    const selectedPlanButton = (
        <ReverseButton
          fullWidth={true}
          label={t('UpgradePremiumPlus.CurrentPlan')}
          icon={checkIcon}
          labelStyle={{marginLeft: 10}}
          sx={{
            '&.Mui-disabled': {
              color: 'primary.main',
              backgroundColor: 'common.grayButtonColor'
            }
          }}
          disabled />
      );
    
    const toPremiumPlusWithLoyalty = planToUse === planTypes.premiumPlusLoyalty && accountCurrentPlan === planTypes.premiumPlus && isPaUser;
    const isInTrial = planToUse === planTypes.premiumPlus && accountCurrentPlan === planTypes.premiumTrial;
    const buttonToShow = toPremiumPlusWithLoyalty ? loyaltyAccentButton : (planToUse === accountCurrentPlan || isInTrial) ?
      selectedPlanButton : accent && planToUse !== accountCurrentPlan ?
        accentButton : regularButton;

    const loading = processing ? <UpdateSpinner /> : null;
    
    const informationDialog = (
      <Modal
        hideActions={true}
        onClose={this.closeInfoModal}
        open={userExperience.modalVisibility[details]}
        centerTitle={true}
        title='Plan Details'
        maxWidth='lg'
        isClosableIconEnable={true}
      >
        <PlanDetails
          plan={planToUse}
          t={t}
        />
      </Modal>
    );

    const processDialog = (
      <MessageDialog
        confirmText={t(processText['button'])}
        isChoiceRequired={false}
        onConfirm={this.planUpgrade}
        onRequestClose={this.handleCloseProcessDialog}
        open={processModal}
        titleText={t(processText['title'])}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>
            {t(processText['body'], { plan: buttonText[planToUse], price: prices[planToUse] })}
          </p>
        }/>
    );

    const successDialog = (
      <MessageDialog
        confirmText={t('Done')}
        isChoiceRequired={false}
        onConfirm={this.handleCloseSuccessDialog}
        onRequestClose={this.handleCloseSuccessDialog}
        open={successModal}
        titleText={t(successText['title'])}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>
            {t(successText['body'], { plan: buttonText[planToUse], oldPlan: buttonText[oldPlan] })}
          </p>
        }/>
    );

    const errorDialog = (
      <MessageDialog
        confirmText={t('Done')}
        isChoiceRequired={false}
        onConfirm={this.handleCloseErrorDialog}
        onRequestClose={this.handleCloseErrorDialog}
        open={errorModal}
        titleText={t('ErrorPlanModal.Title')}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>
            {t('ErrorPlanModal.Body', {phone: label.phoneNumber, email: label.emailAddress})}
          </p>
        }/>
    );
    
    const listItemIconStyles = {
      minWidth: 'auto',
      marginRight: '8px',
      svg: {
        fontSize: '12px',
        color: 'common.black'
      }
    };
    
    const listItemStyles = {
      padding: '0'
    };
    
    const listStyles = {
      paddingLeft: '8px',
      paddingRight: '8px',
    };
    
    const listItemTextStyles = {
      span: {
        fontSize: '12px'
      }
    };

    return (
      <>
        <Paper
          data-test-id='plan-card'
          variant={variant}
          elevation={0}
          sx={{
            width: '317px',
            marginTop: accent ? '0' : '37.5px',
            border: selected ? '2px' : '1px',
            borderColor: selected ? 'primary.main' : 'common.cardBorderColor2',
            borderStyle: 'solid',
            borderRadius: accent ? '11px 11px 0 0' : '0'
          }}
          square={true}
        >
          {accent && <Box
            data-test-id='accent-header'
            sx={{
              boxSizing: 'border-box',
              padding: '13px 0 10px 0',
              background: `transparent linear-gradient(306deg, ${label.planGradient1} 0%, ${label.planGradient2} 59%, ${label.planGradient3} 100%) 0% 0% no-repeat padding-box`,
              borderRadius: '8px 8px 0 0'
            }}
          >
            <Typography
              variant='h4'
              sx={{
                color: 'common.white',
                fontSize: '12px',
                fontWeight: 'normal',
                textAlign: 'center',
                margin: 0
              }}
            >
              {t(plan?.heading)}
            </Typography>
          </Box>}
          <Box
            sx={{
              position:'relative',
              boxSizing: 'border-box',
              padding: '35px 25px',
              textAlign: 'center',
              height: '648px'
            }}
          >
            <Stack
              marginBottom='20px'
              direction='row'
              spacing='2'
              justifyContent='center'
            >
              <Chip
                data-test-id='plan-card-chip'
                label={t(plan?.label)}
                color='primary'
                sx={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  color: 'common.white',
                  background: accent ? `transparent linear-gradient(306deg, ${label.planGradient1} 0%, ${label.planGradient2} 59%, ${label.planGradient3} 100%) 0% 0% no-repeat padding-box` : 'primary.main',
                }}
              />
            </Stack>
            <Typography
              variant='h2'
              sx={{
                fontSize: '27px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}
            >
              {t(plan?.price)}
            </Typography>
            <Typography
              variant='p'
              sx={{
                fontSize: '10px',
                color: 'common.todaySecondaryDataColor',
                marginBottom: '22px'
              }}
              >
              {t(plan?.subHeading)}
            </Typography>
            {plan?.bulletOne && <List
              sx={{
                marginTop: '15px',
                paddingLeft: '8px',
                paddingRight: '8px',
                background: accent ? `transparent linear-gradient(306deg, ${label.planGradient1} 0%, ${label.planGradient2} 59%, ${label.planGradient3} 100%) 0% 0% no-repeat padding-box` : 'transparent',
                borderRadius: accent ? '5px' : '0',
              }}
            >
              {plan.bulletOne.map((text,index) =>
                <ListItem
                  key={`bulletOne${index}`}
                  sx={{
                    ...listItemStyles,
                    color: accent ? 'common.white' : 'common.black'
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      marginRight: '8px',
                      svg: {
                        fontSize: '12px',
                        color: accent ? 'common.white' : 'common.black'
                      }
                  }}
                  >
                    {checkIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(text)}
                    sx={listItemTextStyles}
                  />
                </ListItem>)}
            </List>}
            {plan?.bulletTwo && <List
              sx={listStyles}
            >
              {plan.bulletTwo.map((text,index) =>
                <ListItem
                  key={`bulletOne${index}`}
                  sx={listItemStyles}
                >
                  <ListItemIcon
                    sx={listItemIconStyles}
                  >
                    {checkIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(text)}
                    sx={listItemTextStyles}
                  />
                </ListItem>)}
            </List>}
            {plan?.bulletThree && <List
              sx={listStyles}
            >
              {plan.bulletThree.map((text,index) =>
                <ListItem
                  key={`bulletOne${index}`}
                  sx={listItemStyles}
                >
                  <ListItemIcon
                    sx={listItemIconStyles}
                  >
                    {checkIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={t(text)}
                    sx={listItemTextStyles}
                  />
                </ListItem>)}
              </List>}
            <Box
              sx={{
                maxWidth: {xs: '200px', sm: '200px', md: '100%', lg: '100%'},
                position: 'absolute',
                bottom: '60px',
                width: '267px',
                left: '50%',
                transform: 'translate(-50%, 0)',
              }}
            >
              {buttonToShow}
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, 0)',
                bottom: '0',
                minWidth: '200px'
              }}
            >
              <ReverseButton
                label={t('PlanDisclaimer.SeeDetails')}
                onClick={this.openInfoModal}
                fullWidth={true}
              />
            </Box>
          </Box>
        </Paper>
        {processDialog}
        {successDialog}
        {errorDialog}
        {informationDialog}
        {loading}
      </>
    );

  }
}
