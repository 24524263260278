/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import React from 'react';
import {
  clearNotification,
  setDynamicMessageDialogContent,
  setOpenChangePasswordDialog,
  setOpenDynamicMessageDialog,
  setReputationCardNextValue,
  suppressAppError,
  toggleAgentPortalMsg,
  toggleRefreshTokenDialog
} from '../actions/userExperienceActions';

import {
  checkTokenExpiration,
  logoutUser,
  versionMessage,
  hideExpirePasswordMessage
} from '../actions/authActions';
import Login from '../components/Login';
import ClientService from '../components/ClientService';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import SetPassword from '../components/SetPassword';
import LinkNotification from '../components/LinkNotification';
import SalesPartnerNotification from '../components/SalesPartnerNotification';
import EmailValidation from '../components/EmailValidation';
import RefreshTokenDialog from '../components/RefreshTokenDialog';
import messages from '../constants/messages';
import routes from '../constants/routes';
import { toastr } from 'react-redux-toastr';
import Bugsnag from '@bugsnag/js';
import PropTypes from 'prop-types';
import LabelUtil from '../components/util/LabelUtil';
import IconUtils from '../components/util/IconUtil';
import _ from 'lodash';
import Header from './../components/Header';
import UserUtil from '../components/util/UserUtil';
import PrivacyPolicy from './../components/PrivacyPolicy';
import Routes from '../routes';
import withAppData from '../hoc/withAppData';
import labels from '../constants/labels';

import WinbackCampaign from './../components/campaigns/WinbackCampaign';
import CreateAccountComponent from '../components/leadSignup/CreateAccountComponent';
import DemoMode from '../components/shared/DemoMode';
import North from '../components/whitelabel/North';

const tokenCheck = 60000;

const clientRoute = routes;

export class App extends React.PureComponent {

  constructor(props) {
    super(props);

    this.checkToken = this.checkToken.bind(this);
    this.openChangePasswordDialog = this.openChangePasswordDialog.bind(this);
  }

  componentDidMount() {

    if (this.props.isAuthenticated) {
      this.checkToken(this.props);
      this.props.dispatch(versionMessage(this.props.entryType));
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const {
      isAuthenticated,
      notifications,
      userExperience,
      dispatch,
      entryType,
      user,
      history,
      auth,
      t
    } = this.props;

    const {historicHardwareMode} = userExperience;
    const appRoutePrefix = globalApplicationLabel.path;
    const pathName = history.location.pathname;

    const activeAccount = UserUtil.getActiveAccount(user);
    const shouldShowDeviceAgentPortalMsg = activeAccount?.agent_access_status === 'PENDING' && !pathName.includes(appRoutePrefix + clientRoute.customerPayment.root);

    if (!isAuthenticated && nextProps.isAuthenticated) {
      this.checkToken(nextProps);
      dispatch(versionMessage(entryType));
      dispatch(setReputationCardNextValue());
    }

    if (nextProps.user?.data?.merchantAccounts && user.selectedMerchantAccount !== nextProps.user?.selectedMerchantAccount) {
      const mid = _.find(nextProps.user?.data.merchantAccounts, ['mea_id', parseInt(nextProps.user?.selectedMerchantAccount)])?.mid;
      Bugsnag.addMetadata('account', {
        mid,
      });
    }

    if (isAuthenticated && (historicHardwareMode !== nextProps.userExperience.historicHardwareMode)) {
      history.push(`${appRoutePrefix}${routes.activity.root}${routes.activity.dashboard}`);
    }

    if (maintenanceEnabled && nextProps.userExperience.showMaintenanceMessage &&
      (userExperience.showMaintenanceMessage !== nextProps.userExperience.showMaintenanceMessage &&
        messages.maintenance.siteMessages.length > 0)) {
      dispatch(setOpenDynamicMessageDialog('maintenance'));
      const siteMessages = messages.maintenance.siteMessages.map(siteMessage => t(siteMessage));
      dispatch(setDynamicMessageDialogContent(siteMessages));
    }

    if (shouldShowDeviceAgentPortalMsg && !userExperience.agentPortalMsgShown && !userExperience.salesPartnerNotificationAccepted) {
      toastr.error('', '', {
        timeOut: 0,
        showCloseButton: true,
        removeOnHover: false,
        icon: 'info',
        status: 'info',
        closeOnToastrClick: true,
        component: (
          <SalesPartnerNotification dispatch={dispatch} history={history} t={t}/>)
      });
      dispatch(toggleAgentPortalMsg(true));
    }

    const passwordExpirationDays = user && user.data && user.data.password_exp_days;
    const isOver60daysOld = passwordExpirationDays < 30; //Starts at 90 for a new password

    if (isOver60daysOld && auth.showExpirePasswordMessage) {

      toastr.error(t('Toastr.PasswordExpiration.Title'), '', {
        timeOut: 5000,
        showCloseButton: true,
        removeOnHover: false,
        progressBar: false,
        icon: 'info',
        status: 'info',
        component: (
          <LinkNotification
            message={t('Toastr.PasswordExpiration.Message')}
            onLinkClick={this.openChangePasswordDialog}
            t={t}
          />)
      });

      dispatch(hideExpirePasswordMessage());

    }

    /* istanbul ignore else */
    if (notifications !== nextProps.notifications) {

      if (nextProps.notifications && nextProps.notifications.length > 0) {

        nextProps.notifications.map((notification, index) => {

          if (notification.message === 'No token saved') {
            dispatch(logoutUser());
          } else {
            if (!nextProps.location || (nextProps.location.pathname)) {

              switch (notification.type) {
                case 'Success':
                  toastr.success(t('Success'), notification.message, {
                    timeOut: 3000,
                    icon: IconUtils.getIcon('DoneIcon', '#fff'),
                    progressBar: false,
                    showCloseButton: true,
                    removeOnHover: false,
                  });
                  break;
                case 'Error':
                  const errorMessage = isJSON(notification.message) || _.isObject(notification.message) || notification.message.includes('< in JSON at position 0') || notification.message.includes('<html>')
                    ? t(messages.errors.internalProcessingError) : t(notification.message);
                  if (!userExperience.suppressMessageDialog) {
                    toastr.error(t('Toastr.Error.Title'), errorMessage, {
                      timeOut: 3000,
                      showCloseButton: true,
                      removeOnHover: false,
                      progressBar: false,
                      icon: 'error',
                      status: 'error'
                    });
                  } else {
                    dispatch(suppressAppError(false))
                  }
                  break;
                case 'LightComponent':
                  toastr.light(notification.title, '', {
                    timeOut: 0,
                    showCloseButton: true,
                    removeOnHover: false,
                    icon: 'info',
                    status: 'info',
                    component: (
                      <LinkNotification
                        message={notification.message}
                        linkRoute={globalApplicationLabel.path + clientRoute.account.root + routes.account.tin.root + routes.account.tin.forms}
                        t={t}
                      />)
                  });
                  break;
              }

              dispatch(clearNotification(index));
            }
          }

        });
      }
    }

    if (userExperience.requestNewToken !== nextProps.userExperience.requestNewToken &&
      nextProps.userExperience.requestNewToken === true) {
      dispatch(toggleRefreshTokenDialog(true));
    }

    if (userExperience.requestNewToken !== nextProps.userExperience.requestNewToken &&
      nextProps.userExperience.requestNewToken === false) {
      dispatch(toggleRefreshTokenDialog(false));
    }
  }

  openChangePasswordDialog() {
      this.props.dispatch(setOpenChangePasswordDialog());
      this.props.history.push(globalApplicationLabel.path + clientRoute.account.root);
  }

  checkToken(props) {
    props.dispatch(checkTokenExpiration());

    setTimeout(() => {
      /* istanbul ignore else */
        if (props.isAuthenticated) {
          this.checkToken(props);
        }
      }, tokenCheck);
  }

  render() {

    const {
      isAuthenticated,
      loginError,
      forgotPasswordError,
      registrationError,
      resetPasswordError,
      setPasswordError,
      history,
      auth,
      user,
      userExperience,
      dispatch
    } = this.props;

    const label = LabelUtil.getLabel();

    const appRoutePrefix = globalApplicationLabel.path;

    const pathName = history.location.pathname;

    const isMobileRoute = pathName.includes(appRoutePrefix + clientRoute.mobile.root);

    const isCsRoute = pathName.includes(appRoutePrefix + clientRoute.clientService.root);

    const isResetPassword = pathName.includes(appRoutePrefix + clientRoute.resetPassword);

    const isWinBackCampaign = pathName.includes(appRoutePrefix + clientRoute.campaigns);

    const isPaymentHubsLogin = (
      !isCsRoute &&
      !auth.isAuthenticated &&
      !pathName.includes(appRoutePrefix + clientRoute.forgotPassword) &&
      !pathName.includes(appRoutePrefix + clientRoute.setPassword) &&
      !pathName.includes(appRoutePrefix + clientRoute.resetPassword) &&
      !pathName.includes(appRoutePrefix + clientRoute.emailValidation) &&
      !pathName.includes(appRoutePrefix + clientRoute.leadSignup) &&
      !isWinBackCampaign &&
      (label.abbreviation === 'ph' || label.abbreviation === 'nh')
    );

    const isPayAnywhereLogin = (
      !isResetPassword &&
      !isCsRoute &&
      !auth.isAuthenticated &&
      !pathName.includes(appRoutePrefix + clientRoute.forgotPassword) &&
      !pathName.includes(appRoutePrefix + clientRoute.setPassword) &&
      !pathName.includes(appRoutePrefix + clientRoute.resetPassword) &&
      !pathName.includes(appRoutePrefix + clientRoute.emailValidation) &&
      !pathName.includes(appRoutePrefix + clientRoute.leadSignup) &&
      label.abbreviation === 'pa'
    );

    const isMidSelectorRoute = pathName.includes(appRoutePrefix + clientRoute.midSelector);
    const isMidSelected = userExperience.midSelected;

    const isTestDriveRoute = pathName.includes(appRoutePrefix + clientRoute.testDrive.root);
    const isForgetPasswordRoute =
      pathName.includes(appRoutePrefix + clientRoute.clientService.root + clientRoute.clientService.forgotPassword) ||
      pathName.includes(appRoutePrefix + clientRoute.forgotPassword);
    const isResetPasswordRoute = pathName.includes(appRoutePrefix + clientRoute.resetPassword);
    const isSetPasswordRoute = pathName.includes(appRoutePrefix + clientRoute.setPassword);
    const is404Route = pathName.includes(appRoutePrefix + clientRoute.fourZeroFour);

    const isReceiptRoute = pathName.includes(appRoutePrefix + clientRoute.receipts.root) ||
      pathName.includes(appRoutePrefix + clientRoute.receipts.legacy);
    const isFeedbackRoute = pathName.includes(appRoutePrefix + clientRoute.feedback.root);
    const isInvoiceOrPayNowLandingRoute = (
      pathName.includes(appRoutePrefix + clientRoute.payNow.root) ||
      pathName.includes(appRoutePrefix + clientRoute.invoice.root) ||
      pathName.includes(appRoutePrefix + clientRoute.customerPayment.root)
    ) &&
      !pathName.includes(appRoutePrefix + clientRoute.business.root);

    const isPaymentLinkRoute = pathName.includes(appRoutePrefix + clientRoute.paymentLink.root) && !pathName.includes(appRoutePrefix + clientRoute.business.root);
    const isTermsInfoRoute = pathName.includes(appRoutePrefix + clientRoute.termsInfo) || pathName.includes(appRoutePrefix + clientRoute.smsTerms) || pathName.includes(appRoutePrefix + clientRoute.loyaltyTerms);
    const isEmailValidationRoute = pathName.includes(appRoutePrefix + clientRoute.emailValidation);
    const isPrivacyPolicyRoute = pathName.includes(appRoutePrefix + clientRoute.privacyPolicy);
    const isLeadSignupRoute = pathName.includes(appRoutePrefix + clientRoute.leadSignup);
    const isActivateFlowRoute = pathName.includes(appRoutePrefix + clientRoute.activate);
    const isDemo = UserUtil.isDemoAccount(user);

    const refreshTokenDialog = isAuthenticated ? (
      <RefreshTokenDialog {...this.props} />) : null;


    const loginComponent = (
      <Login
        {...this.props}
        errorMessage={loginError}
      />
    );

    const forgotPasswordComponent = (
      <ForgotPassword
        {...this.props}
        errorMessage={forgotPasswordError}
      />
    );

    const resetPasswordComponent = (
      <ResetPassword
        {...this.props}
        errorMessage={resetPasswordError}
      />
    );

    const setPasswordComponent = (
      <SetPassword
        {...this.props}
        errorMessage={setPasswordError}
      />
    );

    const csLoginComponent = (
      <ClientService
        {...this.props}
        errorMessage={loginError}
      />
    );

    const emailVerificationComponent = (
      <EmailValidation {...this.props}/>
    );

    const privacyPolicyComponent = (
      <PrivacyPolicy
        {...this.props}
      />
    );

    const winbackCampaignComponent = (
      <WinbackCampaign
        {...this.props}
      />
    );

    const leadSignupComponent = (
      <CreateAccountComponent
        {...this.props}
      />
    );

    const northDemoMode =
      isDemo
        ? (<North>
          <DemoMode {...this.props} />
        </North>)
        : null;

    const labelNorth = labels.nh.abbreviation;
    const currentLabel = globalApplicationLabel.abbreviation;

    let passwordComponent = loginComponent;

    if (isForgetPasswordRoute) {
      passwordComponent = forgotPasswordComponent;
    } else if (isResetPasswordRoute) {
      passwordComponent = resetPasswordComponent;
    } else if (isSetPasswordRoute) {
      passwordComponent = setPasswordComponent;
    } else if (isCsRoute) {
      passwordComponent = csLoginComponent;
    } else if (isEmailValidationRoute) {
      passwordComponent = emailVerificationComponent;
    } else if (isPrivacyPolicyRoute) {
      passwordComponent = privacyPolicyComponent;
    } else if (isWinBackCampaign) {
      passwordComponent = winbackCampaignComponent;
    }

    const showHeaderLogo = (isSetPasswordRoute ||
      isResetPasswordRoute ||
      isForgetPasswordRoute ||
      isCsRoute ||
      isEmailValidationRoute ||
      isMidSelectorRoute ||
      (!isMidSelected && !is404Route)
    );

    const showPHAndPAHeaderLogo = isSetPasswordRoute || isResetPasswordRoute || isForgetPasswordRoute || isCsRoute || isEmailValidationRoute;

    const showMenu = !isMobileRoute &&
    !isTestDriveRoute &&
    !isReceiptRoute &&
    !isFeedbackRoute &&
    !isInvoiceOrPayNowLandingRoute &&
    !isPaymentLinkRoute &&
    !isPaymentHubsLogin &&
    !isSetPasswordRoute &&
    !isResetPasswordRoute &&
    !isForgetPasswordRoute &&
    !isLeadSignupRoute &&
    !isCsRoute &&
    !isEmailValidationRoute &&
    !isMidSelectorRoute &&
    isMidSelected &&
    isAuthenticated &&
    !isPayAnywhereLogin &&
    !isWinBackCampaign &&
    !isActivateFlowRoute;

    const header = !isLeadSignupRoute && !isMobileRoute && !isTestDriveRoute && !isReceiptRoute && !isFeedbackRoute && !isInvoiceOrPayNowLandingRoute && !isPaymentLinkRoute
      && !isPaymentHubsLogin && !isPayAnywhereLogin && !isWinBackCampaign && !isActivateFlowRoute && !isForgetPasswordRoute && !isResetPasswordRoute ?
      (<Header showHeaderLogo={showHeaderLogo} showPHAndPAHeaderLogo={showPHAndPAHeaderLogo} {...this.props} />) : null;

    const showAuthenticationComponent = !isTestDriveRoute && !isReceiptRoute && !isFeedbackRoute && !isInvoiceOrPayNowLandingRoute && !isPaymentLinkRoute && !isTermsInfoRoute && !isLeadSignupRoute && !isAuthenticated;

    const content =
      showAuthenticationComponent ?
        passwordComponent : (
          <Routes
            isFetching={user.isFetching}
            showMenu={showMenu}
            mainMenuOpen={userExperience.mainMenuOpen}
            headerHeight={userExperience.headerHeight}
            dispatch={dispatch}
          />
        );

    return (
      <div
        className='portalApplication'
        id='portalApplication'
      >
        {header}
        {content}
        {refreshTokenDialog}
        {northDemoMode}
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  customers: PropTypes.object,
  invoices: PropTypes.object,
  items: PropTypes.object,
  loginError: PropTypes.string,
  notifications: PropTypes.array,
  transactions: PropTypes.object,
  userExperience: PropTypes.object,
  user: PropTypes.object,
  children: PropTypes.node,
  visibilityFilter: PropTypes.object,
};

function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default withAppData(App);
