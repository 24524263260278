/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';

import _ from 'lodash';
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack
} from '@mui/material';

import { connect } from 'react-redux';
import { Field, change, reduxForm, arrayPush, arrayRemove, getFormValues, untouch } from 'redux-form';
import Modal from '../Modal';
import Select from '../Select';
import Tooltip from '../Tooltip';
import TextField from '../TextField';
import ChipInput from '../ChipInput';
import MenuDateItem from '../MenuDateItem';
import FormUtil from '../../util/FormUtil';
import DateUtil from '../../util/DateUtil';
import UserUtil from '../../util/UserUtil';
import IconUtil from '../../util/IconUtil';
import Validator from '../../util/Validator';
import LabelUtil from '../../util/LabelUtil';
import FormatTextUtil from '../../util/FormatTextUtil';
import InvoiceUtil, {
  FormType,
  RecurringEnd,
  PaymentMethod,
  PaymentFrequency,
  FormDefaultOptions,
  INVOICE_FORM_ID,
  UNSELECTED_INDEX,
  CUSTOM_DATE_VALUE,
  CUSTOM_SEND_DATE_VALUE,
  CUSTOM_DUE_DATE_VALUE,
  CUSTOM_END_DATE_VALUE
} from '../../util/InvoiceUtil';
import CustomerUtil from '../../util/CustomerUtil';

import CustomerContactList from '../../invoice/CustomerContactList';

import messages from '../../../constants/messages';
import countryList from '../../../constants/countryStateList';

import { getCustomer, getCustomers, setCustomer } from '../../../actions/customerActions';
import {
  getItems
} from '../../../actions/itemsActions';
import CustomDatePicker from '../DatePicker';
import LineItemList from '../../LineItemList';
import SearchBarField from '../../SearchBarField';
import {Link} from 'react-router-dom';
import routes from '../../../constants/routes';
import numeral from 'numeral';
import IconButton from '../IconButton';
import Avatar from '@mui/material/Avatar';
import {roundToTwoDecimals} from '../../util/CommonUtil';
import PaymentMethodSelect from '../../business/PaymentMethodSelect';
import VisibilityToggle from '../../visibilityToggle';
import {checkIsSpanish} from '../../../locales/i18n';
import CustomToggle from '../Toggle';
import { toastr } from 'react-redux-toastr';
import { removeRewardCode, setRewardCode, validateRewardCode } from '../../../actions/loyaltyVpcActions';
import invoiceFormStyles from '../../../jss/invoiceFormStyles';
import BankingForm from '../ach/BankingForm';
import {
  addExpressItemButtonStyles,
  addExpressItemsButtonStyles,
  addExpressItemsButtonTextStyles,
  addExpressItemsListStyles,
  addExpressItemsSearchButtonStyles, addExpressItemsSearchDropdown,
  addExpressItemsSearchRowStyles,
  addExpressItemsTextStyles
} from '../../../jss/addExpressItemStyles';
import classnames from 'classnames';
import NewFeatureBadge from '../NewFeatureBadge';
import FeatureFlagsUtil from '../../util/FeatureFlagsUtil';

const {
  sendDateOptions,
  dueDateOptions,
  endsOptions,
  timeMeasureOptions,
  invoiceFormTypeOptions,
  paymentFrequencyOptions,
} = FormDefaultOptions;

const today = DateUtil.getTodayWithoutTime();

export const validate = (values) => {
  return (!_.isEmpty(values)) ? Validator.validateInvoiceForm(values) : {};
};

export class InvoiceForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      date: today,
      dueDate: props.initialValues?.[CUSTOM_DUE_DATE_VALUE] || null,
      endDate: props.initialValues?.[CUSTOM_END_DATE_VALUE] || null,
      sendDate: props.initialValues?.[CUSTOM_SEND_DATE_VALUE] || null,
      selectedDialogDate: '',
      isAddressVisible: props.initialValues?.customer_zip
      || props.initialValues?.customer_state
      || props.initialValues?.customer_city
      || props.initialValues?.customer_street_address_1
      || props.initialValues?.customer_street_address_2,
      isCustomerDialogOpen: false,
      isDateFormDialogOpen: false,
      customerIndex: UNSELECTED_INDEX,
      cardVisibility: false,
      isInvalidCode: false,
      isPrePopulatedRewardActive: false
    };

    this.clearCardData = this.clearCardData.bind(this);
    this.setSendToCustomer = this.setSendToCustomer.bind(this);
    this.setPaymentMethod = this.setPaymentMethod.bind(this);
    this.toggleCardNumberVisibility = this.toggleCardNumberVisibility.bind(this);
    this.handleEnterCardManually = this.handleEnterCardManually.bind(this);
    this.displayDateDialog = this.displayDateDialog.bind(this);
    this.deleteRewardDiscount = this.deleteRewardDiscount.bind(this);
    this.validateRewardCode = this.validateRewardCode.bind(this);
    this.clearLoyaltyInfo = this.clearLoyaltyInfo.bind(this);
    this.togglePrePopulatedLoyaltyReward = this.togglePrePopulatedLoyaltyReward.bind(this);
    this.useBankingAccount = this.useBankingAccount.bind(this);
  }

  async componentDidMount() {
    const { selectedInvoice, dispatch, user, history } = this.props;
    const customerIdFromHistory = history?.location?.state?.customerID;
    const customerId = selectedInvoice?.pa_customer_id || selectedInvoice?.customer_id || customerIdFromHistory;
    const dispatchCalls = [];

    dispatchCalls.push(dispatch(getItems(user)));

    if (!!customerId) {
      dispatchCalls.push(dispatch(getCustomers(user)).then(() => {
        this.loadSelectedCustomer(customerId);
      }));
    }

    if (selectedInvoice?.loyalty_info?.reward_amount) {
      dispatchCalls.push(dispatch(setRewardCode(selectedInvoice?.loyalty_info.reward_type, selectedInvoice?.loyalty_info.reward_amount)));
    }

    await Promise.all(dispatchCalls);

  }

  componentDidUpdate(prevProps) {
    const prevCurrentValues = prevProps?.currentValues;
    const {
      currentValues: { type, frequency },
      merchantSettings: { geoTaxEnabled },
      taxes: { taxRate }
    } = this.props;

    if(type && prevCurrentValues.type && type !== prevCurrentValues.type) {
      this.props.dispatch(change(INVOICE_FORM_ID, 'amount', ''));
      this.props.dispatch(untouch(INVOICE_FORM_ID, 'amount'));

      if (geoTaxEnabled || taxRate) {
        const formattedTaxRate = FormatTextUtil.formatPercentageWithoutSymbol(FormatTextUtil.formatNumberToThreeDecimals(taxRate));
        this.props.dispatch(change(INVOICE_FORM_ID, 'taxRate', formattedTaxRate));
      } else {
        this.props.dispatch(change(INVOICE_FORM_ID, 'taxRate', ''));
      }

      this.props.itemCartHandlers.cleanItemizedCart();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /* istanbul ignore else */
    if (
      (nextProps.currentValues?.amount !== this.props.currentValues?.amount) ||
      (nextProps.itemizedCart?.sub_total_amt !== this.props.itemizedCart?.sub_total_amt) ||
      (nextProps.currentValues?.cashDiscounting !== this.props.currentValues?.cashDiscounting)
    ) {
      FormUtil.cashDiscountingAmount(this.props, nextProps);
      InvoiceUtil.recalculateCart(nextProps.itemizedCart, nextProps.currentValues, nextProps.loyaltyVpc.rewardCodeInfo);
    }
  }

  async togglePrePopulatedLoyaltyReward() {
    const preExistingLoyaltyRewardInfo = this.props.customers?.selectedCustomer?.loyalty_vpc_status;

    if(!this.state.isPrePopulatedRewardActive && preExistingLoyaltyRewardInfo) {
      this.setState({isPrePopulatedRewardActive: true});

      await this.props.dispatch(setRewardCode(preExistingLoyaltyRewardInfo.reward_amount_type, preExistingLoyaltyRewardInfo.reward_amount));
      this.props.toggleLoyaltyRewardCode();
      this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCode', preExistingLoyaltyRewardInfo.reward_code));
      this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCodeInformation', this.props.loyaltyVpc.rewardCodeInfo));
    } else {
      this.setState({isPrePopulatedRewardActive: false});
      this.clearLoyaltyInfo();
    }
  }

  componentWillUnmount() {
    const { dispatch, history } = this.props;
    this.deleteRewardDiscount();
    dispatch(setCustomer({}));
    const historyState = history?.location?.state || {};

    history.replace({
      state: {
        ...historyState,
        customerID: null
      }
    });

  }

  loadSelectedCustomer = (customerId) => {
    const { customers } = this.props;

    const filteredCustomers = customers?.filteredData;

    const customerIndex = Array.isArray(filteredCustomers) ? filteredCustomers?.findIndex(customer => customer.id === customerId) : -1;

    if (customerIndex !== -1) {
      this.setState({ customerIndex }, () =>  this.onCustomerSelect(null, false));
    }
  };

  clearLoyaltyInfo() {
    this.props.dispatch(removeRewardCode());
    this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCode', ''));
    this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCodeInformation', ''));
  }

  displayCustomerDialog = async () => {
    await this.props.dispatch(getCustomers(this.props.user));
    this.setState({ isCustomerDialogOpen: true });
  }

  hideCustomerDialog = () => {
    this.setState({ isCustomerDialogOpen: false, customerIndex: UNSELECTED_INDEX });
  }

  getCustomDates = () => {
    const { sendDate, dueDate, endDate } = this.state;

    const customDateValues = {
      [CUSTOM_SEND_DATE_VALUE]: sendDate,
      [CUSTOM_DUE_DATE_VALUE]: dueDate,
      [CUSTOM_END_DATE_VALUE]: endDate,
    }
    const customDateStateKeys = {
      [CUSTOM_SEND_DATE_VALUE]: 'sendDate',
      [CUSTOM_DUE_DATE_VALUE]: 'dueDate',
      [CUSTOM_END_DATE_VALUE]: 'endDate',
    }

    return [customDateValues, customDateStateKeys];
  }

  displayDateDialog = (selectedDialogDate) => () => {
    const { date } = this.state;

    const minInvoiceDate = this.getInvoiceMinDate();
    const [customDateValues, customDateStateKeys] = this.getCustomDates();

    const newDate = customDateValues[selectedDialogDate] || date || minInvoiceDate;

    this.setState({
      isDateFormDialogOpen: true,
      selectedDialogDate,
      date: newDate,
      [customDateStateKeys[selectedDialogDate]]: null
    });
  }

  hideDateDialog = () => {
    this.setState({ isDateFormDialogOpen: false});
  }

  displayCustomerAddress = () => {
    this.setState({ isAddressVisible: true });
  }

  onCustomerSelect = (_, shouldMakeRequest = true) => {
    const { dispatch, customers, user } = this.props;

    const selectedCustomer = customers?.filteredData[this.state.customerIndex];
    const {
      id,
      first_name,
      last_name,
      phone_number,
      email_addresses,
      is_pa_customer
    } = selectedCustomer;

    shouldMakeRequest && dispatch(getCustomer(user, id, is_pa_customer));

    const email = email_addresses && email_addresses[0];
    const phoneNumber = phone_number && FormatTextUtil.formatPhoneNumber(phone_number);
    const isPhoneValid = phone_number && Validator.isPhoneNumber(phone_number);
    const isCbdCannabisMerchant = UserUtil.isCbdCannabisMerchant(user);

    dispatch(change(INVOICE_FORM_ID, 'customer_first', first_name));
    dispatch(change(INVOICE_FORM_ID, 'customer_last', last_name));
    dispatch(change(INVOICE_FORM_ID, 'customer_email_addresses', email ? [email] : []));

    if (!isCbdCannabisMerchant) {
      dispatch(change(INVOICE_FORM_ID, 'customer_phone', isPhoneValid ? phoneNumber : (FormatTextUtil.formatPhoneNumber(phone_number) || '')));
    }

    this.clearLoyaltyInfo();

    this.hideCustomerDialog();
  }

  onCustomerClick = (customerIndex) => {
    this.setState({ customerIndex });
  }

  onDateChange = (date) => {
    this.setState({ date: DateUtil.getMomentDate(date) })
  }

  onChipAdd = (chip) => {
    const { currentValues, dispatch } = this.props;
    if (Validator.validateEmailString(chip)) {
      const emailLength = currentValues?.customer_email_addresses?.length;
      const lastEmailIndex = emailLength && emailLength - 1;
      if (emailLength >= 1) {
        dispatch(arrayRemove(INVOICE_FORM_ID, 'customer_email_addresses', lastEmailIndex));
        const fieldsToClean = ['customer_phone', 'customer_first', 'customer_last'];
        fieldsToClean.forEach((field) => {
          dispatch(change(INVOICE_FORM_ID, field, ''));
        });
        dispatch(setCustomer({}));
      }

      dispatch(arrayPush(INVOICE_FORM_ID, 'customer_email_addresses', chip));
    }
  }

  onChipDelete = (chip, index) => {
    const { dispatch } = this.props;
    const fieldsToClean = ['customer_phone', 'customer_first', 'customer_last'];
    fieldsToClean.forEach((field) => {
      this.props.dispatch(change(INVOICE_FORM_ID, field, ''));
    });
    dispatch(setCustomer({}));
    this.clearLoyaltyInfo();
    this.props.dispatch(arrayRemove(INVOICE_FORM_ID, 'customer_email_addresses', index));
  }

  onDateSelect = () => {
    const { date, selectedDialogDate } = this.state;

    if (selectedDialogDate === CUSTOM_DUE_DATE_VALUE) this.setState({ dueDate: date });
    if (selectedDialogDate === CUSTOM_END_DATE_VALUE) this.setState({ endDate: date });
    if (selectedDialogDate === CUSTOM_SEND_DATE_VALUE) this.setState({ sendDate: date });

    this.props.dispatch(change(INVOICE_FORM_ID, selectedDialogDate, date));

    this.hideDateDialog();
  }

  clearCardData() {
    const { dispatch } = this.props;

    dispatch(change(INVOICE_FORM_ID, 'selectedPaymentMethod', null));
    dispatch(change(INVOICE_FORM_ID, 'paymentMethod', null));
    dispatch(change(INVOICE_FORM_ID, 'cdigits', ''));
    dispatch(change(INVOICE_FORM_ID, 'edate', ''));
    dispatch(change(INVOICE_FORM_ID, 'cvv', ''));
    dispatch(change(INVOICE_FORM_ID, 'zip', ''));
    dispatch(change(INVOICE_FORM_ID, 'zipPlus4', ''));
    dispatch(change(INVOICE_FORM_ID, 'saveCreditCard', false));
  }

  setSendToCustomer() {
    const { dispatch } = this.props;

    this.clearCardData();
    dispatch(change(INVOICE_FORM_ID, 'paymentMethod', PaymentMethod.SEND_TO_CUSTOMER));
  }

  setPaymentMethod(paymentMethod) {
    const { dispatch } = this.props;

    this.clearCardData();
    dispatch(change(INVOICE_FORM_ID, 'selectedPaymentMethod', paymentMethod));
    dispatch(change(INVOICE_FORM_ID, 'paymentMethod', null));
  }

  useBankingAccount() {
    const { dispatch } = this.props;

    this.clearCardData();
    dispatch(change(INVOICE_FORM_ID, 'paymentMethod', PaymentMethod.BANKING_ACCOUNT));
  }

  toggleCardNumberVisibility () {
    this.setState(prevState => ({ cardVisibility: !prevState.cardVisibility }));
  }

  handleFrequencyChange = () => {
    this.props.dispatch(change(INVOICE_FORM_ID, 'selectedSendDateValue', sendDateOptions[0].value));
    this.props.dispatch(change(INVOICE_FORM_ID, 'ends', InvoiceUtil.getEndsOption(null)));
    this.props.dispatch(change(INVOICE_FORM_ID, 'selectedEndDateValue', dueDateOptions[1].value));
    this.props.dispatch(change(INVOICE_FORM_ID, 'numberOfPayments', '1'));
    this.props.dispatch(change(INVOICE_FORM_ID, 'recurring_repeat.repeat', '1'));
    this.props.dispatch(change(INVOICE_FORM_ID, 'recurring_repeat.recurringInterval', timeMeasureOptions[0].value));
    this.props.dispatch(change(INVOICE_FORM_ID, CUSTOM_SEND_DATE_VALUE, null));
    this.props.dispatch(change(INVOICE_FORM_ID, CUSTOM_END_DATE_VALUE, null));

    this.setState({
      sendDate: null,
      endDate: null
    });
  };

  handleEnterCardManually() {
    const { dispatch } = this.props;

    this.clearCardData();
    dispatch(change(INVOICE_FORM_ID, 'paymentMethod', PaymentMethod.CREDIT_CARD));
  }

  handleItemsSearchFocus = () => {
    this.setState({isItemsSearchDropdownOpen: true});
  }

  handleItemsSearchBlur = () => {
    setTimeout(() => {
      this.setState({isItemsSearchDropdownOpen: false});
    }, 500);
  }

  getPaymentMethodsState = () => {
    const { currentValues } = this.props;

    return {
      isSendToCustomer: currentValues?.paymentMethod === PaymentMethod.SEND_TO_CUSTOMER,
      isEnterCardManually: currentValues.paymentMethod === PaymentMethod.CREDIT_CARD,
      isSelectedPaymentMethod: currentValues?.selectedPaymentMethod,
      isBankingAccount: currentValues?.paymentMethod === PaymentMethod.BANKING_ACCOUNT,
    };
  }

  getInvoiceMinDate = () => {
    const { sendDate } = this.state;
    const { selectedSendDateValue, customSendDate } = this.props.currentValues || {};

    let minInvoiceDate = today.clone();

    if (selectedSendDateValue === CUSTOM_DATE_VALUE && !!customSendDate) {
      minInvoiceDate = DateUtil.getMomentDate(customSendDate);
    }

    if (selectedSendDateValue !== CUSTOM_DATE_VALUE) {
      minInvoiceDate = minInvoiceDate.add(selectedSendDateValue, 'day');
    }

    return minInvoiceDate;
  }

  getDateModalValue = (minInvoiceDate) => {
    const {
      date, selectedDialogDate, isDateFormDialogOpen
    } = this.state;

    const [customDateValues] = this.getCustomDates();

    const modalMinDate = (isDateFormDialogOpen && selectedDialogDate) && (
      selectedDialogDate === CUSTOM_SEND_DATE_VALUE ? today.clone() : minInvoiceDate
    ) || undefined;

    let modalValue = date || modalMinDate;

    if ([CUSTOM_SEND_DATE_VALUE].includes(selectedDialogDate)) {
      modalValue = customDateValues[selectedDialogDate] || date || minInvoiceDate;
    } else if ([CUSTOM_DUE_DATE_VALUE, CUSTOM_END_DATE_VALUE].includes(selectedDialogDate)) {
      modalValue = date || customDateValues[selectedDialogDate] || minInvoiceDate;
    }

    return [modalValue, modalMinDate];
  }

  deleteRewardDiscount() {
    this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCode', null));
    this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCodeInformation', null));
    this.props.toggleLoyaltyRewardCode(true);
  }

  async validateRewardCode() {
    const { dispatch, user, currentValues: {rewardCode}, t, toggleLoyaltyRewardCode, customers } = this.props;

    if (rewardCode) {
      await dispatch(validateRewardCode(user, rewardCode));

      if (this.props.loyaltyVpc.rewardCodeInfo) {
        this.setState({isInvalidCode: false});
        toggleLoyaltyRewardCode();
        this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCodeInformation', this.props.loyaltyVpc.rewardCodeInfo));
      } else {
        this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCode', null));
        this.props.dispatch(change(INVOICE_FORM_ID, 'rewardCodeInformation', null));
        this.setState({isInvalidCode: true});
        toastr.error(t('Error'), t('RewardCodeError'), {
          timeOut: 3000,
          showCloseButton: true,
          removeOnHover: false,
          progressBar: false
        });
      }
    }
  }

  onClickNewBadge = () => {
    const appRoutePrefix = globalApplicationLabel.path;
    const invoicesRoute = `${appRoutePrefix}${routes.business.root}${routes.business.invoices}?filterType=BulkInvoices`;
    this.props.history.push(invoicesRoute);
  }

  render() {

    const {
      dueDate, endDate, sendDate, isAddressVisible, isCustomerDialogOpen,
      isDateFormDialogOpen, customerIndex, emailAutoFocus
    } = this.state;

    const {
      user, customers, isProcessing, currentValues, items, openItemsDialog,
      formType, isCashDiscountPerTransactionEnabled, itemCartHandlers,
      itemizedCart, addDiscountToCart, t, submitting, virtualTerminal,
      selectedInvoice, merchantSettings, partialPayment, loyaltyVpc
    } = this.props;

    const defaultTaxRate = this.props.taxes?.taxRate || 0;
    const isLoyaltyProgramEnabled = merchantSettings.loyaltyVpc?.enabled;
    const activeLoyaltyReward = !!this.props.loyaltyVpc.rewardCodeInfo;
    const loyaltyIcon = IconUtil.getIcon('LoyaltyProgramIcon', '#2E2D2D', 23);
    const isPremiumPlusAccount = UserUtil.isPremiumPlusAccount(user);

    const authorizedAmt = partialPayment?.authorized_amt;
    const remainingBalance = partialPayment?.remainingBalance;

    const formattedAuthorizedAmt = !!partialPayment && numeral(authorizedAmt).format('$0,0.00');
    const formattedRemainingBalance = !!partialPayment && numeral(remainingBalance).format('$0,0.00');
    const cardInfo = !!partialPayment && `(${partialPayment.network} - ${partialPayment.last4})`;

    const { type, frequency, ends, cdigits, selectedSendDateValue } = currentValues || {};

    const minInvoiceDate = this.getInvoiceMinDate();
    const [modalValue, modalMinDate] = this.getDateModalValue(minInvoiceDate);

    const isMbp = user && UserUtil.userType(user) === 'MBP';
    const isCustomAmount = type === FormType.CUSTOM_AMOUNT;
    const isItemizedInvoice = type === FormType.ITEMIZED;
    const isInvoice = frequency === PaymentFrequency.INVOICE;
    const isSeries = frequency === PaymentFrequency.SERIES;
    const isRecurringEndsOnDate = ends === RecurringEnd.ON_DATE;
    const isRecurringEndsAfterNumber = ends === RecurringEnd.AFTER_NUMBER_OF_PAYMENTS;
    const cleanCustomers = CustomerUtil.cleanCustomers(customers);
    const isCbdCannabisMerchant = UserUtil.isCbdCannabisMerchant(user);


    const { subTotal, subTotalWithoutLoyalty, total, taxAmount, rewardDiscount } = isCustomAmount
      ? InvoiceUtil.recalculateCartCustomAmount(currentValues, currentValues.rewardCodeInformation)
      : InvoiceUtil.getItemizedSummary(currentValues, itemizedCart, defaultTaxRate);

    const isCustomerSelected = !!customers?.selectedCustomer?.id;

    const selectedCountry = countryList[0];

    const isCustomerFormFilled = (!!currentValues?.customer_email_addresses?.length || !!currentValues?.customer_phone?.length) && !!currentValues?.customer_first?.length && !!currentValues?.customer_last?.length;

    const sendDateSelect = (
      <Field
        label={t('SendDate')}
        component={Select}
        t={t}
        name='selectedSendDateValue'
        disabled={isProcessing}
      >
        {sendDateOptions.map(({ text, value }) => (
          <MenuItem value={value} key={value} className='selectDateMenu'>
            <MenuDateItem
              today={today}
              date={today.clone().add(value, 'day')}
              customText={t(text)}
              showDate={value === selectedSendDateValue}
              t={t}
            />
          </MenuItem>
        ))}

        <MenuItem
          value={CUSTOM_DATE_VALUE}
          key={CUSTOM_SEND_DATE_VALUE}
          className='selectDateMenu'
          onClick={this.displayDateDialog(CUSTOM_SEND_DATE_VALUE)}
        >
          <MenuDateItem today={today} date={sendDate?? currentValues?.customSendDate} t={t}/>
        </MenuItem>
      </Field>
    )


    let dropdownItemRows = (items.filteredItems?.length > 0) ?
      items.filteredItems.map((item, itemIndex) => {

        //For any particular item, the info displayed in the dropdown list should be that of the first price that isnt out of stock, if any, otherwise just show info of the first price
        let firstPriceInStockIndex = !item.is_trackable
          ? 0
          : item.details.prices.reduce((result, currentPrice, currentIndex) => {
            return (currentPrice.quantity > 0 && (result === null)) ? currentIndex : result;
          }, null);
        firstPriceInStockIndex = firstPriceInStockIndex || 0; //In case all prices are out of stock for an item

        const itemBarcode = (item.details.prices.length > 0)
          ? item.details.prices[firstPriceInStockIndex].barcode || t('NoBarCode')
          : t('NoBarCode');

        const itemPrice = (item.details.prices.length > 0)
          ? numeral(item.details.prices[firstPriceInStockIndex].price).format('$0,0.00')
          : '$0.00';

        const itemInStockQuantity = (item.is_trackable && item.details.prices.length > 0)
          ? item.details.prices[firstPriceInStockIndex].quantity > 0
            ? item.details.prices[firstPriceInStockIndex].quantity + ` ${t('InStock').toLowerCase()}`
            : t('OutStock')
          : t('NotTracked');

        return (
          <ListItem key={itemIndex} sx={addExpressItemsSearchRowStyles} data-test-id='itemSearchRow'>
            <ListItemButton onClick={() => openItemsDialog(itemIndex)} sx={addExpressItemsSearchButtonStyles}>
              <Stack sx={addExpressItemsTextStyles}>
                <Stack direction='column' justifyContent='center' alignItems='flex-start'>
                  <div className='itemName'>{item.name}</div>
                  <div className='itemBarcode'>{itemBarcode}</div>
                </Stack>
                <Stack direction='column' justifyContent='center' alignItems='flex-end'>
                  <div className='itemPrice'>{itemPrice}</div>
                  <div className='itemInStockQuantity'>{itemInStockQuantity}</div>
                </Stack>
              </Stack>
            </ListItemButton>
          </ListItem>
        );
      }) : null;

    if (items.filteredDiscounts?.length > 0) {
      dropdownItemRows = dropdownItemRows || [];
      const itemsLength = dropdownItemRows.length;

      dropdownItemRows = dropdownItemRows.concat(items.filteredDiscounts.map((discount, discountIndex) => {

        const discountValue = discount.type === 'percent'
          ? discount.percentage + `% ${t('Off')}`
          : numeral(discount.amount).format('$0,0.00') + ` ${t('Off')}`

        const formattedColor = discount.color ? '#' + discount.color : '#888C8D';
        const avatarStyles = { width: 30, height: 30};

        const disableDiscount = itemizedCart.sub_total_amt <= 0;
        const onClick = !disableDiscount ? () => addDiscountToCart(discountIndex) : undefined;

        return (
          <ListItem key={discountIndex + itemsLength} className={classnames('discountSearchRow', { disabled: disableDiscount })} onClick={onClick} sx={addExpressItemsSearchRowStyles}>
            <Stack sx={addExpressItemsTextStyles}>
              <Stack direction='row' alignItems='center' className='leftSide'>
                <IconButton disableTouchRipple className='discountIcon' size='large'>
                  <Avatar style={{ ...avatarStyles, backgroundColor: formattedColor }}>
                    {IconUtil.getIcon('DiscountIcon', '#FFFFFF')}
                  </Avatar>
                </IconButton>
                <div className='itemName'>{discount.name}</div>
              </Stack>
              <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                <div className='itemPrice'>{discountValue}</div>
              </Stack>
            </Stack>
          </ListItem>
        );

      }));
    }

    const appRoutePrefix = globalApplicationLabel.path;

    const itemsSearchDropdown = (
      <Box className='itemsSearchDropdown fullscreen' sx={addExpressItemsSearchDropdown}>

        <div className='textField'>
          <div className='textFieldInfo'><label>{t('AddItems')}</label></div>
          <SearchBarField searchPlaceholder={t('EnterItem')}
            {...this.props}
            optionalHandleFocus={this.handleItemsSearchFocus}
            optionalHandleBlur={this.handleItemsSearchBlur}
            autoCompleteOff={true}
            filterType={'secondaryFilter'}
          />
        </div>

        {defaultTaxRate > 0 &&
        <div className='taxNote'>
          {`${t('TaxNoteText')} ${InvoiceUtil.normalizeTaxRate(defaultTaxRate)}. ${t('ConfigureTax')} `}
          <Link to={appRoutePrefix + routes.account.root + routes.account.settings} className='linkLike'>{t('Business Settings')}</Link>
        </div>
        }

        <List sx={addExpressItemsListStyles} className={this.state.isItemsSearchDropdownOpen ? '' : 'hideMe'}>
          <ListItem data-test-id='addExpressItemButton' disablePadding sx={addExpressItemButtonStyles} onClick={() => openItemsDialog(-1)}>
            <ListItemButton sx={addExpressItemsButtonStyles}>
              <ListItemText sx={addExpressItemsButtonTextStyles} primary={t('AddExpressItem')} />
            </ListItemButton>
          </ListItem>
          {dropdownItemRows}
        </List>
      </Box>
    );

    const formHasCdigits = virtualTerminal.cardType && cdigits?.length > 0;
    const isSpanishLanguage = checkIsSpanish() ? 'spanishTranslation' : '';

    const paymentMethodsState = this.getPaymentMethodsState();

    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const paymentMethodSelect = (
      <PaymentMethodSelect
        selectedPaymentMethod={currentValues?.selectedPaymentMethod}
        setPaymentMethod={this.setPaymentMethod}
        isEnteringCardManually={paymentMethodsState.isEnterCardManually}
        enterCardManually={this.handleEnterCardManually}
        isSendToCustomer={paymentMethodsState.isSendToCustomer}
        sendToCustomer={this.setSendToCustomer}
        isBankingAccount={paymentMethodsState.isBankingAccount}
        useBankingAccount={this.useBankingAccount}
        isPremiumPlusAccount={isPremiumPlusAccount}
        achEnabled={achEnabled}
        t={t}
      />
    );

    let paymentForm;
    const showSaveCreditCardToggle = (currentValues?.selectedDueDateValue === 0 || (currentValues?.selectedDueDateValue === 999 && (DateUtil.extractDate(currentValues?.customDueDate) === DateUtil.extractDate(today)))) &&
      (currentValues?.selectedSendDateValue === 0 || (currentValues?.selectedSendDateValue === 999 && (DateUtil.extractDate(currentValues?.customSendDate?._d) === DateUtil.extractDate(today))));

    const infoIcon = IconUtil.getIcon('InfoIcon', LabelUtil.getLabel().primaryColor);

    if (paymentMethodsState.isEnterCardManually) {
      paymentForm = (
        <div className='flex'>
          <p className={'autoSaveCardMessage'}>{showSaveCreditCardToggle ? t('AutoSaveCardMessageOneTimeDueDateToday') : t('AutoSaveCardMessageOneTime')}</p>
          <div className={`cardContainerWithVisibilityToggle ${formType !== 'vt' ? 'nonVtCardContainer' : ''}`}>
            {/*The label text is in business.css to stop safari from saving the card number*/}
            <Field component={TextField}
                   label=' '
                   maxLength='25'
                   name='cdigits'
                   disabled={submitting}
                   className={`cardNumber textField debitCard ${isSpanishLanguage} ${this.state.cardVisibility ? 'visible' : ''}`}
                   normalize={FormatTextUtil.formatCardNumber}
            />
            <span className='cardImage'>
                  {formHasCdigits ?
                    <img width='50' src={`${serverDomainUrl}images/cards/${virtualTerminal.cardType}.png`}/>
                    : <img width='50' src={`${serverDomainUrl}images/cards/unknown_card.png`}/>}
            </span>
            <VisibilityToggle
              visibility={this.state.cardVisibility}
              onClick={this.toggleCardNumberVisibility}/>
          </div>

          <Field
            component={TextField}
            name='edate'
            label={t('Expiration')}
            hintText='MM/YY'
            normalize={FormatTextUtil.formatCreditCardExpiration}
            className={`quarterToHalfScreen textField shrinkSpacing ${formType !== 'vt' ? 'nonVtexpirationDate' : ''}`}
            disabled={submitting}
            maxLength='5'
          />

          <Field
            name='cvv'
            component={TextField}
            label={virtualTerminal.isAmex ? 'CID' : 'CVV'}
            hintText={virtualTerminal.isAmex ? 'CID' : 'CVV'}
            maxLength={'10'}
            disabled={submitting}
            normalize={FormatTextUtil.formatWholeNumber}
            className={`quarterToHalfScreen textField ${formType !== 'vt' ? 'nonVtCVV' : ''}`}
          />
          <div className='flex'>
            <Field
              component={TextField}
              name='street_number'
              label={t('BillingStreet.Label')}
              hintText={t('BillingStreet.HintText')}
              maxLength='50'
              normalize={FormatTextUtil.formatStreetNumber}
              className={`halfScreen textField  ${formType !== 'vt' ? 'nonVtBillingStreetNumber' : ''}`}
            />

            <Field
              component={TextField}
              name='zip'
              label={t('ZipCode.Label')}
              hintText={t('ZipCode.HintText')}
              maxLength='5'
              disabled={submitting}
              normalize={FormatTextUtil.formatWholeNumber}
              className='quarterScreen textField'
            />

            <Field
              component={TextField}
              name='zipPlus4'
              label={t('ZipCodePlus.Label')}
              hintText={t('ZipCodePlus.HintText')}
              maxLength='4'
              disabled={submitting}
              normalize={FormatTextUtil.formatWholeNumber}
              className={`quarterScreen textField ${formType !== 'vt' ? 'nonVtZipPlus4' : ''}`}
            />
            <div className={`vtAvsNotice ${formType !== 'vt' ? 'nonVtNotice': ''}`}>
              {t('AvsNotice')}
            </div>
            {showSaveCreditCardToggle && (<div className='saveCreditCardToggle'>
              <Field
                checked={!!this.value}
                className='vtSwitch'
                component={CustomToggle}
                disabled={submitting}
                label={t('SaveCreditCard.Label')}
                name='saveCreditCard'
              />
              <p>
                {t('SaveCreditCard.Notice.Responsibility')}
                <br />
                {t('SaveCreditCard.Notice.Acknowledgement')}
              </p>
            </div>)}
          </div>
        </div>
      );
    } else if (paymentMethodsState.isSendToCustomer) {
      paymentForm = (
        <div className='flex'>
          <p className='sendToCustomerMessage'>
            {t(messages.invoice.sendToCustomer)}
          </p>
        </div>
      );
    } else if(paymentMethodsState.isSelectedPaymentMethod) {
      paymentForm = (
        <div className='flex'>
          <p className='autoSaveCardMessage'>{t(isSeries ? 'AutoSaveCardMessage' : (showSaveCreditCardToggle ? 'SavedCardMessageOneTimeDueDateToday' : 'SavedCardMessageOneTime'))}</p>
        </div>
      );
    } else if (paymentMethodsState.isBankingAccount) {
      paymentForm = (
        <>
          <BankingForm submitting={submitting} t={t} />
          {showSaveCreditCardToggle && (<div className='saveCreditCardToggle'>
            <Field
              checked={!!this.value}
              className='vtSwitch'
              component={CustomToggle}
              disabled={submitting}
              label={t('SaveCreditCard.Label')}
              name='saveCreditCard'
            />
            <p>
              {t('SaveCreditCard.Notice.Responsibility')}
              <br />
              {t('SaveCreditCard.Notice.Acknowledgement')}
            </p>
          </div>)}
        </>
      );
    }

    const isTipsEnabled = merchantSettings.merchantSettings?.is_tip_enabled;

    const preExistingLoyaltyRewardInfo = customers?.selectedCustomer?.loyalty_vpc_status;
    const isCustomerEnrolledInLoyalty = preExistingLoyaltyRewardInfo?.opted_in_loyalty;
    const isValidPreExistingLoyaltyReward = preExistingLoyaltyRewardInfo?.reward_is_eligible && preExistingLoyaltyRewardInfo?.reward_amount && preExistingLoyaltyRewardInfo?.reward_amount_type;

    return (
      <Grid container spacing={3} className='invoiceForm' sx={invoiceFormStyles}>
        <Grid item md={formType === 'vt' ? 7 : 8} xs={12}>

          {formType === 'vt' &&
            <Stack spacing={2} className='titleStack'>
            <h2 className='formTitle'>
              {`${t('Create an invoice')}.`}
            </h2>
              {FeatureFlagsUtil.isFeatureFlagEnabled('bulkInvoiceEnabled') &&
                <NewFeatureBadge
                  t={t} featureText={'New'}
                  tooltip={'BulkInvoices.BulkInvoicesTooltip'}
                  tooltipText={'BulkInvoices.BulkInvoicesTooltipText'}
                  onClickAction={this.onClickNewBadge}
                />}
            </Stack>
          }
          <div className='customerInfo'>

            <div className='chipContainer fullScreen'>
              <div className='textFieldInfo'>
                <label>{t('CustomerEmailAddress')}</label>
              </div>
              <Field
                adornmentEnd={!isMbp && (
                  <IconButton
                    className='selectCustomer'
                    onClick={this.displayCustomerDialog}>
                    {IconUtil.getIcon('SelectCust', '#888C8D')}
                  </IconButton>
                )}
                autoFocus={emailAutoFocus}
                component={ChipInput}
                disabled={isProcessing}
                hintText={t('EnterEmail')}
                name='customer_email_addresses'
                onAdd={(chip) => {
                  this.onChipAdd(chip);
                  this.setState({ emailAutoFocus: true });
                }}
                onBlur={(event)=> {
                  this.onChipAdd(event.target.value);
                  this.setState({ emailAutoFocus: false });
                }}
                onDelete={this.onChipDelete}
                placeholder={t('EnterEmailAddress')}
                t={t}
              />

            </div>

            {!isCbdCannabisMerchant &&
              <Field
                component={TextField}
                name='customer_phone'
                className='customerPhone'
                label={t('CustomerPhoneNumber')}
                hintText={t('EnterPhoneNumber')}
                disabled={isProcessing}
                normalize={FormatTextUtil.formatPhoneNumber}
                maxLength='50'
              />
            }

            <div className='customerName'>
              <Field
                component={TextField}
                name='customer_first'
                className='customerFirst'
                label={t('CustomerName')}
                hintText={t('EnterFirstName')}
                maxLength='50'
                disabled={isCustomerSelected || isProcessing}
              />

              <Field
                component={TextField}
                name='customer_last'
                className='customerLast'
                label={t('CustomerLastName')}
                hintText={t('EnterLastName')}
                maxLength='50'
                disabled={isCustomerSelected || isProcessing}
              />
            </div>

            {isAddressVisible
              ? <div className='addressInfo'>
                <div className='customerStreet'>
                  <div className='customerStreet1Field'>
                    <Field
                      component={TextField}
                      name='customer_street_address_1'
                      label={t('CustomerStreetAddress1')}
                      hintText={t('EnterStreetAddress1')}
                      disabled={isProcessing}
                    />
                  </div>

                  <div className='customerStreet2Field'>
                    <Field
                      component={TextField}
                      name='customer_street_address_2'
                      label={t('CustomerStreetAddress2')}
                      hintText={t('EnterStreetAddress2')}
                      disabled={isProcessing}
                    />
                  </div>
                </div>

                <div className='customerState'>
                  <Field
                    component={Select}
                    t={t}
                    label={t('BusinessForm.State')}
                    className='thirdToHalfScreen textField shipmentState'
                    name='customer_state'
                  >
                    <MenuItem value={-1} disabled={true}>{t('SelectState')}</MenuItem>
                    {selectedCountry.states.map((state, index) => (
                      <MenuItem
                        value={selectedCountry.stateCodes?.[index]}
                        key={index}
                      >{state}</MenuItem>)
                    )}
                  </Field>
                </div>

                <div className='customerCityZip'>
                  <div className='customerCity'>
                    <Field
                      component={TextField}
                      name='customer_city'
                      label={t('City')}
                      hintText={t('EnterCity')}
                      disabled={isProcessing}
                    />
                  </div>

                  <div className='customerZip'>
                    <Field
                      component={TextField}
                      name='customer_zip'
                      label={t('Zip')}
                      hintText={t('EnterZipCode')}
                      disabled={isProcessing}
                    />
                  </div>
                </div>
              </div>
              : <a onClick={this.displayCustomerAddress}>{t('AddCustomerAddress')}</a>
            }
          </div>

          <div className='invoiceInfo'>
            <div className='invoiceName' >
              <Field
                component={TextField}
                errorMessageValue={{maxLength: '50'}}
                maxLength='50'
                name='name'
                label={t('InvoiceName')}
                hintText={t('EnterInvoiceName')}
                disabled={isProcessing}
              />
              <Field
                component={TextField}
                name='invoice_number'
                label={t('InvoiceNumberText')}
                hintText={t('InvoiceNumber.HintText')}
                maxLength='25'
                disabled={isProcessing}
              />
            </div>
            <Field
              component={TextField}
              errorMessageValue={{maxLength: '100'}}
              maxLength='100'
              name='description'
              label={t('InvoiceDescription')}
              hintText={t('Optional')}
              disabled={isProcessing}
            />
          </div>
          <div className='paymentInfo'>

            <Field
              fullWidth
              label={t('InvoiceType')}
              component={Select}
              t={t}
              name='type'
              disabled={isProcessing}
            >
              {invoiceFormTypeOptions.map(({ text, value }) => <MenuItem value={value} key={value}>{t(text)}</MenuItem>)}
            </Field>

            {isCustomAmount && (<>
              <div className='customAmount'>
                <Field
                  component={TextField}
                  name='amount'
                  label={t('PaymentAmount')}
                  hintText='$0.00'
                  maxLength='14'
                  disabled={isProcessing}
                  normalize={InvoiceUtil.normalizeCurrency}
                />
                <Field
                  component={TextField}
                  name='taxRate'
                  label={t('TaxRate')}
                  maxLength='7'
                  hintText='00.000'
                  disabled={isProcessing}
                  normalize={InvoiceUtil.normalizeTaxRateValue}
                  InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                />
              </div>

            </>)}

            {isItemizedInvoice && itemsSearchDropdown}

            {isLoyaltyProgramEnabled && isCustomerEnrolledInLoyalty &&
              <>
                <label className='loyaltySectionTitle'>{t('RewardCode')}</label>

                {
                  isValidPreExistingLoyaltyReward &&
                    <div className='flex'>
                      <div className={`existingLoyaltyReward ${formType === 'vt' ? 'existingLoyaltyRewardMargin' : ''}`}>
                        <p>{`${t('LoyaltyReward')} ${preExistingLoyaltyRewardInfo.reward_amount_type === 'percent' ? `(${preExistingLoyaltyRewardInfo.reward_amount}% ${t('LoyaltyRewardOff')})` : `($${preExistingLoyaltyRewardInfo.reward_amount} ${t('LoyaltyRewardOff')})`}`}</p>
                        <CustomToggle
                          input={{
                            value: this.state.isPrePopulatedRewardActive,
                            onChange: this.togglePrePopulatedLoyaltyReward
                          }}
                        />
                      </div>
                    </div>
                }

                <div className='flex'>
                  <Field
                    component={TextField}
                    name='rewardCode'
                    maxLength=''
                    hintText={t('RewardCodeHintText')}
                    className={`textField fullScreen ${loyaltyVpc.isFetching ? 'rewardFieldFetching' : 'rewardField'}`}
                    disabled={submitting || !!this.props.loyaltyVpc.rewardCodeInfo || !isCustomerFormFilled || !!this.state.prePopulatedLoyaltyRewardInformation}
                    InputProps={{ endAdornment:
                      <>
                        {
                          isCustomerFormFilled && !this.state.isPrePopulatedRewardActive &&
                            <>
                              {
                                loyaltyVpc.isFetching ?
                                <CircularProgress/>
                                :
                                activeLoyaltyReward ?
                                    <InputAdornment position='end' onClick={this.deleteRewardDiscount}>{t('Remove')}</InputAdornment>
                                  :
                                    <InputAdornment position='end' onClick={this.validateRewardCode}>{t('Apply')}</InputAdornment>
                              }
                            </>
                        }
                      </>
                    }}
                  />
                </div>
                {
                  this.state.isInvalidCode && !currentValues.rewardCode &&
                    <p className='invalidRewardCodeIndicator'>{t('EnterValidRewardCode')}</p>
                }
              </>
            }

            {isCashDiscountPerTransactionEnabled && (
              <div className='cashDiscounting'>
                <Field
                  component={CustomToggle}
                  name='cashDiscounting'
                  disabled={isProcessing || !isCashDiscountPerTransactionEnabled}
                />
                <span className='cashDiscountLabel'>{`${t('CashDiscounting')} (${currentValues?.cashDiscountingAmount})`}</span>
                <Tooltip
                  component='span'
                  placement='right'
                  title={t(messages.virtualTerminal.cashDiscountInfo)}
                >
                  {infoIcon}
                </Tooltip>
              </div>
            )}

            { isTipsEnabled && <div className='allowTip'>
              <Field
                name='allow_tip'
                component={CustomToggle}
              />
              <span className='tipText'>
                {t('InvoiceForm.AllowCustomerTip')}
              </span>
            </div>
            }

          </div>
          <div className='paymentFrequency'>

            <Field
              label={t('Frequency')}
              component={Select}
              t={t}
              name='frequency'
              disabled={isProcessing || !!selectedInvoice}
              onChange={this.handleFrequencyChange}
            >
              {paymentFrequencyOptions.map(({ text, value }) => <MenuItem value={value} key={value}>{t(text)}</MenuItem>)}
            </Field>

            {isInvoice && sendDateSelect}

            {isSeries && (
              <>
                <div className='recurringDateFields'>
                  {sendDateSelect}

                  <Field
                    label={t('LastSendDate')}
                    component={Select}
                    t={t}
                    name='ends'
                    disabled={isProcessing}
                  >
                    {endsOptions.map(({ text, value }) => <MenuItem value={value} key={value}>{t(text)}</MenuItem>)}
                  </Field>

                  {isRecurringEndsOnDate && (
                    <Field
                      component={Select}
                      t={t}
                      name='selectedEndDateValue'
                      disabled={isProcessing}
                    >
                      {dueDateOptions.slice(1).map(({ text, value }) => (
                        <MenuItem value={value} key={value} className='selectDateMenu'>
                          <MenuDateItem
                            today={minInvoiceDate}
                            date={minInvoiceDate.clone().add(value, 'day')}
                            customText={t(text)}
                            showDate={value === currentValues?.selectedEndDateValue}
                            t={t}
                          />
                        </MenuItem>
                      ))}
                      <MenuItem
                        value={CUSTOM_DATE_VALUE}
                        key={CUSTOM_END_DATE_VALUE}
                        className='selectDateMenu'
                        onClick={this.displayDateDialog(CUSTOM_END_DATE_VALUE)}
                      >
                        <MenuDateItem today={minInvoiceDate} date={endDate} t={t}/>
                      </MenuItem>
                    </Field>
                  )}

                  {isRecurringEndsAfterNumber && (
                    <Field
                      component={TextField}
                      name='numberOfPayments'
                      label={t('NumberPayments')}
                      disabled={isProcessing}
                    />
                  )}

                </div>
                <div className='recurringRepeatFields'>
                  <Field
                    component={TextField}
                    name='recurring_repeat.repeat'
                    label={t('RepeatEvery')}
                    disabled={isProcessing}
                    normalize={FormatTextUtil.formatWholeNumber}
                  />
                  <Field
                    component={Select}
                    t={t}
                    name='recurring_repeat.recurringInterval'
                    disabled={isProcessing}
                  >
                    {timeMeasureOptions.map(({ text, value }) => <MenuItem value={value} key={value}>{t(text)}</MenuItem>)}
                  </Field>
                </div>
              </>
            )}

            <Field
              label={t('DueDateText')}
              component={Select}
              t={t}
              name='selectedDueDateValue'
              ref='selectedDueDateValue'
              disabled={isProcessing}
            >
              {dueDateOptions.map(({ text, value }) => (
                <MenuItem value={value} key={value} className='selectDateMenu'>
                  <MenuDateItem
                    today={minInvoiceDate}
                    date={minInvoiceDate.clone().add(value, 'day')}
                    customText={t(text)}
                    showDate={value === currentValues?.selectedDueDateValue}
                    t={t}
                  />
                </MenuItem>
              ))}
              <MenuItem
                value={CUSTOM_DATE_VALUE}
                key={CUSTOM_DUE_DATE_VALUE}
                className='selectDateMenu'
                onClick={this.displayDateDialog(CUSTOM_DUE_DATE_VALUE)}
              >
                <MenuDateItem today={minInvoiceDate} date={dueDate ?? currentValues?.customDueDate} t={t}/>
              </MenuItem>
            </Field>

            <div className='paymentInformation'>
              <div className='formItems'>
                {paymentMethodSelect}
                {paymentForm}
              </div>
            </div>

          </div>
        </Grid>
        <Grid item md={formType === 'vt' ? 5 : 4} xs={12}>
          <div className='summary'>
            <div className='summarySection'>
              <h3 className='summaryTitle'>{t('Purchases')}</h3>

              {isCustomAmount && (
                <div className='amounts'>
                  <span className='amountName'>{t('SaleAmount')}</span>
                  <span className='amountValue'>{FormatTextUtil.formatInvoiceCurrency(subTotalWithoutLoyalty)}</span>
                </div>
              )}

              {isItemizedInvoice && itemizedCart?.item_ids.length === 0 && itemizedCart.receipt_discount_id.length === 0 &&
              <div className='amounts'>
                <span className='amountName'>{t('NoItems')}</span>
                <span className='amountValue'>{'$0.00'}</span>
              </div>
              }

              {isItemizedInvoice && (
                <LineItemList
                  {...this.props}
                  isInvoice={true}
                  selectedReceipt={InvoiceUtil.recalculateCart(itemizedCart, currentValues, loyaltyVpc.rewardCodeInfo)}
                  itemCartHandlers={itemCartHandlers}
                  showCartDiscounts={true}
                  hideInputFields={true}
                />
              )}

              {
                activeLoyaltyReward &&
                  <div className='loyaltyDiscount'>
                    {loyaltyIcon}
                    <span>{t('LoyaltyReward')}</span>
                    <span>
                      {
                        this.props.loyaltyVpc.rewardCodeInfo?.type === 'dollar' ?
                          `${isItemizedInvoice ? numeral(itemizedCart?.loyalty_discount_amt).format('$0,0.00') : numeral(rewardDiscount).format('$0,0.00')} ${t('LoyaltyRewardOff')}`
                        :
                          `${this.props.loyaltyVpc.rewardCodeInfo?.amount}% ${t('LoyaltyRewardOff')}`
                      }
                    </span>
                  </div>
              }

              <div className='subtotals'>
                <span className='subtotalName'>{t('Subtotal')}</span>
                <span className='subtotalValue'>{FormatTextUtil.formatInvoiceCurrency(subTotal)}</span>
              </div>
              <div className='subtotals'>
                <span className='subtotalName'>{t('Tax')}</span>
                <span className='subtotalValue'>{FormatTextUtil.formatInvoiceCurrency(taxAmount)}</span>
              </div>
              <div className='subtotals total'>
                <span className='subtotalName'>{t('Total')}</span>
                <span className='subtotalValue'>{FormatTextUtil.formatInvoiceCurrency(total)}</span>
              </div>
              { !!partialPayment && (
                <div className='partialPaymentSection'>
                  <br/>
                  <div className='subtotals'>
                    <span className='subtotalName'>{t('PartialAuth', { cardInfo })}</span>
                    <span className='subtotalValue'>{formattedAuthorizedAmt}</span>
                  </div>
                  <div className='subtotals'>
                    <span className='subtotalName'>{t('BalanceRemaining')}</span>
                    <span className='subtotalValue'>{formattedRemainingBalance}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Grid>

        <Modal
          fullWidth
          maxWidth='md'
          confirmText={t('Add')}
          cancelText={t('Cancel')}
          title={t('SelectCustomer')}
          contentClassName='customerDialogContent'
          externalClassName='customerDialog'
          open={isCustomerDialogOpen}
          onConfirm={this.onCustomerSelect}
          onClose={this.hideCustomerDialog}
          isConfirmDisabled={!customers?.filteredData?.[this.state.customerIndex]}
        >
          <CustomerContactList
            {...this.props}
            customers={cleanCustomers}
            selectedIndex={customerIndex}
            onRowClick={this.onCustomerClick}
          />
        </Modal>

        <Modal
          title={t('CustomDate')}
          confirmText={t('Select')}
          open={isDateFormDialogOpen}
          onConfirm={this.onDateSelect}
          onClose={this.hideDateDialog}
        >
          <div className='datePickerFrom'>
            <Field
              label={t('EnterDate')}
              dateFormat='MM/DD/YYYY'
              component={CustomDatePicker}
              input={{
                value: modalValue,
                onChange: this.onDateChange,
              }}
              minDate={modalMinDate}
              name='customDateSelect'
            />
          </div>
        </Modal>

      </Grid>
    );
  }
}

let InvoiceFormContainer = InvoiceForm;

InvoiceFormContainer = reduxForm({
  validate,
  fields: [],
  form: INVOICE_FORM_ID,
  enableReinitialize: true,
  touchOnBlur: false,
})(InvoiceFormContainer);

function mapStateToProps(state, ownProps) {
  const currentValues = getFormValues(INVOICE_FORM_ID)(state) || {};
  const prefillCustomer = ownProps.history?.location?.state?.customerID && ownProps.customers?.selectedCustomer;
  const selectedInvoice = ownProps.selectedInvoice;
  const isSeriesDraftInvoice = selectedInvoice?.isSeries && selectedInvoice?.is_draft;
  const type = InvoiceUtil.checkInvoiceAmountType(selectedInvoice, ownProps.items);
  const subTotal = InvoiceUtil.getSubTotal(selectedInvoice, type);
  const invoiceTaxRate = InvoiceUtil.getTaxRate(selectedInvoice, type);

  const taxRate = !!selectedInvoice ? invoiceTaxRate : FormatTextUtil.formatNumberToThreeDecimals(ownProps.taxes?.taxRate);
  const isTaxableEnabled = !!invoiceTaxRate || ownProps.merchantSettings?.expressItemDefaultTaxable || ownProps.merchantSettings?.geoTaxEnabled || ownProps.taxes?.taxRate;

  const serviceFeeAmount = isSeriesDraftInvoice ? selectedInvoice?.service_fee_amount : selectedInvoice?.service_fee_amt;

  const isDiscountingEnabled = !!selectedInvoice ? ownProps.merchantSettings?.merchantSettings?.cash_discount_enabled && !!parseFloat(serviceFeeAmount) : ownProps.merchantSettings?.merchantSettings?.cash_discount_enabled;
  const tipsEnabledValue = !!isSeriesDraftInvoice ? selectedInvoice?.allow_tip : selectedInvoice?.inv_allow_tip;
  const isTipsEnabled = !!selectedInvoice && tipsEnabledValue;
  const isCashDiscountPerTransactionEnabled = ownProps.merchantSettings?.merchantSettings?.cash_discount_per_transaction_enabled;

  const seriesStartDate = !!selectedInvoice?.start_date && DateUtil.getMomentDate(selectedInvoice.start_date);
  const invoiceSendDate = !!selectedInvoice?.send_date && DateUtil.getMomentDateFromUnix(selectedInvoice.send_date);
  const startDate = seriesStartDate || invoiceSendDate;
  const sendDate = startDate && DateUtil.extractDate(startDate.isBefore(today) ? today : startDate);

  const recurringFrequency = !!selectedInvoice?.frequency ? selectedInvoice.frequency.split(' ')[0] : '1';
  const recurringInterval = !!selectedInvoice?.frequency ? selectedInvoice.frequency.split(' ')[1] : timeMeasureOptions[0].value;

  const addressIndex = selectedInvoice?.customer_addresses?.length > 0 ? selectedInvoice?.customer_addresses?.length - 1 : 0;
  const rewardCode = selectedInvoice?.loyalty_info?.reward_code;
  const rewardCodeInformation = selectedInvoice?.loyalty_info?.reward_amount ? {type: selectedInvoice?.loyalty_info?.reward_type, amount: selectedInvoice?.loyalty_info?.reward_amount} : null;
  const isCbdCannabisMerchant = UserUtil.isCbdCannabisMerchant(ownProps.user);

  const selectedPaymentMethod = ownProps.customers?.selectedCustomer?.payment_methods?.find(method => method.id === selectedInvoice?.default_payment_id) ?? null;
  const paymentMethod = !selectedPaymentMethod ? PaymentMethod.SEND_TO_CUSTOMER : null;

  return {
    currentValues,
    isProcessing: false,
    initialValues: {
      allow_tip: isTipsEnabled,
      amount: !!subTotal ? FormatTextUtil.formatCurrencyDoubleDecimals(subTotal) : '',
      name: selectedInvoice?.name || '',
      description: selectedInvoice?.description || '',
      invoice_number: selectedInvoice?.invoice || '',
      customer_first: selectedInvoice?.first_name || prefillCustomer?.first_name || '',
      customer_last: selectedInvoice?.last_name || prefillCustomer?.last_name || '',
      customer_phone: !!(selectedInvoice?.phone_number || prefillCustomer?.phone_number) ? FormatTextUtil.formatPhoneNumber(selectedInvoice?.phone_number || prefillCustomer?.phone_number) : '',
      customer_email_addresses: ((selectedInvoice?.email_addresses?.length || prefillCustomer?.email_addresses?.length) > 0) ? (selectedInvoice?.email_addresses || prefillCustomer?.email_addresses) : [],
      customer_zip: selectedInvoice?.customer_addresses?.[addressIndex]?.zip || '',
      customer_state: InvoiceUtil.getCustomerState(selectedInvoice?.customer_addresses?.[addressIndex]?.state),
      customer_city: selectedInvoice?.customer_addresses?.[addressIndex]?.city || '',
      customer_street_address_1: selectedInvoice?.customer_addresses?.[addressIndex]?.street_address_1 || '',
      customer_street_address_2: selectedInvoice?.customer_addresses?.[addressIndex]?.street_address_2 || '',
      [CUSTOM_DUE_DATE_VALUE]: !!selectedInvoice?.due_date ? DateUtil.getMomentDate(selectedInvoice.due_date) : null ,
      [CUSTOM_END_DATE_VALUE]: !!selectedInvoice?.end_date ? DateUtil.getMomentDate(selectedInvoice.end_date) : null,
      [CUSTOM_SEND_DATE_VALUE]: sendDate || null,
      numberOfPayments: selectedInvoice?.end_amount_payments || '1',
      ends: InvoiceUtil.getEndsOption(selectedInvoice),
      type,
      frequency: !!selectedInvoice?.isSeries ? PaymentFrequency.SERIES : PaymentFrequency.INVOICE,
      paymentMethod,
      selectedPaymentMethod,
      saveCreditCard: false,
      cashDiscountingAmount: 0.00,
      cashDiscounting: isDiscountingEnabled,
      selectedDueDateValue: !!selectedInvoice?.due_date && !!sendDate
        ? InvoiceUtil.getDateOption(selectedInvoice.due_date, sendDate, dueDateOptions)
        : dueDateOptions[0].value,
      selectedSendDateValue: !!sendDate && !!selectedInvoice.created_date
        ? InvoiceUtil.getDateOption(sendDate, selectedInvoice.created_date, sendDateOptions)
        : sendDateOptions[0].value,
      selectedEndDateValue: !!selectedInvoice?.end_date && !!selectedInvoice.created_date
        ? InvoiceUtil.getDateOption(selectedInvoice.end_date, selectedInvoice.created_date, dueDateOptions)
        : dueDateOptions[1].value,
      recurring_repeat: { repeat: recurringFrequency, recurringInterval },
      taxRate: isTaxableEnabled && taxRate ? FormatTextUtil.formatPercentageWithoutSymbol(taxRate) : '',
      rewardCode: rewardCode ? rewardCode : '',
      rewardCodeInformation,
      isCbdCannabisMerchant,
      accountType: 'checking'
    },
    isCashDiscountPerTransactionEnabled,
  };
}

export default connect(mapStateToProps)(InvoiceFormContainer);
