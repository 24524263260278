/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { getTinValidation } from '../../actions/validationHistoryActions';
import Loading from '../Loading';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHeader from '../shared/TableHeader';
import { Link } from 'react-router-dom';
import FilterPanel from '../shared/FilterPanel';
import messages from '../../constants/messages';
import Button from '../shared/Button';
import Modal from '../shared/Modal';
import UserUtil from '../util/UserUtil';
import { getForms, getForm } from '../../actions/formsActions';
import NoData from '../NoData';
import PdfExporter from '../util/PdfExporter';
import Drawer from '../shared/Drawer';
import Page from '../shared/Page';

export default class TinValidation extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.handleUpdateInstructionOpen = this.handleUpdateInstructionOpen.bind(this);
    this.handleUpdateInstructionClose = this.handleUpdateInstructionClose.bind(this);
    this.handleOpenAssistanceDialog = this.handleOpenAssistanceDialog.bind(this);
    this.handleCloseAssistanceDialog = this.handleCloseAssistanceDialog.bind(this);
    this.handleOpenDrawer = this.handleOpenDrawer.bind(this);
    this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
    this.downloadForm = this.downloadForm.bind(this);

    this.state = {
      openUpdateInstructionDialog: false,
      openAssistanceDialog: false,
      open: false
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData(this.props);
  }

  loadData(props) {
    props.dispatch(getTinValidation(props.user));
    props.dispatch(getForms(props.user));
  }

  handleUpdateInstructionClose() {
    this.setState({ openUpdateInstructionDialog: false });
  }

  handleUpdateInstructionOpen() {
    this.setState({ openUpdateInstructionDialog: true });
  }

  handleOpenAssistanceDialog() {
    this.setState({ openAssistanceDialog: true });
  }

  handleCloseAssistanceDialog() {
    this.setState({ openAssistanceDialog: false });
  }

  handleOpenDrawer() {
    this.setState({ open: true });
  }

  handleCloseDrawer() {
    this.setState({ open: false });
  }

  downloadForm(filename) {
    this.props.dispatch(getForm(this.props.user, filename)).then(formPdf => {
      if (formPdf.response && formPdf.response.data) {
        PdfExporter.download(formPdf.response.data, filename);
      }

    });
  }

  render() {

    const { isFetching, validationHistory, user, forms, t } = this.props;

    const isEmpty = validationHistory.data === null;

    const pageLoading =  isEmpty && isFetching;

    if (pageLoading) {
      return  (
        <Page
          loading
          title={t('Business Settings')}
          initialSubpage={t('1099K & tax')}
        />
      )
    }

    const downloadIcon = IconUtils.getIcon('FileDownload', '#808080', 20);
    const mailIcon = IconUtils.getIcon('EmailIcon', '#808080', 20);
    const printIcon = IconUtils.getIcon('PrintIcon', '#808080', 20);

    const isHumboldt =  UserUtil.isHumbolt(user);
    const emailToShow =  isHumboldt ? 'customerservice@hbms.com' : '1099K@paymentshub.com';
    const selectedMerchantAccount =  UserUtil.getActiveAccount(user);

    const filterPanel =  (
      <FilterPanel
        {...this.props}
        filterData={this.props.settingsMenuItems}
        selectFilterCallback={this.props.handleMenuSelection}
      />
    );

    const w9FormLink =  (<a className='w9' href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank'>{downloadIcon}Download W-9</a>);

    const corpNameChangeForm =  (<Link className='w9' to={messages.legal.businessInformationChangeRequestform} target='_blank'>{downloadIcon}Download the Business Information Change Request form</Link>);

    const corrections = ' For corrections, please include the following information: %0D' +
      ' * Your Merchant Identification Number (MID) %0D' +
      ' * Tax filing name: %0D' +
      ' * The tax year(s): %0D' +
      ' * The information you need corrected on your 1099-K: ';

    const correctionOfForm =  (
      <div className='correctionOfForm'>
        <span>{'Request a correction on your form  '}</span>
        <a target='_blank' href={`mailto:${emailToShow}?subject=1099K Correction - MID ${selectedMerchantAccount.mid}&body=${corrections}`}>{'here'}</a>
      </div>
    );

    const toolTipContent =  (
      <div>
        <h4>{'How do I identify my Tax Filing Name (Legal/Corporate Name)?'}</h4>
        <p>{'The tax filing name is the business name or personal name you use to file your tax returns.'}</p>
        <p>{'If the business is an Individual or Sole Proprietor, the tax filing name is your personal name.'}</p>
        <p>{'If the business is a corporation; partnership; limited liability company (with an entity designation); non-profit organization; tax-exempt organization; government organization; or association/estate/trust, then your tax filing name is your business’ legal/corporate name.'}</p>
        <p>{'For assistance confirming tax identification information, call the IRS at 800.829.1040 or contact your local Social Security Administration. The IRS will not disclose your correct business name or TIN to us, however, it will confirm if the information you provided is correct or incorrect.'}</p>
      </div>
    );

    const listHeaders =  (
      <TableRow className='tableRow'>
        <TableHeader
          type='table'
          headerId={'customerHeaderOne'}
          name={'Legal Tax Filing Name'}
          hasTooltip
          toolTipContent={toolTipContent}
          toolTipId={'fillingNameTooltipId'}
        />
        <TableHeader
          type='table'
          headerId={'customerHeaderTwo'}
          name={'Federal Tax ID'}
          className='hideMobile' />
        <TableHeader
          type='table'
          headerId={'customerHeaderThree'}
          name={'TIN Type'} />
        <TableHeader
          type='table'
          headerId={'customerHeaderFour'}
          name={'Business Type'} />
        <TableHeader
          type='table'
          headerId={'customerHeaderFive'}
          name={'Response'} />
      </TableRow>
    );

    const tinHistory =  validationHistory.data.length > 0 ?
      validationHistory.data.map((item, index) => {
        const responseIcon = item.response === true ? 'CheckCircle' : 'Clear';

        return (
          <TableRow key={index} className='tableRow tinValidationData'>
            <TableCell variant='footer'>{item.corporate_name}</TableCell>
            <TableCell variant='footer' className='hideMobile'>{item.federal_tax_id}</TableCell>
            <TableCell variant='footer'>{item.tin_type}</TableCell>
            <TableCell variant='footer'>{item.business_type}</TableCell>
            <TableCell variant='footer'>{IconUtils.getIcon(responseIcon, LabelUtil.getLabelColor())}</TableCell>
          </TableRow>
        );
      }) : (
        <TableRow key='noDataTinValidation' className='fullScreen noDataTinValidation'>
          <TableCell variant='footer'></TableCell>
          <TableCell variant='footer'></TableCell>
          <TableCell variant='footer'></TableCell>
          <TableCell variant='footer'></TableCell>
          <TableCell variant='footer'></TableCell>
        </TableRow>
      );

    const updateInstructionButton =  (
      <Button
        label='View update instructions'
        disabled={false}
        onClick={this.handleUpdateInstructionOpen}
        className='updateInstruction'
      />
    );

    const download1099kButton =  (
      <Button
        label='Download 1099-K'
        disabled={false}
        onClick={this.handleOpenDrawer}
        className='download1099k'
      />
    );

    const updateInstructionDialog =  (
      <Modal
        externalClassName='updateInstructionDialog'
        maxWidth='md'
        confirmText='Done'
        title='How to update taxpayer information.'
        open={this.state.openUpdateInstructionDialog}
        onConfirm={this.handleUpdateInstructionClose}
        onClose={this.handleUpdateInstructionClose}
        hideCancelButton
      >
        <p className='titleText'>{'To update your account, please provide one of the following:'}</p>
        <ul className='updateInstructionDialogList'>
          <li className='text'>{'Completed and signed W-9 form (Rev March 2024)'}</li>
          <li className='text'>{'IRS-issued SS-4 letter or LTR147C'}</li>
        </ul>
        {w9FormLink}
        <p className='text'>{'Send your document by mail or by fax:'}</p>
        <div className='contactInfo'>
          <div className='address'>
            {mailIcon}
            <div className='addressText'>
              <span className='titleText'>{'Mail'}</span>
              <span className='text'>{'ATTN: 1099K Payments Hub'}</span>
              <span className='text'>{'250 Stephenson Hwy'}</span>
              <span className='text'>{'Troy, MI 48083'}</span>
            </div>
          </div>
          <div className='fax'>
            {printIcon}
            <div className='faxText'>
              <span className='titleText'>{'Fax'}</span>
              <span className='text'>{'248.283.6243'}</span>
            </div>
          </div>
        </div>
        <div className='detailsRow headerDivider' />
        <p className='titleText'>{'If you need to update your tax filing name, your account may be subject to being underwritten. You’ll need to provide: '}</p>
        <ul className='updateInstructionDialogList'>
          <li className='text'>{'Completed and signed Business Information Change Request form'}</li>
          <li className='text'>{'One of the aforementioned tax documents (W-9, SS-4 letter, LTR 147C)'}</li>
        </ul>
        {corpNameChangeForm}
        <p className='titleText'>{'To update your tax filing name and tax ID number, please contact your sales rep for further assistance. '}</p>
      </Modal>
    );

    const viewDetailsButton =  (
      <div className='linkLike' onClick={this.handleOpenAssistanceDialog}>
        <span>{'View 1099-K assistance details'}</span>
      </div>
    );

    const assistanceDialog =  (
      <Modal
        title='1099-K assistance'
        open={this.state.openAssistanceDialog}
        confirmText='Done'
        maxWidth='sm'
        hideCancelButton
        externalClassName='assistanceDialog'
        onConfirm={this.handleCloseAssistanceDialog}
        onClose={this.handleCloseAssistanceDialog}
      >
        <div className='content assistanceModal'>
          <span className='titleText'>{'Copies'}</span>
          <br />
          <br />
          <span className='text'>{`If your Form 1099K is not available within Payments Hub and you want to request a copy of your 1099K, contact Customer Service at ${LabelUtil.getLabel().phoneNumber}.`}</span>
          <br />
          <br />
          <span className='titleText'>{'Corrections'}</span>
          <br />
          <br />
          <span className='text'>{'For corrections, please include the following information:'}</span>
          <br />
          <span className='text'>{'• Your Merchant Identification Number (MID)'}</span>
          <br />
          <span className='text'>{'• Tax filing name'}</span>
          <br />
          <span className='text'>{'• The tax year(s)'}</span>
          <br />
          <span className='text'>{'• The information you need corrected on your 1099-K'}</span>
          <br />
          <br />
          <div className='formCopyLink'>
            {mailIcon}
            <span className='textWithLink'>{correctionOfForm}</span>
          </div>
        </div>
      </Modal>
    );

    const forms1099k =  (
      <div className='drawerContent'>
        <div className='title'>
          <span className='titleText'>{'Download 1099-K'}</span>
        </div>
        <div className='drawerItems'>
          { forms.data && forms.data.length > 0 ?
            forms.data.map((item, index) => {
              return (
                <div key={index} className='fullScreen' style={{display: 'flex', alignItems: 'center', fontSize: '13px', marginTop: '13px', marginBottom: '19px'}}>
                  <div className='formLinks' style={{marginRight: '10px'}}>
                    <span  className='downloadFormIcon' style={{cursor: 'pointer', height: '24px'}} onClick={()=>this.downloadForm(item)}>{IconUtils.getIcon('FileDownload', '#808080')}</span>
                  </div>
                  <div style={{padding: '0', height: '24px', overflow: 'visible', margin: '0'}}>
                    {item}
                  </div>
                </div>
              );
            }) : (<NoData text='No forms found' />)}
        </div>
      </div>
    );

    const formsDrawer =  (
      <Drawer
        open={this.state.open}
        onClose={this.handleCloseDrawer}
        hideFooter
      >
        {forms1099k}
      </Drawer>
    );


    return (
      <section className='accountTinMatching'>
        <div className='pageWrap'>
          <Page
            loading={pageLoading}
            title={t('Business Settings')}
            initialSubpage={t('1099K & tax')}
          >
            <div className='flexContainer flexContainerResponsiveLayout'>
              {filterPanel}
              <div className='businessSettingsSubsection tinValidationItems'>
                <div className='headerTitleContainer'>
                  <span className='titleText'>{'1099K & tax'}</span>
                </div>
                <div className='subheading'>
                  <span className='subheadingText'>{'Tax'}</span>
                </div>
                <div className='tinContent'>
                  <span className='subheadingText'>{'IRS TIN Match'}</span>
                  <br />
                  <br />
                  <span className='tinContent'>{t(messages.tin.tinContent)}</span>
                  <span className='tinImportant'>{t(messages.tin.tinImportantText)}</span>
                  <br />
                  <br />
                  <br />
                  {updateInstructionButton}
                </div>
                <p className='subheadingText'>{'TIN Validation History'}</p>
                <Table className='tinValidationList' style={{ width: '100%' }}>
                  <TableBody
                    className='tinValidationHistoryTable'>
                    {listHeaders}
                    {tinHistory}
                  </TableBody>
                </Table>
                <div className='detailsRow headerDivider' />
                <div className='tinContent'>
                  <h4 className='subheading'>{'1099-K'}</h4>
                  {download1099kButton}
                  <h5 className='tinContent question'>{'FAQ'}</h5>
                  {messages.tinFaq.map((element, qi) =>
                    (
                      <div key={`question-${qi}`}>
                        <p className='tinContent question' >{t(element.question)}</p>
                        { element.answer.map((answer, ai) => (
                          <p className='tinContent answer'
                             key={`answer-${qi}.${ai}`}
                          >{t(answer)}</p>))}
                      </div>
                    )
                  )}

                  <br />
                  <br />

                  <p className='tinContent'>
                    {'Please review the '}<a style={{ color: LabelUtil.getLabelColor(), textDecoration: 'none' }} href={messages.tin.k1099PolicyLink} target='_blank'>{'1099K Policy Statement'}</a>{' and for more information, see the '}<a style={{ color: LabelUtil.getLabelColor(), textDecoration: 'none' }} href={messages.tin.k1099BestPractices} target='_blank'>{'1099K Best Practices'}</a>{' and '}<a style={{ color: LabelUtil.getLabelColor(), textDecoration: 'none' }} href={messages.tin.k1099PolicyFaq} target='_blank'>{'1099K Policy FAQ'}</a>{'.'}</p>
                  {viewDetailsButton}
                </div>
              </div>
            </div>
          </Page>
        </div>
        <>
          {updateInstructionDialog}
          {assistanceDialog}
          {formsDrawer}
        </>
      </section>
    )
  }
}

TinValidation.defaultProps = {
  isFetching: true,
};
