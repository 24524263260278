/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';;
import { Box, MenuItem } from '@mui/material';

import countryList from '../../constants/countryStateList';

import Select from '../shared/Select';
import TextField from '../shared/TextField';
import Tooltip from '../shared/Tooltip';

import FormatTextUtil from '../util/FormatTextUtil';
import IconUtil from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import Validator from '../util/Validator';

import { inputContainer, largeInput, smallInput } from '../../jss/activationStyles';

export const validate = (values) => Validator.validateActivateBusinessForm(values);

export const onSubmit = () => null;

export class ActivationBusinessFormContainer extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { handleSubmit , t } = this.props;

    const businessTypes = ['Test type 1', 'Test type 2']; //TODO: To be replaced by api values

    const accountTypes = ['Checking', 'Savings'];

    const label = LabelUtil.getLabel();
    const infoIcon = IconUtil.getIcon('InfoIcon', label.primaryColor);


    return (
      <Box component='form' id='activationBusinessForm' onSubmit={handleSubmit}>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('Activation.Flow.Step2.LegalBusinessName')}
              hintText={t('Activation.Flow.Step2.Hint.LegalBusinessName')}
              component={TextField}
              fullWidth
              name='businessName'
              id='businessName'
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('DBAName')}
              hintText={t('Activation.Flow.Step2.Hint.DBAName')}
              component={TextField}
              fullWidth
              name='dbaName'
              id='dbaName'
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('Activation.Flow.Step2.BusinessType')}
              component={Select}
              fullWidth
              name='businessType'
            >
              <MenuItem value={-1} disabled={true}>{t('Select')}</MenuItem>
              {businessTypes.map((businessType, index) => (
                <MenuItem value={businessType[index]} key={index}>{businessType}</MenuItem>
              ))}
            </Field>
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('Activation.Flow.Step2.FederalTaxID')}
              hintText={t('Activation.Flow.Step2.Hint.FederalTaxID')}
              component={TextField}
              fullWidth
              name='taxId'
              id='taxId'
              normalize={FormatTextUtil.formatTaxId}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('CommunicationSettingsForm.BasicAccountAlerts.BusinessEmailLabel')}
              hintText={t('Activation.Flow.Step2.Hint.BusinessEmail')}
              component={TextField} 
              fullWidth
              name='businessEmail'
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('Activation.Flow.Step2.BusinessPhone')}
              hintText={t('EnterPhoneNumber')}
              component={TextField} 
              fullWidth 
              name='businessPhone'
              normalize={FormatTextUtil.formatPhoneNumber}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('BusinessAddress')}
              hintText={t('Activation.Flow.Step1.Hint.Address')}
              component={TextField}
              fullWidth
              name='businessAddress'
              id='businessAddress'
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('Activation.Flow.Step1.Apt')}
              hintText={t('Activation.Flow.Step1.Hint.Apt')}
              component={TextField}
              fullWidth
              name='aptNumber'
              id='aptNumber'
              normalize={FormatTextUtil.formatAlphaNumeric}
            />
          </Box>
        </Box>

        <Box sx={inputContainer}>
          <Box sx={largeInput}>
            <Field
              label={t('City')}
              hintText={t('EnterCity')}
              component={TextField}
              fullWidth
              name='city'
              id='city'
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('State')}
              className='selectField'
              component={Select}
              fullWidth
              name='state'
            >
              <MenuItem value={-1} disabled={true}>{t('Select')}</MenuItem>
              {countryList[0].states.map((state, index) => (
                <MenuItem value={countryList[0].stateCodes?.[index]} key={index}>{state}</MenuItem>
              ))}
            </Field>
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('Activation.Flow.Step1.Zip')}
              hintText={t('Activation.Flow.Step1.Hint.Zip')}
              component={TextField}
              fullWidth
              name='zip'
              id='zip'
              normalize={FormatTextUtil.formatZip}
            />
          </Box>
        </Box>

        <div className='stepContainer__title secondTitle'>
          <div className='title'>{t('Activation.Flow.Step2.SecondTitle')}</div>
          <div className='tooltipHolder'>
              <Tooltip
                placement='top'
                title={t('Activation.Flow.Step2.DepositToolTip')}
              >
                <p>{infoIcon}</p>
              </Tooltip>
            </div>
        </div>

        <Box sx={inputContainer}>
          <Box sx={smallInput} className={'accountType'}>
            <Field
              label={t('Activation.Flow.Step2.DepositAccountType')}
              component={Select}
              fullWidth
              name='accountType'
            >
              <MenuItem value={-1} disabled={true}>{t('Select')}</MenuItem>
              {accountTypes.map((accountType, index) => (
                <MenuItem value={accountType} key={index}>{t(accountType)}</MenuItem>
              ))}
            </Field>
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('AccountNumber')}
              hintText={t('Activation.Flow.Step2.Hint.AccountNumber')}
              component={TextField}
              fullWidth
              name='accountNumber'
              id='accountNumber'
              maxLength={17}
              normalize={FormatTextUtil.formatWholeNumber}
            />
          </Box>
          <Box sx={smallInput}>
            <Field
              label={t('RoutingNumber')}
              hintText={t('Activation.Flow.Step2.Hint.RoutingNumber')}
              component={TextField}
              fullWidth
              name='routingNumber'
              id='routingNumber'
              maxLength={9}
              normalize={FormatTextUtil.formatWholeNumber}
            />
          </Box>
        </Box>
        <p className='inputDescription'>{t('Activation.Flow.Step2.DepositAccountDescription')}</p>
      </Box>
    )
  }
}

let ActivationBusinessForm = reduxForm({
  form: 'activationBusinessForm',
  validate,
  enableReinitialize: true,
  onSubmit
})(ActivationBusinessFormContainer);

export function mapStateToProps(state) {
  const { activation } = state;
  const currentValues = getFormValues('activationBusinessForm')(state) || {};

  return {
    initialValues: activation.activationBusinessForm,
    currentValues
  }
}

ActivationBusinessForm = connect(mapStateToProps)(ActivationBusinessForm);

export default ActivationBusinessForm;
