import moment from 'moment-timezone';
import FormatTextUtil from './FormatTextUtil';
import emailValidator from 'email-validator';
import IconUtil from './IconUtil';
import PaymentUtil from './PaymentUtil';
import CommonUtil from './CommonUtil';

const CustomerUtil = {

  cleanCustomers: (customers) => {
    if (customers && customers.filteredData && Array.isArray(customers.filteredData)) {
      customers.filteredData = customers.filteredData.filter(
        (customer) =>
          customer.first_name &&
          customer.first_name.trim() &&
          customer.last_name &&
          customer.last_name.trim() &&
          ((customer.email_addresses &&
              customer.email_addresses.length &&
              customer.email_addresses[0]) ||
            (customer.phone_number && customer.phone_number.trim()))
      );
    }
    return customers;
  },

  formatPaymentMethod(paymentMethod = {}) {
    
    if (!paymentMethod?.cdigits && !paymentMethod?.accountNumber) {
      return paymentMethod;
    }
    const result = {};
    if (paymentMethod?.cdigits) {
      const zipCode = paymentMethod.zipPlus4 ? paymentMethod.cardZip + paymentMethod.zipPlus4 : paymentMethod.cardZip;
      const expYear = moment(paymentMethod.edate, 'MM/YY').year();
      const expMonth = moment(paymentMethod.edate, 'MM/YY').month() + 1;
      result.type = 'card'
      zipCode && (result.zip = zipCode);
      expMonth && (result.exp_month = expMonth);
      expYear && (result.exp_year = expYear);
      paymentMethod.cvv && (result.cvv = paymentMethod.cvv);
      paymentMethod.streetNumber && (result.address = paymentMethod.streetNumber);
      paymentMethod.cardHolderName && (result.name_on_card = paymentMethod.cardHolderName);
      paymentMethod.cdigits && (result.number = FormatTextUtil.formatWholeNumber(paymentMethod.cdigits));
      paymentMethod.is_default && (result.is_default = paymentMethod.is_default);
    } else {
      const [firstName, lastName] = paymentMethod.accountName.split(' ');
      result.type = 'ach';
      paymentMethod.accountNumber && (result.account_nbr = paymentMethod.accountNumber);
      paymentMethod.routingNumber && (result.routing_nbr = paymentMethod.routingNumber);
      firstName && (result.first_name = firstName);
      lastName && (result.last_name = lastName);
      paymentMethod.accountType = (result.account_type = paymentMethod.accountType);
      paymentMethod.is_default && (result.is_default = paymentMethod.is_default);
    }
    return result;
  },

  formatPaymentMethodsBody(values) {
    let formattedPaymentMethods = values?.payment_methods?.map(this.formatPaymentMethod) || [];

    if (formattedPaymentMethods.every(paymentMethod => !paymentMethod.is_default)) {
      const [firstPaymentMethod] = formattedPaymentMethods;
      if (firstPaymentMethod) {
        firstPaymentMethod.is_default = true;
      }
    }
    return formattedPaymentMethods;
  },

  hasValidEmail: (emailArray) => {
    let validEmail = false;
    emailArray?.forEach((email) => {
      if (emailValidator.validate(email)) {
        validEmail = true;
      }
    });
    return validEmail;
  },

  hasValidPhoneNumber: (phoneNumber) => {
    const phoneNumberRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    return phoneNumberRegEx.test(phoneNumber);
  },
  
  renderPaymentMethod: (paymentMethod) => {
    const paymentMethodInfo = {};
    if (paymentMethod?.type === 'ach') {
      paymentMethodInfo.img = PaymentUtil.getCardImage('ach').cardImgUrl;
      paymentMethodInfo.accountType = CommonUtil.capitalizeFirstLetter(paymentMethod?.ach_type);
      paymentMethodInfo.paddedNumber = `**** **** **** ${paymentMethod?.account_num_last4}`;
      paymentMethodInfo.routingNumber = paymentMethod?.routing_num_last4;
    } else {
      paymentMethodInfo.img = PaymentUtil.getCardImage(paymentMethod?.network).cardImgUrl;
      paymentMethodInfo.expMonth = paymentMethod?.exp_month;
      paymentMethodInfo.expYear = paymentMethod?.exp_year;
      paymentMethodInfo.paddedNumber = `**** **** **** ${paymentMethod?.last4}`;
    }
    paymentMethodInfo.type = paymentMethod?.type ?? 'card';
    paymentMethodInfo.deleteIcon = IconUtil.getIcon('DeleteIcon');
    paymentMethodInfo.id = paymentMethod?.id;
    return paymentMethodInfo;
  }

};

export default CustomerUtil;
