/**
  North American Bancard ("NAB") CONFIDENTIAL MATERIAL

  Copyright 2000 NAB, All Rights Reserved.

  NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
  contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
  protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
  strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
  is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
  and Non-disclosure agreements explicitly covering such access.

  The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
  which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
  ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
  CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
  INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
  IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
  MAY DESCRIBE, IN WHOLE OR IN PART.

  */

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import messages from '../../../constants/messages';
import Button from '../../shared/Button';
import ReverseButton from '../../shared/ReverseButton';

const useStyles = makeStyles({
  paper: { borderRadius: '15px' },
});

export default function MerchantVerifyByCSDialog({ acceptAction, closeAction, t }) {
  const yesButton = <Button label={'Yes'} onClick={acceptAction} />;
  const noButton = <ReverseButton label={'No'} onClick={closeAction} />;
  const classes = useStyles();

  return (
    <Dialog
      className='messageDialog'
      id='MerchantVerifyByCSDialog'
      onClose={closeAction}
      open
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className='dialogTitle'>{t(messages.merchantVerifyByCSDialog.title)}</DialogTitle>
      <DialogContent className='dialogBody editBusinessProfile'>
        {t(messages.merchantVerifyByCSDialog.text)}
      </DialogContent>
      <DialogActions className='dialogActionsMerchantVerifyBCS'>
        {noButton}
        {yesButton}
      </DialogActions>
    </Dialog>
  );
};

MerchantVerifyByCSDialog.propTypes = {
  acceptAction: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
