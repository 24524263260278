import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import ReverseButton from './ReverseButton';

const menuStyle = { maxHeight: 500 };

const MenuButton = ({ type = 'reverseButton', icon, name, label, options, onChange, disabled, labelPosition }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='menuButton'>
      {
        type === 'reverseButton'
          ? (
            <ReverseButton
            icon={icon}
            label={name}
            disabled={disabled}
            labelposition={labelPosition}
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          />
        )
        : (
          <IconButton className='menuIconButton' onClick={handleClick} size='large'>
            <div className='content'>
              <div className='icon'>{icon}</div>
              <div className='label'>{name}</div>
            </div>
          </IconButton>
        )
      }
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={menuStyle}
      >
        {label && <MenuItem key='menu-item-label' value='label' disabled>{label}</MenuItem>}
        {options.map(({ name, icon, value, disabled, onClick }, index) => (
          <MenuItem
            key={`menu-item-${index}-${name}`}
            value={value}
            disabled={disabled}
            onClick={onClick ? onClick : () => onChange(value)}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

MenuButton.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  labelPosition: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.element,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  })).isRequired,
};

export default MenuButton;

