/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import query from 'query-string';
import { change, reset, submit } from 'redux-form';
import moment from 'moment';
import Loading from '../Loading';
import UpdateSpinner from '../UpdateSpinner';
import OnlinePaymentForm from './OnlinePaymentForm';
import { setModalVisibility, setViewOrderSummary } from '../../actions/userExperienceActions';
import { clearPayNowCardPresent } from '../../actions/authActions';
import {
  getInvoiceToPay,
  updateInvoiceToPayNow
} from '../../actions/invoicesActions';
import { getPayNowCustomerPaymentMethods } from '../../actions/customerActions';
import { roundToTwoDecimals } from '../util/CommonUtil';
import PaymentUtil from '../util/PaymentUtil';
import numeral from 'numeral';
import NoData from '../NoData';
import MerchantLogo from '../MerchantLogo';
import messages from '../../constants/messages';
import { highTransactionLimitAmount } from '../../constants/applicationConstants';
import Button from '../shared/Button';
import MessageDialog from '../shared/MessageDialog';
import CardPresentUtil from '../util/CardPresentUtil';
import {
  setCardPresentRoomInfo,
  updateCardPresentState
} from '../../actions/cardPresentActions';
import FormatTextUtil from '../util/FormatTextUtil';
import { validateRewardCodeTokenFromInvoice } from '../../actions/loyaltyVpcActions';
import InvoiceUtil from '../util/InvoiceUtil';
import LabelUtil from '../../components/util/LabelUtil';
import UserUtil from '../util/UserUtil';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import ReverseButton from '../shared/ReverseButton';
import IconUtils from '../util/IconUtil';
import LoyaltyReward from '../invoice/LoyaltyReward';
import Modal from '../shared/Modal';
import HighAmountModal from '../business/HighAmountModal';

export default class OnlinePayment extends Component {

  constructor(props) {

    super(props);

    this.process = this.process.bind(this);
    this.processApplePay = this.processApplePay.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onPayClick = this.onPayClick.bind(this);
    this.hideOrderSummary = this.hideOrderSummary.bind(this);
    this.toggleCardPresent = this.toggleCardPresent.bind(this);
    this.setCardPresentRoomData = this.setCardPresentRoomData.bind(this);
    this.submitLoyaltyRewardCodeForm = this.submitLoyaltyRewardCodeForm.bind(this);
    this.calculateReward = this.calculateReward.bind(this);
    this.removeReward = this.removeReward.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.submitLoyaltyRewardCodeForm = this.submitLoyaltyRewardCodeForm.bind(this);
    this.generateInvoiceRequestJson = this.generateInvoiceRequestJson.bind(this);
    this.mapItemizedItems = this.mapItemizedItems.bind(this);
    this.setInitialReward = this.setInitialReward.bind(this);
    this.openLoyaltyModal = this.openLoyaltyModal.bind(this);
    this.closeLoyaltyModal = this.closeLoyaltyModal.bind(this);
    this.activatePrePopulatedReward = this.activatePrePopulatedReward.bind(this);
    this.removePrepopulatedReward = this.removePrepopulatedReward.bind(this);
    this.closeHighAmountModal = this.closeHighAmountModal.bind(this);
    this.openHighAmountModal = this.openHighAmountModal.bind(this);

    this.state = {
      paymentCompleted: false,
      paymentError: null,
      loading: false,
      entityId: null,
      cardPresentPendingFormData: null,
      cardPresentUtil: null,
      renderer: false,
      openLoyaltyRewardForm: false,
      rewardDiscount: null,
      rewardLoaded: null,
      itemizedCart: InvoiceUtil.initialItemizedCartObject(),
      dataInvoice: {},
      totalAmount: 0,
      tipAmount: 0,
      loyaltyModalOpen: false,
      loyalty: null
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();

    this.setState({cardPresentUtil: new CardPresentUtil(this.props.dispatch, 'payNow')});

  }

  componentWillUnmount() {
    /* istanbul ignore else */
    if (this.props.cardPresent.isUsing && this.state.cardPresentUtil && this.state.cardPresentUtil.connectionState > 3) {
      this.toggleCardPresent();
      this.state.cardPresentUtil.close();
    }
  }

  mapItemizedItems(invoice) {
    const { itemizedCart } = this.state;
    const updateItemizedCart = {};
    for (let key in itemizedCart) {
      if (invoice && invoice.hasOwnProperty(key)) {
        updateItemizedCart[key] = invoice[key];
      }
    }

    this.setState({
      itemizedCart: {...itemizedCart, ...updateItemizedCart},
      dataInvoice: invoice
    });
  }

  setInitialReward(invoice) {
    if (invoice?.loyalty_vpc) {
      invoice.receipt_discount_name?.map((disc, discIndex) => {
        if (invoice.receipt_discount_type[discIndex] === 'Loyalty Discount') {
          this.setState({
            rewardDiscount: {
              amount: Number(invoice.receipt_discount_amt[discIndex]),
              type: 'dollar'
            }, rewardLoaded: Number(invoice.receipt_discount_amt[discIndex])
          })
        }
      });
    }
  }

  calculateReward(subtotal, rewardAmount, rewardType) {
    return rewardType === 'dollar' ? rewardAmount : (Number(subtotal) * (Number(rewardAmount) / 100));
  }

  removeReward() {
    if (this.state.paymentCompleted) return;

    this.updateInvoiceWithReward('remove');
  }

  getTotal(addTip = true) {
    const { invoices } = this.props;
    const { dataInvoice, rewardLoaded, rewardDiscount } = this.state;
    const orderDetails = dataInvoice || invoices.invoiceForPayment;
    const tipAmount = numeral(invoices.invoiceForPayment?.tip_amount).value();
    const totalAmt = orderDetails?.total_amt;

    let totalAmount = roundToTwoDecimals(totalAmt);

    if (addTip) {
      totalAmount = roundToTwoDecimals(roundToTwoDecimals(totalAmt) + roundToTwoDecimals(tipAmount));
    }

    if (rewardDiscount && !rewardLoaded) {
      totalAmount = totalAmount >= 0 ? totalAmount : 0;
    }

    if (rewardLoaded && !rewardDiscount) {
      this.setState({ rewardLoaded: null });
    }

    this.setState({totalAmount, tipAmount});

  }

  generateInvoiceRequestJson = (type) => {
    const { invoices } = this.props;
    const { rewardDiscount } = this.state;
    const { invoiceForPayment: selectedInvoice } = invoices;

    let baseInvoice = {
      type: 'customer_landing_page',
      invoice: {},
    };

    let itemizedCart = {};

    if (type === 'remove') {
      this.setState({ rewardDiscount: null }, this.getTotal);
      const loyaltyVpcDiscountIndex =
        this.state.itemizedCart.receipt_discount_name.indexOf('Loyalty Reward');
      let newCart = InvoiceUtil.deleteDiscount(
        loyaltyVpcDiscountIndex,
        this.state.itemizedCart
      );
      itemizedCart = InvoiceUtil.recalculateCart(
        newCart,
        baseInvoice,
        {}
      );

    } else {
      itemizedCart = InvoiceUtil.recalculateCart(
        this.state.itemizedCart,
        baseInvoice,
        rewardDiscount
      );
      this.setState({itemizedCart});
      const rewardFormatted = {
        type: 'loyalty',
        code: rewardDiscount?.code,
        amount: itemizedCart?.loyalty_discount_amt,
        name: 'Loyalty Reward'
      };

      baseInvoice.invoice.express_discounts = [rewardFormatted];
    }

    baseInvoice.invoice.amount = itemizedCart.total_amt;
    baseInvoice.invoice.sub_total_amount = type === 'remove' ? itemizedCart.sub_total_pre_discounts : itemizedCart.sub_total_amt;
    baseInvoice.invoice.tax_amount = itemizedCart.tax_amt;

    if (itemizedCart?.item_ids) {
      itemizedCart.item_price_id = selectedInvoice?.price_id;
      itemizedCart.is_one_time_use = selectedInvoice?.is_one_time_use;

      this.setState({dataInvoice: {...selectedInvoice, ...itemizedCart}}, this.getTotal);
    }

    return baseInvoice;
  }

  async updateInvoiceWithReward(type) {
    const { dispatch, invoices } = this.props;
    const { invoiceForPayment } = invoices;
    const invoiceId = invoiceForPayment?.id;

    this.setState({loading: true});

    const payload = this.generateInvoiceRequestJson(type);

    const queryString = query.parse(location.search);

    await dispatch(updateInvoiceToPayNow(queryString.token, invoiceId, payload));
    await this.loadData();
    this.setState({loading: false});
  }

  async submitLoyaltyRewardCodeForm(values) {
    const { dispatch, t, invoices } = this.props;
    const queryString = query.parse(this.props.location.search);
    const token = queryString.token;
    const { invoiceForPayment } = invoices;
    const customerId = invoiceForPayment?.pa_customer_id;

    this.setState({loading:true});

    try {
      const result = await dispatch(validateRewardCodeTokenFromInvoice(values.rewardCode, token, customerId));
      const {response: rewardDiscount} = result;
      if (rewardDiscount !== '') {

        this.setState({
          rewardDiscount: {...rewardDiscount, code: values.rewardCode},
          loyaltyModalOpen: false
        });
        await this.updateInvoiceWithReward();
      } else {
        toastr.error(t('LoyaltyPrograms.Clp.InvalidReward'));
      }
    } catch (error) {
      toastr.error(t('LoyaltyPrograms.Clp.Error'));
    } finally {
      this.setState({loading:false});
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const nextProps = this.props;

    // we must have current form data (meaning we're actively trying to process a card), no previous card data (prevent duplicate transactions)
    // and card data in the most recent reducer (meaning we received data to process)
    if (this.state.cardPresentPendingFormData == null &&
      nextProps.cardPresent.isUsing && !nextProps.cardPresent.isWorking && nextProps.cardPresent.state && nextProps.cardPresent.state.cardData &&
      (!prevProps.cardPresent.state || !prevProps.cardPresent.state.cardData || prevProps.cardPresent.state.cardData != nextProps.cardPresent.state.cardData)) {
      // immediately send out the isWorking flag
      this.props.dispatch(updateCardPresentState({isWorking: true}));
      let data = {
        tlv: _.cloneDeep(nextProps.cardPresent.state.cardData),
        cpVersion: _.cloneDeep(nextProps.cardPresent.state.driver.version)
      };

      let chunks = (data.tlv.cardholderName || '').split(' ');
      data.first_name = chunks.shift();
      data.last_name = chunks.join(' ');

      this.setState({cardPresentPendingFormData: data});
    }

    // process if card present data exists
    if (nextProps.cardPresent.isUsing && !prevState.cardPresentPendingFormData && this.state.cardPresentPendingFormData) {
      this.onPayClick();
      this.state.cardPresentUtil.resetReading();
      this.props.dispatch(updateCardPresentState({isWorking: false}));
    }

    // update the token if it exists
    if (nextProps.cardPresent.isUsing && nextProps.auth && nextProps.auth.desktopToken && (prevProps.auth || {}).desktopToken != nextProps.auth.desktopToken) {
      this.state.cardPresentUtil.authenticate(nextProps.auth.desktopToken);
    }

    // kill card present if necessary (usually for logout or state changes)
    if (!nextProps.cardPresent.isUsing && prevProps.cardPresent.isUsing) {
      this.state.cardPresentUtil.disconnect();
    }

    if (prevProps.invoices?.invoiceForPayment !== nextProps.invoices?.invoiceForPayment) {
      this.getTotal();
    }

  }

  openLoyaltyModal() {
    this.setState({loyaltyModalOpen: true});
  }

  closeLoyaltyModal() {
    this.setState({loyaltyModalOpen: false});
  }

  activatePrePopulatedReward() {
    const loyaltyInfo = this.props.invoices?.invoiceForPayment?.customer_loyalty_status;
    if (loyaltyInfo) {
      const rewardCode = loyaltyInfo.reward_code;
      const rewardDiscount = {amount: loyaltyInfo.reward_amount, type: loyaltyInfo.reward_type, code: rewardCode};
      this.props.dispatch(reset('loyaltyVpcApplyBoxForm'));

      this.setState({rewardDiscount: {...rewardDiscount, code: rewardCode}, loyaltyModalOpen: false}, () => {
        this.getTotal();
        this.updateInvoiceWithReward();
        this.props.dispatch(change('loyaltyVpcApplyBoxForm', 'rewardCode', rewardCode))
      });
    }
  }

  removePrepopulatedReward() {
    this.removeReward();
    this.props.dispatch(reset('loyaltyVpcApplyBoxForm'));
  }

  loadData() {

    const { dispatch } = this.props;

    const queryString = query.parse(this.props.location.search);
    const token = queryString.token;

    return dispatch(getInvoiceToPay(token, 'payNow')).then((payload) => {
      if (payload.response && payload.response.paid_date || payload.response && payload.response.pre_auth_validated) {
        // Set payment complete and clear any application storage
        this.setState({paymentCompleted: true});
        dispatch(clearPayNowCardPresent());
      } else if (payload.response && payload.response.payNowType === 'cardpresent') {
        const userId = payload.response && payload.response.sold_by_id;
        this.setCardPresentRoomData(userId, token).then(() => {
          this.toggleCardPresent();
        });
      } else {
        const customerId = payload.response?.customer_id || payload.response?.pa_customer_id;
        dispatch(getPayNowCustomerPaymentMethods(token, customerId));
      }
      this.mapItemizedItems(payload.response);
      this.setInitialReward(payload.response);
      this.getTotal(false);
    });
  }

  setCardPresentRoomData(userId, token) {
    const { dispatch } = this.props;

    return new Promise((resolve, reject) => {
      dispatch(setCardPresentRoomInfo(userId, token))
      resolve();
    });
  }

  toggleCardPresent() {

    const { cardPresent, dispatch } = this.props;

    const isUsing = !cardPresent.isUsing;

    if (isUsing) {
      this.state.cardPresentUtil.connect('payNow');
    } else {
      this.state.cardPresentUtil.disconnect();
    }
    dispatch(updateCardPresentState({isUsing: isUsing}));
    this.setState({renderer: !this.state.renderer});
  }

  onPayClick() {
    const { totalAmount } = this.state;

    if (totalAmount > highTransactionLimitAmount) {
      this.openHighAmountModal();
    } else {
      this.props.dispatch(submit('payNowInvoiceForm'));
    }
  }

  closeHighAmountModal() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  openHighAmountModal() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('highTransactionAmountDialog'));
  }

  hideOrderSummary() {
    this.props.dispatch(setViewOrderSummary(false));
  }

  process(values) {

    const { location, t } = this.props;

    // this means we're processing tlv data from the card reader
    const processedFromCardReader = this.state.cardPresentPendingFormData || null;
    values = _.omit(values, 'creditCardPaymentFlag');

    /* istanbul ignore else */
    if (processedFromCardReader) {
      values = Object.assign({}, values, this.state.cardPresentPendingFormData);
      this.setState({cardPresentPendingFormData: null}); // assure we don't rerun this
    }

    this.setState({
      loading: true,
      paymentCompleted: false,
      paymentError: null,
      entityId: null
    });

    let that = this;

    const queryString = query.parse(location.search);
    const token = queryString.token;
    return new Promise((resolve, reject) => {
      PaymentUtil.processInvoice(values, token, that.props, 'paynow').then((paymentResponse) => {

        if (paymentResponse?.payment?.error) {
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: t(paymentResponse.payment.error) || paymentResponse.payment.error
          });

        } else if (paymentResponse?.payment?.response?.change_amount === 0 && paymentResponse?.payment?.response?.unique_id) {
          that.setState({
            paymentCompleted: true,
            loading: false,
            loyalty: paymentResponse?.payment?.response?.loyalty_vpc
          });
        } else if (paymentResponse?.payment?.response && paymentResponse.payment.response?.response_code !== 'APR') {
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: t('OnlinePayment.ProcessingPaymentError', {error: paymentResponse.payment.response.status_message})
          });
        } else if (paymentResponse?.payment?.response && paymentResponse.payment.response?.response_code === 'APR') {

          if (paymentResponse.payment.response.is_partial_auth) {
            that.setState({
              paymentCompleted: false,
              loading: false,
              paymentError: t(messages.errors.partialPayment.message)
            });
          } else {
            const entityId = paymentResponse?.payment?.response?.entity && paymentResponse.payment.response.entity.id;
            that.setState({
              paymentCompleted: true,
              loading: false,
              entityId: entityId,
	            loyalty: paymentResponse?.payment?.response?.loyalty_vpc,
            });
          }

        } else {
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: t('OnlinePayment.SomethingWentWrongError')
          });
        }
        resolve();
      }).catch(error => {
        that.setState({
          loading: false,
          paymentError: t(messages.errors.invoiceApiError)
        });
        reject(t('OnlinePayment.Error', {error: error.message}));
      });

    })

  }

  /**
   *  Passes arguments received directly onto processInvoiceWithApplePay
   *  If there's any error it rejects the promise in order to inform Apple's Apple Pay UI window, so that it can display an appropriate status to the user
   */
  processApplePay(user, applePaymentData, paymentData, tipAmount) {
    const { t, location } = this.props;

    this.setState({loading: true, paymentCompleted: false, paymentError: null});

    const queryString = query.parse(location.search);

    const merchantToken = {
      type: 'paynow',
      token: queryString.token
    };

    let that = this;
    let errorMessage;

    return new Promise((resolve, reject) => {
      PaymentUtil.processInvoiceWithApplePay(user, applePaymentData, paymentData, tipAmount, that.props, merchantToken).then(function (paymentResponse) {

        if (paymentResponse.payment && paymentResponse.payment.error) {
          errorMessage = paymentResponse.payment.error;
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: errorMessage
          });
          reject(t('OnlinePayment.Error', {error: errorMessage}));
        } else if (paymentResponse.payment && paymentResponse.payment.response && paymentResponse.payment.response?.response_code !== 'APR') {
          errorMessage = paymentResponse.payment.response.status_message;
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: t('OnlinePayment.ApplePayError')
          });
          reject(t('OnlinePayment.Error', {error: errorMessage}));
        } else if (paymentResponse?.payment?.response?.response_code === 'APR') {

          if (paymentResponse.payment.response?.is_partial_auth) {
            errorMessage = t(messages.errors.partialPayment.message);
            that.setState({
              paymentCompleted: false,
              loading: false,
              paymentError: errorMessage
            });
            reject(t('OnlinePayment.Error', {error: errorMessage}));
          } else {
            const entityId = paymentResponse?.payment?.response?.entity?.id;
            that.setState({
              paymentCompleted: true,
              loading: false,
              entityId: entityId
            });
            resolve();
          }

        } else {
          errorMessage = t('OnlinePayment.SomethingWentWrongError');
          that.setState({
            paymentCompleted: false,
            loading: false,
            paymentError: errorMessage
          });
          reject(t('OnlinePayment.Error', {error: errorMessage}));
        }
        resolve();
      }).catch(error => {
        that.setState({loading: false, paymentError: error});
        reject(t('OnlinePayment.Error', {error: error.message}));
      });

    });

  }

  render() {

    const { invoices, customers, t, userExperience, brandColor } = this.props;
    const { rewardDiscount, dataInvoice, tipAmount, totalAmount, itemizedCart, paymentCompleted, loyalty } = this.state;

    const label = globalApplicationLabel;
    const labelLogoSource = '/images/' + label.assetIdentifier;
    const isEmpty = !invoices.invoiceForPayment;
    const labelColor = LabelUtil.getLabel().primaryColor;

    if ((invoices.isFetching || customers.isFetching) && !this.state.loading) {
      return (
        <div className='payNowContainer'>
          <Loading />
        </div>
      )
    } else if (isEmpty && !this.state.loading) {
      return (
        <div className='payNowContainer'>
          <NoData text={t('OnlinePayment.NoData')}/>
        </div>
      )
    } else {
      const orderDetails = dataInvoice || invoices.invoiceForPayment;

      const isPremiumPlusLoyalty = UserUtil.isPremiumPlusWithLoyaltyAccount(orderDetails);
      const isPremiumPlus = UserUtil.isPremiumPlusAccount(orderDetails);
      const showLoyalty = isPremiumPlusLoyalty || isPremiumPlus;

      const disabledPayNowButton = invoices.isProcessing;

      const buttonDisabledStyle = {
        opacity: .5,
        cursor: 'not-allowed'
      };

      const cardPresentPaymentType = orderDetails.payNowType === 'cardpresent'
      const buttonLabel = orderDetails.is_pre_auth ?
        t('OnlinePayment.ButtonAuthorize') : t('OnlinePayment.ButtonPayNow');
      const showPayNowButton = !paymentCompleted && !cardPresentPaymentType;

      const payNowButton = showPayNowButton ? (
        <Button
          className='submitButton payNowSubmitButton'
          disabled={ disabledPayNowButton }
          id='submitButton'
          label={ buttonLabel }
          onClick={ () => !disabledPayNowButton && this.onPayClick() }
          style={ disabledPayNowButton ? buttonDisabledStyle : null }
        />
      ) : null;

      const orderRows = orderDetails?.item_names?.length && (
        orderDetails.item_names.map((orderName, key) => {
          let itemAmount = orderDetails.item_quantity[key] ? orderDetails.item_quantity[key] * (orderDetails.item_unit_price[key]) : orderDetails.item_unit_price[key];

          const cashDiscountPerItem = FormatTextUtil.formatCashDiscountPerItem(orderDetails.item_quantity.length, orderDetails.item_quantity[key], key, orderDetails.service_fee_amt);

          itemAmount = itemAmount + cashDiscountPerItem;

          return (
            <div className='orderRow' key={key}>
              <div className='orderRowInfo'>
                <div className='orderRowName'>{orderName}</div>
              </div>
              <div className='orderRowValue'>{numeral(itemAmount).format('$0,0.00')}</div>
            </div>
          )
        }));
      const subTotalAmount = numeral(orderDetails.sub_total_amt).value();

      const billTo = orderDetails.customer_id ?
        t('OnlinePayment.BillTo', {name: FormatTextUtil.formatName(orderDetails.first_name, orderDetails.last_name, t('OnlinePayment.UnnamedCustomer'))}) : '';

      const orderSummary = (
        <div className='orderSummaryContainer'>
          <div className='orderSummaryItemsContainer'>

            <div className='orderSummaryHeaderContainer'>
              <div className='orderSummaryHeader'>{orderDetails.business_name || ''}</div>
              <div className='orderSummaryDetails'>
                <div className='summaryDetailsLine billTo'>{billTo}</div>
                <div className='summaryDetailsLine'>{t('OnlinePayment.InvoiceName', {invoice: orderDetails.invoice})}</div>
                <div className='summaryDetailsLine'>{moment.utc(orderDetails.receipt_date).format(t('OnlinePayment.DetailsDateFormat'))}</div>
              </div>
            </div>

            <div className='orderSummaryItems'>
              {orderRows}
            </div>
          </div>
          <div className='orderSummaryTotalsContainer'>
            {rewardDiscount ? <div className='orderRow'>
              <div className='orderRowInfo'>
                <p><b>{t('LoyaltyPrograms.Clp.DiscountOrReward')}</b></p>
                {!paymentCompleted && !orderDetails.paid_date ? (
                  <p>
                    <ReverseButton
                      disabled={disabledPayNowButton}
                      className='removeReward'
                      label={t('LoyaltyPrograms.Clp.RemoveReward')}
                      onClick={this.removeReward}
                    />
                  </p>
                ) : null}
              </div>
              <div className='orderRowValue'>
                <p className='price'>-{itemizedCart?.loyalty_discount_amt ? numeral(itemizedCart?.loyalty_discount_amt).format('$0,0.00') : numeral(rewardDiscount.amount).format('$0,0.00')}</p>
                {!paymentCompleted && !orderDetails.paid_date ?
                  <p className='price'>&nbsp;</p> : null}
              </div>
            </div> : null}

            <div className='orderRow subtotal'>
              <div className='orderRowInfo'>{t('Subtotal')}</div>
              <div className='orderRowValue'>{numeral(subTotalAmount).format('$0,0.00')}</div>
            </div>

            <div className='orderRow tax'>
              <div className='orderRowInfo'>{t('Tax')}</div>
              <div className='orderRowValue'>{numeral(orderDetails.tax_amt).format('$0,0.00')}</div>
            </div>

            {!!tipAmount && (
              <div className='orderRow tip'>
                <div className='orderRowInfo'>{t('Tip')}</div>
                <div className='orderRowValue'>{numeral(tipAmount).format('$0,0.00')}</div>
              </div>
            )}

            <div className='orderRow total'>
              <div className='orderRowInfo infoBold'>{paymentCompleted && t('Total')}</div>
              <div className='orderRowValue infoBold'>{numeral(totalAmount).format('$0,0.00')}</div>
            </div>
            <div>
              {showLoyalty && !paymentCompleted && !this.state.paymentCompleted && !rewardDiscount ? (
                <div className='loyaltyButtonContainer'>
                  <ReverseButton
                    className='button loyaltyButton'
                    icon={IconUtils.getIcon('LoyaltyVpcSmallTag', brandColor || labelColor)}
                    label={t('LoyaltyPrograms.Clp.ApplyReward')}
                    onClick={this.openLoyaltyModal}
                  />
                </div>
              ) : null}
            </div>
          </div>

        </div>);

      // TODO: setup ISV header properties from API
      const pointAndPayHeader = cardPresentPaymentType && (
        <div className='isvInvoiceHeader'>
          <img alt={'point&amp;pay'} src={`${serverDomainUrl}images/pnp/sticky-logo.svg`} />
        </div>
      );

      const orderSummaryResponsiveDialog = (<MessageDialog
          bodyText={orderSummary}
          confirmText={t('Close')}
          externalClassName={'orderSummaryDialog'}
          isChoiceRequired={false}
          onConfirm={this.hideOrderSummary}
          onRequestClose={this.hideOrderSummary}
          open={userExperience.viewOrderSummary}
          titleText={t('OnlinePayment.OrderSummary')}
        />
      );

      const loyaltyModal = (
        <Modal
          hideCancelButton
          open={this.state.loyaltyModalOpen}
          contentClassName='loyaltyModal'
          maxWidth='lg'
          onClose={this.closeLoyaltyModal}
          onConfirm={this.closeLoyaltyModal}
          confirmText={t('Ok')}>
            <LoyaltyReward
              {...this.props}
              submitLoyaltyRewardCodeForm={this.submitLoyaltyRewardCodeForm}
              activatePrePopulatedReward={this.activatePrePopulatedReward}
              removePrepopulatedReward={this.removePrepopulatedReward}
            />
        </Modal>
      );

      const dba = invoices.invoiceForPayment?.business_name;
      const highAmountModal = (
        <HighAmountModal
          onClose={this.closeHighAmountModal}
          open={userExperience?.modalVisibility?.highTransactionAmountDialog}
          bodyText={t('HighAmountModal.ExternalBody', {dba})}
          t={t}
        />
      );

      const archivedInvoiceDialog = (
        <MessageDialog
          bodyText={t('OnlinePayment.CanceledInvoiceBody')}
          confirmText={''}
          externalClassName={'archivedInvoiceDialog'}
          hideCloseIcon
          hideConfirmButton
          isChoiceRequired
          onConfirm={null}
          open={!orderDetails.is_active}
          titleText={t('OnlinePayment.CanceledInvoiceTitle')}
        />
      );

      const preAuthId = orderDetails.pre_auth_id ? orderDetails.pre_auth_id : this.state.entityId;

      return (
        <div className='payNowContainer'>
          {pointAndPayHeader}
          <div className='payInvoiceContainer'>

            <div className='content'>

              <div className='mainContent'>
                <div className='payInvoiceFormContainer'>

                  <MerchantLogo
                    additionalClass='invoiceLogo'
                    altText={orderDetails.business_name}
                    logoBase64={orderDetails.business_logo}
                    size={100}
                  />

                  <div className='errorText invoicePaymentError'>
                    {this.state.paymentError ? this.state.paymentError : ''}
                  </div>
                  <OnlinePaymentForm
                    {...this.props}
                    entityId={preAuthId}
                    openHighAmountModal={this.openHighAmountModal}
                    handleSubmitApplePay={this.processApplePay}
                    onSubmit={this.process}
                    onToggleCardPresent={this.toggleCardPresent}
                    paymentCompleted={paymentCompleted}
                    loyalty={loyalty}
                    isPremiumPlusLoyalty={showLoyalty}
                  />
                  {payNowButton}
                </div>

                <div className='invoiceFooter'>
                  <div className='address'>{`${orderDetails.business_address} | ${orderDetails.business_city}, ${orderDetails.business_state} ${orderDetails.business_zip} | ${orderDetails.business_phone} ${orderDetails.business_website ? '| ' + orderDetails.business_website : ''}`}</div>
                  <img className='poweredBy' src={labelLogoSource + '/poweredBy.png'} />
                </div>

              </div>

              {orderSummary}
              {orderSummaryResponsiveDialog}
              {archivedInvoiceDialog}
              {loyaltyModal}
              {highAmountModal}
            </div>
            {this.state.loading ? (<UpdateSpinner/>) : null}
          </div>
        </div>
      );
    }
  }
}
