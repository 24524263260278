import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MDDrawer from '@mui/material/Drawer';

import Button from './Button';
import ReverseButton from './ReverseButton';
import Loading from '../Loading';

const Drawer = ({
  children,
  open = false,
  anchor='right',
  confirmText='Save',
  cancelText='Cancel',
  actions,
  onClose,
  onCloseIcon,
  onConfirm,
  contentClassName = '',
  actionsClassName = '',
  externalClassName = '',
  isClosableIconEnable = true,
  isCancelDisabled = false,
  isConfirmDisabled = false,
  isLoading = false,
  hideActions = false,
  hideFooter = false,
  hideCancelButton = false,
  hideConfirmButton = false
}) => {
  return (
    <MDDrawer className={`drawer ${externalClassName}`} anchor={anchor} open={open} onClose={onClose}>
      { isLoading && <Loading/>}
      {
        isClosableIconEnable &&
        <span onClick={onCloseIcon || onClose} className='close-icon'>
          <CloseIcon/>
        </span>
      }
      <div className={`content ${contentClassName}`}>{children}</div>
      {!hideFooter && <div className={`footer ${actionsClassName}`}>
        {!hideActions && (
          actions
            ? actions.map((action, index) => <span key={`action-${index}`}>{action}</span>)
            : <>
              {!hideCancelButton && <ReverseButton onClick={isCancelDisabled ? undefined : onClose} label={cancelText} disabled={isCancelDisabled} />}
              {!hideConfirmButton && <Button onClick={isConfirmDisabled ? undefined : onConfirm} label={confirmText} disabled={isConfirmDisabled} />}
            </>
        )}
      </div>}
    </MDDrawer>
  )
}

export default Drawer;
