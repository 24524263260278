/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React from 'react';
import withTranslate from '../hoc/withTranslate';

import _ from 'lodash';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import NoData from './NoData';
import SearchBarAccessory from './appBar/accessories/SearchBarAccessory';
import UpdateSpinner from './UpdateSpinner';
import MasterDetailLayout from './MasterDetailLayout';

import UserUtil from './util/UserUtil';

import DateFilterAccessory from './appBar/accessories/DateFilterAccessory';
import FilterPanel from './shared/FilterPanel';
import TableHeader from './shared/TableHeader';
import DetailPanel from './shared/detailPanel/DetailPanel';

export const MasterLayoutComponent = (props) => {
  const {
    header,
    entityText = '',
    className = '',
    table: { data = {}, rows = [], columns = [], noDataText = 'No data' } = {},
    filter: {dataName, options, dateRange, selectedIndex, selectedFilter, betaDisclaimer } = {},
    detail: { openDetail, detailContent, detailProps } = {},
    displayLoadingBar,
    displayLoadingSpinner,
    handleCreateNew,
    handleCloseDetail,
    handleRowSelection,
    handleFilterSelection,
    handleDateSelection,
    pageTitle,
    pageLoading,
    ...store
  } = props;

  const { auth, user, merchantSettings } = store;

  const isEmpty = data?.filteredData && _.isEmpty(data?.filteredData);
  const isCsUser = user && auth && UserUtil.isUserCS(user, auth);
  const isClosedAccount = !(user && UserUtil.isActive(user));
  const hasMobileScrollNote = data?.filteredData && data?.filteredData?.length > 0;


  const searchBar = <SearchBarAccessory {...store} />;

  const filterPanel = (
    <FilterPanel
      {...store}
      filterData={options}
      visibilityFilter={{ filter: { value: selectedFilter } }}
      selectFilterCallback={handleFilterSelection}
      buttonOnClick={handleCreateNew}
      buttonIcon={null}
      buttonText={'Create'}
      disabled={isCsUser || isClosedAccount}
      betaDisclaimer={betaDisclaimer}
    />
  );

  const listHeaders = !isEmpty ? (
    <TableRow className='tableRow'>
      {columns.map(({ attribute, name }, index) => (
        <TableHeader
          {...store}
          type='table'
          clickable={true}
          attribute={attribute}
          headerId={`${entityText}Header${index}`}
          name={props.t(name)}
          key={`${attribute}Column${index}`}
        />
      ))}
    </TableRow>
  ) : null;

  const filteredDataRows = !isEmpty ? (
    rows.map((values, i) => {
      const tableRowClassName = selectedIndex === i
        ? `tableRow selectedListRow ${entityText}Row`
        : `tableRow ${entityText}Row`;

      return (
        <TableRow
          key={i}
          className={tableRowClassName}
          onClick={() => handleRowSelection(i)}
        >
          {values.map((value, j) => {
            const key = `row${i}-${j}`;
            const tableCellClassName = `cellData cellData_${columns?.[j].attribute}`;

            return (
              <TableCell className={tableCellClassName} key={key}>{value}</TableCell>
            );
          })}
        </TableRow>
      );
    })
  ) : (
    <NoData text={noDataText} type='table' />
  );

  const filteredDataList = (
    <TableBody className={`${entityText}List`}>
      {listHeaders}
      {filteredDataRows}
    </TableBody>
  );

  const pageAccessories = [
    {
      name: 'search',
      accessory: searchBar,
      showInMobile: true,
      hide: false
    },
    {
      name: 'dateFilter',
      accessory: (
        <DateFilterAccessory
          {...store}
          defaultValue={dateRange?.text}
          handleSelection={handleDateSelection}
          showAllTime={false}
          showPreviousCustomSelection={false}
          disableCompactVersion={true}
          dataName={dataName}
        />
      ),
      showInMobile: true,
      hide: !(dateRange && merchantSettings?.customReportStartTime )
    }
  ];

  return (
    <section className={`masterLayout ${className}`}>
      <MasterDetailLayout
        {...store}
        header={header}
        pageTitle={pageTitle}
        pageLoading={displayLoadingBar}
        pageAccessories={pageAccessories}
        filterPanel={filterPanel}
        tableData={data}
        tableRowLayout={filteredDataList}
        hasMobileScrollNote={hasMobileScrollNote}
      />
      {!displayLoadingBar ? (
        <DetailPanel
          shouldDisplay
          {...detailProps}
          open={openDetail}
          onCloseClick={handleCloseDetail}
        >
          {detailContent}
        </DetailPanel>
      ) : null
      }
      {displayLoadingSpinner ? <UpdateSpinner /> : null}
    </section>
  );
};

export default withTranslate(MasterLayoutComponent);
