/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';

import _ from 'lodash';
import query from 'query-string';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import {
  change,
  reset,
  submit,
  isValid,
  getFormValues,
  getFormInitialValues,
  getFormMeta,
  hasSubmitSucceeded,
  hasSubmitFailed,
  getFormSyncErrors,
  isSubmitting
} from 'redux-form';
import {Box} from '@mui/material'

import InvoiceForm from '../shared/enhancedInvoices/InvoiceForm';
import InvoiceDetail from './InvoiceTypeDetail';
import InvoicePreview from '../invoice/InvoicePreview';
import TitlePanelInvoiceSummary from './TitlePanelInvoiceSummary';
import MasterLayout from '../MasterLayout';

import UserUtil from '../util/UserUtil';
import LabelUtil from '../util/LabelUtil';
import IconUtils from '../util/IconUtil';
import DateUtils from '../util/DateUtil';
import ReceiptUtil from '../util/ReceiptUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import InvoiceUtil, {
  InvoiceFilterTypes,
  InvoiceFormTypes,
  INVOICE_FORM_ID,
  FormType,
  PaymentMethod,
  PaymentFrequency
} from '../util/InvoiceUtil';

import Modal from '../shared/Modal';
import BottomBar from '../shared/BottomBar';
import MessageDialog from '../shared/MessageDialog';
import ErrorMessageDialog from '../shared/ErrorMessageDialog';

import routes from '../../constants/routes';
import messages from '../../constants/messages';
import planTypes from '../../constants/planTypes';
import { highTransactionLimitAmount } from '../../constants/applicationConstants';
import {BulkInvoicesSteps} from '../../constants/bulkInvoices';
import {
  getInvoices,
  getInvoice,
  getInvoiceSeries,
  updateInvoice,
  payInvoiceWithoutToken,
  getBulkInvoices,
  getBulkInvoice, completeBulkImport,
} from '../../actions/invoicesActions';
import { processCreditCardVoid } from '../../actions/transactionsActions';
import {
  setModalVisibility,
  setVisibilityFilter,
  setOpenMessageDialog,
  togglePlanListDialog,
  setSelectedDateRange
} from '../../actions/userExperienceActions';
import {getItems, setSalesItem} from '../../actions/itemsActions';
import {roundToTwoDecimals} from '../util/CommonUtil';
import VirtualTerminalFormItemSelection from './VirtualTerminalFormItemSelection';
import { getDiscounts } from '../../actions/discountsActions';
import numeral from 'numeral';
import HighAmountModal from './HighAmountModal';
import LowAmountModal from './LowAmountModal';
import Collapse from '@mui/material/Collapse';
import {getCustomer, getCustomers, setCustomer} from '../../actions/customerActions';
import withTranslate from '../../hoc/withTranslate';
import withLayoutBreakPoints from '../util/WithLayoutBreakPoints';
import Bugsnag from '@bugsnag/js';
import { removeRewardCode } from '../../actions/loyaltyVpcActions';
import PlanUpgradeOptIn from '../account/PlanUpgradeOptIn';
import PlanList from '../account/plans/PlanList';
import FilterPanel from '../shared/FilterPanel';
import Loading from '../Loading'
import Page from '../shared/Page';
import FilterUtil from '../util/FilterUtil';
import BulkInvoices from './BulkInvoices';
import NewFeatureBadge from '../shared/NewFeatureBadge';
import FeatureFlagsUtil from '../util/FeatureFlagsUtil';

export class InvoicesComponent extends Component {

  constructor(props) {
    super(props);

    const dateRange = props?.userExperience?.selectedDate?.dateRange
      ? props.userExperience.selectedDate.dateRange
      : DateUtils.getPersonalizedDateRange('Today', '00:00:00');

    this.state = {
      dateRange,
      submitType: '',
      invoiceFormType: '',
      selectedIndex: 0,
      selectedInvoice: null,
      openDetail: false,
      openInvoiceFormModal: false,
      openPreviewModal: false,
      openCashPaidInvoiceDialog: false,
      selectedFilter: InvoiceFilterTypes.INVOICES,
      isItemsDialogOpen: false,
      selectedItemIndex: null,
      isEditingItem: false,
      addToOrderAdditionalInfo: null,
      itemizedCart: InvoiceUtil.initialItemizedCartObject(),
      isLoadingData: false,
      isLoadingInvoiceDetailData: false,
      showLowAmountWarningModal: false,
      showConfirmModal: false,
      errorModal: null,
      futurePaymentModal: null,
      partialPayment: null,
      isPayInvoice: false,
      currentValues: {},
      isInvoiceSuccessDialogOpen: false,
      isSavingDraft: false
    };

    this.initialFilter = { property: 'type', value: InvoiceFilterTypes.INVOICES };

    this.handleLoadData = this.handleLoadData.bind(this);
    this.handleSetFilters = this.handleSetFilters.bind(this);
    this.handleCloseDetail = this.handleCloseDetail.bind(this);
    this.addDiscountToCart = this.addDiscountToCart.bind(this);
    this.closeItemsDialog = this.closeItemsDialog.bind(this);
    this.openItemsDialog = this.openItemsDialog.bind(this);
    this.onAddItemClick = this.onAddItemClick.bind(this);
    this.onRemoveFromOrderClick = this.onRemoveFromOrderClick.bind(this);
    this.handleAddItemToOrder = this.handleAddItemToOrder.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.cleanItemizedCart = this.cleanItemizedCart.bind(this);
    this.changeItemQuantity = this.changeItemQuantity.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.deleteDiscount = this.deleteDiscount.bind(this);
    this.selectInvoice = this.selectInvoice.bind(this);
    this.toggleLoyaltyRewardCode = this.toggleLoyaltyRewardCode.bind(this);
    this.getCartForPreview = this.getCartForPreview.bind(this);
    this.closeFuturePaymentDialog = this.closeFuturePaymentDialog.bind(this);
    this.hideCCInvoiceConfirmationDialog = this.hideCCInvoiceConfirmationDialog.bind(this);
    this.handleCCPrintReceipt = this.handleCCPrintReceipt.bind(this);
    this.handleViewInTransactions = this.handleViewInTransactions.bind(this);
    this.handleActionsFromHistory = this.handleActionsFromHistory.bind(this);
    this.handleInvoiceSavedAsDraft = this.handleInvoiceSavedAsDraft.bind(this);
  }

  componentDidMount() {
    const { auth, dispatch, user, items, merchantSettings } = this.props;

    if (UserUtil.isPremiumPlusAccount(user)) {

      dispatch(setVisibilityFilter(this.initialFilter, 'filter'));

      const isPayanywhereMerchant = UserUtil.isPayanywhere(user);

      if (isPayanywhereMerchant) {
        dispatch(getDiscounts(user));
      }

      if (isPayanywhereMerchant && (!items.categories || merchantSettings.express_category_enabled || items.salesItemsPagination?.pageSize)) {
        dispatch(getItems(user));
      }

      const params = this.getParams();
      params.ofType = params.seriesId && InvoiceFilterTypes.SERIES;

      if (auth?.needsInfo) {
        dispatch(setOpenMessageDialog('restrictedMbp'));
      }

      const filterType = params?.filterType;

      if (filterType) {
        this.handleFilterSelection({ name: filterType });
        switch (filterType) {
          case 'Paid':
            params.ofType = InvoiceFilterTypes.PAID;
            break;
          case 'Unpaid':
            params.ofType = InvoiceFilterTypes.UNPAID;
            break;
          case 'Overdue':
            params.ofType = InvoiceFilterTypes.OVERDUE;
            break;
          case 'FailedPayments':
            params.ofType = InvoiceFilterTypes.FAILED;
            break;
          case 'Scheduled':
            params.ofType = InvoiceFilterTypes.SCHEDULED;
            break;
          case 'Series':
            params.ofType = InvoiceFilterTypes.SERIES;
            break;
          case 'Drafts':
            params.ofType = InvoiceFilterTypes.DRAFT;
            break;
          case 'Canceled':
            params.ofType = InvoiceFilterTypes.CANCELED;
            break;
          case 'BulkInvoices':
            params.ofType = InvoiceFilterTypes.BULK;
          default:
            params.ofType = params.ofType;
            break;
        }
      }

      this.handleActionsFromHistory();

      return this.handleLoadData(params);

    }

  }

  componentDidUpdate(prevProps) {
    const {bulkImports, dispatch, user} = this.props;
    const prevSubmit = prevProps.submitSucceeded;
    const currentSubmit = this.props.submitSucceeded;
    if (this.state.isSavingDraft && currentSubmit && currentSubmit !== prevSubmit) {
      this.handleInvoiceSavedAsDraft();
    }

    if (bulkImports.bulkInvoiceId !== prevProps.bulkImports.bulkInvoiceId) {
      const invoiceDetailsAvailable = [BulkInvoicesSteps.READY, BulkInvoicesSteps.INPROGRESS].includes(bulkImports?.bulkInvoice?.status);
      if(bulkImports.bulkInvoiceId && invoiceDetailsAvailable){
        dispatch(getBulkInvoice(user, bulkImports.bulkInvoiceId, true))
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter('','filter'));
  }

  getParams = () => {
    return query.parse(this.props?.location?.search);
  };

  handleActionsFromHistory() {

    const { history } = this.props;

    const { openNewInvoice , customerID  } = history?.location?.state || {};
    if (openNewInvoice) {
      if (!customerID) {
        history.replace({ state: {} });
      }
      this.handleOpenCreateModal();
    }
  }

  async handleLoadData(params = {}) {
    this.setState({isLoadingData: true});

    const { dispatch, user, invoices } = this.props;
    const { dateRange } = this.state;

    const {
      seriesId,
      receiptId,
      ofType = InvoiceFilterTypes.INVOICES,
      isSeries,
      shouldOpenDetails,
    } = params;

    dispatch(setVisibilityFilter({ property: 'type', value: ofType, isSeries, shouldKeepSearch: true }, 'filter'));
    this.setState({ selectedFilter: ofType });
    const requiresAllInvoices = [InvoiceFilterTypes.INVOICES, InvoiceFilterTypes.SERIES , InvoiceFilterTypes.CANCELED].includes(ofType) || !!receiptId;


    const dateRangeFilter = requiresAllInvoices
      ? DateUtils.getAllTime()
      : dateRange;

    const isSeriesOrReceiptEmpty = !seriesId && !receiptId;
    const isSeriesValid = seriesId && (requiresAllInvoices || _.size(invoices.data.series) === 0);
    const isReceiptValid = receiptId;

    dispatch(reset(INVOICE_FORM_ID));
    dispatch(getCustomers(user));
    dispatch(getBulkInvoices(user, `${BulkInvoicesSteps.READY},${BulkInvoicesSteps.INPROGRESS}`));
    /* istanbul ignore else */
    if (isSeriesOrReceiptEmpty || isSeriesValid || isReceiptValid) {
      await dispatch(
        getInvoices(user, isSeriesOrReceiptEmpty ? dateRange : dateRangeFilter)
      );
    }


    if (seriesId) {
     this.handleSetFilters({seriesId}, ofType, isSeries, shouldOpenDetails);
    } else if (receiptId) {
      this.handleSetFilters({receiptId}, ofType, isSeries, shouldOpenDetails);
    }

    this.setState({isLoadingData: false});
  }

  handleInvoiceSavedAsDraft() {
    const {t} = this.props;
    toastr.success(t('DraftNotification.Title'), t('DraftNotification.Description'), {
      icon: IconUtils.getIcon('InvoiceDraftIcon', LabelUtil.getLabelColor(), ),
      progressBar: false,
      removeOnHover: false,
      className: 'draftInvoiceNotification'
    });

    this.setState({isSavingDraft: false});
  }

  handleSetFilters({ seriesId = null, receiptId = null }, selectedFilter, isSeries, shouldOpenDetails = true) {

    const { dispatch, invoices } = this.props;

    dispatch(setVisibilityFilter(seriesId || receiptId));

    this.setState({ selectedFilter },   () => {
      if (shouldOpenDetails && _.size(invoices.filteredData)) {
        let findFn = null;
        if (seriesId) {
          findFn = invoice => `${invoice.id}` === `${seriesId}`;
        } else  {
          findFn = invoice => `${invoice.invoice}` === `${receiptId}`;
        }

        const index = invoices.filteredData.findIndex(findFn);
        this.handleRowSelection(index);
      }
    });

  }
  handleSaveInvoice = async (values) => {
    const fixedValues = {
      ...values
    };
    if (values.selectedPaymentMethod === null && (values.selectedDueDateValue > 0 || values.selectedSendDateValue > 0)) {
      fixedValues.saveCreditCard = true;
    }
    if (fixedValues.amount === '') {
      fixedValues.amount = 0;
    }
    const amount = fixedValues.type === 'itemized'
      ? InvoiceUtil.recalculateCart(this.state.itemizedCart, fixedValues, this.props.loyaltyVpc.rewardCodeInfo).sub_total_amt
      : numeral(fixedValues.amount).value();
    let effectiveLoyaltyDiscountAmount = amount - numeral(fixedValues?.rewardCodeInformation?.amount).value()
    const shouldChangeLoyaltyDiscountAmount = fixedValues.type !== 'itemized' && (effectiveLoyaltyDiscountAmount < 0) && fixedValues?.rewardCodeInformation?.type === 'dollar';
    if (shouldChangeLoyaltyDiscountAmount) {
      fixedValues.rewardCodeInformation.amount = `${numeral(fixedValues.rewardCodeInformation.amount).value() - Math.abs(effectiveLoyaltyDiscountAmount)}`
    }
    if (amount > highTransactionLimitAmount) {
      const { dispatch } = this.props;
      dispatch(setModalVisibility('highTransactionAmountDialog'));
      this.setState({ currentValues: fixedValues });
    } else if (amount === 0 && this.state.submitType !== 'saveDraft') {
      this.setState({ showLowAmountWarningModal: true, currentValues: fixedValues });
    } else {
      await this.saveInvoice(fixedValues);
    }
  };
  saveInvoice = async (values = this.state.currentValues) => {
    this.closeHighAmountDialog();
    this.closeLowAmountDialog();
    const that = this;

    const { isInvoiceFormValid, invoiceFormInitialValues } = that.props;
    const { submitType, invoiceFormType, selectedIndex } = that.state;

    const isSavingDraft = submitType === 'saveDraft';
    const isUpdatingDraft = invoiceFormType === InvoiceFormTypes.DRAFT;

    const itemizedCart = InvoiceUtil.recalculateCart(that.state.itemizedCart, values, that.props.loyaltyVpc.rewardCodeInfo);

    const formValues = {
      ...values,
      isDraft: isSavingDraft
    };

    if (isInvoiceFormValid) {
      try {
        if (isUpdatingDraft) {
          const selectedInvoice = this.getSelectedInvoice(selectedIndex);

          await InvoiceUtil.updateDraftInvoice(
            formValues,
            invoiceFormInitialValues,
            itemizedCart,
            that.props,
            selectedInvoice
          ).then(this.resolveInvoices);
        } else {
          await InvoiceUtil.processInvoice(
            formValues,
            itemizedCart,
            that.props,
            !isSavingDraft
          ).then((response)=> this.handleSendInvoiceResponse(response, formValues));
        }
      } catch (error) {
        that.handleCloseInvoiceFormModal();
        that.setState({ submitType: '', partialPayment: null });

        throw error;
      }
    }
  };
  payInvoice = (values) => {
    const { dispatch, user, userExperience } = this.props;

    const invoiceId = userExperience.modalVisibility?.payment?.response?.invoice_id;

    dispatch(payInvoiceWithoutToken(user, invoiceId, values))
      .then((response) => this.handleSendInvoiceResponse(response, values));
  };
  handleSendInvoiceResponse = (response, formValues) => {
    const { dispatch, t, merchantSettings } = this.props;
    if (formValues?.paymentMethod !== PaymentMethod.SEND_TO_CUSTOMER && formValues?.frequency !== PaymentFrequency.SERIES) {
      const requested_amt = response?.response?.requested_amt;
      const authorized_amt = response?.response?.authorized_amt;
      const response_code = response?.response?.response_code;
      const isApproved = response_code === 'APR';
      const isAvs = response_code === 'AVS';
      const isPartialPayment = requested_amt !== authorized_amt;
      const transactionId = FormatTextUtil.formatTransactionId(response?.response?.uniq_id);

      if (isApproved && isPartialPayment) {
        dispatch(setModalVisibility('partialPayment', {
          payment: {
            ...response,
            response: {
              ...response.response,
              entity: {
                id: transactionId
              }
            }
          },
          receipt: {
            id: transactionId
          },
          formValues,
          postPaymentError: {
            type: 'partial',
            heading: 'Errors.PartialPayment.Heading',
            message: 'Errors.PartialPayment.Message'
          }
        }));

      } else if (isApproved || (isAvs && merchantSettings?.merchantSettings?.ignore_avs_failure)) {
        const { submitType } = this.state;
        const isSavingDraft = submitType === 'saveDraft';
        if (isSavingDraft) {
          this.resolveInvoices();
        } else {
          dispatch(setModalVisibility('invoiceDialog', {
            invoicePayment: response.response,
            receipt: {
              loyalty_vpc: response.response.loyalty_vpc,
              id: transactionId
            },
            postPaymentError: null
          }));
        }
      } else if (response?.response?.id) {
        const modalData = { loading: false };
        modalData.futurePaymentModal = { display: true };
        this.setState(modalData);
        this.resolveInvoices();
      } else {
        const { status_message, response_code } = response?.response ?? {};
        const modalData = { loading: false };
        if(response_code === 'AVS') {
          modalData.errorModal = {status_code: t('Errors.Avs.Message') };
        } else {
          modalData.errorModal = {status_code: status_message };
        }
        this.setState(modalData);
      }
    } else {
      this.resolveInvoices();
    }

  };

  resolveInvoices = async () => {
    const { submitType } = this.state;

    const isSavingDraft = submitType === 'saveDraft';

    if (!isSavingDraft) {
      this.setState({ selectedIndex: 0, selectedFilter: InvoiceFilterTypes.INVOICES });
    }

    this.handleCloseInvoiceFormModal();
    this.setState({
      submitType: '',
      partialPayment: null,
      isPayInvoice: false
    });

    await this.handleLoadData();
  };

  async onSendClick () {
    const { user, dispatch, invoices } = this.props;
    const { selectedIndex } = this.state;

    const selectedInvoice = invoices.filteredData[selectedIndex];

    const { isFailed } = selectedInvoice.statusList;

    await InvoiceUtil.sendInvoice(
      { user, dispatch }, selectedInvoice
    );

    if (isFailed) {
      this.handleCloseDetail();
    }

    this.props.dispatch(removeRewardCode());
    await this.handleLoadData();

    this.handleRowSelection(selectedIndex);

  };

  handleCloseDetail () {
    this.setState({ selectedIndex: 0, openDetail: false });
  };

  handleOpenPreviewModal = () => {
    this.setState({ openPreviewModal: true });
  };

  handleClosePreviewModal = () => {
    this.setState({ openPreviewModal: false });
  };

  handleOpenCreateModal = () => {
    this.setState({ openInvoiceFormModal: true, invoiceFormType: InvoiceFormTypes.CREATE });
    this.props.dispatch(setVisibilityFilter(''));
  };

  handleOpenEditDraftModal = (invoice) => {
    const { items } = this.props;

    let itemizedCart = InvoiceUtil.initialItemizedCartObjectFromInvoice(invoice, items);
    itemizedCart = InvoiceUtil.recalculateCart(itemizedCart, undefined, this.props.loyaltyVpc.rewardCodeInfo);

    this.setState({
      openInvoiceFormModal: true,
      invoiceFormType: InvoiceFormTypes.DRAFT,
      itemizedCart,
    });
  };

  handleCloseInvoiceFormModal = () => {
    this.setState({
      openInvoiceFormModal: false,
      invoiceFormType: '',
      itemizedCart: InvoiceUtil.initialItemizedCartObject()
    });
    this.props.dispatch(setCustomer({}));
    this.props.dispatch(removeRewardCode());
  };

  handleFilterSelection = ({ name }) => {
    const { dispatch } = this.props;
    this.setState({ selectedIndex: null, selectedFilter: name });
    dispatch(setVisibilityFilter({ property: 'type', value: name }, 'filter'));
  };

  handleDateSelection = (value, dateRange) => {
    const { dispatch, user } = this.props;

    this.setState({ dateRange });
    dispatch(getInvoices(user, dateRange));
  };

  handleRowSelection = async (selectedIndex) => {
    const {user, dispatch, customers} = this.props;

    const invoice = this.getSelectedInvoice(selectedIndex);
    if(invoice === undefined) return;

    const customerId = invoice?.customer_id || invoice?.pa_customer_id;
    const customerInvoice = customers.data && Array.isArray(customers.data) && customers.data?.find((customer) => customer?.id === customerId);

    const { isSeries, isInvoiceFromSeries, isDraft } = invoice?.statusList ?? {};

    this.setState({
      openDetail: !isDraft,
      selectedInvoice: invoice,
      isLoadingInvoiceDetailData: true
    });

    if (customerInvoice && (isSeries || isInvoiceFromSeries) ) {
      dispatch(getCustomer(user, customerInvoice?.id, customerInvoice.is_pa_customer));
    }

    await dispatch(isSeries ?
      getInvoiceSeries(user, invoice?.id) : getInvoice(user, invoice?.id)
    ).then(() => {
      if (isDraft) {
        this.handleOpenEditDraftModal(invoice);
      }

      this.setState({ isLoadingInvoiceDetailData: false }, () => {
        const { invoices } = this.props;
        const selectedIndex = invoices.filteredData.findIndex(inv => inv?.id === invoice?.id);
        this.setState({ selectedIndex });
      });
    });
  };

  handleSaveSendInvoice = () => {
    this.setState({ submitType: 'saveSend', isSavingDraft: false }, () => {
      this.props.dispatch(submit(INVOICE_FORM_ID));
    });
  };

  handleSaveDraft = () => {
    this.setState({ submitType: 'saveDraft', isSavingDraft: true }, () => {
      this.props.dispatch(submit(INVOICE_FORM_ID));
    });
  };

  handleCloseCashPaidInvoiceDialog = async () => {
    this.setState({ openCashPaidInvoiceDialog: false });

    this.props.dispatch(setModalVisibility('hidden'));

    await this.handleLoadData();
  };

  hideCCInvoiceConfirmationDialog() {
    this.resolveInvoices();
    this.props.dispatch(setModalVisibility('hidden'));
  }

  handleViewInTransactions = () => {
    this.resolveInvoices();
    location.reload();
    this.props.dispatch(setSelectedDateRange('Today', DateUtils.getToday()));
  };

  handleCloseVoidInvoiceDialog = () => {
    this.props.dispatch(setModalVisibility('hidden'));

    this.resolveInvoices();
  };

  handlePreviewInvoice = () => {
    const { dispatch, isInvoiceFormValid } = this.props;

    if (isInvoiceFormValid) {
      this.handleOpenPreviewModal()
    } else {
      dispatch(submit(INVOICE_FORM_ID));
    }
  }

  handleDeleteDraft = async () => {
    const { dispatch, user } = this.props;
    const { selectedIndex } = this.state;

    const selectedInvoice = this.getSelectedInvoice(selectedIndex);
    const {isDraft, isSeries} = selectedInvoice.statusList;

    const payload = isSeries ? {
      type: 'series',
      active: false
    } : {
      type: 'invoice',
      invoice: {
        is_active: false
      }
    };


    if (isDraft) {
      this.closeConfirmDialog();
      await dispatch(updateInvoice(user, selectedInvoice.id, payload));
      await this.handleLoadData();
      this.handleFilterSelection({ name: 'Drafts' })
    }

  }

  handleCCPrintReceipt() {
    const {invoicePayment} = this.props.userExperience.modalVisibility;

    const selectedReceipt = {
      type: 'Credit Sale',
      id: FormatTextUtil.formatTransactionId(invoicePayment.uniq_id)
    };

    return ReceiptUtil.getPrintReceipt(this.props, selectedReceipt).then(() => {
      this.hideCCInvoiceConfirmationDialog();
    }).catch(error => toastr.error('Error', error));
  }

  handlePrintReceipt = async () => {
    return ReceiptUtil.getPrintReceipt(
      this.props,
      this.props.transactions.selectedReceipt
    ).catch((error) => toastr.error('Error', error));
  };

  handleSendReceipt = () => {
    const { selectedReceipt } = this.props.transactions
    if(selectedReceipt) {
      ReceiptUtil.sendReceipt(
        this.props,
        selectedReceipt,
        this.props.userExperience.modalVisibility.invoiceValues['email_addresses'],
        this.props.userExperience.modalVisibility.invoiceValues['phone_number']
      );
    }
  };

  addDiscountToCart(discountIndex) {
    let newCart = InvoiceUtil.addDiscountToCart(discountIndex, this.props.items, this.state.itemizedCart);
    newCart = InvoiceUtil.recalculateCart(newCart, undefined, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({itemizedCart: newCart});
  }

  openItemsDialog(selectedItemIndex) {

    const { items } = this.props;
    const selectedItem = items.filteredItems && items.filteredItems.length > 0 && items.filteredItems[selectedItemIndex];

    this.props.dispatch(setSalesItem(selectedItem, items));
    this.setState({selectedItemIndex: selectedItemIndex, isItemsDialogOpen: true});
  }

  closeItemsDialog() {

    const { items } = this.props;

    this.props.dispatch(setSalesItem(null, items));
    this.setState({selectedItemIndex: null, isItemsDialogOpen: false, isEditingItem: false});
  }

  onAddItemClick(itemCurrentSubtotal, itemCurrentTax, selectedModifiers) {

    this.setState({
      addToOrderAdditionalInfo: {
        itemCurrentSubtotal,
        itemCurrentTax,
        selectedModifiers
      }
    }, () => {
      this.props.dispatch(submit('virtualTerminalFormItemSelection'));
    });

  }

  onRemoveFromOrderClick(itemIndex) {
    this.deleteItem(itemIndex);
    this.closeItemsDialog();
  }

  handleAddItemToOrder(formValues) {
    const { addToOrderAdditionalInfo, itemizedCart, selectedItemIndex, isEditingItem, currentValues } = this.state;
    const { selectedItem } = this.props.items;
    const { taxRate } = this.props.taxes;

    let newCart = InvoiceUtil.handleAddItemToOrder(formValues, addToOrderAdditionalInfo, itemizedCart, selectedItemIndex, isEditingItem, selectedItem, taxRate)
    newCart = InvoiceUtil.recalculateCart(newCart, currentValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({itemizedCart: newCart});
    this.closeItemsDialog();

  }

  cleanItemizedCart() {
    const itemizedCart = InvoiceUtil.initialItemizedCartObject();
    this.setState({ itemizedCart });
  }

  changeItemQuantity(itemIndex, isSubtracting) {
    let itemizedCart = InvoiceUtil.changeItemQuantity(itemIndex, isSubtracting, this.state.itemizedCart);
    itemizedCart = InvoiceUtil.recalculateCart(itemizedCart, this.state.currentValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({ itemizedCart });
  }

  deleteItem(itemIndex) {
    let itemizedCart = InvoiceUtil.deleteItem(itemIndex, this.state.itemizedCart);
    itemizedCart = InvoiceUtil.recalculateCart(itemizedCart, this.state.currentValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({ itemizedCart });
  }

  editItem(itemIndex) {
    const { items } = this.props;
    const { itemizedCart } = this.state;

    const { selectedItem } = InvoiceUtil.editItem(itemIndex, items, itemizedCart);

    this.props.dispatch(setSalesItem(selectedItem, items));
    this.setState({selectedItemIndex: itemIndex, isItemsDialogOpen: true, isEditingItem: true});
  }

  deleteDiscount(discountIndex) {
    let newCart = InvoiceUtil.deleteDiscount(discountIndex, this.state.itemizedCart);
    newCart = InvoiceUtil.recalculateCart(newCart, this.state.currentValues, this.props.loyaltyVpc.rewardCodeInfo);
    this.setState({ itemizedCart: newCart });
  }

  confirmPartialPayment = () => {
    const { userExperience: { modalVisibility }, dispatch } = this.props;

    const partialPayment = modalVisibility?.payment?.response;
    const authorizedAmt = parseFloat(partialPayment?.authorized_amt);
    const requestedAmt = parseFloat(partialPayment?.requested_amt);
    const remainingBalance = (requestedAmt - authorizedAmt).toFixed(2);

    this.setState({
      partialPayment: { ...partialPayment, remainingBalance, authorized_amt: authorizedAmt, requested_amt: requestedAmt},
      isPayInvoice: true
    });

    const formattedRemainingBalance = FormatTextUtil.formatCurrencyWithMaxDigit(remainingBalance, 20);
      dispatch(change(INVOICE_FORM_ID, 'amount', formattedRemainingBalance));

    this.props.dispatch(setModalVisibility('hidden'));
  };


  onProcessVoidButtonAction = () => {

    const that = this;

    this.props.dispatch(setModalVisibility('hidden'));

    return new Promise((resolve) => {
      that.onProcessVoid().then(() => {
        this.setState({ isPayInvoice: true });
      }).catch((e) => {
        let errorMessage;

        try {
          const parsedError = JSON.parse(e);
          errorMessage = parsedError?.reason;
          toastr.error('Error', errorMessage);
        } catch {
          errorMessage = e;
          toastr.error('Error', errorMessage);
        }

        this.props.dispatch(setModalVisibility('vtInvoice', { errorMessage }));
      }).finally(() => {
        resolve();
      });
    });

  };

  onProcessVoid = () => {
    let that = this;

    return new Promise(function (resolve, reject) {

      const {payment, receipt} = that.props.userExperience.modalVisibility;

      const voidPayload = {
        id: payment?.response?.entity?.id,
        item_ids: !!receipt?.line_items ? _.map(receipt.line_items, 'id') : []
      };

      that.props.dispatch(processCreditCardVoid(voidPayload, that.props.user)).then((voidResponse) => {
        if (voidResponse.hasOwnProperty('error')) {
          const err = voidResponse.error || that.props.t(messages.errors.voidRefundError);
          Bugsnag.notify(new Error(err));
          reject(err);
        } else {
          resolve(voidResponse);
        }
      });
    });
  };

  closeHighAmountDialog = () => {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  closeLowAmountDialog = () => this.setState({ showLowAmountWarningModal: false });

  onCloseConfirmModal = () => this.setState({ showConfirmModal: false });

  closePaymentErrorModal = () => this.setState({ errorModal: null });

  closeFuturePaymentDialog = () => this.setState({ futurePaymentModal: null });

  saveDraftConfirmModal = () => {
    this.handleSaveDraft();
    this.onCloseConfirmModal();
  };

  closeConfirmDialog = () => {
    this.handleCloseInvoiceFormModal();
    this.onCloseConfirmModal();
  };

  openConfirmDialog = () => {
    const { items, invoiceFormInitialValues, invoiceFormValues } = this.props;
    const { invoiceFormType, itemizedCart, selectedInvoice } = this.state;

    const untouchedItemizedCart = invoiceFormType === InvoiceFormTypes.DRAFT
      ? InvoiceUtil.initialItemizedCartObjectFromInvoice(selectedInvoice, items)
      : InvoiceUtil.initialItemizedCartObject();

    const cartTouched = invoiceFormValues?.type === FormType.ITEMIZED && !_.isEqual(itemizedCart, InvoiceUtil.recalculateCart(untouchedItemizedCart, invoiceFormInitialValues, this.props.loyaltyVpc.rewardCodeInfo));

    const anyTouched = Object.keys(this.props.fields).length > 0 || cartTouched;
    if (anyTouched) {
      this.setState({ showConfirmModal: true });
    } else {
      this.closeConfirmDialog();
      this.props.dispatch(removeRewardCode());
    }
  };

  getSelectedInvoice = (selectedIndex) => {
    const data = this.props.invoices;
    const filteredData = data?.filteredData || [];
    return filteredData[selectedIndex];
  };

  getDisableSendInvoiceButton() {
    const { invoiceFormValues } = this.props;
    const { itemizedCart } = this.state;
    const isItemizedInvoice = invoiceFormValues?.type === FormType.ITEMIZED;

    return InvoiceUtil.getDisableSendInvoice(itemizedCart, isItemizedInvoice);
  }

  selectInvoice(section, data, searchValue, filteredData, selectedIndex) {

    const { invoiceFormType } = this.state;
    if (invoiceFormType === InvoiceFormTypes.DRAFT) {
      return this.state.selectedInvoice;
    }

    const selectedInvoices = _.concat(data?.selectedInvoice?.invoices, data?.selectedInvoice?.series);
    let selectedInvoice = selectedInvoices
      .find((invoice) => `${invoice?.id}` === `${filteredData[selectedIndex]?.id}` );

    if (!selectedInvoice && searchValue !== '') {
      selectedInvoice = selectedInvoices.find(invoice => invoice?.statusList.isSeries ? `${invoice?.id}` === `${searchValue}` : `${invoice?.invoice}` === `${searchValue}`);
    }
    return selectedInvoice;
  }

  toggleLoyaltyRewardCode(isRemove) {
    let newCart;
    if(isRemove) {
      newCart = InvoiceUtil.recalculateCart(this.state.itemizedCart, this.props?.vtFormValues, {});
      this.props.dispatch(removeRewardCode());
    } else {
      newCart = InvoiceUtil.recalculateCart(this.state.itemizedCart, this.props?.vtFormValues, this.props.loyaltyVpc.rewardCodeInfo);
    }

    this.setState({itemizedCart: newCart});
  }

  getCartForPreview() {
    const { invoiceFormValues } = this.props;
    const { itemizedCart, openPreviewModal } = this.state;

    if (!openPreviewModal) return {};

    return  InvoiceUtil.previewCart(itemizedCart, invoiceFormValues, this.props.loyaltyVpc.rewardCodeInfo);
  }

  handleClosePlanListDialog = () => {
    this.props.dispatch(togglePlanListDialog(false));
  };

  handleOpenPlanListDialog = () => {
    this.props.dispatch(togglePlanListDialog(true));
  };

  handleBulkImportsViewAll = () => {
    this.resolveInvoices();
    this.props.dispatch(completeBulkImport());
  };

  onClickNewBadge = () => {
    this.openConfirmDialog();
    this.setState({ selectedIndex: null, selectedFilter: InvoiceFilterTypes.BULK });
    this.props.dispatch(setVisibilityFilter({ property: 'type', value: InvoiceFilterTypes.BULK }, 'filter'));
  }

  render() {
    const {
      auth, invoices, merchantSettings, userExperience, history,
      user, dispatch, transactions, invoicesFilterCount, customers,
      visibilityFilter, t, isCardOnFileValid, brandColor, invoiceFormValues,
      employees, accountProfile, bulkImports
    } = this.props;

    const {
      invoiceFormType, selectedIndex, selectedFilter, openDetail,
      openInvoiceFormModal, openPreviewModal, openCashPaidInvoiceDialog,
      dateRange, errorModal, futurePaymentModal, partialPayment, isPayInvoice
    } = this.state;

    const { modalVisibility: { partialPaymentDialogOpen, vtInvoiceDialogOpen, payment, invoicePayment, receipt, postPaymentError, errorMessage }, openMessageDialog = false } = userExperience;

    const {
      isSeriesSection,
      isDraftSection,
      isCanceledSection,
      isBulkingInvoice
    } = InvoiceUtil.getFilteredSections(selectedFilter);

    const section = visibilityFilter?.filter?.value;
    const searchValue = visibilityFilter?.search;

    const data = invoices || {};
    const columns = InvoiceUtil.getColumns(selectedFilter);
    const filteredData = data?.filteredData || [];
    const isFetchingCustomerData = customers?.selectedCustomer?.isFetching;

    const rows = filteredData.map(
      InvoiceUtil.getDataFormat(merchantSettings, selectedFilter, invoices?.data?.series)
    );

    const selectedInvoice = this.selectInvoice(section, data, searchValue, filteredData, selectedIndex);

    const isEmpty = _.isEmpty(data.filteredData) || _.isEmpty(data.data);
    const isLoading = this.state.isLoadingData;
    const isLoadingInvoiceDetailData = this.state.isLoadingInvoiceDetailData;
    const { isProcessing = true, isFetching = true } = data;

    const isPremiumPlus = UserUtil.isPremiumPlusAccount(user);
    const isPremiumPlusLoyalty = UserUtil.isPremiumPlusWithLoyaltyAccount(user);
    const shouldShowInvoices = (isPremiumPlus || isPremiumPlusLoyalty) && !isBulkingInvoice;
    const optInData = [
      {
        img: '/images/enhancedInvoices/upgradeLeftTop.svg',
        text: t('UpgradePremiumPlus.EnhancedInvoices.TopLeft'),
      },
      {
        img: '/images/enhancedInvoices/upgradeRightTop.svg',
        text: t('UpgradePremiumPlus.EnhancedInvoices.TopRight'),
      },
      {
        img: '/images/enhancedInvoices/upgradeLeftBottom.svg',
        text: t('UpgradePremiumPlus.EnhancedInvoices.BottomLeft'),
      },
      {
        img: '/images/enhancedInvoices/upgradeRightBottom.svg',
        text: t('UpgradePremiumPlus.EnhancedInvoices.BottomRight')
      }
    ];

    const merchantSettingsNeeded =
      merchantSettings?.customReportStartTime === null;

    const isLoadingReceipt =
      transactions?.isFetchingReceiptPdf || transactions?.isSendingReceipt;
    const isProcessingTransaction =  transactions?.isProcessing;

    const displayLoadingSpinner =
      isProcessing || isFetching || isLoadingReceipt || isProcessingTransaction || isLoadingInvoiceDetailData || isFetchingCustomerData;
    const displayLoadingBar =
      userExperience?.geoTaxFetching ||
      (isEmpty && isFetching && merchantSettingsNeeded) ||
      isLoading || userExperience.isFetching;

    const modalVisibility = userExperience && userExperience.modalVisibility;
    const cashPaidInvoiceResponse = modalVisibility && modalVisibility.response;
    const transactionPath =
      globalApplicationLabel.path +
      routes.activity.root +
      routes.activity.transactions;

    const isOwner = auth?.isOwner;
    const membershipStatus = user?.data?.username
      ? UserUtil.membershipStatus(user)
      : null;

    const itemCartHandlers = {
      cleanItemizedCart: this.cleanItemizedCart,
      changeItemQuantity: this.changeItemQuantity,
      deleteItem: this.deleteItem,
      editItem: this.editItem,
      deleteDiscount: this.deleteDiscount
    }

    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const customerPaymentMethods = customers?.selectedCustomer?.payment_methods;
    const paymentMethods = FilterUtil.filterPaymentMethodsAch(customerPaymentMethods, achEnabled);

    const detailContent = selectedInvoice && openDetail ? (
      <InvoiceDetail
        {...this.props}
        user={user}
        history={history}
        dispatch={dispatch}
        invoice={selectedInvoice}
        selectedFilter={selectedFilter}
        merchantSettings={merchantSettings}
        sendInvoice={this.onSendClick}
        handleLoadData={this.handleLoadData}
        handleCloseDetail={this.handleCloseDetail}
        paymentMethods={paymentMethods}
        isCardOnFileValid={isCardOnFileValid}
      />
    ) : null;

    const hideTitlePanel =
      [isEmpty, isSeriesSection, isDraftSection, isCanceledSection].some(flag => Boolean(flag));

    const titlePanel = (
      <Collapse in={!hideTitlePanel}>
        <TitlePanelInvoiceSummary
          invoices={data.data?.invoices}
          selectedFilter={selectedFilter}
          className='titlePanel'
        />
      </Collapse>
    );

    const restrictedAccessDialog = openMessageDialog ===
      'restrictedMbp' &&
      !UserUtil.isHumbolt(user) && (
        <ErrorMessageDialog
          messageTitle={t('RestrictedAccessDialog.Title')}
          messageContent={t(messages.user.restrictedMbpAccess)}
        />
      );

    const cashPaidInvoiceStatusMessage = (
      <div className='paymentStatusMessageDialog'>
        {cashPaidInvoiceResponse ? (
          <div className='successWrapper'>
          <span>
            {t('CashPaidInvoiceStatusMessage.SuccessStatus')}
          </span>
            <div
              className={`paymentLink ${
                isProcessing && 'disabledSubmitButton'
              } `}
              onClick={!isProcessing && this.handlePrintReceipt}
            >
              {t('CashPaidInvoiceStatusMessage.PrintReceipt')}
            </div>
            <Link
              className='paymentLink'
              to={transactionPath}
              query={{
                receiptId:
                  modalVisibility && modalVisibility.invoiceValues.receipt_id,
              }}
            >
              {t('CashPaidInvoiceStatusMessage.ViewInTransactions')}
            </Link>
            {modalVisibility.invoiceValues['email_addresses'].length > 0 ||
            modalVisibility.invoiceValues['phone_number'].length > 0 ? (
              <div>
                <div
                  className={`paymentLink sendReceiptLink ${
                    isProcessing && 'disabledSubmitButton'
                  }`}
                  onClick={!isProcessing && this.handleSendReceipt}
                >
                  {t('CashPaidInvoiceStatusMessage.SendReceipt')}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className='declinedWrapper'>
            <div className='statusMessage'>
              {t('CashPaidInvoiceStatusMessage.DeclinedStatus')}
            </div>
            <div className='pleaseTryAgain'>{t('CashPaidInvoiceStatusMessage.PleaseTryAgain')}</div>
          </div>
        )}
      </div>
    );

    const voidInvoiceStatusMessage = (
      <div className='declinedWrapper'>
        <div className='statusMessage'>
          {errorMessage}
        </div>
      </div>
    );

    const authorizedAmt = payment && parseFloat(payment.response.authorized_amt);
    const requestedAmt = payment && parseFloat(payment.response.requested_amt);
    const remainingBalance = payment && (requestedAmt - authorizedAmt);

    const formattedAuthorizedAmt = payment && numeral(authorizedAmt.toFixed(2)).format('$0,0.00');
    const formattedRemainingBalance = payment && numeral(remainingBalance.toFixed(2)).format('$0,0.00');

    const partialPaymentDialog = (
      <MessageDialog
        titleText={t('PartialPaymentModal.Title', { authorizedAmt: formattedAuthorizedAmt })}
        bodyText={t('PartialPaymentModal.Description', { remainingBalance: formattedRemainingBalance })}
        confirmText={t('PartialPaymentModal.ConfirmText', { remainingBalance: formattedRemainingBalance })}
        cancelText={t('PartialPaymentModal.CancelText')}
        isChoiceRequired={true}
        open={!!partialPaymentDialogOpen}
        onConfirm={this.confirmPartialPayment}
        onRequestClose={this.onProcessVoidButtonAction}
        hideCloseIcon={true}
      />
    );
    const paymentErrorModal = (
      <Modal
        contentClassName='paymentErrorModal'
        onClose={this.closePaymentErrorModal}
        cancelText={t('Close')}
        hideConfirmButton={true}
        open={Boolean(errorModal)}
        title={<>
          <span className='title-icon'>{IconUtils.getIcon('Error', brandColor)}</span>
          {`${t('UnableProcessPayment')}.`}
        </>}
      >
        {t('DeclinedTryDifferentPayment', { statusCode: errorModal?.status_code ? ` - ${errorModal.status_code}` : '' })}
      </Modal>
    );
    const futurePaymentDialog = (
      <Modal
          cancelText={t('Close')}
          contentClassName='futurePaymentModal'
          hideConfirmButton
          onClose={this.closeFuturePaymentDialog}
          open={Boolean(futurePaymentModal)}
      >
          <p>{t('FuturePaymentDisclaimer')}</p>
      </Modal>
    );
    const cashPaidInvoiceDialog = (
      <Modal
        id='paymentStatusModal'
        title={cashPaidInvoiceResponse ? `${t('Approved')}.` : `${t('Declined')}.`}
        titleIcon={
          cashPaidInvoiceResponse
            ? IconUtils.getIcon('CheckCircle', LabelUtil.getLabelColor())
            : IconUtils.getIcon('Error', LabelUtil.getLabelColor())
        }
        confirmText={t('Done')}
        onConfirm={this.handleCloseCashPaidInvoiceDialog}
        open={openCashPaidInvoiceDialog || modalVisibility?.invoiceDialogOpen}
        hideCancelButton
      >
        {cashPaidInvoiceStatusMessage}
      </Modal>
    );
    const voidInvoiceDialog = (
      <Modal
        id='invoiceStatusModal'
        onClose={this.handleCloseVoidInvoiceDialog}
        cancelText={t('Close')}
        hideConfirmButton={true}
        open={vtInvoiceDialogOpen}
        title={`${t('Failure')}.`}
        titleIcon={IconUtils.getIcon('Error', LabelUtil.getLabelColor())}
      >
        {voidInvoiceStatusMessage}
      </Modal>
    );

    const invoicePreviewDialog = (
      <Modal
        isClosableIconEnable
        hideConfirmButton
        hideCancelButton
        open={openPreviewModal}
        onClose={this.handleClosePreviewModal}
      >
        <InvoicePreview
          cart={this.getCartForPreview()}
          loyaltyReward={this.props.loyaltyVpc?.rewardCodeInfo}
        />
      </Modal>
    );

    const itemsDialog = (
      <Modal
        hideActions
        hideTitle
        maxWidth='md'
        externalClassName='itemsVTDialog'
        contentClassName='itemsDialogBody'
        onClose={this.closeItemsDialog}
        open={this.state.isItemsDialogOpen}
      >
        {<VirtualTerminalFormItemSelection
          {...this.props}
          selectedItemIndex={this.state.selectedItemIndex}
          itemizedCart={this.state.itemizedCart}
          isEditingItem={this.state.isEditingItem}
          onRequestClose={this.closeItemsDialog}
          onAddToOrderClick={this.onAddItemClick}
          onRemoveFromOrderClick={this.onRemoveFromOrderClick}
          onSubmit={this.handleAddItemToOrder}
        />}
      </Modal>
    );

    const highAmountDialog = (
      <HighAmountModal
        onClose={this.closeHighAmountDialog}
        open={userExperience?.modalVisibility?.highTransactionAmountDialog}
        t={t}
      />
    );

    const lowAmountDialog = (
      <LowAmountModal
        onClose={this.closeLowAmountDialog}
        onConfirm={() => this.saveInvoice()}
        open={this.state.showLowAmountWarningModal}
        t={t}
      />
    );

    const confirmModal = (
      <Modal
        title={t('UnsavedChanges')}
        confirmText={t('Discard')}
        cancelText={t('GoBack')}
        thirdAction={{
          label: t('SaveDraft'),
          onClick: this.saveDraftConfirmModal
        }}
        open={this.state.showConfirmModal}
        onClose={this.onCloseConfirmModal}
        onConfirm={this.closeConfirmDialog}
      >{t('DiscardInvoice')}</Modal>
    );

    const showFillOutRequiredFieldsError = this.props.submitFailed && Object.keys(this.props.syncErrors).length > 0;

    const bottomBarLeftActions = [
      {
        key: 'previewInvoice',
        label: t('PreviewInvoice'),
        labelStyle: {marginLeft: '10px', whiteSpace: 'noWrap'},
        icon: IconUtils.getIcon('Visibility'),
        onClick: this.handlePreviewInvoice
      },
    ];

    if (invoiceFormType === InvoiceFormTypes.DRAFT && selectedInvoice) {
      bottomBarLeftActions.push({
        key: 'deleteDraft',
        label: t('DeleteDraft'),
        labelStyle: {marginLeft: '10px'},
        icon: IconUtils.getIcon('DeleteIcon'),
        onClick: this.handleDeleteDraft
      });
    }

    const isMobileSize = this.props.LayoutBreakPoints.isMaxTablet;

    if (isMobileSize) {
      bottomBarLeftActions.push({
        key: 'saveDraft',
        label: t('SaveDraft'),
        labelStyle: {marginLeft: '10px', whiteSpace: 'noWrap'},
        icon: IconUtils.getIcon('EmailIcon'),
        onClick: this.handleSaveDraft,
      });
    }

    const sendOrPayInvoice = !!isPayInvoice ? {
      key: 'payInvoice',
      fullWidth: isMobileSize,
      type: 'contained',
      label: t('PayAmount', { amount: numeral(partialPayment?.remainingBalance || invoiceFormValues?.amount).format('$0,0.00') }),
      disabled: this.getDisableSendInvoiceButton(),
      labelStyle: {whiteSpace: 'noWrap'},
      onClick: this.handleSaveSendInvoice,
    } : {
      key: 'sendInvoice',
      fullWidth: isMobileSize,
      type: 'contained',
      label: t('SendInvoice'),
      disabled: this.getDisableSendInvoiceButton(),
      labelStyle: {whiteSpace: 'noWrap'},
      onClick: this.handleSaveSendInvoice,
    };

    const planListDialog = (
      <Modal
        title={t('UpgradePremiumPlus.ModalTitle')}
        centerTitle
        isClosableIconEnable
        floatingCloseButton
        hideConfirmButton
        hideCancelButton
        open={userExperience.openPlanListDialog}
        onClose={this.handleClosePlanListDialog}
        maxWidth='lg'
      >
        <PlanList {...this.props} />
      </Modal>
    );

    const isApproval = invoicePayment && invoicePayment.response_code === 'APR';
    const isPaymentApproved = isApproval && postPaymentError === null;
    const isLoyaltyProgramEnabled = merchantSettings.loyaltyVpc?.enabled;
    const isPercentReward = receipt?.loyalty_vpc?.reward_amount_type === 'percent';
    const loyaltyIcon = IconUtils.getIcon('LoyaltyProgramIcon');
    const showViewInTransactions = !(auth.isManager && !auth.isManagerActivity);

    if (isLoyaltyProgramEnabled && receipt?.loyalty_vpc && typeof receipt?.loyalty_vpc !== 'string') {
      receipt.loyalty_vpc.program_label = (receipt.loyalty_vpc.program_name === 'Loyalty Program') ? t('LoyaltyPrograms.TitleSingular') : receipt.loyalty_vpc.program_name;
      receipt.loyalty_vpc.points_label = (receipt.loyalty_vpc.points_name === 'points') ? t('LoyaltyPrograms.Points') : receipt.loyalty_vpc.points_name;
    }

    const paymentStatusMessage = (
      <div className='paymentStatusMessageDialog'>
        {isFetching && <div><Loading/><br/></div>}
          <div className='successWrapper'>
            <span className='statusTitle'>{t('PaymentStatusMessageDialog.Title')}</span>
            {
              isLoyaltyProgramEnabled && receipt?.loyalty_vpc && typeof receipt?.loyalty_vpc !== 'string' &&
                <div className='loyaltyStatusMessage'>
                  <div className='loyaltyIcon'>{loyaltyIcon}</div>
                  {
                    receipt?.loyalty_vpc?.reward_is_active && receipt.loyalty_vpc.points_earned === receipt.loyalty_vpc.points_to_earn_reward ?
                      <>
                        <p>{t('PaymentStatusMessageDialog.LoyaltyRewardEarned', {amount: isPercentReward ? `${receipt.loyalty_vpc.reward_amount}%` : `$${receipt.loyalty_vpc.reward_amount}`})}</p>
                      </>
                    :
                      <>
                        <p>{`${receipt.loyalty_vpc.points_earned} ${t('PaymentStatusMessageDialog.LoyaltyRewardInfoFirst')} ${receipt.loyalty_vpc.points_to_earn_reward} ${t('PaymentStatusMessageDialog.LoyaltyRewardInfoSecond', {amount: isPercentReward ? `${receipt.loyalty_vpc.reward_amount}%` : `$${receipt.loyalty_vpc.reward_amount}`, points_label: receipt?.loyalty_vpc?.points_label })}`}</p>
                      </>
                  }
                </div>
            }
            {
              <div
                className={`paymentLink ${isFetching && 'disabledSubmitButton'} `}
                onClick={!isFetching ? this.handleCCPrintReceipt : undefined}
              >
                {t('PrintReceiptButton')}
              </div>
            }
            {
              showViewInTransactions &&
              <div onClick={this.handleViewInTransactions}>
                <Link className='paymentLink'
                      to={`${transactionPath}?receiptId=${receipt?.id}`}>
                  {t('PaymentStatusMessageDialog.ViewInTransactionsButton')}
                </Link>
              </div>
            }

          </div>
      </div>
    );

    const bulkInvoicesTooltip = invoiceFormType !== InvoiceFormTypes.DRAFT && FeatureFlagsUtil.isFeatureFlagEnabled('bulkInvoiceEnabled') && (<NewFeatureBadge t={t} featureText={'New'} tooltip={'BulkInvoices.BulkInvoicesTooltip'} tooltipText={'BulkInvoices.BulkInvoicesTooltipText'} onClickAction={this.onClickNewBadge}/>);

    const content = shouldShowInvoices ? (
      <MasterLayout
        {...this.props}
        pageTitle={t('Invoices')}
        entityText='invoice'
        className='businessInvoices'
        header={titlePanel}
        table={{data, rows, columns, noDataText: t('NoInvoices')}}
        filter={{
          selectedIndex,
          selectedFilter,
          dataName: t('InvoicesData'),
          options: InvoiceUtil.getOptions(invoicesFilterCount),
          dateRange: !isSeriesSection ? dateRange : null,
        }}
        detail={{openDetail, detailContent}}
        displayLoadingBar={displayLoadingBar}
        displayLoadingSpinner={displayLoadingSpinner}
        handleCloseDetail={this.handleCloseDetail}
        handleRowSelection={this.handleRowSelection}
        handleCreateNew={this.handleOpenCreateModal}
        handleDateSelection={this.handleDateSelection}
        handleFilterSelection={this.handleFilterSelection}
      />
    ) : (<Page
      accessories={[]}
      title={t('Invoices')}
    >
      {isBulkingInvoice ?
        <div className='pageWrap'>
        <Box
          className='pageScrollableArea flexContainerResponsiveLayout'
          display='flex'
          flexDirection='row'
        >
          <FilterPanel
            buttonIcon={null}
            buttonOnClick={this.handleOpenCreateModal}
            buttonText={'Create'}
            filterData={InvoiceUtil.getOptions(invoicesFilterCount)}
            pageTitle={t('Invoices')}
            selectFilterCallback={this.handleFilterSelection}
            visibilityFilter={{filter: {value: selectedFilter}}}
            {...this.props}
          />
          <BulkInvoices
            resolveInvoices={this.handleBulkImportsViewAll}
            data-test-id='bulk-landing'
            dispatch={dispatch}
            invoices={bulkImports}
            t={t}
            user={user}
            userExperience={userExperience}
          />
        </Box>
        </div>
        : <div className='upgradeInvoices'>
          <FilterPanel {...this.props} />
          <div className='upgradeContainerPadding upgradeInvoicesContainer'>
            <PlanUpgradeOptIn
              {...this.props}
              data={optInData}
              feature={t('EnhancedInvoices')}
              openPlansModal={this.handleOpenPlanListDialog}
              planType={planTypes.premiumPlus}
            />
          </div>
          {planListDialog}
        </div>}
    </Page>)

    return (
      <>
        <section className='invoicesContainer'>
          {content}
          <Modal
            fullScreen
            hideActions
            isClosableIconEnable
            onClose={this.openConfirmDialog}
            id='createInvoiceModal'
            title={invoiceFormType === InvoiceFormTypes.DRAFT ? t('EditDraftInvoice') : t('CreateInvoice')}
            externalClassName='createInvoiceModal'
            open={openInvoiceFormModal}
            onCloseIcon={this.openConfirmDialog}
            paperProps={{
              sx: {
                maxWidth: '100%'
              }
            }}
            badgeComponent={bulkInvoicesTooltip}
          >
            <InvoiceForm
              { ...this.props }
              openItemsDialog={this.openItemsDialog}
              addDiscountToCart={this.addDiscountToCart}
              selectedInvoice={invoiceFormType === InvoiceFormTypes.DRAFT && selectedInvoice}
              itemizedCart={this.state.itemizedCart}
              itemCartHandlers={itemCartHandlers}
              onSubmit={isPayInvoice ? this.payInvoice : this.handleSaveInvoice}
              submitting={this.props.submitting}
              partialPayment={partialPayment}
              toggleLoyaltyRewardCode={this.toggleLoyaltyRewardCode}
            />
            <BottomBar
              className='wrap-margin'
              rightContent={showFillOutRequiredFieldsError && <span className='settingsErrorBottom noWrap' style={{ margin: '17px' }}>{t('SubmitButtonClickedError')}</span>}
              leftActions={bottomBarLeftActions}
              rightActions={[
                ...!isMobileSize ? [
                  {
                    key: 'cancel',
                    label: t('Cancel'),
                    onClick: this.openConfirmDialog,
                  },
                  {
                    key: 'saveDraft',
                    label: t('SaveDraft'),
                    labelStyle: { marginLeft: '10px',  whiteSpace: 'nowrap', marginRight: '10px' },
                    icon: IconUtils.getIcon('EmailIcon'),
                    onClick: this.handleSaveDraft,
                  }
                ] : [],
                {
                  ...sendOrPayInvoice
                },
              ]}
            />
            <Modal
              id='paymentStatusModal'
              onClose={this.hideCCInvoiceConfirmationDialog}
              hideConfirmButton
              cancelText={t('Close')}
              open={userExperience.modalVisibility.ccInvoiceDialogOpen}
              title={isPaymentApproved ? `${t('Approved')}.` : `${t('Declined')}.`}
              titleIcon={
                isPaymentApproved
                  ? IconUtils.getIcon('CheckCircle', LabelUtil.getLabelColor())
                  : IconUtils.getIcon('Error', LabelUtil.getLabelColor())
              }
            >
              {paymentStatusMessage}
            </Modal>
          </Modal>
          {cashPaidInvoiceDialog}
          {voidInvoiceDialog}
          {restrictedAccessDialog}
          {invoicePreviewDialog}
          {itemsDialog}
          {highAmountDialog}
          {lowAmountDialog}
          {confirmModal}
          {paymentErrorModal}
          {partialPaymentDialog}
          {futurePaymentDialog}
        </section>

      </>
    );

  }
}

const mapStateToProps = (state) => ({
  invoiceFormInitialValues: getFormInitialValues(INVOICE_FORM_ID)(state),
  invoiceFormValues: getFormValues(INVOICE_FORM_ID)(state),
  isInvoiceFormValid: isValid(INVOICE_FORM_ID)(state),
  fields: getFormMeta(INVOICE_FORM_ID)(state),
  syncErrors: getFormSyncErrors(INVOICE_FORM_ID)(state),
  submitSucceeded: hasSubmitSucceeded(INVOICE_FORM_ID)(state),
  submitFailed: hasSubmitFailed(INVOICE_FORM_ID)(state),
  submitting: isSubmitting(INVOICE_FORM_ID)(state),
  isCardOnFileValid: isValid('cardOnFileForm')(state)
});

export default connect(mapStateToProps)(withTranslate(withLayoutBreakPoints(InvoicesComponent)));
