/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setSelectedShippingMethod } from '../../../actions/shoppingCartActions';
import Select from '../../shared/Select';
import Drawer from '../../shared/Drawer';
import shippingServiceCodes from '../../../constants/shippingServiceCodes';
import IconUtil from '../../util/IconUtil';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import numeral from 'numeral';
import ShoppingCartUtil from '../../util/ShoppingCartUtil';
import _ from 'lodash';
import AddressUtil, { ShippingAddress } from '../../util/AddressUtil';
import {postEquipmentOrder} from '../../../actions/equipmentOrderActions';
import i18n from '../../../locales/i18n';

export default class CheckoutSupply extends Component {
  constructor(props) {
    super(props);

    this.handleShippingChange = this.handleShippingChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.loadData = this.loadData.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
    this.getShipmentData = this.getShipmentData.bind(this);
    this.handleShippingAddressChange = this.handleShippingAddressChange.bind(this);

    this.state = {
      shippingPrice: null,
      shippingAddress: ShippingAddress.Shipping
    };
  }

  componentDidMount() {
    this.loadData(this.props);
  }


  componentDidUpdate() {

    if (this.props.shoppingCart.shipping.data &&
      this.props.shoppingCart.shipping.data.RatedShipment &&
      _.isNull(this.state.shippingPrice) &&
      !this.props.shoppingCart.shipping.selected) {
      this.handleShippingChange(null, _.findKey(shippingServiceCodes, {internalCode: 'FEDEX_GROUND'}));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.showCheckoutDialog !== this.props.showCheckoutDialog && nextProps.showCheckoutDialog) {
      this.loadData(nextProps);
    }
  }

  loadData(props) {
    props.getShippingRequest();
  }

  handleShippingChange(event, value) {

    const {shoppingCart, user} = this.props;
    const { shipping } = shoppingCart;

    const shippingData = this.getShipmentData(shipping);

    const selectedShippingChoice = _.find(shippingData, {Service: {Code: value}});

    const costToShip = ShoppingCartUtil.getShippingLabel(selectedShippingChoice, shoppingCart.shipping.dataPremium, user);

    this.props.dispatch(setSelectedShippingMethod(value));

    this.setState({shippingPrice: costToShip && costToShip.fee ? costToShip.fee : 0.00});
  }

  handleCloseDialog() {
    this.props.handleCheckoutClick();
  }

  submitOrder() {
    let that = this;
    const { shoppingCart, user } = this.props;
    const { shippingAddress } = this.state;

    return this.props.dispatch(postEquipmentOrder(user, shoppingCart, 'SUPPLIES', shippingAddress))
      .then(function () {
        that.props.clearCart();
        that.props.handleCheckoutClick();
        that.props.openSuccessDialog();
        that.props.loadSupplies(that.props);
      });
  }

  getShipmentData(shipping) {
    return (shipping.data && shipping.data.RatedShipment) || (shipping.dataPremium && shipping.dataPremium.RatedShipment);
  }

  handleShippingAddressChange(shippingAddress) {
    this.setState({ shippingAddress });
  }

  render() {
    const { t } = i18n;
    const { shippingAddress } = this.state;
    const {accountProfile, user, shoppingCart, equipmentOrder} = this.props;
    const {cart, shipping, allotments} = shoppingCart;
    const closeIcon = IconUtil.getIcon('Close', '#3E3935');

    const noData = shipping.data === null && shipping.dataPremium === null;
    const isLoadingOrEmpty = equipmentOrder.isFetching ||
      accountProfile.data === null ||
      noData ||
      (shipping.isFetching) ||
      (shipping.isFetchingPremium) ||
      cart === null;

    const paymentMethod = (
      <div className='paymentMethod'>
        <p>{t('CheckoutSupplyPage.PaymentMethod')}</p>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          <RadioGroup
            value={'debitMyAccount'}
            name={'paymentMethod'}
          >
            <FormControlLabel
              control={<Radio style={{marginBottom: 0}} />}
              label={
                <span style={{fontSize: 13}}>
                  <p style={{margin: 0}}>{t('CheckoutSupplyPage.DebitMyAccountLabel')}</p>
                  <p style={{margin: 0}}>{t('CheckoutSupplyPage.DebitMyAccountSubLabel')}</p>
                </span>
              }
              value={'debitMyAccount'}
            />
          </RadioGroup>
        </div>
      </div>
    );

    const title = (
      <div className='orderCheckoutHeader'>
        <div className='OrderCheckoutTitle'>
          {t('CheckoutSupplyPage.OrderCheckoutTitle')}
        </div>
      </div>
    );

    let dialogContent = null;

    if (!isLoadingOrEmpty) {

      const shippingData = this.getShipmentData(shipping);
      const shippingChoices = _.filter(shippingData, ((shippingMethod) => {

        const shippingLabel = ShoppingCartUtil.getShippingLabel(shippingMethod, shoppingCart.shipping.dataPremium, user);

        if (shippingLabel.internalCode === 'FEDEX_GROUND') {
          return shippingMethod;
        }

      }));

      const shippingMethod = (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          <RadioGroup
            value={shipping.selected ? shipping.selected : ''}
            name={'shippingMethod'}
            onChange={this.handleShippingChange}
          >
            {
              shippingChoices.map((shippingMethod, index) => {
                const shippingLabel = ShoppingCartUtil.getShippingLabel(shippingMethod, shoppingCart.shipping.dataPremium, user);

                return (
                  <FormControlLabel
                    key={index}
                    control={<Radio style={{marginBottom: 0}} />}
                    label={
                      <span style={{fontSize: 13}}>
                        <p style={{margin: 0}}>{shippingLabel.labelText}</p>
                      </span>
                    }
                    value={shippingMethod.Service.Code}
                  />
                )

              })
            }
          </RadioGroup>
        </div>);

      const displayShippingMethod = (
        <div className='shippingMethod'>
          <p>{t('CheckoutSupplyPage.ShippingMethod')}</p>
          {shippingMethod}
        </div>
      );

      const shippingAddressSelect = (
        <Select
          name='shippingAddress'
          className='shippingAddressSelect'
          input={{
            onChange: this.handleShippingAddressChange,
            value: shippingAddress
          }}
        >
          {Object.values(ShippingAddress).map((value, i) => (
            <MenuItem key={`sipping-address-${i}`} value={value}>{t(value)}</MenuItem>
          ))}
        </Select>
      )

      const deliverTo = (
        <div className='deliverTo'>
          <p>{t('CheckoutSupplyPage.DeliverTo')}</p>
          <div className='deliveryAddressDetail'>
            <div>{shippingAddressSelect}</div>
            <div>
              {AddressUtil.getShippingAddress(accountProfile, shippingAddress).map((content, i) => (
                <p key={`address-detail-${i}`}>{content}</p>
              ))}
            </div>
          </div>
        </div>
      );

      let subTotal = 0;
      let shippingCost = this.state.shippingPrice ? parseFloat(this.state.shippingPrice) : 0.00;

      cart.productIds.forEach((productId) => {

        const cartProduct = cart.productHashes[productId];
        const productTotal = ShoppingCartUtil.calculateProductTotalCostAfterAllotment(cartProduct, allotments);

        subTotal = productTotal + subTotal;
      });

      let totalOrder = subTotal + shippingCost;

      const totalAmount = (
        <div className='total'>
          <div>
            <div>{t('Subtotal')}</div>
            <div>{numeral(subTotal).format('$0.00')}</div>
          </div>
          <div>
            <div>{t('Shipping')}</div>
            <div>{numeral(shippingCost).format('$0.00')}</div>
          </div>
          <div>
            <div>{t('Total')}</div>
            <div>{numeral(totalOrder).format('$0.00')}</div>
          </div>
        </div>
      );

      dialogContent = (
        <section className='checkoutContent'>
          {title}
          {paymentMethod}
          {displayShippingMethod}
          {deliverTo}
          {totalAmount}
        </section>
      );

    }

    return (
      <Drawer
        open={this.props.showCheckoutDialog}
        confirmText={t('CheckoutSupplyPage.PlaceOrderButton')}
        cancelText={t('Cancel')}
        onConfirm={this.submitOrder}
        onClose={this.handleCloseDialog}
        hideCancelButton={isLoadingOrEmpty && this.props.auth.isSpr}
        hideConfirmButton={isLoadingOrEmpty && this.props.auth.isSpr}
        isLoading={isLoadingOrEmpty}
        actionsClassName='summaryFooter'
      >
        {dialogContent}
      </Drawer>
    );

  }
};

CheckoutSupply.propTypes = {
  accountProfile: PropTypes.object,
  shoppingCart: PropTypes.object,
  shipping: PropTypes.object,
  checkoutModal: PropTypes.bool,
  clearCart: PropTypes.func,
  loadSupplies: PropTypes.func,
  openSuccessDialog: PropTypes.func,
  getShippingRequest: PropTypes.func,
  user: PropTypes.object,
  submitOrder: PropTypes.func,
};
