/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Divider } from '@mui/material'

import Button from '../shared/Button';

import Facebook from '../icons/FacebookBlackIcon';
import Twitter from '../icons/TwitterBlackIcon';
import Instagram from '../icons/InstagramBlackIcon';
import Yelp from '../icons/YelpBlackIcon';
import YouTube from '../icons/YouTubeBlackIcon';

import IconUtils from '../util/IconUtil';
import DateUtils from '../util/DateUtil';
import SettingsUtil from '../util/SettingsUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import InvoiceUtil, {
  InvoicesStatus,
  RecurringEnd,
  PaymentFrequency,
  InvoiceTypeIcons,
  INVOICE_FORM_ID,
  CUSTOM_DATE_VALUE,
  CUSTOM_END_DATE_VALUE,
  CUSTOM_DUE_DATE_VALUE,
  CUSTOM_SEND_DATE_VALUE,
  RECEIPT_FORM_ID,
} from '../util/InvoiceUtil';
import LabelUtil from '../util/LabelUtil';

import withTranslate from '../../hoc/withTranslate';
import _ from 'lodash';
import numeral from 'numeral';
import ThemeUtil from '../util/ThemeUtil';

export const InvoicePreview = ({ currentValues = {}, merchant = {}, cart = {}, loyaltyReward, t}) => {

  const {
    ends,
    customer_last,
    customer_first,
    invoice_number,
    numberOfPayments,
    selectedEndDateValue,
    selectedDueDateValue,
    selectedSendDateValue,
    recurring_repeat: { repeat, recurringInterval } = {},
    description,
    name
  } = currentValues;

  const {
    logo,
    website,
    yelp_link,
    twitter_link,
    facebook_link,
    merchant_name,
    instagram_link,
    street_address,
    social_media_enabled,
    brand_color
  } = merchant;

  const isItemized = !!cart?.item_names?.length;
  const hasItemizedDiscounts = !!cart?.receipt_discount_id?.length;

  const isSeries = currentValues.frequency === PaymentFrequency.SERIES;
  const isAddressComplete = street_address && street_address.address1 && street_address.city;

  const { subTotal, subTotalWithoutLoyalty, total, taxAmount } = InvoiceUtil.recalculateCartCustomAmount(currentValues, currentValues.rewardCodeInformation);

  const subtotalToShow = isItemized ? cart?.sub_total_amt : subTotal;
  const taxToShow = isItemized ? cart?.tax_amt : taxAmount;
  const totalToShow = isItemized ? cart?.total_amt : total;
  const amountToShow = FormatTextUtil.formatInvoiceCurrency(totalToShow);

  const itemHasDiscounts = cart?.item_discount_amt?.length || cart?.item_discount_rate?.length;

  const sendDate = selectedSendDateValue === CUSTOM_DATE_VALUE
    ? currentValues[CUSTOM_SEND_DATE_VALUE]
    : DateUtils.addTimeToToday(selectedSendDateValue, 'days');

  const dueDate = selectedDueDateValue === CUSTOM_DATE_VALUE
    ? currentValues[CUSTOM_DUE_DATE_VALUE]
    : DateUtils.addTimeToToday(selectedDueDateValue, 'days');

  const endDate = selectedEndDateValue === CUSTOM_DATE_VALUE
    ? currentValues[CUSTOM_END_DATE_VALUE]
    : DateUtils.addTimeToToday(selectedEndDateValue, 'days');

  const frequencyText = repeat && recurringInterval
    ? `${repeat} ${repeat > 1 ? recurringInterval : recurringInterval.replace('s', '')}`
    : '';

  const startText = sendDate ? DateUtils.formatDate(sendDate, 'Do') : '';

  const endText = ends === RecurringEnd.ON_DATE
    ? ` until ${DateUtils.extractDate(endDate)}`
    : ends === RecurringEnd.AFTER_NUMBER_OF_PAYMENTS
      ? ` after ${numberOfPayments} payment(s)`
      : '';

  const modifiersObject = cart.modifier_sets || cart.item_modifiers;
  const expressModifiers = cart.express_options;

  const hasItems = !!(cart.item_ids && cart.item_ids.length && !_.isEmpty(cart.item_ids.filter(item => !!item)));
  const hasExpressModifiers = expressModifiers?.length > 0 && !_.isEmpty(expressModifiers.filter(item => !!item));
  const hasModifiers = !!(modifiersObject && modifiersObject.length && !_.isEmpty(modifiersObject.filter(item => !!item)));
  let expressModifierTotals = [];

  if (hasItems && hasExpressModifiers) {
    expressModifierTotals = expressModifiers.map(modifiers => modifiers.reduce((partialSum, modifier) => partialSum + numeral(modifier?.price || 0).value(), 0));
  }

  const cdItemPrices = cart.cd_item_price?.length > 0 && cart.cd_item_price;
  const cdItemModifierPrices = cart.cd_item_modifier_prices?.length > 0 && cart.cd_item_modifier_prices;
  const cdItemExpressModifierPrices = cart.cd_item_express_modifier_prices?.length > 0 && cart.cd_item_express_modifier_prices;
  const cdItemDiscountAmounts = cart.cd_item_discount_amt?.length > 0 ? cart.cd_item_discount_amt : cart.item_discount_amt;
  const label = LabelUtil.getLabel();

  return (

    <ThemeProvider theme={ThemeUtil.getTheme(ThemeUtil.themeConfig({}, brand_color))}>
      <div className='invoicePreview'>

        {logo && <img className='logoImg' src={`data:image/${SettingsUtil.getImgExt(logo)};base64,${logo}`} />}

        <div className='previewHeader'>
          <h2>{merchant_name}</h2>
          <p>{name}</p>
          <p>{`${t('InvoicePreview.BillTo')} ${customer_first} ${customer_last}`}</p>
          {invoice_number && <p>{`${t('InvoicePreview.InvoiceNumber')}${invoice_number}`}</p>}
        </div>

        <Divider />

        <div className='previewPayment'>
          <h1>{`${t('InvoicePreview.Invoice')} ${amountToShow}`}</h1>
          <p>{`${t('InvoicePreview.PayBy')} ${DateUtils.formatDate(dueDate)}`}</p>
          <Button label={t('InvoicePreview.PayNow')} />
        </div>

        <Divider />

        <div className='previewDetail'>

          {isSeries && (
            <div className='recurringInfo'>
              {IconUtils.getIcon(InvoiceTypeIcons[InvoicesStatus.SERIES])}
              <span>{`${t('InvoicePreview.RepeatsEvery')} ${frequencyText} ${t('InvoicePreview.RepeatsTime')} ${startText}${endText}`}</span>
            </div>
          )}

          <ul className='listing'>
            {isItemized ? cart.item_names.map((item, index) => {

              const itemRateDiscount = (itemHasDiscounts && cart?.item_discount_rate[index]) ? cart?.item_discount_rate[index] : null;
              const itemTotalAmt = !!cdItemPrices ? cdItemPrices[index] : cart.item_unit_price[index];
              const itemTotalWithDiscount = itemTotalAmt * (itemRateDiscount/100);
              const mappedModifiers = cart?.modifier_sets?.map(InvoiceUtil.mapItemModifiers);

              return (
                <li key={index}>
                  <span className='row' key={index}>
                    <div className='holder'>
                      <span className='extra'>{`${item} (${cart?.item_quantity[index]})`}</span>
                      {hasModifiers ? mappedModifiers[index].map((modifier, modIndex) =>
                        <span className='modifier' key={modIndex}>{modifier?.name}</span>) : null}
                      {hasExpressModifiers && !!expressModifierTotals[index] &&
                        <span className='modifier'>{cart.express_options[index][0].name}</span>
                      }
                      {itemHasDiscounts && (cart?.item_discount_amt[index] || cart?.item_discount_rate[index] || cdItemDiscountAmounts?.[index]) ?
                        <span className='modifier'>{`Discount ${cart?.item_discount_rate[index] ? `(${FormatTextUtil.formatPercentage(`${cart?.item_discount_rate[index]}`)})` : ''}`}</span> : null}
                    </div>
                    <div className='holder'>
                      <span className='amount'>{FormatTextUtil.formatInvoiceCurrency(itemTotalAmt)}</span>
                      {hasModifiers ? (!!cdItemModifierPrices ? cdItemModifierPrices[index].map((modifier, modIndex) =>
                        <span className='modifierAmount' key={modIndex}>{FormatTextUtil.formatInvoiceCurrency(modifier)}</span>) :
                        mappedModifiers[index].map((modifier, modIndex) =>
                        <span className='modifierAmount' key={modIndex}>{FormatTextUtil.formatInvoiceCurrency(modifier?.price)}</span>)) : null}
                      {hasExpressModifiers && !!expressModifierTotals[index] &&
                        <span className='modifierAmount'>
                          {
                            numeral(!!cdItemExpressModifierPrices ?
                              cdItemExpressModifierPrices[index].reduce((partialSum, modifier) => partialSum + modifier, 0)
                              : expressModifierTotals[index] * cart.item_quantity[index]
                            ).format('$0,0.00')
                          }
                        </span>
                      }

                      {itemHasDiscounts && !!cdItemDiscountAmounts && cdItemDiscountAmounts?.[index] ?
                        <span className='modifierAmount'>{`-${FormatTextUtil.formatInvoiceCurrency(cdItemDiscountAmounts[index])}`}</span> :
                        itemHasDiscounts && (cart?.item_discount_amt[index] || cart?.item_discount_rate[index]) ?
                          <span className='modifierAmount'>{cart?.item_discount_rate[index] ? `-${FormatTextUtil.formatInvoiceCurrency(itemTotalWithDiscount)}`: `-${FormatTextUtil.formatInvoiceCurrency(cart?.item_discount_amt[index])}`}</span> : null}

                    </div>
                  </span>
                </li>)}) :
              <li>
                <span className='row'>
                  <span className='extra'>{!isItemized && description ? t('InvoicePreview.Description') : t('InvoicePreview.SaleAmount')}</span>
                  <span className='amount'>{!isItemized && description ? description : FormatTextUtil.formatInvoiceCurrency(subTotalWithoutLoyalty)}</span>
                </span>
              </li>}
            {!!loyaltyReward ? (
              <li>
                <span className='row'>
                  <span className='extra'>{t('LoyaltyReward')}{loyaltyReward.type === 'percent' ? ` (${loyaltyReward?.amount}%)` : ''}</span>
                  <span className='amount'>{`-${FormatTextUtil.formatInvoiceCurrency(Number(cart.loyalty_discount_amt))}`}</span>
                </span>
              </li>
            ) : null}
            {isItemized && hasItemizedDiscounts ? cart?.receipt_discount_name?.map((disc, discIndex) => <li key={discIndex}>
              <span className='row'>
                  <span className='extra'>{`${disc} ${cart?.receipt_discount_percentage[discIndex] ? `(${FormatTextUtil.formatPercentage(cart?.receipt_discount_percentage[discIndex])})` : ''}`}</span>
                  <span className='amount'>{`-${FormatTextUtil.formatInvoiceCurrency(Number(cart?.receipt_discount_amt[discIndex]))}`}</span>
                </span>
            </li>) : null}
            <li>
              <span className='row'>
                <span className='extra'>{t('Subtotal')}</span>
                <span className='amount'>{FormatTextUtil.formatInvoiceCurrency(subtotalToShow)}</span>
              </span>
              <span className='row'>
                <span className='extra'>{t('Tax')}</span>
                <span className='amount'>{FormatTextUtil.formatInvoiceCurrency(taxToShow)}</span>
              </span>
            </li>
            <li>
            <span className='row'>
              <span className='item'>{t('Total')}</span>
              <span className='summaryTotal amount'>{FormatTextUtil.formatInvoiceCurrency(totalToShow)}</span>
            </span>
            </li>
          </ul>
        </div>

        <div className='previewFooter'>

          {(merchant_name || isAddressComplete) && (
            <div className='previewAddress'>
              {merchant_name && <h2 className='name merchant'>{merchant_name}</h2>}
              {isAddressComplete && <p className='card address'>{`${street_address.address1}`}</p>}
              {isAddressComplete && <p className='card city'>{`${street_address.city}, ${street_address.state} ${street_address.zip_code}`}</p>}
              {website && <p className='card website'>{website}</p>}
            </div>
          )}

          {social_media_enabled && (
            <div className='social'>
              <ul>
                {facebook_link && <li>
                  <Facebook id='facebook' />
                </li>}
                {instagram_link && <li>
                  <Instagram id='instagram' />
                </li>}
                {twitter_link && <li>
                  <Twitter id='twitter' />
                </li>}
                {yelp_link && <li>
                  <Yelp id='yelp' />
                </li>}
              </ul>
            </div>
          )}

          <img src={label.brandLogo} className='poweredBy' alt={label.brand} />

        </div>

      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state, ownProps) => {

  let currentValues, merchant;

  if (ownProps.useReceiptSettingsValues) {

    currentValues = {
      customer_last: 'Name',
      customer_first: 'Customer',
      amount: '$0.00'
    };

    const receiptFormValues = getFormValues(RECEIPT_FORM_ID)(state);

    merchant = Object.assign({}, receiptFormValues, {
      street_address: {
        address1: receiptFormValues?.address,
        city: receiptFormValues?.city,
        state: receiptFormValues?.state,
        zip_code: receiptFormValues?.zip
      }
    });

  } else {

    currentValues = getFormValues(INVOICE_FORM_ID)(state);
    merchant = state.merchantSettings?.merchantSettings || {};

  }

  return {
    merchant,
    currentValues
  };
};

export default connect(mapStateToProps)(withTranslate(InvoicePreview));
