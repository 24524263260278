/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { setSubmitButtonEnabled } from '../../actions/userExperienceActions';
import { MenuItem } from '@mui/material';
import CustomToggle from '../shared/Toggle';
import Select from '../shared/Select';
import TextField from '../shared/TextField';
import { connect } from 'react-redux';
import TextUtil from '../util/FormatTextUtil';
import Validator from '../util/Validator';
import {
  Field,
  reduxForm,
  getFormValues,
  arrayRemove,
  arrayPush
} from 'redux-form';
import _ from 'lodash';
import FormUtil from '../util/FormUtil';
import UserUtil from '../util/UserUtil';
import IconUtil from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import MessageDialog from '../shared/MessageDialog';
import Tooltip from '../shared/Tooltip';
import { Trans } from 'react-i18next';
import ChipInput from '../shared/ChipInput';
import Box from '@mui/material/Box';
import {
  communicationDisputesStyles
} from '../../jss/chipInputStyles';

const label = LabelUtil.getLabel();

export const validate = (values, props) => {
  if (!_.isEmpty(values) && !_.isEmpty(label.abbreviation)) {
    const hasCommunicationPreferences = (UserUtil.userType(props.user) === 'MBP') || UserUtil.isEPX(props.user);
    const userIsMBPEPX = UserUtil.isEPX(props.user) && UserUtil.userType(props.user) !== 'PA';
    const userIsEdge = props.merchantSettings?.edgeStatus?.is_edge;

    return Validator.validateCommunicationsSettingsForm(values, label.abbreviation, hasCommunicationPreferences, userIsMBPEPX, userIsEdge);
  } else {
    return {};
  }
};

const styles = {
  inputStyle: {color: '#393A3F'},
  alertInputStyle: {
    color: '#393A3F',
    textAlign: 'right',
    width: '100%',
  },
  alertHintStyle: {
    textAlign: 'right',
    width: '100%',
  },
  checkbox: {
    color: '#888C8D'
  },
  errorStyle: {
    verticalAlign: 'bottom'
  },
  disabledStyle: {
    background: '#0000000D',
    borderRadius: '10px',
  }
};

export class CommunicationsSettingsForm extends Component {

  constructor(props) {
    super(props);
    this.handleRequestAdd = this.handleRequestAdd.bind(this);
    this.handleRequestDelete = this.handleRequestDelete.bind(this);
    this.updateDisputeNotificationsFocus = this.updateDisputeNotificationsFocus.bind(this);
    this.state = {
      emailAutoFocus: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let enableSubmit = FormUtil.shouldEnableSubmit(nextProps);

    /* istanbul ignore else */
    if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }
  }

  handleRequestAdd(chip) {
    const { dispatch, currentValues } = this.props;
    if (Validator.validateEmailString(chip)) {
      if (!currentValues.comm_disputes?.includes(chip.toLowerCase())) {
        dispatch(arrayPush('communicationsSettingsForm', 'comm_disputes', chip.toLowerCase()));
        this.updateDisputeNotificationsFocus(true);
      }
    }
  }

  handleRequestDelete(chip, index) {
    const { dispatch } = this.props;
    dispatch(arrayRemove('communicationsSettingsForm', 'comm_disputes', index));
  }

  updateDisputeNotificationsFocus(focus) {
    this.setState({ emailAutoFocus: focus });
  }

  render() {
    const { emailAutoFocus } = this.state;
    const {
      handleSubmit, user, auth, currentValues, communicationAddresses, merchantSettings,
      accountProfile, disableFields, userExperience, t
    } = this.props;

    const businessAddress = accountProfile && accountProfile.data ? accountProfile.data.business_address : '';
    const shippingAddress = accountProfile && accountProfile.data ? accountProfile.data.shipping_address_1 : '';
    const verifyingEmail = accountProfile && accountProfile.data && userExperience.uxStorage && userExperience.uxStorage.emailValidated ? userExperience.uxStorage.emailValidated === 'progress' && !accountProfile?.data?.email_validated : false;
    const fax = accountProfile && accountProfile.data ? accountProfile.data.fax : '';

    const hasCommunicationPreferences = (UserUtil.userType(user) === 'MBP') || UserUtil.isEPX(user);
    const userIsMBPEPX = UserUtil.isEPX(user) && UserUtil.userType(user) !== 'PA';
    const userIsEdge = merchantSettings?.edgeStatus?.is_edge;
    const isOwner = UserUtil.isOwner(user) || auth?.isOwner;
    const mid = UserUtil.getActiveAccount(user).mid;
    const showClosingReminders = [
      mid && merchantSettings?.merchantSettings?.manual_capture,
      mid && !merchantSettings?.merchantSettings?.manual_capture && merchantSettings?.merchantSettings?.paper_signature_enabled
    ].some(flag => flag);


    const emailIcon = IconUtil.getIcon('EmailIcon', '#888C8D');
    const mailboxIcon = IconUtil.getIcon('MailboxIcon', '#888C8D');
    const faxIcon = IconUtil.getIcon('PrintIcon', '#888C8D');
    const infoIcon = IconUtil.getIcon('InfoIcon', label.primaryColor);
    const lockIcon = IconUtil.getIcon('LockIcon', '#23201f');


    const itemsDailySnapshotEmailText =
      communicationAddresses['comm_dailySnapshotEmail'] && communicationAddresses['comm_dailySnapshotEmail'].length > 0 ?
        t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationsItemsDailySnapshotEmail.Option3WithEmail', { emailAddress: communicationAddresses['comm_dailySnapshotEmail'] }) : t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationsItemsDailySnapshotEmail.Option3WithoutEmail');

    const communicationsItemsDailySnapshotEmail = [
      <MenuItem
        key='none'
        value={'NONE'}>
        {t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationsItemsDailySnapshotEmail.Option1')}
      </MenuItem>,
      <MenuItem
        key='email-hold-only'
        value={'EMAIL_HOLD_ONLY'}>
        <span style={{ padding: '0px 12px', marginLeft: 0, marginTop: '5px' }}>{emailIcon}</span>
        {t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationsItemsDailySnapshotEmail.Option2')}
      </MenuItem>,
      <MenuItem
        key='email'
        value={'EMAIL'}>
        <span style={{ padding: '0px 12px', marginLeft: 0, marginTop: '5px'}}>{emailIcon}</span>
        {itemsDailySnapshotEmailText}
      </MenuItem>,
    ];

    const communicationsItemsClosingEmail = [
      <MenuItem
        key='none'
        value={false}>
        {t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationsItemsClosingEmail.Option1')}
      </MenuItem>,
      <MenuItem
        key='email'
        value={true}>
        <span style={{ padding: '0px 12px', marginLeft: 0 }}>{emailIcon}</span>
        {t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationsItemsClosingEmail.Option2')}
      </MenuItem>,
    ];

    const communicationsItemsLoginNotification = [
      <MenuItem
        key='loginNotificationOptedIn'
        value={true}
      >
        <span style={{ padding: '0px 12px', marginLeft: 0 }}>{emailIcon}</span>
        {t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationItemsLoginNotification.OptedIn')}
      </MenuItem>,
      <MenuItem
        key='loginNotificationOptedOut'
        value={false}
      >
        {t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationItemsLoginNotification.OptedOut')}
      </MenuItem>
    ]

    const businessAddressItem = (
      <MenuItem
        key='business_address'
        value={'USPS_DBA_ADDRESS'}>
        <span style={{ padding: '0px 12px', marginLeft: 0 }}>{mailboxIcon}</span>
        {`Business Address: ${businessAddress}`}
      </MenuItem>);

    const faxItem = (
      <MenuItem
        key='fax'
        value={'FAX'}>
        <span style={{ padding: '0px 12px', marginLeft: 0 }}>{faxIcon}</span>
        {`Fax: ${fax}`}
      </MenuItem>);

    const shippingAddressItem = (
      <MenuItem
        key='shipping_address'
        value={'USPS_CORPORATE_ADDRESS'}>
        <span style={{ padding: '0px 12px', marginLeft: 0 }}>{mailboxIcon}</span>
        {`Business Shipping Address: ${shippingAddress}`}
      </MenuItem>);

    /**
     * Returns items for compliance and statements with
     * their respective email from api.
     * @param {*} emailAddress
     * @returns jsx
     */
    const communicationItems = (emailAddress) => {
      const items =  [
        <MenuItem
          disabled
          key='empty'
          value={''}>
          {t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationItems.Option1')}
        </MenuItem>,
        <MenuItem
          key='email'
          value={'EMAIL'}>
          <span style={{ padding: '0px 12px', marginLeft: 0 }}>{emailIcon}</span>
          {emailAddress && emailAddress.length > 0 ? t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationItems.Option2', { emailAddress: communicationAddresses['comm_disputes'] }) : t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationItems.Option3')}
        </MenuItem>,
      ];

      const shouldAddBusiness = !userIsMBPEPX && userIsEdge;
      const shouldAddShipping = accountProfile.data && user.data && accountProfile.data.shipping_address_1 && !userIsMBPEPX && userIsEdge;
      if (shouldAddBusiness) {
        items.push(businessAddressItem);
      }
      if (shouldAddShipping) {
        items.push(shippingAddressItem);
      }
      return items;
    };


    const emailPendingVerificationMessage = (
      <div className='emailVerifyContainer'>
        <span className='email'>{communicationAddresses['comm_disputes'] ? communicationAddresses['comm_disputes'] : ''}</span>
        <span className='pending'>
          <span className='text'>{'Pending verification'}</span>
          <Tooltip
              component='span'
              placement='right'
              title={t('Tooltips.PendingVerification')}
          >
            <span onClick={this.props.sendValidationEmail}>{infoIcon}</span>
          </Tooltip>
        </span>
      </div>
    );

    const communicationDailySnapshotEmail = hasCommunicationPreferences ? (
      <div className='communicationHolder'>
        <Field
          className='fullScreen'
          component={Select}
          label={t('CommunicationSettingsForm.BasicAccountAlerts.DailySnapshotEmail')}
          disabled={disableFields}
          name='comm_dailySnapshotEmail'
        >
          {communicationsItemsDailySnapshotEmail}
        </Field>
      </div>
    ) : null;

    const communicationStatements = userIsEdge ? (
      <div className='communicationHolder'>
        <Field
          className='fullScreen'
          component={Select}
          label='Statements'
          disabled={userIsEdge}
          name='comm_statements'
        >
          {communicationItems(communicationAddresses['comm_statements'] ? communicationAddresses['comm_statements'] : '')}
        </Field>
      </div>
    ): null;

    const communicationCompliance = userIsMBPEPX ? (
      <div className='communicationHolder'>
        <Field
          className='fullScreen'
          component={Select}
          label='Compliance'
          disabled={disableFields}
          name='comm_compliance'
        >
          {communicationItems(communicationAddresses['comm_compliance'] ? communicationAddresses['comm_compliance'] : '')}
        </Field>
      </div>
    ) : null;


    const communicationDisputes = hasCommunicationPreferences ? (
      <Box className='communicationHolder' sx={communicationDisputesStyles}>
        <div className='textFieldInfo'>
          <label className='disputesLabel'>{t('CommunicationSettingsForm.BasicAccountAlerts.DisputeNotifications')}</label>
        </div>
        <Field
          className='fullScreen'
          component={props => (
            <ChipInput
              {...props}
              autoFocus={emailAutoFocus}
              multiple
              hintText={t('CommunicationSettingsForm.BasicAccountAlerts.EnterEmail')}
              name='comm_disputes'
              onAdd={(chip) => {
                this.handleRequestAdd(chip);
              }}
              onDelete={(chip, index) => this.handleRequestDelete(chip, index)}
              onBlur={(event) => {
                this.handleRequestAdd(event.target.value);
              }}
            />
          )}
          disabled={disableFields}
          name='comm_disputes'
          displayempty='true'
        />
      </Box>
    ) : null;

    const manualCaptureBatchNotification = showClosingReminders ? (
      <div className='communicationHolder manualCaptureBatchNotification'>
        <Field
          className='fullScreen'
          component={Select}
          label={t('CommunicationSettingsForm.BasicAccountAlerts.ManualCaptureBatchNotification')}
          disabled={disableFields}
          name='manual_close_notifications_enabled'
        >
          {communicationsItemsClosingEmail}
        </Field>

      </div>
    ) : null;

    const tipAdjustmentEmail = showClosingReminders ? (
      <div className='communicationHolder tipAdjustmentEmail'>
        <Field
          className='fullScreen'
          component={Select}
          label={t('CommunicationSettingsForm.BasicAccountAlerts.TipAdjustment')}
          disabled={disableFields}
          name='tip_adjust_manual_notification_enabled'
        >
          {communicationsItemsClosingEmail}
        </Field>
        {verifyingEmail ? emailPendingVerificationMessage : null}
      </div>
    ) : null;

    const loginAttemptNotification = isOwner && (
      <div className='communicationHolder loginAttemptNotification'>
        <Field
          className='fullScreen'
          component={Select}
          label={t('CommunicationSettingsForm.BasicAccountAlerts.CommunicationLoginNotification')}
          disabled={disableFields}
          name='send_login_attempt_email'
        >
          {communicationsItemsLoginNotification}
        </Field>
      </div>
    );

    const submitButtonDisabled = userExperience && !userExperience.enableFormSubmitButton;

    const emailCommunicationDialog = (<MessageDialog
        confirmText={t('Close')}
        disabled={submitButtonDisabled}
        externalClassName='emailCommunicationDialog'
        isChoiceRequired={false}
        onConfirm={this.props.handleEmailCommunicationClose}
        onRequestClose={this.props.handleEmailCommunicationClose}
        open={this.props.openEmailCommunicationDialog}
        titleText={t('VerifyEmailAddress')}
        bodyText={
          <p>{t('VerifyEmailAddressContent', { userName: user.data.username })}</p>
        } />
    );

    const isPremiumPlusAccount = UserUtil.isPremiumPlusAccount(user);
    const isUserCS = UserUtil.isUserCS(user, auth);
    const isUserCSWrite = UserUtil.isUserCSWrite(user);
    const invoiceAlertsToggleDisabled = isUserCS || isUserCSWrite;

    const invoiceAlerts = (isPremiumPlusAccount &&
      <React.Fragment>
        <div className='detailsRow headerDivider'></div>
        <div className='invoiceAlertsContainer'>
          <div className='formHeading invoiceAlertsHeader'>{t('CommunicationSettingsForm.BasicAccountAlerts.InvoiceAlertsHeader')}</div>
          <div className='subText invoiceAlertsDescription'>{t('CommunicationSettingsForm.BasicAccountAlerts.InvoiceAlertsDescription')}</div>
          <div className='invoiceAlertsColumn'>
            <Field component={CustomToggle} key={'enhanced_invoices_invoice_sent'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.InvoiceSent')} labelPosition='end' name={'enhanced_invoices_invoice_sent'} />
            <Field component={CustomToggle} key={'enhanced_invoices_invoice_paid'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.InvoicePaid')} labelPosition='end' name={'enhanced_invoices_invoice_paid'} />
            <Field component={CustomToggle} key={'enhanced_invoices_invoice_canceled'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.InvoiceCanceled')} labelPosition='end' name={'enhanced_invoices_invoice_canceled'} />
            <Field component={CustomToggle} key={'enhanced_invoices_series_enrolled'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.SeriesEnrolled')} labelPosition='end' name={'enhanced_invoices_series_enrolled'} />
            <Field component={CustomToggle} key={'enhanced_invoices_series_payment_method_updated'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.PaymentMethodUpdated')} labelPosition='end' name={'enhanced_invoices_series_payment_method_updated'} />
            <Field component={CustomToggle} key={'enhanced_invoices_invoice_payment_amount_updated'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.InvoicePaymentAmountUpdated')} labelPosition='end' name={'enhanced_invoices_invoice_payment_amount_updated'} />
          </div>
          <div className='invoiceAlertsColumn'>
            <Field component={CustomToggle} key={'enhanced_invoices_payment_pending'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.PaymentPending')} labelPosition='end' name={'enhanced_invoices_payment_pending'} />
            <Field component={CustomToggle} key={'enhanced_invoices_payment_failed'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.PaymentFailed')} labelPosition='end' name={'enhanced_invoices_payment_failed'} value={true}/>
            <Field component={CustomToggle} key={'enhanced_invoices_card_on_file_added'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.CardOnFileAdded')} labelPosition='end' name={'enhanced_invoices_card_on_file_added'} />
            <Field component={CustomToggle} key={'enhanced_invoices_card_on_file_removed'} disabled={invoiceAlertsToggleDisabled} label={t('CommunicationSettingsForm.BasicAccountAlerts.CardOnFileRemoved')} labelPosition='end' name={'enhanced_invoices_card_on_file_removed'} />
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <form onSubmit={handleSubmit}>
        <div className='basicAlerts'>
          <div className='formHeading'>
            {t('CommunicationSettingsForm.CustomEmailAlertsSection.Title')}
          </div>
          <div className='dailySummaryAlert'>
            <Field
              component={CustomToggle}
              labelPosition='end'
              label={t('CommunicationSettingsForm.CustomEmailAlertsSection.DailySummaryAlert')}
              name='daily_batch_activity_summary'
            />
          </div>
        </div>
        <div className='customAlerts'>
          <div className='customAlertsRow'>
            <Field
              component={CustomToggle}
              labelPosition='end'
              label={t('CommunicationSettingsForm.CustomEmailAlertsSection.CustomAlerts')}
              name='no_transaction_activity'
            />
            <Field
              hintText={t('CommunicationSettingsForm.CustomEmailAlertsSection.NoTransactionActivityHint')}
              component={TextField}
              className='alignBottom'
              disabled={!currentValues.no_transaction_activity}
              styles={!currentValues.no_transaction_activity ? styles.disabledStyle : null}
              fullWidth={false}
              normalize={TextUtil.formatDays}
              name='no_transaction_activity_target_value'
              onFocus={() => this.updateDisputeNotificationsFocus(false)}
            />
            <br/>
          </div>
        </div>
        <div className='alertsContainer'>
          <div className='formItems'>
            <Field
              label={t('CommunicationSettingsForm.CustomEmailAlertsSection.CustomEmailLabel')}
              hintText={t('CommunicationSettingsForm.CustomEmailAlertsSection.CustomEmailHint')}
              component={TextField}
              className='fullScreen alignBottom'
              fullWidth={false}
              name='alert_to'
              disabled={!currentValues.no_transaction_activity && !currentValues.daily_batch_activity_summary}
              onFocus={() => this.updateDisputeNotificationsFocus(false)}
            />
            <Field
              label={t('CommunicationSettingsForm.CustomEmailAlertsSection.AdditionalEmailLabel')}
              hintText={t('CommunicationSettingsForm.CustomEmailAlertsSection.AdditionalEmailHint')}
              component={TextField}
              className='fullScreen alignBottom'
              fullWidth={false}
              name='alert_cc'
              disabled={!currentValues.alert_to}
              onFocus={() => this.updateDisputeNotificationsFocus(false)}
            />
          </div>
        </div>
        <div className='detailsRow headerDivider'/>

        <div className='communicationAlerts'>
          <div className='formHeading'>{t('CommunicationSettingsForm.BasicAccountAlerts.Title')}</div>
          <div className='subText'>
            <Trans
                components={{ phone: <span />}}
                i18nKey='CommunicationSettingsForm.BasicAccountAlerts.SubTitle'
                values={{ phoneNumber: label.phoneNumber }}
            />
          </div>

          <div className='businessEmailWrapper'>
            <span className='lockIcon'>{lockIcon}</span>
            <div className='fieldHolder'>
              <div className='businessEmailLabel'>{t('CommunicationSettingsForm.BasicAccountAlerts.BusinessEmailLabel')}</div>
              <div className='textFieldHolder'><span>{accountProfile && accountProfile.data &&
                accountProfile.data.principal_email || ''
              }</span></div>
            </div>
          </div>

          {communicationDailySnapshotEmail}
          {manualCaptureBatchNotification}
          {tipAdjustmentEmail}
          {communicationStatements}
          {communicationCompliance}
          {communicationDisputes}
          {loginAttemptNotification}
        </div>
        {invoiceAlerts}
        {emailCommunicationDialog}
      </form>
    );
  }
}

export const CommunicationsSettings = reduxForm({
  form: 'communicationsSettingsForm',
  validate,
  fields: [
    'alert_cc',
    'alert_to',
    'daily_batch_activity_summary',
    'batch_over',
    'batch_over_target_value',
    'batch_under',
    'batch_under_target_value',
    'no_transaction_activity',
    'no_transaction_activity_target_value',
    'tip_adjust_manual_notification_enabled',
    'manual_close_notifications_enabled',
    'comm_dailySnapshotEmail',
    'comm_compliance',
    'comm_disputes',
    'comm_statements',
    'enhanced_invoices_invoice_sent',
    'enhanced_invoices_invoice_paid',
    'enhanced_invoices_invoice_canceled',
    'enhanced_invoices_series_enrolled',
    'enhanced_invoices_series_payment_method_updated',
    'enhanced_invoices_invoice_payment_amount_updated',
    'enhanced_invoices_payment_pending',
    'enhanced_invoices_payment_failed',
    'enhanced_invoices_card_on_file_added',
    'enhanced_invoices_card_on_file_removed',
    'send_login_attempt_email'
  ],
  enableReinitialize: true,
})(CommunicationsSettingsForm);

function getAlertValues(data, name) {
  let alertValues = {};

  let alertValuesResult = _.find(data, function (alert) {
    return alert.alert_name === name;
  });

  alertValues.checked = alertValuesResult ? true : false;
  alertValues.targetValue = alertValuesResult ? alertValuesResult.target_value : null;

  return alertValues;
}

function mapStateToProps(state, ownProps) {

  let initialValues = {};
  let communicationAddresses = {};
  const alertsData = state?.alerts?.data;
  const communicationsData = state?.communications?.data;
  const merchantSettings = state?.merchantSettings?.merchantSettings;
  const currentValues = getFormValues('communicationsSettingsForm')(state) || {};

  /* istanbul ignore else */
  if (merchantSettings) {
    initialValues.tip_adjust_manual_notification_enabled = Boolean(merchantSettings?.tip_adjust_manual_notification_enabled);
    initialValues.manual_close_notifications_enabled = Boolean(merchantSettings?.manual_close_notifications_enabled);
    initialValues.send_login_attempt_email = Boolean(merchantSettings?.send_login_attempt_email);
  }

  /* istanbul ignore else */
  if (alertsData) {
    // Alerts section
    initialValues.alert_to = alertsData.alert_to ? alertsData.alert_to[0] : state?.user?.data?.username;
    initialValues.alert_cc = alertsData.alert_cc ? alertsData.alert_cc[0] : '';

    initialValues.daily_batch_activity_summary = getAlertValues(alertsData.alerts, 'daily_batch_activity_summary').checked;

    const batchOver = getAlertValues(alertsData.alerts, 'batch_over');
    initialValues.batch_over = batchOver.checked;
    initialValues.batch_over_target_value = batchOver.targetValue ? TextUtil.formatCurrency(batchOver.targetValue) : '';

    const batchUnder = getAlertValues(alertsData.alerts, 'batch_under');
    initialValues.batch_under = batchUnder.checked;
    initialValues.batch_under_target_value = batchUnder.targetValue ? TextUtil.formatCurrency(batchUnder.targetValue) : '';

    const noTransactionActivity = getAlertValues(alertsData.alerts, 'no_transaction_activity');
    initialValues.no_transaction_activity = noTransactionActivity.checked;
    initialValues.no_transaction_activity_target_value = noTransactionActivity.targetValue ? TextUtil.formatDays(noTransactionActivity.targetValue) : '';

    // Invoice alerts
    const invoiceSent = getAlertValues(alertsData.alerts, 'enhanced_invoices_invoice_sent');
    initialValues.enhanced_invoices_invoice_sent = invoiceSent.checked;

    const invoicePaid = getAlertValues(alertsData.alerts, 'enhanced_invoices_invoice_paid');
    initialValues.enhanced_invoices_invoice_paid = invoicePaid.checked;

    const invoiceCanceled = getAlertValues(alertsData.alerts, 'enhanced_invoices_invoice_canceled');
    initialValues.enhanced_invoices_invoice_canceled = invoiceCanceled.checked;

    const invoiceSeriesEnrolled = getAlertValues(alertsData.alerts, 'enhanced_invoices_series_enrolled');
    initialValues.enhanced_invoices_series_enrolled = invoiceSeriesEnrolled.checked;

    const invoiceSeriesPaymentMethodUpdated = getAlertValues(alertsData.alerts, 'enhanced_invoices_series_payment_method_updated');
    initialValues.enhanced_invoices_series_payment_method_updated = invoiceSeriesPaymentMethodUpdated.checked;

    const invoiceSeriesPaymentAmountUpdated = getAlertValues(alertsData.alerts, 'enhanced_invoices_invoice_payment_amount_updated');
    initialValues.enhanced_invoices_invoice_payment_amount_updated = invoiceSeriesPaymentAmountUpdated.checked;

    const invoicePaymentPending = getAlertValues(alertsData.alerts, 'enhanced_invoices_payment_pending');
    initialValues.enhanced_invoices_payment_pending = invoicePaymentPending.checked;

    const invoicePaymentFailed = getAlertValues(alertsData.alerts, 'enhanced_invoices_payment_failed');
    initialValues.enhanced_invoices_payment_failed = invoicePaymentFailed.checked;

    const invoiceCardOnFileAdded = getAlertValues(alertsData.alerts, 'enhanced_invoices_card_on_file_added');
    initialValues.enhanced_invoices_card_on_file_added = invoiceCardOnFileAdded.checked;

    const invoiceCardOnFileRemoved = getAlertValues(alertsData.alerts, 'enhanced_invoices_card_on_file_removed');
    initialValues.enhanced_invoices_card_on_file_removed = invoiceCardOnFileRemoved.checked;
  }

  /* istanbul ignore else */
  if (communicationsData) {
    initialValues.comm_dailySnapshotEmail = communicationsData && _.find(communicationsData, {communication_type: 'DAILY_SUMMARY'}) ? _.find(communicationsData, {communication_type: 'DAILY_SUMMARY'}).channel : '';
    communicationAddresses.comm_dailySnapshotEmail = communicationsData &&
      _.find(communicationsData, {communication_type: 'DAILY_SUMMARY'}) &&
      typeof _.find(communicationsData, {communication_type: 'DAILY_SUMMARY'}).address === 'string' &&
      _.find(communicationsData, {communication_type: 'DAILY_SUMMARY'}).address;

    initialValues.comm_compliance = communicationsData && _.find(communicationsData, {communication_type: 'COMPLIANCE'}) ? _.find(communicationsData, {communication_type: 'COMPLIANCE'}).channel : '';
    communicationAddresses.comm_compliance = communicationsData &&
      _.find(communicationsData, {communication_type: 'COMPLIANCE'}) &&
      typeof _.find(communicationsData, {communication_type: 'COMPLIANCE'}).address === 'string' &&
      _.find(communicationsData, {communication_type: 'COMPLIANCE'}).address;

    initialValues.comm_disputes = communicationsData && _.find(communicationsData, {communication_type: 'DISPUTES'}) ? _.find(communicationsData, {communication_type: 'DISPUTES'}).address : [];
    if (!_.isEmpty(initialValues.comm_disputes)) {
      initialValues.comm_disputes = _.uniq(initialValues.comm_disputes.toLowerCase().split(','));
    }
    communicationAddresses.comm_disputes = communicationsData &&
      _.find(communicationsData, {communication_type: 'DISPUTES'}) &&
      typeof _.find(communicationsData, {communication_type: 'DISPUTES'}).address === 'string' &&
      _.find(communicationsData, {communication_type: 'DISPUTES'}).address;

    initialValues.comm_statements = communicationsData && _.find(communicationsData, {communication_type: 'STATEMENTS'}) ? _.find(communicationsData, {communication_type: 'STATEMENTS'}).channel : '';
    communicationAddresses.comm_statements = communicationsData &&
      _.find(communicationsData, {communication_type: 'STATEMENTS'}) &&
      typeof _.find(communicationsData, {communication_type: 'STATEMENTS'}).address === 'string' &&
      _.find(communicationsData, {communication_type: 'STATEMENTS'}).address;
  }

  return {
    initialValues,
    communicationAddresses,
    currentValues,
  };
}

export default connect(mapStateToProps)(CommunicationsSettings);

