/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Box, Typography, Tooltip} from '@mui/material';
import {setSubmitButtonEnabled} from '../../../actions/userExperienceActions';
import {reduxForm} from 'redux-form';
import FileInput from '../../FileInput';
import Validator from '../../util/Validator';
import IconUtils from '../../util/IconUtil';
import LabelUtil from '../../util/LabelUtil';
import attachFileOptions from '../../../constants/attachFileOptions';
import {BulkInvoicesDialogStyles} from '../../../jss/inlineStyles'
import IconButton from '../../shared/IconButton';

export const validate = values => {
  if (values) {
    return Validator.validateBulkInvoicesImport(values);
  } else {
    return {};
  }
};

export class Import extends Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.pristine &&
      !this.props.submitting &&
      !this.props.onDropFileFailed &&
      (prevProps.pristine || prevProps.submitting || prevProps.onDropFileFailed)
    ) {
      this.props.dispatch(setSubmitButtonEnabled(true));
    }
  }

  render() {

    const {handleSubmit, pristine, reset, submitting, t} = this.props;
    const color = LabelUtil.getLabelColor();
    const icon = IconUtils.getIcon('ImgUploadIcon', '#404143');
    const downloadIcon = IconUtils.getIcon('FileDownload','inherited', 20);
    const deleteIcon = IconUtils.getIcon('DeleteIcon', '#404143');
    const uploadIcon = IconUtils.getIcon('UploadFile');
    const fileIcon = IconUtils.getIcon('AddFile');

    const templateDownloadLink = (
      <a
        className='linkAsButton'
        download
        href='/files/bulkInvoicingTemplate.csv'
      >
        <Box
          alignItems='center'
          display='flex'
          gap='10px'
        >
          <Typography
            component='span'
            sx={{fontSize: '12px'}}
          >{t('DownloadTemplate')}</Typography>
          {downloadIcon}
        </Box>
      </a>);

    return (
      <form onSubmit={handleSubmit}>
        <Box
          className='importItemsForm'
          sx={BulkInvoicesDialogStyles}
        >
          <ol>
            <li>
              <strong>{t('DownloadTemplate')}</strong>
              <Box
                sx={{marginTop: '10px', marginLeft:'30px'}}>
                {templateDownloadLink}
              </Box>
            </li>
            <li>
              <strong>{t('CompleteYourFile')}</strong>
              <Typography>
                {t('CompleteYourFileInstructions')}
                <br/>
                <a
                  className='link'
                  target='_blank'
                  href='https://north-american-bancard.elevio.help/en/articles/150'
                >
                  <Typography
                    component={'span'}
                    className='instructions'
                  >
                    {t('ViewInstructions')}
                  </Typography>
                </a>
              </Typography>
            </li>
            <li>
              <strong>{t('UploadFile')}</strong>
            </li>
          </ol>
          {!this.props.importDetails.fileStatus ?
            <FileInput
              className='bulkInput'
              dropzoneOptions={attachFileOptions.importBulkInvoiceDropzoneOptions}
              name='bulk_invoice_template'
              onDropFunction={this.props.onFileDrop}
            >
              <Box alignItems='center'
                   display='flex'
                   flexDirection='column'
                   gap='10px'
                   justifyContent='center'
              >
                {fileIcon}
                <Box
                  alignItems='center'
                  display='flex'
                  flexDirection='column'
                  gap='5px'
                >
                  <Typography
                    component={'span'}
                    className='dropLabel bold'
                  >
                    {t('DropFile')}
                  </Typography>
                  <Typography
                    component={'span'}
                    className='dropLabel'
                  >
                    {t('DropFileFormat')}
                  </Typography>
                </Box>
              </Box>
            </FileInput> :
            <Box className='fileBox'
                 data-testid='bulkImportDialogUploadWrapper'>
              <Box className='fileNameWrapper'
              >
                {uploadIcon}
                <Tooltip title={this.props.importDetails.fileName} arrow>
                  <Typography className='fileName' variant='primaryFontFamilyRegular'>
                    {this.props.importDetails.fileName}
                  </Typography>
                </Tooltip>
              </Box>
              <IconButton
                disableTouchRipple
                onClick={this.props.onFileRemove}
                size='large'
              >
                {deleteIcon}
              </IconButton>
            </Box>
          }
        </Box>
      </form>
    )
  }

}

let ImportBulkingInvoices = reduxForm({
  form: 'importBulkingInvoicesForm',
  validate,
  fields: ['bulk_invoice_template'],
  enableReinitialize: true,
})(Import);

function mapStateToProps(state, ownProps) {

  return {
    initialValues: state.items.itemsLibrary,
    onDropFileFailed: state.items.onDropFileFailed
  }
}

ImportBulkingInvoices = connect(mapStateToProps)(ImportBulkingInvoices);

export default ImportBulkingInvoices;

