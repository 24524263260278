/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import UserUtil from '../components/util/UserUtil';
import Receipt from '../containers/Receipt';
import Invoice from '../containers/Invoice';
import CustomerPayment from '../containers/CustomerPayment';
import PayNow from '../containers/PayNow';
import PaymentLink from '../containers/PaymentLink';
import TermsAndConditions from '../containers/TermsAndConditions';
import NonAuthTerms from '../components/legal/NonAuthTerms';
import SMSServiceTerms from '../components/legal/SMSServiceTerms';
import Rating from '../containers/Rating';
import Mid from '../containers/Mid';
import ActivateFlow from '../containers/ActivateFlow';
import ClientService from '../components/ClientService';
import Login from '../components/Login';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import SetPassword from '../components/SetPassword';
import CreateAccountComponent from '../components/leadSignup/CreateAccountComponent';
import CommunicationSettings from '../components/account/CommunicationSettings';
import Funding from '../components/account/Funding';
import Integrations from '../components/account/Integrations';
import ApiAccess from '../components/account/ApiAccess';
import Profile from '../components/account/Profile';
import Settings from '../components/account/Settings';
import ReceiptSettings from '../components/account/ReceiptSettings';
import AccountBusiness from '../components/account/Business';
import Pci from '../components/account/Pci';
import Deposits from '../components/activity/Deposits';
import Batches from '../components/activity/Batches';
import Reports from '../components/activity/Reports';
import Sales from '../components/activity/Sales';
import Dashboard from '../components/activity/Dashboard';
import Transactions from '../components/activity/Transactions';
import Categories from '../components/inventory/Categories';
import Discounts from '../components/inventory/Discounts';
import Items from '../components/inventory/Items';
import ArchivedItems from '../components/inventory/ArchivedItems';
import Modifiers from '../components/inventory/Modifiers';
import Customers from '../components/business/Customers';
import Employees from '../components/business/Employees';
import Reputation from '../components/business/Reputation';
import VirtualTerminal from '../components/business/VirtualTerminal';
import Invoices from '../components/business/Invoices';
import PaymentLinks from '../components/business/PaymentLinks';
import Disputes from '../components/business/Disputes';
import Method from '../components/mobile/Method';
import ReportsMobile from '../components/mobile/Reports';
import TopSellers from '../components/mobile/TopSellers';
import Volume from '../components/mobile/Volume';
import MethodTestDrive from '../components/testDrive/Method';
import ReportsTestDrive from '../components/testDrive/Reports';
import TopSellersTestDrive from '../components/testDrive/TopSellers';
import VolumeTestDrive from '../components/testDrive/Volume';
import PageNotFoundComponent from '../components/PageNotFound';
import routes from '../constants/routes';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { replace } from 'connected-react-router'
import TinValidation from '../components/account/TinValidation';
import Supplies from '../components/account/Supplies';
import Hardware from '../components/account/supplies/Hardware';
import EmailValidation from '../components/EmailValidation';
import Statements from '../components/activity/Statements';
import ForceResetPassword from '../components/ForceResetPassword';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Campaigns from '../containers/Campaigns';

import CustomersMobile from '../components/mobile/Customers';
import EmployeesMobile from '../components/mobile/Employees';
import CategoriesMobile from '../components/mobile/Categories';
import ItemsMobile from '../components/mobile/Items';
import Devices from '../components/account/Devices';
import Loading from '../components/Loading';

import { closeMainMenu } from '../actions/userExperienceActions';
import MainMenu from '../components/MainMenu/MainMenu';
import withAppData from '../hoc/withAppData';
import withActivityLayout from '../hoc/withActivityLayout';
import withAccountLayout from '../hoc/withAccountLayout';
import withInventoryLayout from '../hoc/withInventoryLayout';
import withBusinessLayout from '../hoc/withBusinessLayout';
import withMobileLayout from '../hoc/withMobileLayout';
import withTestDriveLayout from '../hoc/withTestDriveLayout';
import StoreSettings from '../components/account/StoreSettings';
import withTranslate from '../hoc/withTranslate';
import LoyaltyTerms from '../components/legal/LoyaltyTerms';
import FeatureFlagsUtil from '../components/util/FeatureFlagsUtil';
import Box from '@mui/material/Box';
import { isNorth as isNorthLabel } from '../components/util/WhiteLabelUtil';
import {appRootStyles} from '../jss/appRootStyles';


const appRoutePrefix = globalApplicationLabel.path;
const loginRoute = appRoutePrefix + routes.login;

const PageNotFound = withTranslate(PageNotFoundComponent)

const UserIsAuthenticated = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => Boolean(state.user.data),
  authenticatingSelector: state => state.user.isFetching,
  redirectAction: replace,
  wrapperDisplayName: 'UserIsAuthenticated'
});

const NeedsToResetPassword = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.user && UserUtil.passwordResetNeeded(state.user),
  authenticatingSelector: state => state.user.isFetching,
  redirectAction: replace,
  FailureComponent: withAppData(ForceResetPassword),
  wrapperDisplayName: 'NeedsToResetPassword',
  allowRedirectBack: false
});

const UserIsAdmin = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && (state.auth.isAdmin || state.auth.isOwner || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsAdmin',
  allowRedirectBack: false
});

const isOwnerUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && (state.auth.isOwner || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsOwner',
  allowRedirectBack: false
});

const IsReportUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && (state.auth.isReport || state.auth.isManager || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsReport',
  allowRedirectBack: false
});

const IsReportUserOrManagerWithActivity = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && (state.auth.isReport || (state.auth.isManager && state.auth.isManagerActivity) || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsReport',
  allowRedirectBack: false
});

const IsManagerUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && (state.auth.isManager || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsManager',
  allowRedirectBack: false
});

const IsManagerWithVTUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && (state.auth.isManager && state.auth.isManagerVT) || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr,
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsManagerVT',
  allowRedirectBack: false
});

const IsManagerWithInvoiceUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && ((state.auth.isManager && state.auth.isManagerInvoice) || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsManagerWithInvoice',
  allowRedirectBack: false
});

const IsManagerWithActivityUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && ((state.auth.isManager && state.auth.isManagerActivity) || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsManager',
  allowRedirectBack: false
});

const IsManagerWithReputationUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && ((state.auth.isManager && state.auth.isManagerReputation) || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsManagerReputation',
  allowRedirectBack: false
});

const IsManagerWithPaymentLinksUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && ((state.auth.isManager && state.auth.isManagerPaymentLinks) || state.auth.isOwner || state.auth.isAdmin || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'UserIsManagerPaymentLinks',
  allowRedirectBack: false
});

const HasAcceptedTermsAndConditions = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && ((state.auth.termsAndConditionsEnabled === false) || (state.auth.termsAndConditionsEnabled === true && state.auth.hasAcceptedTermsAndConditions) || state.auth.isSpr),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: withAppData(TermsAndConditions),
  wrapperDisplayName: 'HasAcceptedTermsAndConditions',
  allowRedirectBack: false
});

const IsEPX = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.user && UserUtil.isEPX(state.user),
  authenticatingSelector: state => state.auth.isFetching,
  wrapperDisplayName: 'IsEPX',
  redirectAction: replace,
  FailureComponent: PageNotFound,
});

const IsPayanywhere = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.user && UserUtil.isPayanywhere(state.user),
  authenticatingSelector: state => state.auth.isFetching,
  wrapperDisplayName: 'IsPA',
  redirectAction: replace,
  FailureComponent: PageNotFound,
});

const IsNotMbpUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.user && UserUtil.userType(state.user) !== 'MBP',
  authenticatingSelector: state => state.auth.isFetching,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'IsNotMbpUser',
  redirectAction: replace,
});

const IsNotHbtUser = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.user &&  UserUtil.userType(state.user) !== 'HBT',
  authenticatingSelector: state => state.auth.isFetching,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'IsNotHbtUser',
  redirectAction: replace,
});

const IsVtCapable = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.user && UserUtil.isVtCapable(state.user, state.auth, state.merchantSettings),
  authenticatingSelector: state => state.auth.isFetching,
  wrapperDisplayName: 'IsVtCapable',
  redirectAction: replace,
  FailureComponent: PageNotFound,
});

const IsBatchCapable = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state && UserUtil.isBatchCapable(state.user, state.userExperience.historicHardwareMode),
  authenticatingSelector: state => state.auth.isFetching,
  wrapperDisplayName: 'IsBatchCapable',
  FailureComponent: PageNotFound,
  redirectAction: replace,
});

const IsMidSelected = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.userExperience && state.userExperience.midSelected,
  redirectAction: replace,
  FailureComponent: withAppData(Mid),
  wrapperDisplayName: 'MidSelected',
  predicate: userExperience => userExperience.midSelected,
  allowRedirectBack: false
});

const IsMerchantStoreEnabled = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.auth && FeatureFlagsUtil.isFeatureFlagEnabled('showStore'),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'IsMerchantStoreEnabled',
  allowRedirectBack: false
});

const IsMerchantActive = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => state.user && UserUtil.isActive(state.user),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'IsMerchantActive',
  allowRedirectBack: false
});

const IsPaymentLinksEnabled = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: state => (!state.merchantSettings?.merchantSettings || state.merchantSettings?.merchantSettings?.payment_links_enabled),
  authenticatingSelector: state => state.auth.isFetching,
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'IsPaymentLinksEnabled',
  allowRedirectBack: false
});

const IsNorth = connectedReduxRedirect({
  redirectPath: loginRoute,
  authenticatedSelector: () => globalApplicationLabel.abbreviation === 'nh',
  redirectAction: replace,
  FailureComponent: PageNotFound,
  wrapperDisplayName: 'IsNorth',
  allowRedirectBack: false
});

const IsNorthDemoAccount = connectedReduxRedirect({
    redirectPath: loginRoute,
    // TODO implement correctly once we're able to know it's a North demo account
    authenticatedSelector: () => globalApplicationLabel.abbreviation === 'nh',
    redirectAction: replace,
    FailureComponent: PageNotFound,
    wrapperDisplayName: 'IsNorthDemoAccount',
    allowRedirectBack: false
});

class Routes extends Component {

  constructor(props) {
    super(props);

    this.closeMainMenu = this.closeMainMenu.bind(this);
    this.routeList = (
      <Switch>
        <Route exact path={appRoutePrefix} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(withActivityLayout(Dashboard))))))}/>
        <Route path={loginRoute} component={withAppData(Login)}/>
        <Route path={appRoutePrefix + routes.privacyPolicy} component={withAppData(PrivacyPolicy)}/>
        <Route path={appRoutePrefix + routes.merchantsLogin} component={withAppData(Login)}/>
        <Route path={appRoutePrefix + routes.forgotPassword} component={withAppData(ForgotPassword)}/>
        <Route path={appRoutePrefix + routes.termsInfo + '/:type'} component={withAppData(NonAuthTerms)}/>
        <Route path={appRoutePrefix + routes.smsTerms} component={withAppData(SMSServiceTerms)}/>
        <Route path={appRoutePrefix + routes.loyaltyTerms} component={withAppData(LoyaltyTerms)}/>
        <Route path={appRoutePrefix + routes.resetPassword} component={withAppData(ResetPassword)}/>
        <Route path={appRoutePrefix + routes.updatePassword} component={withAppData(ForceResetPassword)}/>
        <Route path={appRoutePrefix + routes.setPassword} component={withAppData(SetPassword)}/>
        <Route path={appRoutePrefix + routes.leadSignup} component={withAppData(IsNorth(CreateAccountComponent))}/>
        <Route path={appRoutePrefix + routes.clientService.root} component={withAppData(ClientService)}/>
        <Route path={appRoutePrefix + routes.clientService.root + routes.clientService.forgotPassword} component={withAppData(ForgotPassword)}/>
        <Route path={appRoutePrefix + routes.emailValidation} component={withAppData(EmailValidation)}/>
        <Route path={appRoutePrefix + routes.termsAndConditions.root} component={NeedsToResetPassword(UserIsAuthenticated(withAppData(TermsAndConditions)))}/>
        <Route path={appRoutePrefix + routes.midSelector} component={NeedsToResetPassword(UserIsAuthenticated(HasAcceptedTermsAndConditions(withAppData(Mid))))}/>
        <Route exact path={appRoutePrefix + routes.account.root} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(withAccountLayout(Profile))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.business} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(withAccountLayout(AccountBusiness))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.store} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(IsMerchantStoreEnabled(withAccountLayout(StoreSettings)))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.pci} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(withAccountLayout(Pci))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.funding} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(Funding))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.communicationSettings} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(CommunicationSettings))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.integrations} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(Integrations))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.apiAccess} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(ApiAccess))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.salesSettings} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(withAccountLayout(ReceiptSettings))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.tin.root + routes.account.tin.validate} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(TinValidation))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.supplies} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(Supplies))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.hardware} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(Hardware))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.settings} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(IsMerchantActive(HasAcceptedTermsAndConditions(withAccountLayout(Settings)))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.devices} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(UserIsAdmin(HasAcceptedTermsAndConditions(withAccountLayout(Devices))))))}/>
        <Route path={appRoutePrefix + routes.account.root + routes.account.personal} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(withAccountLayout(Profile))))))}/>
        <Route path={appRoutePrefix + routes.activity.root + routes.activity.sales + '/:filter?'} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withActivityLayout(Sales))))))}/>
        <Route path={appRoutePrefix + routes.activity.root + routes.activity.transactions + '/:customerId?'} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withActivityLayout(Transactions))))))}/>
        <Route path={appRoutePrefix + routes.activity.root + routes.activity.deposits} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withActivityLayout(Deposits))))))}/>
        <Route path={appRoutePrefix + routes.activity.root + routes.activity.batches} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(IsBatchCapable(withActivityLayout(Batches)))))))}/>
        <Route path={appRoutePrefix + routes.activity.root + routes.activity.reports} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUserOrManagerWithActivity(HasAcceptedTermsAndConditions(withActivityLayout(Reports))))))}/>
        <Route path={appRoutePrefix + routes.activity.root + routes.activity.statements} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withActivityLayout(Statements))))))}/>
        <Route path={appRoutePrefix + routes.activity.root + routes.activity.dashboard} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(withActivityLayout(Dashboard))))))}/>
        <Route exact path={appRoutePrefix + routes.activity.root} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsReportUser(HasAcceptedTermsAndConditions(withActivityLayout(Dashboard))))))}/>
        <Route exact path={appRoutePrefix + routes.inventory.root} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsNotMbpUser(withInventoryLayout(Items)))))))}/>
        <Route path={appRoutePrefix + routes.inventory.root + routes.inventory.items} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsNotMbpUser(withInventoryLayout(Items)))))))}/>
        <Route path={appRoutePrefix + routes.inventory.root + routes.inventory.categories} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsNotMbpUser(withInventoryLayout(Categories)))))))}/>
        <Route path={appRoutePrefix + routes.inventory.root + routes.inventory.modifiers} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsNotMbpUser(withInventoryLayout(Modifiers)))))))}/>
        <Route path={appRoutePrefix + routes.inventory.root + routes.inventory.discounts} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsNotMbpUser(withInventoryLayout(Discounts)))))))}/>
        <Route path={appRoutePrefix + routes.inventory.root + routes.inventory.archivedItems} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsNotMbpUser(withInventoryLayout(ArchivedItems)))))))}/>
        <Route exact path={appRoutePrefix + routes.business.root} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(withBusinessLayout(Employees))))))}/>
        <Route path={appRoutePrefix + routes.business.root + routes.business.employees} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(withBusinessLayout(Employees))))))}/>
        <Route path={appRoutePrefix + routes.business.root + routes.business.reputation} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithReputationUser(IsMerchantActive(HasAcceptedTermsAndConditions(IsNotHbtUser(withBusinessLayout(Reputation))))))))}/>
        <Route path={appRoutePrefix + routes.business.root + routes.business.customers} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsNotMbpUser(withBusinessLayout(Customers)))))))}/>
        <Route path={appRoutePrefix + routes.business.root + routes.business.onlinePayments} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerUser(HasAcceptedTermsAndConditions(IsVtCapable(IsManagerWithVTUser(withBusinessLayout(VirtualTerminal))))))))}/>
        <Route path={appRoutePrefix + routes.business.root + routes.business.disputes} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsEPX(HasAcceptedTermsAndConditions(withBusinessLayout(Disputes))))))}/>
        <Route path={appRoutePrefix + routes.business.root + routes.business.invoices} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithInvoiceUser(HasAcceptedTermsAndConditions(IsVtCapable(withBusinessLayout(Invoices)))))))}/>
        <Route path={appRoutePrefix + routes.business.root + routes.business.paymentLinks} component={NeedsToResetPassword(IsManagerWithPaymentLinksUser(IsPaymentLinksEnabled(IsPayanywhere(UserIsAuthenticated(IsMidSelected(HasAcceptedTermsAndConditions(withBusinessLayout(PaymentLinks))))))))}/> /

        <Route path={appRoutePrefix + routes.receipts.root + '/:shortHash'} component={withTranslate(Receipt)} />
        <Route path={appRoutePrefix + routes.receipts.legacy + '/:shortHash'} component={withTranslate(Receipt)} />
        <Route path={appRoutePrefix + routes.feedback.root + '/:receiptId/:userId/:accountId/:stars'} component={withTranslate(Rating)} />
        <Route path={appRoutePrefix + routes.invoice.root} component={withTranslate(Invoice)} />
        <Route path={appRoutePrefix + routes.payNow.root} component={withTranslate(PayNow)}/>
        <Route path={appRoutePrefix + routes.paymentLink.root} component={withTranslate(PaymentLink)}/>
        <Route path={appRoutePrefix + routes.customerPayment.root} component={withAppData(CustomerPayment)}/>
        <Route path={appRoutePrefix + routes.campaigns} component={withTranslate(Campaigns)}/>
        <Route path={appRoutePrefix + routes.fourZeroFour} component={PageNotFound}/>
        <Route path={appRoutePrefix + routes.activate} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(HasAcceptedTermsAndConditions(IsNorthDemoAccount(withTranslate(ActivateFlow))))))}/>

        {!isNorthLabel() && ([
          <Route key='mobileRoot' exact path={appRoutePrefix + routes.mobile.root} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(Volume))))))}/>,
          <Route key='mobileVolume' path={appRoutePrefix + routes.mobile.root + routes.mobile.volume} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(Volume))))))}/>,
          <Route key='mobileTopSellers' path={appRoutePrefix + routes.mobile.root + routes.mobile.topSellers} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(TopSellers))))))}/>,
          <Route key='mobileMethod' path={appRoutePrefix + routes.mobile.root + routes.mobile.method} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(Method))))))}/>,
          <Route key='mobileCustomers' path={appRoutePrefix + routes.mobile.root + routes.mobile.customers} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(CustomersMobile))))))}/>,
          <Route key='mobileEmployees' path={appRoutePrefix + routes.mobile.root + routes.mobile.employees} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(EmployeesMobile))))))}/>,
          <Route key='mobileCategories' path={appRoutePrefix + routes.mobile.root + routes.mobile.categories} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(CategoriesMobile))))))}/>,
          <Route key='mobileItems' path={appRoutePrefix + routes.mobile.root + routes.mobile.items} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(ItemsMobile))))))}/>,
          <Route key='mobileReports' path={appRoutePrefix + routes.mobile.root + routes.mobile.reports} component={NeedsToResetPassword(UserIsAuthenticated(IsMidSelected(IsManagerWithActivityUser(HasAcceptedTermsAndConditions(withMobileLayout(ReportsMobile))))))}/>,
          <Route key='testDriveRoot' exact path={appRoutePrefix + routes.testDrive.root} component={withTestDriveLayout(VolumeTestDrive)}/>,
          <Route key='testDriveVolume' path={appRoutePrefix + routes.testDrive.root + routes.testDrive.volume} component={withTestDriveLayout(VolumeTestDrive)}/>,
          <Route key='testDriveTopSellers' path={appRoutePrefix + routes.testDrive.root + routes.testDrive.topSellers} component={withTestDriveLayout(TopSellersTestDrive)}/>,
          <Route key='testDriveMethod' path={appRoutePrefix + routes.testDrive.root + routes.testDrive.method} component={withTestDriveLayout(MethodTestDrive)}/>,
          <Route key='testDriveReports' path={appRoutePrefix + routes.testDrive.root + routes.testDrive.reports} component={withTestDriveLayout(ReportsTestDrive)}/>,
        ])}

        <Route path='*' component={PageNotFound}/>
      </Switch>
    );
  }

  closeMainMenu() {
    if (this.props.mainMenuOpen) {
      this.props.dispatch(closeMainMenu());
    }
  }

  render() {
    const { isFetching, showMenu, headerHeight } = this.props;

    let childComponent;

    if (isFetching) {
      childComponent = <Loading/>;
    } else {
      childComponent = this.routeList;
    }

    const menu = showMenu ? <MainMenu closeMainMenu={this.closeMainMenu}/> : null;

    return (
      <Box className='AppChildComponentLayout' sx={appRootStyles(headerHeight)}>
        {menu}
        <div className='childComponentWrapper' id='childComponentWrapper' onClick={this.closeMainMenu}>
          {childComponent}
        </div>
      </Box>
    );
  }
}

export default Routes;
