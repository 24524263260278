/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import queryString from 'query-string';
import {
  setSelectedChartItems,
  setVisibilityFilter, updateItemsChart
} from '../../actions/userExperienceActions';
import { getActivities } from '../../actions/activitiesActions';
import { getReports } from '../../actions/reportsActions';
import DateFilterAccessory from '../appBar/accessories/DateFilterAccessory';
import DateUtils from '../util/DateUtil';
import ChartDataUtil from '../util/ChartDataUtil';
import SalesDataUtil from '../util/SalesDataUtil';
import SettingsUtil from '../util/SettingsUtil';
import PropTypes from 'prop-types';
import FilterPanel from '../shared/FilterPanel';
import NoData from '../NoData';
import commonUtil from '../util/CommonUtil';
import withStyles from '@mui/styles/withStyles';
import IconUtils from '../util/IconUtil';
import UserUtil from '../util/UserUtil';
import VolumeChart from './charts/VolumeChart';
import MethodChart from './charts/MethodChart';
import CustomersChart from './charts/CustomersChart';
import EmployeeChartTable from './charts/EmployeesChart';
import ItemsChart from './charts/ItemsChart';
import CategoriesChart from './charts/CategoriesChart';
import MenuItem from '../shared/MenuItem';
import {menuItemHeaderStyle} from '../../jss/inlineStyles';
import Page from '../shared/Page';
import ButtonMenuAccessory from '../appBar/accessories/ButtonMenuAccessory';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const defaultDateSelectionPeriod = 'Today';
const defaultTimeSelection = '00:00:00';

export const MenuItemHeader = withStyles(menuItemHeaderStyle)((props) => <MenuItem {...props} />)

class Sales extends React.PureComponent {

  constructor(props) {
    super(props);

    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.handleItemSalesToggle = this.handleItemSalesToggle.bind(this);
    this.handleItemsGraphSelection = this.handleItemsGraphSelection.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.handleComparison = this.handleComparison.bind(this);
    this.handleRequestDelete = this.handleRequestDelete.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);

    const { userExperience } = props;
    const { selectedDate } = userExperience;

    const dateRange = selectedDate.dateRange && selectedDate.value !== 'All Time' ?
      selectedDate.dateRange : DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, defaultTimeSelection);

    this.state = {
      dateRange: dateRange,
      itemSalesToggle: '#',
      compared: false,
      comparedTo: '',
      chipData: [],
      itemsTableCheckBox: false,
      itemsGraphSelection: 'total',
      itemsData: [],
      openComparedToMenu: null
    };

    this.styles = {
      chip: {
        marginLeft: 10,
        float: 'right'
      },
      border: {
        borderWidth: 10
      }
    };

  }

  componentDidMount() {
    this.loadData(this.props);

    const { location, dispatch } = this.props;

    const query = queryString.parse(location.search);

    if (query.filter) {
      switch (query.filter) {
        case 'volume':
          dispatch(setVisibilityFilter({
            property: 'type',
            value: 'Volume'
          }, 'filter'));
          break;
        case 'employees':
          dispatch(setVisibilityFilter({
            property: 'type',
            value: 'Employees'
          }, 'filter'));
          break;
        case 'customers':
          dispatch(setVisibilityFilter({
            property: 'type',
            value: 'Customers'
          }, 'filter'));
          break;
        case 'items':
          dispatch(setVisibilityFilter({
            property: 'type',
            value: 'Items'
          }, 'filter'));
          break;
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.props.selectedMerchantAccount !== nextProps.selectedMerchantAccount) {
      this.loadData(nextProps);
    }

    if (this.props.merchantSettings.customReportStartTime !== nextProps.merchantSettings.customReportStartTime) {
      const dateRange = this.props.userExperience.selectedDate.dateRange ? this.props.userExperience.selectedDate.dateRange :
        DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, nextProps.merchantSettings.customReportStartTime);
      this.setState({dateRange: dateRange});
    }

  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
  }

  loadData(props) {

    const { user, userExperience, dispatch } = props;
    const { historicHardwareMode } = userExperience;

    return SettingsUtil.getMerchantSettings(props).then((res) => {

      const startTime = (res.response && res.response.merchant_settings && res.response.merchant_settings.report_start) ? res.response.merchant_settings.report_start : '00:00:00';
      const dateRange = userExperience.selectedDate.dateRange && userExperience.selectedDate.value !== 'All Time' ? userExperience.selectedDate.dateRange :
        DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, startTime);

      dispatch(getActivities(user, dateRange, null, historicHardwareMode));
      dispatch(getReports(user, dateRange, null, false, null, historicHardwareMode));
      dispatch(setVisibilityFilter({
        property: 'type',
        value: 'Volume'
      }, 'filter'));
      dispatch(setSelectedChartItems([], []));
    });
  }

  handleDateSelection(value, dateRange) {
    const { user, userExperience, dispatch } = this.props;
    const { historicHardwareMode } = userExperience;

    this.setState({dateRange: dateRange});
    dispatch(getActivities(user, dateRange, null, historicHardwareMode));
    dispatch(getReports(user, dateRange, null, false, null, historicHardwareMode));
    dispatch(setSelectedChartItems([], []));
  }


  handleItemSalesToggle(value) {
    this.setState({itemSalesToggle: value});
  };

  handleItemsGraphSelection(value) {
    this.props.dispatch(updateItemsChart(value));
    this.setState({
      itemsGraphSelection: value,
      itemsTableCheckBox: value === 'custom',
    });

  };

  handleFilterSelection(filter) {
    this.props.dispatch(setVisibilityFilter({
      property: 'type',
      value: filter.name
    }, 'filter'));
  }

  handleComparison(data) {
    let dateRange = null;
    switch (data.text) {
      case 'Previous Month':
        dateRange = DateUtils.getPreviousMonth(this.state.dateRange);
        break;
      case 'Previous 3 Months':
        dateRange = DateUtils.getPreviousThreeMonths(this.state.dateRange);
        break;
      case 'Previous 6 Months':
        dateRange = DateUtils.getPreviousSixMonths(this.state.dateRange);
        break;
    }
    this.props.dispatch(getReports(this.props.user, dateRange, 'comparison', false));
    /* istanbul ignore else */
    if (!_.some(this.state.chipData, data)) {
      this.setState({compared: true, chipData: data, comparedTo: data.text, openComparedToMenu: null});
    }
  }

  handleRowSelection(rowIds) {
    const itemsData = SalesDataUtil.formatItemsData(this.props.reports.data);
    let itemsMapped = {};
    _.each(itemsData.itemsTableData, item => {
      itemsMapped[item['index']] = item;
    });
    if (rowIds === 'all' ) {
      this.props.dispatch(setSelectedChartItems(itemsData.itemsTableData.map((value, index) => {
        return itemsMapped[index];
      }), itemsData.itemsTableData.map((value, index) => index)));
    } else if( rowIds === 'none') {
      this.props.dispatch(setSelectedChartItems([], []));
    }else {
      this.props.dispatch(setSelectedChartItems(rowIds.map(index => {
        return itemsMapped[index];
      }), rowIds));
    }

  }

  handleRequestDelete() {
    this.setState({chipData: null, comparedTo: '', compared: false});
    this.setState({openComparedToMenu: null});
  };

  render() {
    const { isFetching, activities, visibilityFilter, reports, userExperience, merchantSettings, t } = this.props;
    const { historicHardwareMode, showHistoricHardwareModeNotice } = userExperience;
    const isEmpty = activities.data === null || reports.data === null || merchantSettings.customReportStartTime === null;
    const showLoading = (isEmpty && isFetching);
    const comparedChip = [
      {key: 0, label: t('Compared to previous month'), text: t('Previous Month')},
      {
        key: 1,
        label: t('Compared to previous 3 months'),
        text: t('Previous 3 Months')
      },
      {
        key: 2,
        label: t('Compared to previous 6 months'),
        text: t('Previous 6 Months')
      },
    ];

    const pageLoading = showLoading;

    if (pageLoading) {
      return (
        <Page
          loading
          title={t('Sales')}
        />
      )
    }

    const filterDataMBP = [{name: t('Volume')}, {name: t('Method')}];
    const filterDataPA = [
      {name: 'Volume'},
      {name: 'Method'},
      {name: 'Customers'},
      {name: 'Employees'},
      {name: 'Items'},
      {name: 'Categories'}
    ];

    const userType = UserUtil.userType(this.props.user);
    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const filterData = (userType === 'MBP') || historicHardwareMode ? filterDataMBP : filterDataPA;

    const chartData = ChartDataUtil.processData(activities.data, userType, achEnabled);
    const salesData = SalesDataUtil.processData(reports.data);
    const volumeData = SalesDataUtil.volumeData(reports.data);
    const employeesData = SalesDataUtil.employeeChart(activities.data);
    const categoriesChartData = SalesDataUtil.categoriesChart(reports.data?.categorySales);
    const volumeComparisonData = SalesDataUtil.getVolumeChange(reports.comparison, reports.data);
    const itemsData = SalesDataUtil.formatItemsData(reports.data);
    const itemsChartValues = SalesDataUtil.itemsChart(itemsData.itemsTableData, this.state.itemsGraphSelection);
    const itemsChartCustomValues = SalesDataUtil.itemsChartCustom(userExperience.selectedItems);
    const methodVoidRefunds = SalesDataUtil.methodVoidRefunds(activities.data);

    //Customers Data
    const customersCurrentActivities = activities.data.currentActivities.data && activities.data.currentActivities.data.customers !== undefined ? activities.data.currentActivities.data.customers : null;
    const customersPreviousActivities = activities.data.previousActivities.data && activities.data.previousActivities.data.customers !== undefined ? activities.data.previousActivities.data.customers : null;
    const uniqueCustomers = customersCurrentActivities ? customersCurrentActivities.unique : 0;
    const uniqueCustomersPrevious = customersPreviousActivities ? customersPreviousActivities.unique : 0;
    const uniqueCustomersDifference = commonUtil.difference(uniqueCustomers, uniqueCustomersPrevious);
    const newCustomers = customersCurrentActivities ? customersCurrentActivities.new : 0;
    const newCustomersPrevious = customersPreviousActivities ? customersPreviousActivities.new : 0;
    const newCustomersDifference = commonUtil.difference(newCustomers, newCustomersPrevious);
    const categoriesArrayLength = _.get(reports, 'data.categorySales.length' , 0);
    const categoriesChartDataToggle = this.state.itemSalesToggle === '$' ? categoriesChartData.categoriesSalesTotalChartCount : categoriesChartData.categoriesChartCount;

    const formatVolumeData = ChartDataUtil.formatVolumeTableData(volumeData, volumeComparisonData);
    const formatMethodData = ChartDataUtil.formatMethodTableData(activities.data, methodVoidRefunds, userType, achEnabled);

    const methodTableData = _.orderBy(formatMethodData, (this.props.visibilityFilter.order && this.props.visibilityFilter.order.value) || 'name', (this.props.visibilityFilter.order && this.props.visibilityFilter.order.dir) || 'asc');
    const volumeTableData = _.orderBy(formatVolumeData, (this.props.visibilityFilter.order && this.props.visibilityFilter.order.value) || 'name', (this.props.visibilityFilter.order && this.props.visibilityFilter.order.dir) || 'asc');
    const customersTableData = _.orderBy(chartData.groupByCustomerCurrent, (this.props.visibilityFilter.order && this.props.visibilityFilter.order.value) || 'name', (this.props.visibilityFilter.order && this.props.visibilityFilter.order.dir) || 'asc');
    const employeeTableData = _.orderBy(employeesData.currentTransactions, (this.props.visibilityFilter.order && this.props.visibilityFilter.order.value) || 'sold_by', (this.props.visibilityFilter.order && this.props.visibilityFilter.order.dir) || 'asc');
    const itemsTableData = _.orderBy(itemsData.itemsTableData, (this.props.visibilityFilter.order && this.props.visibilityFilter.order.value) || 'sold_by', (this.props.visibilityFilter.order && this.props.visibilityFilter.order.dir) || 'asc');
    const categoriesTableData = _.get(reports, 'data.categorySales') && _.orderBy(reports.data.categorySales, (this.props.visibilityFilter.order && this.props.visibilityFilter.order.value) || 'name', (this.props.visibilityFilter.order && this.props.visibilityFilter.order.dir) || 'asc');

    const activitySalesClass = showHistoricHardwareModeNotice ? 'activitySalesWithNotice ' : 'activitySales ';


    const compareAccessory = (
      <ButtonMenuAccessory
        buttonType='text'
        buttonProps={{
          endIcon: <KeyboardArrowDownIcon />,
          children: this.state.comparedTo ? t(`${this.state.comparedTo}`) : t('ComparedTo'),
        }}
        menuItems={[
          {
            text: t('ComparedTo'),
            icon: IconUtils.getIcon('CompareIcon', '#888C8D'),
            disabled: true
          },
          {
            text: t('None'),
            active: !this.state.compared,
            onClick: () => this.handleRequestDelete(),
          },
          {
            text: t('Previous Month'),
            active: (this.state.compared) && this.state.comparedTo == comparedChip[0].text,
            onClick: () => this.handleComparison(comparedChip[0]),
          },
          {
            text: t('Previous 3 Months'),
            active: (this.state.compared && this.state.comparedTo == comparedChip[1].text),
            onClick: () => this.handleComparison(comparedChip[1]),
          },
          {
            text: t('Previous 6 Months'),
            active: (this.state.compared && this.state.comparedTo == comparedChip[2].text),
            onClick: () => this.handleComparison(comparedChip[2]),
          }
        ]}
      />
    );

    const pageAccessories = [
      {
        name: 'compare',
        accessory: compareAccessory,
        showInMobile: true,
        hide: false
      },
      {
        name: 'dateFilter',
        accessory: (
          <DateFilterAccessory
            {...this.props}
            defaultValue={this.state.dateRange.text}
            handleSelection={this.handleDateSelection}
            showAllTime={false}
            showPreviousCustomSelection={false}
            disableCompactVersion={true}
            dataName={'Sales data'}
          />
        ),
        showInMobile: true,
        hide: false
      },
    ];

    return (
      <section className={activitySalesClass}>
        <Page
          accessories={pageAccessories}
          loading={pageLoading}
          title={t('Sales')}
        >
          <div className='pageScrollableArea salesWrapper flexContainerResponsiveLayout'>
            <FilterPanel
              {...this.props}
              defaultIcon=''
              className='filterPanel'
              filterData={filterData}
              selectFilterCallback={this.handleFilterSelection}
            />
            <div className='salesData masterListContainer'>
              <div className='salesChart'>
                {({
                  'Volume': (activities.data.totalSales.length === 0 ? (
                        <NoData text={t('NoSalesData.Volume')}/>) :
                      <VolumeChart
                        salesChartLabels={chartData.salesChartLabels}
                        salesChartCurrentCount={chartData.salesChartCurrentCount}
                        salesChartPreviousCount={chartData.salesChartPreviousCount}
                        volumeComparisonData={volumeComparisonData}
                        volumeData={volumeData}
                        volumeTableData={volumeTableData}
                        compared={this.state.compared}
                        {...this.props}
                      />
                  ),

                  'Items': (
                    categoriesArrayLength ?
                      <ItemsChart
                        compared={this.state.compared}
                        itemsChartLabels={this.state.itemsTableCheckBox ? itemsChartCustomValues.itemsChartLabels : itemsChartValues.itemsChartLabels}
                        itemsChartValues={this.state.itemsTableCheckBox ? itemsChartCustomValues.itemsChartValues : itemsChartValues.itemsChartValues}
                        itemsTableCheckBox={this.state.itemsTableCheckBox}
                        handleSelection={this.handleItemsGraphSelection}
                        handleRowSelection={this.handleRowSelection}
                        salesData={salesData}
                        itemsData={itemsData}
                        itemsTableData={itemsTableData}
                        {...this.props}
                      />
                      :
                      (<NoData text={t('NoSalesData.Items')} />)
                  ),
                  'Method': (activities.data.paymentMethods.length > 0 || activities.data.creditCardNetworkSales.length > 0 ?
                      <MethodChart
                        paymentMethodData={chartData.paymentMethodSalesDataSet}
                        paymentMethodLabels={chartData.paymentMethodLabels}
                        creditSalesDataSet={chartData.creditSalesDataSet}
                        creditSalesLabels={chartData.creditSalesLabels}
                        compared={this.state.compared}
                        activities={activities}
                        methodTableData={methodTableData}
                        {...this.props}
                      /> :
                      (<NoData text={t('NoSalesData.Method')} />)
                  ),

                  'Categories': (
                    categoriesArrayLength ?
                      <CategoriesChart
                        handleItemSalesToggle={this.handleItemSalesToggle}
                        chartData={categoriesChartDataToggle}
                        categoriesChartLabels={categoriesChartData.categoriesChartLabels}
                        yAxesType={this.state.itemSalesToggle}
                        reports={reports}
                        categoriesArrayLength={categoriesArrayLength}
                        salesData={salesData}
                        categoriesTableData={categoriesTableData}
                        t={t}
                        {...this.props}
                      />
                      :
                      (<NoData text={t('NoSalesData.Categories')} />)

                  ),
                  'Customers': (activities.data.currentActivities.data.transactions.length > 0 ?
                      <CustomersChart
                        chartData={chartData}
                        uniqueCustomersDifference={uniqueCustomersDifference}
                        uniqueCustomers={uniqueCustomers}
                        newCustomers={newCustomers}
                        newCustomersDifference={newCustomersDifference}
                        tableData={customersTableData}
                        {...this.props}
                      />
                      : <NoData text={t('NoSalesData.Customers')} />
                  ),

                  'Employees': (activities.data.currentActivities.data.transactions.length > 0 > 0 ?
                    <EmployeeChartTable
                      compared={this.state.compared}
                      employeesData={employeesData}
                      employeesTableData={employeeTableData}
                      {...this.props}/>
                    : <NoData text={t('NoSalesData.Employees')} />),

                  'Modifiers': <NoData text='Chart Coming Soon'/>,

                  'Discounts': <NoData text='Chart Coming Soon'/>
                }[visibilityFilter.filter ? visibilityFilter.filter.value : 'Volume'])
                }
              </div>
            </div>
          </div>
        </Page>
      </section>
    );
  }
}


Sales.defaultProps = {
  isFetching: true,
};

Sales.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selectedMerchantAccount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

export default Sales;
