/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TitlePanel from './../TitlePanel';
import moment from 'moment';
import ReportsNavItem from './../activity/ReportsNavItem';
import FlashReport from '../activity/reports/FlashReport';
import ProductSalesReport from '../activity/reports/ProductSalesReport';
import LanguageSelector from '../shared/LanguageSelector';

const dummyTransactionData = {
  data: {
    categorySales: [
      {
        name: 'Cool Stuff', sold: 3, total: 299.99, total_with_modifier: 299.99,
        transactions: [
          {sold: 2, name: 'something cool', total: 159},
          {sold: 1, name: 'something really cool', total: 140.99}
        ]
      },
      {
        name: 'Household Items', sold: 9, total: 33.00, total_with_modifier: 33.00,
        transactions: [
          {sold: 3, name: 'plastic wrap', total: 9},
          {sold: 5, name: 'wrapping paper', total: 15.50},
          {sold: 1, name: 'plastic plates', total: 3},
          {sold: 1, name: 'wine bottle opener', total: 2},
          {sold: 2, name: 'paper towel', total: 10},
          {sold: 2, name: 'paper weight', total: 5},
          {sold: 1, name: 'knife', total: 13.5},
          {sold: 2, name: 'napkins', total: 6.50},
          {sold: 1, name: 'tooth picks', total: .45},
        ]
      },
      {
        name: 'School Items', sold: 2, total: 10.34, total_with_modifier: 10.34,
        transactions: [
          {sold: 1, name: 'pencil case', total: 2.34},
          {sold: 1, name: 'pencils', total: 8},
        ]
      },
      {
        name: 'Not categorized', sold: 1, total: 620.01, total_with_modifier: 620.01,
        transactions: [
          {sold: 1, name: 'deluxe dog house', total: 620.01}
        ]
      }
    ],
    discounts: [
      {name: 'Cool People Discount', sold: 1, total: -6.66},
      {name: 'Floor Stock', sold: 5, total: -200.66},
      {name: 'Express Discounts', sold: 0, total: 0},
      {name: 'Item Express Discounts', sold: 0, total: 0},
    ],
    refunds: {amount: 0, total: 0},
    voids: {amount: 2, total: -22.00},
    unpaidAmount: {total: -2},
    tips: {amount: 3, total: 2.45},
    tax: 320.87,
    cash: {amount: 3, total: 55.34},
    creditCards: [
      {name: 'Mastercard - Payment', sold: 21, total: 554.21},
      {name: 'Visa - Payment', sold: 10, total: 445.79}
    ]
  }
};

const dummyUser = {
  data: {
    user_id: '0000000001',
    username: 'test+drive@nabancard.com',
    merchantAccounts: [
      {
        mea_code: '0000001',
        mea_id: 123,
        mid: '31300300000000',
      }
    ]
  },
  selectedMerchantAccount: '123',
};

export default class Reports extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.viewToShow = this.viewToShow.bind(this);

    this.state = {
      selectedNav: 'nav',
      exportFilename: null
    };
  }

  handleClick(reportType) {
    if (reportType === 'nav') {
      this.setState({selectedNav: reportType, exportFilename: null});
      return;
    }
    let pdfFilename = reportType === 'Flash Report' ? 'Flash_Report_test_drive.pdf' :
      'Product_Sales_Report_test_drive.pdf';
    this.setState({selectedNav: reportType, exportFilename: serverDomainUrl + 'files/' + pdfFilename});
  }

  viewToShow() {
    switch (this.state.selectedNav) {
      case 'Flash Report':
        return (<FlashReport {...this.props} reportDate={this.props.dateRange} reports={dummyTransactionData} user={dummyUser} />);
      case 'Product Sales Report':
        return (<ProductSalesReport {...this.props} reportDate={this.props.dateRange} reports={dummyTransactionData} user={dummyUser} />);
      default:
        return (
          <div className='reportNavigation'>
            <div className='navSelect' onClick={this.handleClick.bind(null, 'Flash Report')}>
              <ReportsNavItem
                name='flashReport'
                icon='FlashIcon'
                active={this.state.selectedNav === 'Flash Report'}
                header='Flash Report'
                text='Totals for net sales, discounts, tax, tips, voids, refunds, and payment method. Ideal for closing your day.'
              />
            </div>
            <div className='navSelect' onClick={this.handleClick.bind(null, 'Product Sales Report')}>
              <ReportsNavItem
                name='productSalesReport'
                icon='CategoriesIcon'
                active={this.state.selectedNav === 'Product Sales Report'}
                header='Product Sales Report'
                text='Item and category performance by quantity and sales volume.'
              />
            </div>
          </div>
        )
    }
  }

  reportsToolbar() {
    const exportLink = this.state.exportFilename ?
      (<a className='mobileExportButton' href={this.state.exportFilename} target='_blank'>{'EXPORT PDF'}</a>) : null;

    const reportsToolbar = this.state.selectedNav !== 'nav' ? (
      <div className='reportsToolbar'>
        <div className='backButton' onClick={this.handleClick.bind(null, 'nav')}>{'BACK'}</div>
        {exportLink}
      </div>
    ) : null;
    return reportsToolbar
  }

  render() {
    const { selectedNav } = this.state
    const { dateRange } = this.props

    const viewToShow = this.viewToShow();
    const reportsToolbar = this.reportsToolbar();

    const startDate = moment(dateRange.startDate).format('MM/DD/YYYY h:mm:ss a')
    const endDate = moment(dateRange.endDate).format('MM/DD/YYYY h:mm:ss a')

    return (
      <div className='testDriveReports'>
        <TitlePanel title={selectedNav !== 'nav' ? selectedNav : 'Reports'} subText={`${startDate} - ${endDate}`}/>
        {reportsToolbar}
        {viewToShow}
        <LanguageSelector  {...this.props} />
      </div>
    )
  }
}

Reports.propTypes = {
  dateRange: PropTypes.object,
};
