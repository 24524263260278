/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import _ from 'lodash';
import LabelUtil from './LabelUtil';
import FormatTextUtils from './FormatTextUtil';
import numeral from 'numeral';
import commonUtil from './CommonUtil';
import applicationConstants from '../../constants/applicationConstants'
import { isMoment } from 'moment';
import moment from 'moment-timezone';


const ChartDataUtil = {

  /**
   * @function Processes the activitiesData object into variables that
   * different charts utilize.
   * @param {*} activitiesData
   * @param {*} userType
   * @returns object
   */
  processData: function (activitiesData, userType, achEnabled) {

    let labelColor = LabelUtil.getLabelColor();

    let chartData = {};

    chartData.creditSalesDataSet = [
      activitiesData.visaSales.toFixed(2),
      activitiesData.masterCardSales.toFixed(2),
      activitiesData.amexSales.toFixed(2),
      activitiesData.discoverSales.toFixed(2)
    ];

    chartData.creditPercentageDataSet = [
      activitiesData.visaPercentage,
      activitiesData.masterCardPercentage,
      activitiesData.amexPercentage,
      activitiesData.discoverPercentage
    ];

    chartData.paymentMethodSalesDataSet = [
      activitiesData.swipedSales.toFixed(2),
      activitiesData.chipSales.toFixed(2),
      activitiesData.contactlessSales.toFixed(2),
      activitiesData.keyedSales.toFixed(2),
      activitiesData.cashSales.toFixed(2)
    ];

    chartData.paymentMethodPercentageDataSet = [
      activitiesData.swipedPercentage,
      activitiesData.chipPercentage,
      activitiesData.contactlessPercentage,
      activitiesData.keyedPercentage,
      activitiesData.cashPercentage
    ];

    chartData.paymentMethodLabels = [
      'Swiped',
      'Chip (EMV)',
      'Contactless',
      'Keyed',
      'Cash'
    ];

    chartData.paymentMethodBackgroundColor = [
      '#F0F0F0',
      '#C0C0C0',
      '#606060',
      '#909090',
      '#000000'
    ];

    chartData.paymentMethodHoverColor = [
      labelColor,
      labelColor,
      labelColor,
      labelColor,
      labelColor
    ];

    if (userType === 'PA') {
      chartData.paymentMethodSalesDataSet.push(activitiesData.giftCardSales.toFixed(2));
      chartData.paymentMethodPercentageDataSet.push(activitiesData.giftCardPercentage);
      chartData.paymentMethodLabels.push('Gift Card');
      chartData.paymentMethodBackgroundColor.push('#CCCCCC');
      chartData.paymentMethodHoverColor.push(labelColor);
    }

    chartData.creditSalesLabels = [
      'Visa',
      'Mastercard',
      'American Express',
      'Discover'
    ];

    chartData.creditSalesBackgroundColor = [
      '#F0F0F0',
      '#C0C0C0',
      '#606060',
      '#909090'
    ];

    chartData.creditSalesHoverColor = [
      labelColor,
      labelColor,
      labelColor,
      labelColor
    ];
    
    if (achEnabled) {
      chartData.creditSalesDataSet.push(activitiesData.achSavingsSales.toFixed(2));
      chartData.creditSalesDataSet.push(activitiesData.achCheckingSales.toFixed(2));
      chartData.creditPercentageDataSet.push(activitiesData.achSavingsPercentage);
      chartData.creditPercentageDataSet.push(activitiesData.achCheckingPercentage);
      chartData.paymentMethodSalesDataSet.push(activitiesData.achSales.toFixed(2));
      chartData.paymentMethodPercentageDataSet.push(activitiesData.achPercentage);
      chartData.paymentMethodLabels.push('Bank Account');
      chartData.creditSalesLabels.push('Savings Account');
      chartData.creditSalesLabels.push('Checking Account');
      chartData.paymentMethodBackgroundColor.push('#CCCCCC');
      chartData.paymentMethodHoverColor.push(labelColor);
    }
    
    chartData.salesChartLabels = this.generateLabelsForTimePeriod(activitiesData.requestedDateRange);
    chartData.salesChartPreviousCount = this.generateSalesCount(chartData.salesChartLabels, activitiesData.totalSales, activitiesData.requestedDateRange, true);
    chartData.salesChartCurrentCount = this.generateSalesCount(chartData.salesChartLabels, activitiesData.totalSales, activitiesData.requestedDateRange, false);

    chartData.itemChartLabels = _.map(activitiesData.items, 'name');
    chartData.itemChartPreviousCount = _.map(activitiesData.items, function (value) {
      return value.previousTotal ? numeral(value.previousTotal).format('0.0') : 0;
    });
    chartData.itemChartCurrentCount = _.map(activitiesData.items, function (value) {
      return value.total ? numeral(value.total).format('0.0') : 0;
    });

    chartData.itemSalesTotalChartLabels = _.map(activitiesData.itemsSales, 'name');
    chartData.itemSalesTotalChartPreviousCount = _.map(activitiesData.itemsSales, function (value) {
      return value.previousTotal ? numeral(value.previousTotal).format('0.0') : 0;
    });
    chartData.itemSalesTotalChartCurrentCount = _.map(activitiesData.itemsSales, function (value) {
      return value.total ? numeral(value.total).format('0.0') : 0;
    });

    chartData.categoriesChartLabels = _.map(activitiesData.categories, 'name');
    chartData.categoriesChartPreviousCount = _.map(activitiesData.categories, function (value) {
      return value.previousTotal ? numeral(value.previousTotal).format('0.0') : 0;
    });
    chartData.categoriesChartCurrentCount = _.map(activitiesData.categories, function (value) {
      return value.total ? numeral(value.total).format('0.0') : 0;
    });

    chartData.categoriesSalesTotalChartLabels = _.map(activitiesData.categorySales, 'name');
    chartData.categoriesSalesTotalChartPreviousCount = _.map(activitiesData.categorySales, function (value) {
      return value.previousTotal ? numeral(value.previousTotal).format('0.0') : 0;
    });

    chartData.categoriesSalesTotalChartCurrentCount = _.map(activitiesData.categorySales, function (value) {
      return value.total ? numeral(value.total).format('0.0') : 0;
    });

    _(activitiesData.currentActivities.data.transactions).forEach(
      function (transaction) {
        const emptyFirstName = (transaction.customer_first_name === '' || transaction.customer_first_name === null);
        const emptyLastName = (transaction.customer_last_name === '' || transaction.customer_last_name === null);
        if (transaction.customer_id && (emptyFirstName && emptyLastName)) {
          transaction.customer_id = null;
          transaction.pa_customer_id = null;
        }
      }
    );

    const filteredPaTransactions = activitiesData?.currentActivities?.data?.transactions?.length ?  activitiesData.currentActivities.data.transactions.filter((transaction) => ((transaction?.pa_customer_id && transaction?.customer_id) || (transaction?.pa_customer_id && !transaction?.customer_id))) : [{}];
    const filteredRegularTransactions = activitiesData?.currentActivities?.data?.transactions?.length ? activitiesData.currentActivities.data.transactions.filter((transaction) => (transaction?.customer_id && !transaction?.pa_customer_id)) : [{}];
    const filteredNoIdTransactions = activitiesData?.currentActivities?.data?.transactions?.length ? activitiesData.currentActivities.data.transactions.filter((transaction) => (!transaction?.customer_id && !transaction?.pa_customer_id)): [{}];

    const paCustomersCurrent = this.getCustomerDataByIdTypeCurrent(filteredPaTransactions, 'pa_customer_id');
    const regularCustomersCurrent = this.getCustomerDataByIdTypeCurrent(filteredRegularTransactions, 'customer_id');
    const regularNoCustomersCurrent = this.getCustomerDataByIdTypeCurrent(filteredNoIdTransactions, 'customer_id');
    chartData.groupByCustomerCurrent = [...paCustomersCurrent, ...regularCustomersCurrent, ...regularNoCustomersCurrent];

    const filteredPaTransactionsPrev = activitiesData?.previousActivities?.data?.transactions?.length ?  activitiesData.previousActivities.data.transactions.filter((transaction) => ((transaction?.pa_customer_id && transaction?.customer_id) || (transaction?.pa_customer_id && !transaction?.customer_id))) : [{}];
    const filteredRegularTransactionsPrev = activitiesData?.previousActivities?.data?.transactions?.length ? activitiesData.previousActivities.data.transactions.filter((transaction) => (transaction?.customer_id && !transaction?.pa_customer_id)) : [{}];
    const filteredNoIdTransactionsPrev = activitiesData?.previousActivities?.data?.transactions?.length ? activitiesData.previousActivities.data.transactions.filter((transaction) => (!transaction?.customer_id && !transaction?.pa_customer_id)): [{}];

    const paCustomersPrevious = this.getCustomerDataByIdTypePrevious(filteredPaTransactionsPrev, 'pa_customer_id');
    const regularCustomersPrevious = this.getCustomerDataByIdTypePrevious(filteredRegularTransactionsPrev, 'customer_id');
    const regularNoCustomersPrevious = this.getCustomerDataByIdTypeCurrent(filteredNoIdTransactionsPrev, 'customer_id');
    chartData.groupByCustomerPrevious = [...paCustomersPrevious, ...regularCustomersPrevious, ...regularNoCustomersPrevious];

    chartData.customersChartLabels = _.map(chartData.groupByCustomerCurrent, function (value) {
      return value.name;
    });

    _.map(chartData.groupByCustomerCurrent, function (currentCustomer) {
      const previousCustomer = _.find(chartData.groupByCustomerPrevious, {customerId: currentCustomer.customerId});
      currentCustomer.difference = commonUtil.difference(currentCustomer.totalSales,
        previousCustomer ? previousCustomer.totalSales : 0);
    });

    chartData.customersChartDataCurrent = _.map(chartData.groupByCustomerCurrent, 'totalSales');
    chartData.customersChartDataPrevious = _.map(chartData.groupByCustomerPrevious, 'totalSales');

    return chartData;
  },

  processValuesAxisYLabels: (value) => {
    if (window.screen.width <= applicationConstants.sizeTabletBreakpoint) {
      return `${value / 1000}k`
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  },

  processValuesAxisXLabels: (value) => {
    if (window.screen.width <= applicationConstants.sizeTabletBreakpoint) {
      value = value.substring(0, 5) + '...';
    }
    return value;
  },

  /**
   * @function Gets the difference in days, months and years of the 'end' and 'start' dates
   * on the period parameter and returns an array of formatted date strings based
   * on the period's interval (days, months, years).
   * @param {object} period the period object.
   * @returns string[]
   */
  generateLabelsForTimePeriod: (period) => {
    const endDate = isMoment(period.endDate) ? period.endDate : moment(period.endDate);
    const startDate = isMoment(period.startDate) ? period.startDate : moment(period.startDate);
    let finalLabels = [];
    let labelUnit = 'hours';
    const differenceInDays = endDate.diff(startDate, 'days');
    const differenceInWeeks = endDate.diff(startDate, 'weeks');
    const differenceInMonths = endDate.diff(startDate, 'months');
    const differenceInYears = endDate.diff(startDate, 'years');

    labelUnit = differenceInDays >= 1 ? 'days' : labelUnit;
    labelUnit = differenceInWeeks >= 1 ? 'weeks' : labelUnit;
    labelUnit = differenceInMonths >= 1 ? 'months' : labelUnit;
    // Group by year does not exists but if added change 'months' to 'years' in next labelUnit
    labelUnit = differenceInYears >= 1 ? 'months' : labelUnit;
    for (let date = startDate; !date.isAfter(endDate); date.add(1, labelUnit)) {
      let label = labelUnit === 'hours' ? date.format('hA') : 'timeLabel';
      label = labelUnit === 'days' ? date.format('dddd') : label;
      label = labelUnit === 'weeks' ? (date.format('wo') + ' Week') : label;
      label = labelUnit === 'months' ? date.format('MMM') : label;
      label = differenceInYears >= 1 ? date.format('MMM/YYYY') : label;
      finalLabels.push(label);
    }

    return finalLabels;
  },

  /**
   * @function Maps through the labels and sales and generates
   * the data object for the count (previous or current) to be used
   * by the chart.
   * @param {array} labels the labels array.
   * @param {object} sales the sales object.
   * @param {object} dateRange the date range of the query.
   * @param {boolean} isPrevious flag to know which total to use (default false).
   * @returns string|number[]
   */
  generateSalesCount: function (labels, sales, dateRange, isPrevious) {
    // Evaluates remaining data sets.
    let remainingTicks = isPrevious ? sales.filter((sale) => sale.previousTotal ? true : false).map((sale) => sale.previousTotal && sale) :
      sales.filter((sale) => sale.total ? true : false).map((sale) => sale.total && sale);
    // Evaluates the current label by time.
    const curentTimeLabelIndex = this.generateCurrentLabelIndex(labels);
    // Evaluates if the period being requested is in the past
    const endDate = isMoment(dateRange.endDate) ? dateRange.endDate : moment(dateRange.endDate);
    const hasCurrentPeriodPassed = endDate.isBefore(moment().startOf('day')) ? true : false;
    // Generates count
    let count = labels.map((label, index) => {
      let value = 0;
      // Evaluates if the label has a corresponding data set.
      for (let index = 0; index < sales.length; index++) {
        const activity = sales[index];
        const activityTotal = isPrevious ? activity.previousTotal : activity.total;
        value = activity.name === label && activityTotal ? numeral(activityTotal).format('0.00') : 0;
        // If the label has a corresponding data set returns its value.
        if (value > 0) {
          // Removes the data set from remaining ticks.
          remainingTicks = remainingTicks.filter((item) => {
            return item.name === activity.name ? false : true;
          });

          return value;
        }
      }
      // If no data set matched, returns the default value, 0 if label is in the past else null.
      value = !isPrevious &&
      !hasCurrentPeriodPassed &&
      index > curentTimeLabelIndex &&
      remainingTicks.length === 0 ?
        null : 0;
      return value;
    });

    return count;
  },
  /**
   * @function Loops through the labels and compares it to the current date on different label formats
   * if it founds it returns its index so the count knows the current (last) tick.
   * @param {string[]} labels
   * @returns {number} number
   */
  generateCurrentLabelIndex: (labels) => {
    const currentDate = moment();
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      if (
        label === currentDate.format('hA') ||
        label === currentDate.format('dddd') ||
        label === (currentDate.format('wo') + ' Week') ||
        label === (currentDate.format('Do/MMM')) ||
        label === currentDate.format('MMM') ||
        label === currentDate.format('MMM/YYYY')
      ) {
        return i;
      }
    }
  },

  formatVolumeTableData: (volumeData, volumeComparisonData) => {
    return [
      {
        name: 'Gross Sales',
        change: volumeComparisonData.grossSales,
        quantity: volumeData.grossSalesTotalQuantityOfTransactions,
        totalSales: volumeData.grossSalesTotal
      },
      {
        name: 'Discounts',
        change: volumeComparisonData.discounts,
        quantity: volumeData.discountsTotalQuantity,
        totalSales: volumeData.discountsTotal
      },
      {
        name: 'Refunds',
        change: volumeComparisonData.refunds,
        quantity: volumeData.refundsTotalQuantity,
        totalSales: volumeData.refundsTotal
      },
      {
        name: 'Void',
        change: volumeComparisonData.void,
        quantity: volumeData.voidsTotalQuantity,
        totalSales: volumeData.voidsTotal
      },
      {
        name: 'Unpaid',
        change: volumeComparisonData.unpaid,
        quantity: 'N/A',
        totalSales: volumeData.unpaidAmount
      },
      {
        name: 'Net Sales',
        change: volumeComparisonData.netSales,
        quantity: 'N/A',
        totalSales: volumeData.totalNetSales
      },
      {
        name: 'Tips',
        change: volumeComparisonData.tips,
        quantity: volumeData.tipsTotalQuantity,
        totalSales: volumeData.tipsTotal
      },
      {
        name: 'Tax',
        change: volumeComparisonData.tax,
        quantity: 'N/A',
        totalSales: volumeData.tax
      },
    ];
  },
  formatMethodTableData: (activities, methodVoidRefunds, userType, achEnabled) => {
    const tableData = [
      {
        name: 'Chip-Read Credit',
        transactions: activities.chipSalesCount,
        grossSales: activities.chipSales,
        refunds: methodVoidRefunds.chipRefunds,
        voids: methodVoidRefunds.chipVoids,
        netSales: methodVoidRefunds.netChip
      },
      {
        name: 'Contactless Credit',
        transactions: activities.contactlessSalesCount,
        grossSales: activities.contactlessSales,
        refunds: methodVoidRefunds.contactlessRefunds,
        voids: methodVoidRefunds.contactlessVoids,
        netSales: methodVoidRefunds.netContactless
      },
      {
        name: 'Swiped Credit',
        transactions: activities.swipedSalesCount,
        grossSales: activities.swipedSales,
        refunds: methodVoidRefunds.swipedRefunds,
        voids: methodVoidRefunds.swipedVoids,
        netSales: methodVoidRefunds.netSwiped
      },
      {
        name: 'Keyed Credit',
        transactions: activities.keyedSalesCount,
        grossSales: activities.keyedSales,
        refunds: methodVoidRefunds.keyedRefunds,
        voids: methodVoidRefunds.keyedVoids,
        netSales: methodVoidRefunds.netKeyed
      },
      {
        name: 'Cash',
        transactions: activities.cashSalesCount,
        grossSales: activities.cashSales,
        refunds: methodVoidRefunds.cashRefunds,
        voids: methodVoidRefunds.cashVoids,
        netSales: methodVoidRefunds.netCash
      }
    ];

    if (userType === 'PA') {
      tableData.push({
        name: 'Gift Card',
        transactions: activities.giftCardSalesCount,
        grossSales: activities.giftCardSales,
        refunds: methodVoidRefunds.giftCardRefunds,
        voids: methodVoidRefunds.giftCardVoids,
        netSales: methodVoidRefunds.netGiftCard
      });
    }
    
    if (achEnabled) {
      tableData.push({
        name: 'Bank Account',
        transactions: activities.achCheckingSalesCount + activities.achSavingsSalesCount,
        grossSales: activities.achCheckingSales + activities.achSavingsSales,
        refunds: methodVoidRefunds.achRefunds,
        voids: methodVoidRefunds.achVoids,
        netSales: methodVoidRefunds.netAch
      });
    }

    return tableData;
  },

  getCustomerDataByIdTypeCurrent: (customerTransactions, idType) => {
    return _(customerTransactions)
      .groupBy(idType)
      .map((transactions, key) => {
        let lastVisit = _.maxBy(transactions, 'datetime');
        lastVisit = lastVisit?.datetime ? moment(lastVisit.datetime).format('MM/DD/YYYY h:mm:ss a') : 'N/A';
        let isNew = !!_.find(transactions, {is_new_customer: true});
        isNew = isNew ? 'New' : 'Repeat';
        return ({
          customerId: Number(key),
          totalSales: _.sumBy(transactions, function (transaction) {
            if (transaction.type === 'Credit Sale' || transaction.type === 'Cash Sale') {
              let amount = transaction.ccs_authorized_amt ? transaction.ccs_authorized_amt : transaction.amount;
              return parseFloat(amount);
            }
          }),
          countSales: _.countBy(transactions, idType)[Number(key)],
          name: FormatTextUtils.formatName(transactions[0].customer_first_name, transactions[0].customer_last_name, 'Unnamed Customers'),
          lastVisit,
          isNew
        });
      }).value();
  },

  getCustomerDataByIdTypePrevious: (customerTransactions, idType) => {
    return _(customerTransactions)
      .groupBy(idType)
      .map((transactions, key) => ({
        customerId: Number(key),
        isNew: !!_.find(transactions, {is_new_customer: true}),
        totalSales: _.sumBy(transactions, function (transaction) {
          if (transaction.type === 'Credit Sale' || transaction.type === 'Cash Sale') {
            let amount = transaction.ccs_authorized_amt ? transaction.ccs_authorized_amt : transaction.amount;
            return parseFloat(amount);
          }
        }),
        countSales: _.countBy(transactions, idType),
        name: FormatTextUtils.formatName(transactions[0].customer_first_name, transactions[0].customer_last_name, 'Unnamed Customers')
      })).value();
  }

};

export default ChartDataUtil;
