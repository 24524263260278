/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import query from 'query-string';
import { submit } from 'redux-form';
import { Trans } from 'react-i18next';
import {
  setVisibilityFilter,
  setModalVisibility,
  toggleOpenBatchTransactions,
  setSelectedDateRange
} from '../../actions/userExperienceActions';
import { setCustomer } from '../../actions/customerActions';
import { getEmployees } from '../../actions/employeesActions';
import {
  getTransactionReceipts,
  setTransactionReceipt,
  processCreditCardVoid,
  processMerchantCreditCardVoid,
  processCashRefund,
  processCreditRefund,
  processMerchantCreditRefund,
  completeCreditCardPayment,
  cancelPreAuthPayment,
  updateTipTransaction
} from '../../actions/transactionsActions';
import { deleteRecurringPayment } from '../../actions/invoicesActions';
import { putBatches } from '../../actions/batchesActions';
import messages from '../../constants/messages';
import ReceiptUtil from '../util/ReceiptUtil';
import TransactionDetail from './TransactionDetail';
import MasterDetailLayout from '../MasterDetailLayout';
import FilterPanel from '../shared/FilterPanel';
import DateUtils from '../util/DateUtil';
import LabelUtil from '../util/LabelUtil';
import CsvUtil from '../util/CsvUtil';
import CsvExporter from '../util/CsvExporter';
import IconUtil from '../util/IconUtil';
import TaxUtil from '../util/TaxUtil';
import QueryUtil from '../util/QueryUtil';
import TransactionsUtil from '../util/TransactionsUtil';
import moment from 'moment';
import 'moment-timezone';
import SendReceiptForm from './SendReceiptForm';
import PaymentDialog from '../PaymentDialog';
import UserUtil from '../util/UserUtil';
import _ from 'lodash';
import UpdateSpinner from '../UpdateSpinner';
import ReverseButton from '../shared/ReverseButton';
import Button from '../shared/Button';
import DateFilterAccessory from '../appBar/accessories/DateFilterAccessory';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import TextUtil from '../util/FormatTextUtil';
import numeral from 'numeral';
import '../shared/detailPanel/DetailPanelOptions';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import MessageDialog from '../shared/MessageDialog';
import routes from './../../constants/routes';
import { Link } from 'react-router-dom';
import TransactionsSummaryHeader from './TransactionsSummaryHeader';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import { toastr } from 'react-redux-toastr';
import Pagination from '../shared/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import generalOptions from '../../constants/generalOptions';
import MobileUtil from '../util/MobileUtil';
import Modal from '../shared/Modal';
import Page from '../shared/Page';
import EmployeeFilterAccessory from '../appBar/accessories/EmployeeFilterAccessory';
import actionTypes from '../../constants/actionTypes';
import OpenTransactionsSummaryHeader from './OpenTransactionsSummaryHeader';
import SettingsUtil from '../util/SettingsUtil';
import applicationConstants from '../../constants/applicationConstants';
import AdvancedSearch from './AdvancedSearch';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {
  buttonAccessory,
  employeeAccessory
} from '../../jss/appBarStyles';
import IconButtonAccessory from '../appBar/accessories/IconButtonAccesory';
import ExportImportAccessory from '../appBar/accessories/ExportImportAccessory';
import ButtonAccessory from '../appBar/accessories/ButtonAccesory';

import {
  closeIcon
} from '../../jss/advancedSearchStyles';
import {commonSelectStyles} from '../../jss/commonSelectStyles';

const defaultDateSelectionPeriod = 'Today';
const defaultTimeSelection = '00:00:00';
let today = moment().tz('America/New_York').format();
const transactionRequestBatch = 'batch';
const transactionRequestVoid = 'void';
const transactionVerboseBRIC = 'verbose';

export default class Transactions extends React.PureComponent {

  constructor(props) {
    super(props);

    this.closeTransactionInfo = this.closeTransactionInfo.bind(this);
    this.closeTooManyTransactionsInfo = this.closeTooManyTransactionsInfo.bind(this);
    this.handleCompleteReceipt = this.handleCompleteReceipt.bind(this);
    this.handleCancelPreAuth = this.handleCancelPreAuth.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.handlePrintReceipt = this.handlePrintReceipt.bind(this);
    this.handleRefundReceipt = this.handleRefundReceipt.bind(this);
    this.handleRemoveAutopay = this.handleRemoveAutopay.bind(this);
    this.handleSendReceipt = this.handleSendReceipt.bind(this);
    this.handleSendReceiptClose = this.handleSendReceiptClose.bind(this);
    this.handleVoidClose = this.handleVoidClose.bind(this);
    this.handleVoidReceipt = this.handleVoidReceipt.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.processComplete = this.processComplete.bind(this);
    this.processCancelPreAuth = this.processCancelPreAuth.bind(this);
    this.processRefund = this.processRefund.bind(this);
    this.processVoid = this.processVoid.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.setReceipt = this.setReceipt.bind(this);
    this.showCompleteReceipt = this.showCompleteReceipt.bind(this);
    this.showCancelPreAuth = this.showCancelPreAuth.bind(this);
    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.showIncompleteTransactions = this.showIncompleteTransactions.bind(this);
    this.showOpenBatchTransaction = this.showOpenBatchTransaction.bind(this);
    this.showRefundReceipt = this.showRefundReceipt.bind(this);
    this.showSendReceipt = this.showSendReceipt.bind(this);
    this.showVoidReceipt = this.showVoidReceipt.bind(this);
    this.onProcessFormClick = this.onProcessFormClick.bind(this);
    this.handlePageSelection = this.handlePageSelection.bind(this);
    this.setOpenTransactionTipChanges = this.setOpenTransactionTipChanges.bind(this);
    this.onCloseTransactionsClick = this.onCloseTransactionsClick.bind(this);
    this.onSaveTipsClick = this.onSaveTipsClick.bind(this);
    this.closeSuccessClosedOpenTransactionDialog = this.closeSuccessClosedOpenTransactionDialog.bind(this);
    this.closeErrorClosedOpenTransactionDialog = this.closeErrorClosedOpenTransactionDialog.bind(this);
    this.closeErrorSaveTipDialog = this.closeErrorSaveTipDialog.bind(this);
    this.displayCloseAllTransactionsDialog = this.displayCloseAllTransactionsDialog.bind(this);
    this.closeCloseAllTransactionsDialog = this.closeCloseAllTransactionsDialog.bind(this);

    const { userExperience, location } = props;
    const { selectedDate } = userExperience;

    let dateRange = selectedDate.dateRange ? selectedDate.dateRange :
      DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, defaultTimeSelection);

    const queryString = query.parse(location?.search ?? '');

    if (queryString?.customerId || queryString?.receiptId || queryString?.paCustomerId || queryString?.bric) {
      if (queryString?.receiptId || queryString?.bric) {
        dateRange = selectedDate.dateRange ? selectedDate.dateRange : DateUtils.getSearchPreviousRange();
      } else {
        dateRange = DateUtils.getSearchPreviousRange();
      }
    }

    this.state = {
      selectedIndex: 1,
      dateRange,
      showSendReceipt: false,
      refundAttempt: false,
      openTransactionInfo: false,
      initialLoad: false,
      refundTotals: null,
      pageNumber: 1,
      tooManyTransactionsToExportDialog: false,
      openTransactionTipChanges: [],
      showSpinner: false,
      showPendingChangesDialog: false,
      showSuccessClosedOpenTransaction: false,
      showErrorClosedOpenTransaction: false,
      showErrorSaveTipDialog: false,
      openCloseAllTransactionsDialog: false,
      showAdvancedSearch: false
    };

  }

  componentDidMount() {
    this.loadData(this.props).then(this.setFilters);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { visibilityFilter, user, userExperience } = this.props;
    const loadDeclinedTransactions = visibilityFilter?.filter?.value?.includes('Declined Transactions');
    const loadOpenTransactions = visibilityFilter?.filter?.value?.includes('Open Transactions');
    const loadGiftCardActivations = visibilityFilter?.filter?.value?.includes('Gift Card Activations');
    const wasVoidHistoricHardwareFilter = prevProps?.visibilityFilter?.filter?.value?.includes('Voids') && !!prevProps?.userExperience?.historicHardwareMode;

    const filterChanged = visibilityFilter?.filter?.value !== prevProps.visibilityFilter?.filter?.value;

    const currentDateRange = this.state.dateRange;

    const wasGiftCardActivations = !loadGiftCardActivations && prevProps?.visibilityFilter?.filter?.value?.includes('Gift Card Activations');

    if (filterChanged && (loadDeclinedTransactions || loadOpenTransactions || loadGiftCardActivations || wasGiftCardActivations)) {
      this.loadData(this.props);
    } else if (filterChanged && wasVoidHistoricHardwareFilter) {
      this.loadTransactionData(user, currentDateRange, false, null, userExperience.historicHardwareMode, undefined, undefined, undefined);
    }

    if (JSON.stringify(prevState?.dateRange) !== JSON.stringify(this.state?.dateRange)) {
     this.props.dispatch(setSelectedDateRange(currentDateRange?.text, currentDateRange));
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const { userExperience, merchantSettings, location } = this.props;
    const queryString = query.parse(location?.search ?? '');

    if (merchantSettings.customReportStartTime !== nextProps.merchantSettings.customReportStartTime) {
      let dateRange = userExperience.selectedDate.dateRange || DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, nextProps.merchantSettings.customReportStartTime);
      if (queryString?.customerId || queryString?.receiptId || queryString?.paCustomerId || queryString?.bric) {
         if (queryString.receiptId || queryString.bric) {
           dateRange = userExperience.selectedDate.dateRange ? userExperience.selectedDate.dateRange : DateUtils.getSearchPreviousRange();
         } else {
           dateRange = DateUtils.getSearchPreviousRange();
         }
       }
      this.setState({ dateRange: dateRange });
    }

  }

  displayCloseAllTransactionsDialog() {
    this.setState({openCloseAllTransactionsDialog: true});
  }

  closeCloseAllTransactionsDialog() {
    this.setState({openCloseAllTransactionsDialog: false});
  }

  setFilters() {

    const { location, dispatch } = this.props;

    const queryString = query.parse(location?.search ?? '');
    const validQuery = QueryUtil.isValidQuery(queryString);
    this.setState({ initialLoad: true });

    if (_.isEmpty(queryString) || !validQuery) {
      return dispatch(setVisibilityFilter({ property: 'type', value: 'All Transactions' }, 'filter'));
    } else if (queryString?.receiptId) {
      dispatch(setVisibilityFilter(queryString.receiptId));
    } else if (queryString?.transactionId) {
      dispatch(setVisibilityFilter(queryString.transactionId));
    } else if (queryString?.bric) {
      dispatch(setVisibilityFilter(queryString.bric));
    }

    this.showDetailForRow(0);
    this.setState({ forceDetailOpen: true });
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter('', 'filter'));
    this.props.dispatch(setCustomer({}));
  }

  loadData(props) {

    const that = this;
    const { user, userExperience, dispatch, taxes, location, transactions, visibilityFilter, merchantSettings } = props;
    const { historicHardwareMode } = userExperience;

    const isVoidTransactionFilter = visibilityFilter?.filter?.value?.includes('Voids');
    const loadDeclinedTransactions = visibilityFilter?.filter?.value?.includes('Declined Transactions');

    const isAutoClose = !Boolean(merchantSettings?.merchantSettings?.manual_capture);
    const isPaperSignature = Boolean(merchantSettings?.merchantSettings?.paper_signature_enabled);
    const isAutoSignature = isAutoClose && isPaperSignature;

    const isOpenTransactionFilter = visibilityFilter?.filter?.value?.includes('Open Transactions');
    const loadOpenTransactions = isOpenTransactionFilter && !isAutoSignature;
    const loadOpenTransactionsAutoSigned = isOpenTransactionFilter && isAutoSignature;

    const loadGiftCardActivations = visibilityFilter?.filter?.value?.includes('Gift Card Activations');

    return new Promise((resolve, reject) => {
      SettingsUtil.getMerchantSettings(props).then((res) => {
        if (_.isEmpty(taxes.geoLocation)) {
          TaxUtil.getGeoLocation(this);
        }

        const queryString = query.parse(location?.search ?? '');

        const openBatchOnLoad = queryString?.openBatch;

        let dateRange = null;
        let openTransactionsDateRange = null;
        let transactionRequestType = null;

        if (isVoidTransactionFilter && !!historicHardwareMode) {
          transactionRequestType = transactionRequestVoid;
        } else if (openBatchOnLoad) {
          dispatch(toggleOpenBatchTransactions(true));
          transactionRequestType = transactionRequestBatch;
        } else {
          dispatch(toggleOpenBatchTransactions(false));
          const startTime = (res.response && res.response.merchant_settings && res.response.merchant_settings.report_start) ? res.response.merchant_settings.report_start : '00:00:00';
          dateRange = userExperience.selectedDate.dateRange || DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, startTime);

          if (queryString?.customerId || queryString?.receiptId || transactions.showTransactionDetails || queryString?.paCustomerId || queryString?.bric) {
            if (queryString.receiptId || queryString?.bric || transactions.showTransactionDetails) {
              dateRange = userExperience.selectedDate.dateRange ? userExperience.selectedDate.dateRange : DateUtils.getSearchPreviousRange();
            } else {
              dateRange = DateUtils.getSearchPreviousRange();
            }
          }

          if (isOpenTransactionFilter) {
            openTransactionsDateRange = dateRange;
            dateRange = DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, startTime);
          }

        }

        const dispatchArray = [
          dispatch(getTransactionReceipts(user, dateRange, false, transactionRequestType, historicHardwareMode, undefined, undefined, undefined, loadDeclinedTransactions, loadOpenTransactions, loadOpenTransactionsAutoSigned, queryString?.invoiceId,  queryString?.seriesId, queryString?.paCustomerId, queryString?.customerId, queryString?.paymentLinkId, loadGiftCardActivations, queryString?.bric)),
          dispatch(getEmployees(user))
        ];

        if (isOpenTransactionFilter) {
          dispatchArray.push(dispatch(getEmployees(user)));
          dispatchArray.push(dispatch(getTransactionReceipts(user, openTransactionsDateRange, false, transactionRequestType, historicHardwareMode,false,false,false,false,false,false, queryString?.invoiceId, queryString?.seriesId, queryString?.paCustomerId, queryString?.customerId)));
        }

        Promise.all(dispatchArray).then((result ) => {
          if (transactions.showTransactionDetails === true) {
            that.setReceipt(result?.length && result[0].response[that.state.selectedIndex]);
          } else {
            this.showDetailForRow(0);
          }
          resolve();
        });

      });

    });

  }

  loadTransactionData(user, dateRange, allAccounts, type, historicHardwareMode, pageNumber, count, largeCsvExport) {
    const { visibilityFilter, dispatch, merchantSettings, location } = this.props;
    const queryString = query.parse(location?.search || '');

    let { customerId = null, paCustomerId = null, bric = null} = queryString || {};

    const isAutoClose = !Boolean(merchantSettings?.merchantSettings?.manual_capture);
    const isPaperSignature = Boolean(merchantSettings?.merchantSettings?.paper_signature_enabled);
    const isAutoSignature = isAutoClose && isPaperSignature;

    const loadDeclinedTransactions = visibilityFilter?.filter?.value?.includes('Declined Transactions');
    const isOpenTransactionFilter = visibilityFilter?.filter?.value?.includes('Open Transactions');
    const isVoidTransactionFilter = visibilityFilter?.filter?.value?.includes('Voids');
    const loadOpenTransactions = isOpenTransactionFilter && !isAutoSignature;
    const loadOpenTransactionsAutoSigned = isOpenTransactionFilter && isAutoSignature;

    const loadGiftCardActivations = visibilityFilter?.filter?.value?.includes('Gift Card Activations');

    const transactionRequestType = isVoidTransactionFilter && !!historicHardwareMode ? transactionRequestVoid : type;

    return dispatch(getTransactionReceipts(user, dateRange, allAccounts, transactionRequestType, historicHardwareMode, pageNumber, count, largeCsvExport, loadDeclinedTransactions, loadOpenTransactions, loadOpenTransactionsAutoSigned, null, null, paCustomerId, customerId, null, loadGiftCardActivations, bric));
  }

  onProcessFormClick(refundTotals) {
    const { dispatch } = this.props;
    this.setState({ refundTotals: refundTotals }, () => {
      dispatch(submit('paymentDialogForm')); // Wont submit if form is invalid
    });
  }

  showOpenBatchTransaction() {

    const { user, userExperience, dispatch } = this.props;
    const { historicHardwareMode } = userExperience;

    dispatch(toggleOpenBatchTransactions(true));
    this.loadTransactionData(user, null, false, transactionRequestBatch, historicHardwareMode, undefined, undefined, undefined);
  }

  handleDateSelection(value, dateRange) {

    const { user, userExperience, dispatch, location } = this.props;
    const { historicHardwareMode } = userExperience;

    this.props.dispatch(setTransactionReceipt(null));
    this.props.dispatch(setCustomer({}));
    this.props.history.replace(location.path);

    this.setState({ pageNumber: 1 });
    this.setState({ tooManyTransactionsToExportDialog: false });
    if (value === 'Open Batch') {
      this.showOpenBatchTransaction();
    } else {
      dispatch(toggleOpenBatchTransactions(false));
      this.setState({ dateRange: dateRange });
      this.loadTransactionData(user, dateRange, false, null, historicHardwareMode, undefined, undefined, undefined);
    }

  }

  handlePageSelection(pageNumber) {

    const { user, userExperience } = this.props;
    const { historicHardwareMode } = userExperience;

    if (this.state.pageNumber !== pageNumber) {
      this.setState({ pageNumber: pageNumber });
      const openBatchTransactions = userExperience.openBatchTransactions ? transactionRequestBatch : null;
      this.loadTransactionData(user, this.state.dateRange, false, openBatchTransactions, historicHardwareMode, pageNumber, undefined, undefined);
    }

  }

  handleFilterSelection(filter) {
    const { user, userExperience } = this.props;
    const { historicHardwareMode } = userExperience;

    if (filter.name === messages.transactionInfo.title) {
      this.setState({ openTransactionInfo: true });
    } else {
      this.props.dispatch(setTransactionReceipt(null));
      this.props.dispatch(setVisibilityFilter({
        property: 'type',
        value: filter.name
      }, 'filter'));

      if (filter.name === 'Voids' && !!historicHardwareMode) {
        this.loadTransactionData(user, this.state.dateRange, false, transactionRequestVoid, historicHardwareMode, undefined, undefined, undefined);
      }
    }
  }

  showIncompleteTransactions() {
    this.props.dispatch(setVisibilityFilter('Incomplete Transactions'));
  }

  setReceipt(receipt) {
    this.props.dispatch(setTransactionReceipt(receipt, this.props.transactions.receipts))
  }

  handleSendReceipt(values) {
    const selectedReceipt = this.props.transactions.selectedReceipt;
    if (selectedReceipt) ReceiptUtil.sendReceipt(this.props, selectedReceipt, values['email_addresses'], values['phone_numbers']);
    this.handleSendReceiptClose();
  }

  handleRemoveAutopay() {
    let that = this;
    let { rp_id: recurringPaymentId } = this.props.transactions.selectedReceipt;

    return new Promise(function (resolve, reject) {
      that.props.dispatch(deleteRecurringPayment(that.props.user, recurringPaymentId)).then(() => {
        that.loadData(that.props);
        resolve();
      });
    });
  }

  showVoidReceipt() {
    this.props.dispatch(setModalVisibility('transaction', { operationType: 'void' }));
  }

  showRefundReceipt() {
    this.props.dispatch(setModalVisibility('transaction', { operationType: 'refund' }));
  }

  showCompleteReceipt() {
    this.props.dispatch(setModalVisibility('transaction', { operationType: 'complete' }));
  }

  showCancelPreAuth() {
    this.props.dispatch(setModalVisibility('transaction', {operationType: 'cancel'}));
  }

  handleVoidClose() {

    this.props.dispatch(setModalVisibility('hidden'));

    if (this.props.userExperience.modalVisibility.showConfirmation) {
      this.loadData(this.props);
      this.handleFilterSelection({name: 'All Transactions'});
    }

  }

  remainingRefundAmount(selectedReceipt) {
    const relatedRefunds = selectedReceipt.subTransactions || [];
    const totalRefundAmount = _.sumBy(relatedRefunds, (refund) => {
      return Math.abs(refund.amount);
    });
    const totalAmount = selectedReceipt.type === 'Cash Sale' ||
      selectedReceipt.type === 'Cash Sale - Split Payment' ?
      selectedReceipt.amount : selectedReceipt.ccs_authorized_amt;
    const remainingRefund = (Math.round(parseFloat(totalAmount * 100)) - Math.round(totalRefundAmount * 100)) / 100;
    return remainingRefund;
  }

  handleRefundReceipt(formValues) {

    let that = this;
    let receipt = this.props.transactions.selectedReceipt;

    const callProcessRefund = (type) => {

      return new Promise((resolve, reject) => {

        return that.processRefund(formValues).then((refundResponse) => {

          const recordId = type === 'Cash Refund' ? refundResponse.response.id : refundResponse.response.refund_id;

          that.props.transactions.selectedReceipt.id = recordId;
          that.props.transactions.selectedReceipt.type = type;

          that.props.dispatch(setModalVisibility('transaction', { operationType: 'refund', showConfirmation: true }));
          resolve();

        }).catch(error => {
          that.props.dispatch(setModalVisibility('transaction', {
            operationType: 'refund',
            showError: true,
            errorMessage: error
          }));
          resolve();
        });

      });

    };

    return new Promise((resolve) => {

      if (receipt.type.includes('Credit Sale')) {
        that.setState({ refundAttempt: true }, () => {

          callProcessRefund('Credit Refund').then(() => {
            resolve();
          });

        });
      } else {
        callProcessRefund('Cash Refund').then(() => {
          resolve();
        });
      }

    })

  }

  processRefund(formValues) {

    const that = this;
    const receipt = this.props.transactions.selectedReceipt;
    const t = this.props.t;

    return new Promise((resolve, reject) => {

      const refundCallback = (refundResponse) => {

        const refundResponseInfo = refundResponse.response;

        const approvedCashRefund = refundResponseInfo && refundResponseInfo.unique_id && refundResponseInfo.unique_id.includes('car_');

        if (!approvedCashRefund && (!refundResponseInfo || refundResponseInfo.response_code !== 'APR')) {
          let refundError = refundResponseInfo && refundResponseInfo.error_message ? refundResponseInfo.error_message : t(messages.errors.voidRefundError);
          const statusMessage = refundResponseInfo && refundResponseInfo.status_message ? ' ' + refundResponseInfo.status_message : '';
          refundError = refundError + statusMessage;
          reject(refundError);
        } else {
          resolve(refundResponse);
        }
      };

      const isNonPaMID = receipt.uniq_id.includes('trans_');

      if (receipt.type.includes('Credit Sale')) {
        if(isNonPaMID)
           that.props.dispatch(processMerchantCreditRefund(receipt, that.props.user, formValues, that.state.refundTotals)).then(refundCallback);
        else
          that.props.dispatch(processCreditRefund(receipt, that.props.user, formValues, that.state.refundTotals)).then(refundCallback);
      } else {
        that.props.dispatch(processCashRefund(receipt, that.props.user, formValues, that.state.refundTotals)).then(refundCallback);
      }

    })

  }

  processVoid() {

    const that = this;
    const receipt = this.props.transactions.selectedReceipt
    const isNonPaMID = receipt.uniq_id.includes('trans_');
    const t = this.props.t;

    const voidType = isNonPaMID ? processMerchantCreditCardVoid : processCreditCardVoid;

    return new Promise((resolve, reject) => {
      that.props.dispatch(voidType(receipt, that.props.user))
        .then((voidResponse) => {

          const voidResponseInfo = voidResponse.response;

          if (!voidResponseInfo || voidResponseInfo.status_code !== '00') {

            let voidError = voidResponseInfo && voidResponseInfo.error_message ? voidResponseInfo.error_message : t(messages.errors.voidRefundError);
            const statusMessage = voidResponseInfo && voidResponseInfo.status_message ? ' ' + voidResponseInfo.status_message : '';
            voidError = voidError + statusMessage;

            reject(voidError);
          } else {
            resolve(voidResponse);
          }
        })
    })
  }

  handleVoidReceipt() {

    const that = this;

    return new Promise((resolve, reject) => {

      return that.processVoid().then((voidResponse) => {
        // TODO: We need to standardize theses responses from API, it's not consistent between ACH and Credit Sales
        const cancellationType = voidResponse.response?.cancellation_type ?? 'void';
        const isAch = voidResponse.response?.uniq_id?.includes('ach')
        const transactionType = isAch ? 'ACH' : 'Credit'
        const type = cancellationType === 'refund' ? `${transactionType} Refund` : transactionType === 'ACH' ? 'ACH Void' : 'Void';

        that.props.transactions.selectedReceipt.id = isAch ? voidResponse.response.id : voidResponse.response.void_id;
        that.props.transactions.selectedReceipt.type = type;

        that.props.dispatch(setModalVisibility('transaction', {
          refundAsVoid: (cancellationType === 'void' && that.state.refundAttempt),
          operationType: cancellationType,
          showConfirmation: true
        }));

        resolve();

      }).catch(error => {
        that.props.dispatch(setModalVisibility('transaction', {
          operationType: 'void',
          showError: true,
          errorMessage: error
        }));
        resolve();
      });

    });

  }

  processComplete() {

    const that = this;

    return new Promise((resolve, reject) => {
      that.props.dispatch(completeCreditCardPayment(that.props.transactions.selectedReceipt, that.props.taxes.geoLocation, that.props.user))
        .then((completeResponse) => {
          if (!completeResponse.response || completeResponse.response.response_code === 'DCL' || completeResponse.error) {
            let msg = completeResponse.error ? completeResponse.error : that.props.t(messages.errors.completePreAuthError);
            reject(msg);
          } else {
            resolve(completeResponse);
          }
        });
    })
  }

  processCancelPreAuth() {

    const that = this;

    return new Promise((resolve, reject) => {
      that.props.dispatch(cancelPreAuthPayment(that.props.transactions.selectedReceipt, that.props.taxes.geoLocation, that.props.user))
        .then((completeResponse) => {
          if (!completeResponse.response || completeResponse.response.response_code === 'DCL' || completeResponse.error) {
            let msg = completeResponse.error ? completeResponse.error : that.props.t(messages.errors.completePreAuthError);
            reject(msg);
          } else {
            resolve(completeResponse);
          }
        });
    })
  }

  handleCompleteReceipt() {

    const that = this;

    return this.processComplete().then((resp) => {
      that.props.dispatch(setModalVisibility('transaction', { operationType: 'complete', showConfirmation: true }));
    }).catch(error => {
      that.props.dispatch(setModalVisibility('transaction', {
        operationType: 'complete',
        showError: true,
        errorMessage: error
      }));
    });
  }

  handleCancelPreAuth() {

    const that = this;

    return this.processCancelPreAuth().then((resp) => {
      that.props.dispatch(setModalVisibility('transaction', {operationType: 'cancel', showConfirmation: true}));
    }).catch(error => {
      that.props.dispatch(setModalVisibility('transaction', {
        operationType: 'cancel',
        showError: true,
        errorMessage: error
      }));
    });
  }

  showSendReceipt() {
    this.props.dispatch(setModalVisibility('hidden'));
    this.setState({ showSendReceipt: true });
  }

  async handleSendReceiptClose() {
    this.setState({ showSendReceipt: false });
    await this.loadData(this.props);
    this.handleFilterSelection({name: 'All Transactions'});
  }

  handlePrintReceipt() {
    return ReceiptUtil.getPrintReceipt(this.props, this.props.transactions.selectedReceipt).catch(error => toastr.error('Error', error));
  }

  showDetailForRow(selectedIndex) {

    const { transactions } = this.props;

    if (selectedIndex >= 0 && transactions && transactions.filteredData) {
      const receipt = transactions.filteredData[selectedIndex]
      this.setReceipt(receipt);
      this.setState({ selectedIndex: selectedIndex });
    }

  }

  setOpenTransactionTipChanges(openTransactionTipChanges) {
    const openTransactionTipChangeArray = [...this.state.openTransactionTipChanges];

    const result = this.state.openTransactionTipChanges.findIndex((openTransactionTip) => openTransactionTip.id === openTransactionTipChanges.id);
    if (result !== -1) {
      openTransactionTipChangeArray[result].value = openTransactionTipChanges.value;
      openTransactionTipChangeArray[result].isInvalid = openTransactionTipChanges.isInvalid;
    } else {
      openTransactionTipChangeArray.push(openTransactionTipChanges);
    }

    this.setState({openTransactionTipChanges: openTransactionTipChangeArray})
  }

  onCloseTransactionsClick() {
    const {openTransactionTipChanges} = this.state;
    const { transactions, dispatch, user } = this.props;

    this.setState({openCloseAllTransactionsDialog: false});

    if (openTransactionTipChanges.length > 0) {
      this.setState({showPendingChangesDialog: true});
      return;
    }

    this.setState({showSpinner:true});

    const toSave = transactions.filteredData.map((transaction) => {
      return transaction?.batch_number;
    });

    dispatch(putBatches(user, toSave)).then(data => {
      this.setState({
        showSpinner:false,
        showErrorClosedOpenTransaction: data.type === actionTypes.batchesPutFailure,
        showSuccessClosedOpenTransaction: data.type === actionTypes.batchesPutSuccess
      });
    });

  }

  onSaveTipsClick() {

    const {openTransactionTipChanges} = this.state;
    const {transactions, dispatch, user} = this.props;

    const toSave = openTransactionTipChanges.map((openTransactionTipChange) => {

      const tipValue = numeral(openTransactionTipChange.value).value();
      const transaction = transactions.filteredData.find((transaction) => {
        return (transaction.id === openTransactionTipChange.id) && (transaction.tip_amount === null) && (tipValue > 0);
      });

      return transaction ? {...transaction, tip_amount: openTransactionTipChange?.value} : null;
    }).filter((transaction) => Boolean(transaction));

    this.setState({showSpinner: true});

    if (toSave.length) {
      return dispatch(updateTipTransaction(toSave, user)).then((data) => {
        this.setState({
          showSpinner: false,
          showPendingChangesDialog: false,
          showErrorSaveTipDialog: data.type === actionTypes.transactionUpdateFailure,
        });

        if (data.type === actionTypes.transactionUpdateSuccess) {
          const newOpenTransactionTipChanges = [];
          data.response.filter((transaction) => transaction.response_code === 'DCL')
            .forEach((transaction) => {

              const result = openTransactionTipChanges.findIndex((openTransactionTip => openTransactionTip.id === transaction.sale_id));

              if (result !== -1) {
                newOpenTransactionTipChanges.push(openTransactionTipChanges[result]);
              }

            });

          this.setState({openTransactionTipChanges: newOpenTransactionTipChanges})

          this.loadData(this.props);
        }
      });
    } else {
      this.setState({
        showSpinner: false,
        showPendingChangesDialog: false,
        showErrorSaveTipDialog: false,
      });
    }

  }

  onSendClick() {
    this.props.dispatch(submit('sendReceiptForm'));
  }

  closeTransactionInfo() {
    this.setState({ openTransactionInfo: false });
  }

  closeTooManyTransactionsInfo() {
    this.setState({ tooManyTransactionsToExportDialog: false });
  }

  handleExportClick(value) {

    const { transactions, userExperience, user, auth, visibilityFilter, merchantSettings } = this.props;
    const { historicHardwareMode } = userExperience;
    const showDeclinedTransactions = visibilityFilter?.filter?.value?.includes('Declined Transactions');
    const transactionTypeLabel =  showDeclinedTransactions ? '_Declined' : '';
    const labelName = LabelUtil.getLabel().text;

    const isManualClose = Boolean(merchantSettings?.merchantSettings?.manual_capture);
    const isOpenTransactionsFilter = Boolean(visibilityFilter?.filter?.value?.includes('Open Transactions'));

    const isLargeProcessor = UserUtil.isHumboltMbpOrLargeProcessor(user) || historicHardwareMode;

    const transactionsType = userExperience.openBatchTransactions ? transactionRequestBatch : transactionVerboseBRIC;

    if (isLargeProcessor && transactions.rowCount > 0) {

      if (transactions.rowCount > generalOptions.maximumTransactionExport) {
        this.setState({ tooManyTransactionsToExportDialog: true });
      } else {
        return this.loadTransactionData(user, this.state.dateRange, false, transactionsType, historicHardwareMode, null, transactions.rowCount, true).then((returned) => {
          if (returned?.response) {
            const transactionsJson = CsvUtil.createTransactionCsv(returned.response, user, auth.debtRepayment, showDeclinedTransactions);
            CsvExporter.download(transactionsJson, labelName + transactionTypeLabel + '_Transaction_' + moment().format('MMDDYYYY') + '.csv');
          }
        });
      }

    } else {
      switch (value) {
        case 'CSV File':
          if (!transactions.filteredData.length) {
            const transactionHeaders = ['Date', 'TransactionSource', 'Transaction Type', 'Account Number', 'Invoice', 'Sold By', 'Customer Name', 'Total Transaction Amount', 'Payment Amount', 'Authorized Amount', 'Tip', '$ Discount', '$ Tax', 'State Tax', 'County Tax', 'City Tax', 'Custom Tax', 'Payment Type', 'Card Brand', 'Account', 'Comment'];
            CsvExporter.download({}, labelName + transactionTypeLabel + '_Transaction_' + moment().format('MMDDYYYY') + '.csv', transactionHeaders);
          } else {
            if (isManualClose && isOpenTransactionsFilter) {
              const transactionsJson = CsvUtil.createManualCloseOpenTransactionsCsv(transactions.filteredData, user);
              CsvExporter.download(transactionsJson, labelName + transactionTypeLabel + '_OpenTransaction_' + moment().format('MMDDYYYY') + '.csv');
            } else {
              return this.loadTransactionData(user, this.state.dateRange, false, transactionsType, historicHardwareMode, null, transactions.rowCount, true).then((returned) => {
                if (returned?.response) {
                  const transactionsJson = CsvUtil.createTransactionCsv(returned.response, user, auth.debtRepayment, showDeclinedTransactions);
                  CsvExporter.download(transactionsJson, labelName + transactionTypeLabel + '_Transaction_' + moment().format('MMDDYYYY') + '.csv');
                }
              });
            }
          }
          return;
        default:
          return null;
      }
    }

  }

  closeSuccessClosedOpenTransactionDialog() {
    this.setState({showSuccessClosedOpenTransaction: false});
    this.loadData(this.props);
  }

  closeErrorClosedOpenTransactionDialog() {
    this.setState({showErrorClosedOpenTransaction: false});
  }

  closeErrorSaveTipDialog() {
    this.setState({showErrorSaveTipDialog: false});
  }

  openAdvancedSearch = () => this.setState({showAdvancedSearch: true});

  closeAdvancedSearch = () => this.setState({showAdvancedSearch: false});

  render() {

    const { showSpinner, openTransactionTipChanges } = this.state;
    const { isFetching, transactions, merchantSettings, userExperience, user, auth, visibilityFilter, t } = this.props;

    const { showConfirmation, showError } = userExperience.modalVisibility;
    const { historicHardwareMode } = userExperience;
    const { operationType } = userExperience.modalVisibility;
    const { selectedReceipt, isProcessing, isFetchingReceiptPdf, isFetching: isTransactionFetching, openReceiptsEmployees } = transactions;

    const showDeclinedTransactions = Boolean(visibilityFilter?.filter?.value?.includes('Declined Transactions'));
    const isOpenTransactionsFilter = Boolean(visibilityFilter?.filter?.value?.includes('Open Transactions'));

    const isManualClose = Boolean(merchantSettings?.merchantSettings?.manual_capture);
    const isAutoClose = !Boolean(merchantSettings?.merchantSettings?.manual_capture);
    const isPaperSignature = Boolean(merchantSettings?.merchantSettings?.paper_signature_enabled);
    const isAutoSignature = isAutoClose && isPaperSignature;
    const isOwnerOrAdmin = [auth.isOwner, auth.isAdmin].some((flag) => (Boolean(flag) === true && (isManualClose || isAutoSignature)));
    const isManagerActivityEnabled = [auth.isManager, auth.isManagerActivity].every((flag) => (Boolean(flag) === true && (isManualClose || isAutoSignature)));
    const showOpenTransactions = [isOwnerOrAdmin, isManagerActivityEnabled].some((flag) => Boolean(flag) === true);

    const isPremiumPlus = UserUtil.isPremiumPlusAccount(user);
    const isPremiumPlusWithLoyalty= UserUtil.isPremiumPlusWithLoyaltyAccount(user);
    const isPaymentLinks = isPremiumPlus || isPremiumPlusWithLoyalty;

    const appRoutePrefix = globalApplicationLabel.path;
    const isEmpty = transactions.receipts === null || merchantSettings.customReportStartTime === null;
    const readOnly = !UserUtil.isActive(user);

    const isVoidOperation = operationType === 'void';
    const isCompleteOperation = operationType === 'complete';
    const isCancelOperation = operationType === 'cancel';

    // NOTE: for the purpose of transaction display when in historic hardware mode treat like large processor (MBP/HBT)
    const isLargeProcessor = UserUtil.isHumboltMbpOrLargeProcessor(user) || historicHardwareMode;

    const pageLoading = (isEmpty && isFetching) || !this.state.initialLoad || isTransactionFetching || showSpinner || isFetchingReceiptPdf
    if (pageLoading) {
      return (
        <Page
          title={t('Transactions')}
          loading={pageLoading}
        />
      )
    }

      const ReplayIcon = IconUtil.getIcon('ReplayIcon', LabelUtil.getLabelColor());
      const SendIcon = IconUtil.getIcon('SendIcon', LabelUtil.getLabelColor());
      const PrintIcon = IconUtil.getIcon('PrintIcon', LabelUtil.getLabelColor());
      const InvoicesIcon = IconUtil.getIcon('MainMenu_InvoicesIcon', LabelUtil.getLabelColor());
      const ConfirmIcon = IconUtil.getIcon('CheckCircle', LabelUtil.getLabelColor());
      const WarningIcon = IconUtil.getIcon('Warning', '#ec251b');

    const filterMBPandHBT = [
      { name: 'All Transactions' },
      { name: 'Credit Sales' },
      { name: 'Debit Sales' },
      { name: 'Refunds' },
      { name: 'Voids' },
      { name: 'Declined Transactions', border: true },
    ];

      const filterNonPAEpx = [
        { name: 'All Transactions' },
        { name: 'Credit Sales' },
        { name: 'Debit Sales' },
        { name: 'Bank Account Sales' },
        { name: 'Refunds' },
        { name: 'Voids' },
        { name: 'Declined Transactions', border: true },
        {/* spacer */ },
        { name: messages.transactionInfo.title, icon: 'InfoIcon' }
      ];

    const giftCardSubMenus = [];

    if (merchantSettings?.merchantSettings?.generic_gc_enabled) {
      giftCardSubMenus.push(
        { name: 'Gift Card Sales' },
        { name: 'Gift Card Activations' }
      );
    }

      const filterDataPA = [
        { name: 'All Transactions' },
        showOpenTransactions ? { name: 'Open Transactions'}: undefined,
        { name: 'All Sales' },
        { name: 'Credit Sales' },
        { name: 'Debit Sales' },
        { name: 'Cash Sales' },
        { name: 'Bank Account Sales' },
        { name: 'Pre-Auths' },
        { name: 'Virtual Terminal' },
        { name: 'Invoices' },
        ...giftCardSubMenus,
        { name: 'Refunds' },
        { name: 'Voids' },
        { name: '1099K' },
        { name: 'Declined Transactions', border: true },
      ].filter((filter) => Boolean(filter));

    if (merchantSettings?.merchantSettings?.ebt_enabled) {
      filterMBPandHBT.splice(3, 0, { name: 'EBT Sales' });
      filterNonPAEpx.splice(3, 0, { name: 'EBT Sales' });
      filterDataPA.splice(5, 0, { name: 'EBT Sales' });
    }


    if (isPaymentLinks) {
      const insertIndex = filterDataPA.findIndex(data => data.name === 'Invoices') + 1;
      filterDataPA.splice(insertIndex, 0, { name: 'Payment Links' });
    }

    const isVtCapable = UserUtil.isVtCapable(user, auth, merchantSettings);

    if (isVtCapable) {

      const insertVT = filterArray => {
        const indexVT = filterArray.findIndex(filter => filter.name === 'Refunds');
        filterArray.splice(indexVT, 0, {name: 'Virtual Terminal'});
      }

      insertVT(filterMBPandHBT);
      insertVT(filterNonPAEpx);
    }

    const refundVoidValidSources = [
      'PayAnywhere iOS',
      'Phone Swipe iOS',
      'PayAnywhere Android',
      'PayAnywhere Portal',
      'PaymentsHub Portal',
      'Phone Swipe Portal',
      'PayAnywhere iOS SDK',
      'Phone Swipe iOS SDK',
      'Mobile App',
      'PayAnywhere Android SDK',
      'Phone Swipe Android SDK',
      'Phone Swipe Android',
      'PayAnywhere Android Storefront',
      'Phone Swipe Android Storefront',
      'PayAnywhere Shop',
      'Payanywhere iOS',
      'Payanywhere Android',
      'Payanywhere Portal',
      'Payanywhere iOS SDK',
      'Payanywhere Android SDK',
      'Payanywhere Android Storefront',
      'Payments Hub',
      'Payanywhere Smart Terminal',
      'Payanywhere Smart POS',
      'Payanywhere Smart Flex',
      'Payanywhere Smart Terminal Mini',
      'Payanywhere Smart POS+',
      'Payanywhere Smart Keypad',
      'Payanywhere Smart PINPad Pro',
      'Payanywhere JS SDK',
      'Payanywhere Smart POS Mini',
      'Payanywhere Smart Flex (Mini)',
      'North Hub Portal'
    ];

    const styles = {
      transactionInfoStyle: {
        width: '60%',
        minWidth: '480px',
        maxWidth: 'none'
      },
      textStyle: {
        paddingBottom: 15
      },
      titleStyle: {
        paddingBottom: 50
      },
      exportDialog: {
        width: '20%',
      },
      closeTransactionInfoDialog: {
        minWidth: '400px',
        width: '20%',
      }
    };

    const hasTransactions = transactions.filteredData && transactions.filteredData.length > 0
    const userType = UserUtil.userType(user);
    const isMBPorHBTUser = userType === 'MBP' || userType === 'HBT';
    const nonPaEpx = UserUtil.isEPX(user) && userType !== 'PA';
    const filterData = nonPaEpx ? filterNonPAEpx : isMBPorHBTUser ? filterMBPandHBT : filterDataPA;
    const upperCaseRefundVoidValidSources = refundVoidValidSources.map((source) => source.toUpperCase());
    const isNonPaMID = selectedReceipt?.uniq_id?.includes('trans_');
    const isEpxNonPATransaction = isNonPaMID && nonPaEpx;
    const isHybridMerchant = (userType === 'Hybrid' || userType === 'PA') && isNonPaMID;
    const sourceCanRefundAndVoid = selectedReceipt?.txn_source && upperCaseRefundVoidValidSources.includes(selectedReceipt.txn_source.toUpperCase()) || isEpxNonPATransaction || isHybridMerchant;
    const submitButtonDisabled = !userExperience.enableFormSubmitButton && isProcessing;
    const noDataText = nonPaEpx ? null : t('NoDataText');
    const noDataComponent = nonPaEpx && (
      <div className='noDataMessage'>
        {t('NoDataHint')}
        <span
            className='linkLike'
            onClick={this.showOpenBatchTransaction}
        >
          {t('NoDataHintHere')}
        </span>
      </div>
    );


    const handleCancelPayment = isVoidOperation
      ? this.handleVoidReceipt
      : isCompleteOperation
        ? this.handleCompleteReceipt
        : isCancelOperation
          ? this.handleCancelPreAuth
          : this.handleRefundReceipt;

    const cancelPaymentActions = {
      process: {
        label: isVoidOperation ? t('Void') : isCompleteOperation ? t('Complete') : isCancelOperation ? t('Cancel Pre-Auth') : t('Refund'),
        onClick: this.onProcessFormClick
      },
      cancel: {
        label: t('Cancel'),
        onClick: this.handleVoidClose
      }
    };

    const confirmActions = {
      process: {
        label: t('SendReceipt'),
        onClick: this.showSendReceipt
      },
      cancel: {
        label: t('PrintReceipt'),
        onClick: this.handlePrintReceipt
      }
    };

    const failureActions = {
      process: {
        label: t('Done'),
        onClick: this.handleVoidClose
      },
      cancel: {
        label: t('Close'),
        onClick: this.handleVoidClose
      }
    };

    const sendReceiptForm = (
      <SendReceiptForm
          onSubmit={this.handleSendReceipt}
          t={t}
          user={user}
      />);

    const sendReceiptDialog = (
      <MessageDialog
          bodyText={sendReceiptForm}
          cancelText={t('Cancel')}
          closeAction={this.handleSendReceiptClose}
          confirmText={t('Send')}
          isChoiceRequired={false}
          onConfirm={this.onSendClick}
          onRequestClose={this.handleSendReceiptClose}
          open={this.state.showSendReceipt}
          scrollable
          titleText={t('SendReceipt')}
      />
    );

    const defaultDateFilterValue = userExperience.openBatchTransactions ? 'Open Batch' : this.state.dateRange.text;

    const countComplete = !showDeclinedTransactions && _.countBy(transactions.filteredData, (complete) => {
      return complete.is_complete;
    });

    const countCompleteValue = countComplete.false;

    const incompleteTransactionsFilter = countComplete.false ? (
      <div className='incompleteTransactionsFilter'>
        <div className='filterIconContainer'>
          {IconUtil.getIcon('AlertErrorIcon')}
        </div>
        <div className={MobileUtil.isMobileDevice() ? 'filterMessageContainer mobile' : 'filterMessageContainer'}>
          <div className='filterMessageTitle'>
            {`${countCompleteValue} ${t('IncompleteTransaction')} ${countCompleteValue > 1 ? t('Transactions') : t('Transaction')}`}
          </div>
          <div className='filterMessageDetails'>
            {t('IncompleteFilterMessage')}
          </div>
          <div className='filterLinkContainer' onClick={this.showIncompleteTransactions}>
            <ReverseButton
                label={t('View')}
                onClick={this.showIncompleteTransactions}
            />
          </div>
        </div>
      </div>) : null;

    const totalAmount = selectedReceipt ? selectedReceipt.type === 'Cash Sale' ? selectedReceipt.amount : selectedReceipt.ccs_authorized_amt : false;
    let refundableAmount = selectedReceipt && numeral(selectedReceipt.refunded_amount || 0).difference(parseFloat(totalAmount || 0));

    let hasRemainingRefund =  false;

      if (selectedReceipt && parseFloat(totalAmount) > 0) {
        hasRemainingRefund = refundableAmount > 0;
      }

      const canVoidTypes = ['Credit Sale', 'Paid Invoice', applicationConstants.achSale];
      const canRefundTypes = ['Credit Sale', 'Paid Invoice', 'Cash Sale', applicationConstants.achSale];
      const achTypes = ['checking', 'savings'];
      const isAchSale = selectedReceipt?.type === applicationConstants.achSale;

      const receiptTypeIsVoidable = selectedReceipt?.type && !_.isEmpty(canVoidTypes.filter(validType => selectedReceipt?.type?.includes(validType)));
      const receiptTypeIsRefundable = selectedReceipt?.type && !_.isEmpty(canRefundTypes.filter(validType => selectedReceipt?.type?.includes(validType)));
      const noPreviousRefund = totalAmount && refundableAmount === parseFloat(totalAmount);

      const canVoidCC = selectedReceipt?.network && sourceCanRefundAndVoid &&
        receiptTypeIsVoidable &&
        moment(selectedReceipt?.datetime).isSame(today, 'day') &&
        selectedReceipt?.network !== 'AliPay' && !selectedReceipt?.void_uniq_id && hasRemainingRefund && noPreviousRefund;

      const canVoidACH = isAchSale && selectedReceipt?.is_ach_batch_open &&
        sourceCanRefundAndVoid && receiptTypeIsVoidable && !selectedReceipt?.settled_date  &&
        !selectedReceipt?.void_uniq_id && hasRemainingRefund && noPreviousRefund;

      const canVoid = canVoidCC || canVoidACH;

      const canRefundCC = !achTypes.includes(selectedReceipt?.cc_type?.toLowerCase()) && sourceCanRefundAndVoid &&
        receiptTypeIsRefundable &&
        !selectedReceipt?.void_uniq_id && hasRemainingRefund;

      const canRefundACH = isAchSale && sourceCanRefundAndVoid &&
        receiptTypeIsRefundable && !canVoidACH &&
        !selectedReceipt?.void_uniq_id && hasRemainingRefund;

      const canRefundAchDisabled = (isAchSale && !selectedReceipt?.settled_date);

      const canRefund = canRefundCC || canRefundACH;

      const canCompleteOrCancel = selectedReceipt && selectedReceipt.type === 'Pre Auth' && moment().subtract(7, 'days').isBefore(selectedReceipt.datetime);

    const detailOptions = transactions.selectedReceipt && !isNonPaMID ? [
      {
        onClick: this.showSendReceipt,
        text: t('SendReceipt'),
        icon: SendIcon
      },
      {
        onClick: this.handlePrintReceipt,
        text: t('PrintReceipt'),
        icon: PrintIcon
      },
    ] : [];

      if ((canRefund && !readOnly) && transactions.selectedReceipt) {
        detailOptions.push({
          onClick: this.showRefundReceipt,
          text: t('RefundTransaction'),
          icon: ReplayIcon,
          disabled: canRefundAchDisabled,
          tooltip: canRefundAchDisabled ? t('RefundAchDisabled') : null
        });
      }

      if ((canVoid && !readOnly) && transactions.selectedReceipt) {
        detailOptions.push({
          onClick: this.showVoidReceipt,
          text: t('VoidTransaction'),
          icon: ReplayIcon,
        });
      }

    if ((canCompleteOrCancel) && (transactions.selectedReceipt && !isNonPaMID)) {
      detailOptions.push({
        onClick: this.showCancelPreAuth,
        text: t('CancelPreAuth'),
        icon: ReplayIcon
      });
      detailOptions.push({
        onClick: this.showCompleteReceipt,
        text: t('CompletePreAuth'),
        icon: ConfirmIcon
      });
    }

    const declinedHeading = showDeclinedTransactions ? `${t('Declined')} ` : '';
    const detailHeading = TransactionsUtil.getTransactionType(selectedReceipt, showDeclinedTransactions);

    const amount = transactions.selectedReceipt && numeral(transactions.selectedReceipt.amount).format('$0,0.00');
    const date = transactions.selectedReceipt && moment(transactions.selectedReceipt.datetime).format('MMMM D, YYYY') + ' ' + moment(transactions.selectedReceipt.datetime).format('h:mm a');

    if (detailHeading === 'Paid Invoice' && !(auth.isManager && !auth.isManagerActivity)) {
      detailOptions.push({
        text: t('ViewInvoice'),
        href: `${appRoutePrefix}${routes.business.root}${routes.business.invoices}?receiptId=${selectedReceipt.invoice}`,
        icon: InvoicesIcon
      });
    }

    const newDetail = !isOpenTransactionsFilter && transactions.selectedReceipt ? (
      <DetailPanel
        heading={`${declinedHeading}${amount} ${t(TextUtil.formatTextToKey(detailHeading))}`}
        subheading={date}>
        {
          showDeclinedTransactions && (
            <div className='declinedErrorBox'>
              <span className='icon'>{WarningIcon}</span>
              <span className='errorLabel'>
                {transactions?.selectedReceipt?.status_message?.length > 0 && transactions?.selectedReceipt?.status_message?.toLowerCase()}
              </span>
            </div>
          )
        }
        <DetailPanelOptions options={detailOptions} />
        <TransactionDetail
          {...this.props}
          transactionRow={this.state.selectedIndex}
          removeAutopay={this.handleRemoveAutopay}
          loadData={this.loadData} />
      </DetailPanel>
    ) : null;

    const searchBar = (
      <SearchBarAccessory {...this.props} searchPlaceholder={t('Search')} t={t} />
    );

    const filterPanel = (
      <FilterPanel
        {...this.props}
        filterData={filterData}
        selectFilterCallback={this.handleFilterSelection}
      />
    );

    const transactionInfo = (
      <Modal
          cancelText={t('Close')}
          hideConfirmButton
          onClose={this.closeTransactionInfo}
          open={this.state.openTransactionInfo}
          title={t('TransactionInfo.Title')}
      >
        <div className='transactionInfo'>
          <p>{t('TransactionInfo.Details')}</p>
        </div>
      </Modal>
    );

    const exportingDialog = (
      <Modal
          actions={[]}
          open={transactions.isExporting}
          title={t('ExportingDialogTitle')}
      >
        <div className='exportProgress'>
          <CircularProgress />
        </div>
      </Modal>
    );

    const maxTransactions = numeral(generalOptions.maximumTransactionExport).format('0,0');

    const tooManyTransactionDialog = (
      <Modal
        confirmText={t('Okay')}
        onConfirm={this.closeTooManyTransactionsInfo}
        open={this.state.tooManyTransactionsToExportDialog}
        title={t('TooManyTransactionDialogTitle')}
        hideCancelButton
      >
        <div className='exportProgress'>
          <div className='transactionInfo'>
            <Trans i18nKey={'TooManyTransactionDialogTransactionInfo'}>
              <p>The amount of data returned in your selection exceeds the {{maxTransactions}} row limit. Please select a smaller date range.</p>
            </Trans>
          </div>
        </div>
      </Modal>
    );

    const openTransactionsLength = openTransactionTipChanges.length;
    const pendingChangesDialog = (
      <Modal
          cancelText={t('Cancel')}
          confirmText={t('Save')}
          onClose={() => this.setState({ showPendingChangesDialog: false })}
          onConfirm={this.onSaveTipsClick}
          open={this.state.showPendingChangesDialog}
          title={t('PendingChangesTitle')}
      >
        <div className='exportProgress'>
          <div className='transactionInfo'>
            <Trans
                count={openTransactionTipChanges.length}
                i18nKey={'PendingChangesInfo'}
            >
              <p>You have {{openTransactionsLength}} unsaved changes.</p>
            </Trans>
          </div>
        </div>
      </Modal>
    );

    const successClosedOpenTransactionDialog = (
      <Modal
          autoScrollBodyContent={false}
          className={'Dialog'}
          confirmText={t('Okay')}
          contentStyle={styles.closeTransactionInfoDialog}
          modal
          onConfirm={this.closeSuccessClosedOpenTransactionDialog}
          open={this.state.showSuccessClosedOpenTransaction}
          title={t('CloseTransactions')}
          titleClassName='transactionInfoTitle'
          titleStyle={styles.titleStyle}
      >
        <div className='exportProgress'>
          <div className='transactionInfo'>
            <p>{t('SuccessModalInfo')}</p>
          </div>
        </div>
      </Modal>
    );

    const errorClosedOpenTransactionDialog = (
      <Modal
          autoScrollBodyContent={false}
          className={'Dialog'}
          confirmText={t('Okay')}
          contentStyle={styles.closeTransactionInfoDialog}
          modal
          onConfirm={this.closeErrorClosedOpenTransactionDialog}
          open={this.state.showErrorClosedOpenTransaction}
          title={t('CloseTransactions')}
          titleClassName='transactionInfoTitle'
          titleStyle={styles.titleStyle}
      >
        <div className='exportProgress'>
          <div className='transactionInfo'>
            <p>{t('ErrorModalInfo')}</p>
          </div>
        </div>
      </Modal>
    );

    const errorSaveTipDialog = (
      <Modal
          autoScrollBodyContent={false}
          className={'Dialog'}
          contentStyle={styles.closeTransactionInfoDialog}
          modal
          onConfirm={this.closeErrorSaveTipDialog}
          open={this.state.showErrorSaveTipDialog}
          title={t('ErrorSaveTipModalTitle')}
          titleClassName='transactionInfoTitle'
          titleStyle={styles.titleStyle}
      >
        <div className='exportProgress'>
          <div className='transactionInfo'>
            <p>{t('ErrorSaveTipModalInfo')}</p>
          </div>
        </div>
      </Modal>
    );

    const closeAllTransactionsConfirmationDialog = (
      <MessageDialog
          bodyText={
          <p className='confirmationText'>{t('CloseAllTransactionsInfo')}</p>
        }
          cancelText={t('Cancel')}
          confirmText={t('CloseAll')}
          externalClassName='closeAllTransactionsConfirmationDialog'
          isChoiceRequired
          onConfirm={this.onCloseTransactionsClick}
          onRequestClose={this.closeCloseAllTransactionsDialog}
          open={this.state.openCloseAllTransactionsDialog}
          scrollable
          titleText={t('CloseAllTransactionsTitle')}
      />
    );

    const refundDialogActions = showConfirmation ? confirmActions : showError ? failureActions : cancelPaymentActions;

    const numberOfPages = isLargeProcessor && (transactions.rowCount > transactions.pageSize) ? (transactions.rowCount + transactions.pageSize - 1) / transactions.pageSize : 0;

    const paginationComponent = isLargeProcessor && numberOfPages > 0 ? (
      <Pagination rowCount={transactions.rowCount}
        pageSize={transactions.pageSize}
        initialPage={this.state.pageNumber}
        onChangePage={this.handlePageSelection} />
    ) : null;

    const paymentDialog = (
      <PaymentDialog
        {...this.props}
        dialogActions={refundDialogActions}
        closeAction={cancelPaymentActions.cancel.onClick}
        submitButtonDisabled={submitButtonDisabled}
        onSubmit={handleCancelPayment}
        isSimplifiedDialog={canCompleteOrCancel || isEpxNonPATransaction || isHybridMerchant}
      />
    );

    const forceOpenDetails = (transactions.showTransactionDetails === true) ? true : this.state.forceDetailOpen;

    const headerToShow = isOpenTransactionsFilter ? <OpenTransactionsSummaryHeader {...this.props}/> :
      (incompleteTransactionsFilter ? incompleteTransactionsFilter : <TransactionsSummaryHeader {...this.props} isSummary />);
    const activityTransactionsClass = userExperience.showHistoricHardwareModeNotice ? 'activityTransactionsWithNotice ' : 'activityTransactions ';

    const specialColumns = showDeclinedTransactions ? {
      show: ['status_message', 'type']
    } : isOpenTransactionsFilter ? {
        show: isPaperSignature ? ['tip_amount']: [],
        hide: []
      }: null;

    const tipsInvalid = openTransactionTipChanges.some((tip) => tip.isInvalid);
    const saveTipsButtonDisabled = !isOpenTransactionsFilter || openTransactionTipChanges.length === 0 || tipsInvalid;
    const closeTransactionsButtonDisabled = !isOpenTransactionsFilter || !hasTransactions || tipsInvalid;

    const bottomBar = isOpenTransactionsFilter ? (
      <div className='detailPanelHolderButtons saveContainer'>
        <div className='openTransactionsBottomBar'>
          <div>
            {tipsInvalid && (
              <span className={'errorMessage'}>{t(messages.errors.openTransactions.tipExceedsLimit)}</span>
            )}
            {isPaperSignature && (
              <ReverseButton disabled={saveTipsButtonDisabled}
                  label={t('SaveTips')}
                  onClick={this.onSaveTipsClick}
              />
            )}
            {isManualClose && (
              <Button disabled={closeTransactionsButtonDisabled}
                  label={t('CloseAllTransactions')}
                  onClick={this.displayCloseAllTransactionsDialog}
              />
            )}
          </div>
        </div>
      </div>
    ) : null;

    const advancedSearchAccessory = (
      <IconButtonAccessory
        onClick={this.openAdvancedSearch}
        icon={IconUtil.getIcon('SliderIcon', '#404143')}
      />
    );

    const pageAccessories = [
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
      {
        name: 'advancedSearch',
        accessory: advancedSearchAccessory,
        showInMobile: true,
        hide: false
      },
      {
        name: 'openBatchButton',
        accessory: (
          <ButtonAccessory
            onClick={this.showOpenBatchTransaction}
            endIcon={IconUtil.getIcon('BatchIcon', LabelUtil.getLabelColor())}
          >{t('OpenBatchButton')}</ButtonAccessory>
        ),
        showInMobile: true,
        hide: !(UserUtil.isEPX(user) && userType !== 'PA')
      },
      {
        name: 'employeeFilter',
        accessory: (
          <EmployeeFilterAccessory
            {...this.props}
            employees={{data: openReceiptsEmployees}}
          />
        ),
        showInMobile: true,
        hide: !isOpenTransactionsFilter
      },
      {
        name: 'dateFilter',
        accessory: (
          <DateFilterAccessory
            {...this.props}
            defaultValue={defaultDateFilterValue}
            handleSelection={this.handleDateSelection}
            showAllTime={false}
            showOpenBatch={nonPaEpx}
            showPreviousCustomSelection={false}
            disableCompactVersion={true}
            dataName={'Transactions data'}
          />
        ),
        showInMobile: true,
        hide: isOpenTransactionsFilter
      },
      {
        name: 'exportButton',
        accessory: (
          <ExportImportAccessory
            exportTitle={t('ExportTransactions')}
            handleSelection={this.handleExportClick}
          />
        ),
        showInMobile: true,
        hide: false
      },
    ];

    const advancedSearch = (
      <Dialog
        id='advancedSearchDialog'
        open={this.state.showAdvancedSearch}
        className='dialogBody dialogRootContainer inventoryFiltersDialog detailDialog'
        fullScreen
        onClose={this.closeAdvancedSearch}
      >
        <Box sx={closeIcon} onClick={this.closeAdvancedSearch}>
          {IconUtil.getIcon('Close', '#404143', '25px')}
        </Box>
        <AdvancedSearch t={t} onFilter={this.closeAdvancedSearch} />
      </Dialog>
    );

    return (
      <section className={activityTransactionsClass}>
        <div>
          {(isProcessing || isFetchingReceiptPdf) ? <UpdateSpinner /> : paymentDialog}
        </div>
        {sendReceiptDialog}
        {transactionInfo}
        {exportingDialog}
        <MasterDetailLayout
          {...this.props}
          pageTitle={t('Transactions')}
          pageLoading={pageLoading}
          pageAccessories={pageAccessories}
          containerClass={isOpenTransactionsFilter ? 'bottomBarActivated' : ''}
          detailDisplayCallback={this.showDetailForRow}
          dynamicLoading='transactions'
          enableRowSelection={isOpenTransactionsFilter}
          enableTipChanges={isPaperSignature}
          filterPanel={filterPanel}
          forceOpenDetail={forceOpenDetails}
          hasMobileScrollNote={hasTransactions}
          isLargeProcessor={isLargeProcessor}
          noDataComponent={noDataComponent}
          noDataMessage={noDataText}
          openTransactionTipChanges={openTransactionTipChanges}
          paginationComponent={paginationComponent}
          setOpenTransactionTipChanges={this.setOpenTransactionTipChanges}
          specialColumns={specialColumns}
          tableData={transactions.filteredData}
          titleComponent={headerToShow}
          showDeclinedTransactions={showDeclinedTransactions}
        >
          {newDetail}
        </MasterDetailLayout>
        {tooManyTransactionDialog}
        {pendingChangesDialog}
        {successClosedOpenTransactionDialog}
        {errorClosedOpenTransactionDialog}
        {errorSaveTipDialog}
        {closeAllTransactionsConfirmationDialog}
        {bottomBar}
        {advancedSearch}
      </section>
    )

  }
}

Transactions.defaultProps = {
  isFetching: true
};
