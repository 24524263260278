import React from 'react';

import moment from 'moment';

const LastLoginActivity = ({ lastLogin, t }) => {

  const lastActivity = lastLogin
    ? moment(lastLogin).local().format('MM/DD/YYYY h:mm a')
    : 'N/A';

  return (
    <div className='lastLoginActivity'>
      <div className='formHeading'>{t('LastLoginActivityHeader')}</div>
      <div className='lastLoginActivityContainer'>
        {lastLogin && <span className='lastLoginStatus'/>}
        {lastActivity}
      </div>
    </div>
  )
}

export default LastLoginActivity;
