/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import numeral from 'numeral';
import CustomCheckbox from '../Checkbox';
import moment from 'moment';
import FormatTextUtils from '../../util/FormatTextUtil';
import withLayoutBreakPoints from '../../util/WithLayoutBreakPoints';

export const InvoiceSummary = ( { invoice, onClick, showCheckbox, checkboxClick, checkboxInput, actionItem, t, LayoutBreakPoints }) => {
  const { isPaid, isZeroAmountInvoice, isInvoiceFromSeries } = invoice.statusList;
  const isAutoPay = isInvoiceFromSeries && invoice.series?.auto_pay;

  let leftTop, leftBottom, rightTop, rightBottom;

  const name = invoice.name;
  const amount = numeral(invoice.total_amt).format('$0,0.00');
  const maskedCardNumber = FormatTextUtils.formatMaskedCardNumber(invoice.payment_first6, invoice.payment_last4, invoice.payment_network);
  const network = invoice?.payment_network &&
    `${isAutoPay ? t('AutopayCard', { processor: invoice.payment_network, lastDigits: maskedCardNumber}) : `${invoice.payment_network} ${maskedCardNumber}`}`;

  if (isPaid && !isZeroAmountInvoice) {
    const paidDate = `${t('PaidDate')}${moment(invoice.paid_date).format('ll')}`;

    leftTop = name;
    leftBottom = paidDate;
    rightTop = amount;
    rightBottom = network || t('CashSale');

  } else {
    const recurringInfo = isInvoiceFromSeries && FormatTextUtils
      .getInvoiceRepeat(invoice.series?.frequency, invoice.series?.end_date, 'll');

    let dueDate = invoice.due_date;

    leftTop = name;
    leftBottom = recurringInfo;
    rightTop = amount;
    rightBottom = isAutoPay ? network : t('InvoiceSummary.Due', { date: moment.utc(dueDate).format('ll') });
  }

  if (invoice?.statusList.isPartiallyPaid) {
    rightTop = t('InvoiceSummary.BalanceRemaining', { amount: numeral(invoice?.unpaid_amount).format('$0,0.00') })
  }

  const isMobileOrTabletLayout = LayoutBreakPoints?.isMaxTablet;

  return (
    <div className='invoiceSummary' onClick={onClick} >
      <div className='leftSide'>
        {showCheckbox && (
          <div onClick={checkboxClick}>
            <CustomCheckbox input={{value: checkboxInput}} />
          </div>
        )}
        <div className='info'>
          <div className='mainInfo'>{leftTop}</div>
          {leftBottom && (
            <span className='subtitle'>{leftBottom}</span>
          )}
          {(rightBottom && isMobileOrTabletLayout) && (
            <p className='subtitle mobile'>{rightBottom}</p>
          )}
        </div>
      </div>
      <div className='rightSide'>
        <div className='info'>
          <span className='mainInfo'>{rightTop}</span>
          {(rightBottom && !isMobileOrTabletLayout) && (
            <span className='subtitle'>{rightBottom}</span>
          )}
        </div>
        {actionItem && (
          <div className='menuContainer'>
            {actionItem}
          </div>
        )}
      </div>
    </div>
  );

}

export default withLayoutBreakPoints(InvoiceSummary);

